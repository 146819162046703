<template>
  <svg
    id="graph"
    class="conversionTrendsGraph"
    :width="chartWidth"
    :height="chartHeight"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs"
  >
    <g id="yAxis">
      <template v-for="(data, i) in yAxisData">
        <text
          :key="'yAxisText' + i"
          :y="getYAxisTextY(i)"
          :x="yAxisTextX"
          class="conversionTrendsGraph__axisText conversionTrendsGraph__yAxisText"
        >
          {{ data }}
        </text>

        <path
          :key="'yAxisLine' + i"
          :d="getYAxisLine(i)"
          class="conversionTrendsGraph__yAxisLine"
        />
      </template>
    </g>

    <g id="xAxis">
      <template v-for="(data, i) in xAxisData">
        <ChartVisitBar
          :key="'xAxisBar' + i"
          :width="10"
          :height="data.height"
          :y="data.y"
          :x="data.x"
          :fill="barColor"
          :show-stroke="false"
          @click="onClick(data.dailyCount)"
          @mouseenter="onMouseEnter(data)"
          @mouseleave="onMouseLeave"
        />

        <text
          :key="'xAxisText' + i"
          :y="xAxisTextY"
          :x="getXAxisTextX(data.x)"
          class="conversionTrendsGraph__axisText conversionTrendsGraph__xAxisText"
        >
          {{ data.dailyCount.displayDate }}
        </text>
      </template>
    </g>

    <path :d="baselinePath" class="conversionTrendsGraph__baseline" />
  </svg>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ConversionTrendDailyCount } from "@/models/conversion-trend/ConversionTrendDailyCount";
import { Colors } from "@/const/Colors";
import ChartVisitBar from "@/components/chart/ChartVisitBar.vue";

export const BAR_AREA_WIDTH = 210;
export const BAR_AREA_HEIGHT = 65;
export const Y_AXIS_WIDTH = 40;
export const Y_AXIS_MARGIN = 14;
export const X_AXIS_START = 10 + Y_AXIS_WIDTH;
export const X_AXIS_HEIGHT = 10;
export const X_AXIS_MARGIN = 20;
export const FONT_SIZE = 10;
export const LOWER_LIMIT = 0;
export const BAR_WIDTH = 10;
export const BAR_MAX_HEIGHT = 57;
export const BAR_MIN_Y = 5;

export interface XAxisData {
  dailyCount: ConversionTrendDailyCount;
  x: number;
  y: number;
  height: number;
}

@Component({
  components: { ChartVisitBar }
})
export default class ConversionTrendsGraph extends Vue {
  @Prop({ type: Array, required: true })
  data!: ConversionTrendDailyCount[];

  onClick(data: ConversionTrendDailyCount) {
    this.$emit("click", data);
  }

  onMouseEnter(data: XAxisData) {
    this.$emit("mouseenter", data);
  }

  @Emit("mouseleave")
  onMouseLeave() {}

  chartWidth = BAR_AREA_WIDTH + Y_AXIS_WIDTH;
  chartHeight = BAR_AREA_HEIGHT + X_AXIS_HEIGHT;

  barColor = Colors.Cv;

  yAxisTextX = Y_AXIS_WIDTH - 3;

  xAxisTextY = this.chartHeight;

  get upperLimit(): number {
    const values = this.data.map(data => data.count);
    const max = Math.max(...values);

    return this.getUpperLimit(max);
  }

  get yAxisData(): number[] {
    const percentile25 = this.upperLimit * 0.25;
    const percentile50 = this.upperLimit * 0.5;
    const percentile75 = this.upperLimit * 0.75;

    return [
      LOWER_LIMIT,
      percentile25,
      percentile50,
      percentile75,
      this.upperLimit
    ];
  }

  get xAxisData(): XAxisData[] {
    const result: XAxisData[] = [];

    this.data.forEach((data, i) => {
      const x =
        i === 0 ? X_AXIS_START : result[i - 1].x + BAR_WIDTH + X_AXIS_MARGIN;
      const percentage = data.count / this.upperLimit;
      const height = Math.round(BAR_MAX_HEIGHT * percentage);
      const y = BAR_MAX_HEIGHT - height + BAR_MIN_Y;

      const value = {
        dailyCount: data,
        x,
        height,
        y
      };

      result.push(value);
    });

    return result;
  }

  get baselinePath(): string {
    return `M ${Y_AXIS_WIDTH} ${BAR_AREA_HEIGHT - FONT_SIZE / 2 + 2} H ${
      this.chartWidth
    }`;
  }

  getYAxisLine(index: number): string {
    const y = BAR_AREA_HEIGHT - FONT_SIZE / 2 + 2 - Y_AXIS_MARGIN * index;

    return `M ${Y_AXIS_WIDTH} ${y} H ${this.chartWidth}`;
  }

  getYAxisTextY(index: number): number {
    return BAR_AREA_HEIGHT - Y_AXIS_MARGIN * index;
  }

  getXAxisTextX(data: number): number {
    return data + BAR_WIDTH / 2;
  }
  getUpperLimit(value: number): number {
    // 最大値（全データ）が0の場合など4未満を受け取った場合、4を返す
    if (value < 4) {
      return 4;
    }

    // 234の場合に100、3456の場合に1000など、数値のオーダー
    let value_order = 10 ** (String(value).length - 1);

    // valueが1桁の場合、2桁で最初の4の倍数で12を返す
    if (value_order === 1) {
      return 12;
    }

    // valueが2桁の場合、20の倍数を返す（3桁以上は常に4の倍数）
    if (value_order === 10) {
      value_order = 20;
    }

    return Math.ceil(value / value_order) * value_order;
  }
}
</script>

<style lang="scss" scoped>
.conversionTrendsGraph__yAxisLine {
  stroke: $colorBase400;
  stroke-width: 1;
}

.conversionTrendsGraph__axisText {
  font-size: 10px;

  fill: $colorBase700;
}

.conversionTrendsGraph__yAxisText {
  text-anchor: end;
}

.conversionTrendsGraph__xAxisText {
  line-height: 1;

  text-anchor: middle;
}

.conversionTrendsGraph__baseline {
  stroke: $colorBlue900;
  stroke-width: 1;
}
</style>
