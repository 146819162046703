import store from "@/store";
import { Route } from "vue-router";
import { isGuestUser } from "@/util/common-util";

export function validateTourPermission(
  _to: Route,
  _from: Route,
  next: Function
) {
  if (
    store.state.client.client &&
    !tourValidClients.includes(store.state.client.client.id)
  ) {
    next({ name: "home" });
  } else if (isGuestUser(store.state.auth.user)) {
    next({ name: "home" });
  } else {
    next();
  }
}

const localEnvironmentClients = [1001];
// eslint-disable-next-line prettier/prettier
const internalClients: number[] = [27, 121, 145, 184, 218, 262, 306, 366, 371, 376];
// eslint-disable-next-line prettier/prettier
export const VALID_CLIENTS_FOR_ERROR_TOUR: number[] = localEnvironmentClients.concat([20, 37, 48, 90, 119, 159, 162, 185, 202, 203, 205, 211, 220, 250, 269, 296, 323, 333, 349, 355, 362, 364, 373, 381, 386, 390, 409, 410, 425, 432, 451, 459, 462, 465, 467]);
// eslint-disable-next-line prettier/prettier
export const VALID_CLIENTS_FOR_MULTI_INFLOW_TOUR: number[] = localEnvironmentClients.concat([3, 10, 19, 20, 32, 36, 44, 46, 48, 82, 90, 93, 119, 120, 148, 150, 153, 159, 162, 177, 185, 199, 202, 203, 205, 211, 220, 222, 226, 246, 250, 269, 274, 286, 295, 296, 314, 323, 328, 333, 349, 355, 358, 362, 364, 369, 373, 375, 378, 381, 386, 390, 403, 409, 410, 413, 419, 421, 424, 425, 428, 429, 432, 433, 442, 445, 446, 449, 451, 459, 462, 465, 467, 469]);

export const getTourValidClients = (...arrays: number[][]): number[] => {
  const combinedArray: number[] = [];
  arrays.forEach(childArray => {
    childArray.forEach(value => {
      if (!combinedArray.includes(value)) {
        combinedArray.push(value);
      }
    });
  });
  return combinedArray;
};

export const tourValidClients: number[] = getTourValidClients(
  internalClients,
  VALID_CLIENTS_FOR_ERROR_TOUR,
  VALID_CLIENTS_FOR_MULTI_INFLOW_TOUR
);
