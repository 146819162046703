<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <span class="filterConditionLabel">
    <span
      class="filterConditionLabel__filterConditionContainer"
      :class="{
        'filterConditionLabel__filterConditionContainer--nowrap': !wrap
      }"
      @click="onClick"
    >
      <FilterPeriodLabel
        ref="periodLabel"
        :period-days="filterCondition.periodDays"
      />
      <span class="filterConditionLabel__edge">
        <Icon :icon="iconRight" :size="12" :color="iconColor" />
      </span>
      <FilterDeviceLabel
        v-if="filterCondition.deviceTypes.length > 0"
        ref="deviceLabel"
        :filtered-devices="filterCondition.deviceTypes"
        :has-edge-icon="filterCondition.filterNodes.length > 0"
      />
      <span
        v-for="(n, index) in filterCondition.filterNodes"
        :key="index"
        class="filterConditionLabel__item"
      >
        <span
          class="filterConditionLabel__title"
          v-html="filterTypeLabel(n)"
        ></span>
        <span class="filterConditionLabel__detail">{{ nodeDetail(n) }}</span>
        <span v-if="showEdgeIcon(n, index)" class="filterConditionLabel__edge">
          <Icon :icon="iconRight" :size="12" :color="iconColor" />
        </span>
      </span>
    </span>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

import {
  FilterNode,
  FilterNodeType
} from "@/models/search/filter-node/FilterNode";
import { ChildFilterNodeForReferrer } from "@/models/search/filter-node/ChildFilterNodeForReferrer";
import { ChildFilterNodeForInflowParameter } from "@/models/search/filter-node/ChildFilterNodeForInflowParameter";
import { ChildFilterNodeForSearchEngine } from "@/models/search/filter-node/ChildFilterNodeForSearchEngine";
import { ChildFilterNodeForAd } from "@/models/search/filter-node/ChildFilterNodeForAd";
import { FilterCondition } from "@/models/search/filter-condition/FilterCondition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { FilterNodeForConversion } from "@/models/search/filter-node/FilterNodeForConversion";
import { FilterReferrerUrlCondition } from "@/models/search/filter-node-condition/FilterReferrerUrlCondition";
import { FilterLandingPageUrlCondition } from "@/models/search/filter-node-condition/FilterLandingPageUrlCondition";
import { FilterLandingPageTitleCondition } from "@/models/search/filter-node-condition/FilterLandingPageTitleCondition";
import { FilterSearchEngineCondition } from "@/models/search/filter-node-condition/FilterSearchEngineCondition";
import { SearchEngine } from "@/models/system/SearchEngine";
import {
  FilterAdTypeCondition,
  selectOption as adTypeOptions
} from "@/models/search/filter-node-condition/FilterAdTypeCondition";
import { FilterAdMediaCondition } from "@/models/search/filter-node-condition/FilterAdMediaCondition";
import { FilterAdInventoryCondition } from "@/models/search/filter-node-condition/FilterAdInventoryCondition";
import { FilterAdCampaignCondition } from "@/models/search/filter-node-condition/FilterAdCampaignCondition";
import { FilterAdGroupCondition } from "@/models/search/filter-node-condition/FilterAdGroupCondition";
import { FilterNodeForBrowseSite } from "@/models/search/filter-node/FilterNodeForBrowseSite";
import { FilterPageTitleCondition } from "@/models/search/filter-node-condition/FilterPageTitleCondition";
import { FilterSiteUrlCondition } from "@/models/search/filter-node-condition/FilterSiteUrlCondition";
import { FilterNodeForEvent } from "@/models/search/filter-node/FilterNodeForEvent";
import { FilterNodeForLaunchApp } from "@/models/search/filter-node/FilterNodeForLaunchApp";
import { FilterNodeForBrowseApp } from "@/models/search/filter-node/FilterNodeForBrowseApp";
import { FilterNodeForBusinessEvent } from "@/models/search/filter-node/FilterNodeForBusinessEvent";
import { FilterNodeForContact } from "@/models/search/filter-node/FilterNodeForContact";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";

import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";

import FilterDeviceLabel from "@/components/filter/FilterDeviceLabel.vue";
import FilterPeriodLabel from "@/components/filter/FilterPeriodLabel.vue";
import { ExclusionType } from "@/models/search/filter-node/FilterExclusion";

@Component({
  components: {
    Icon,
    FilterDeviceLabel,
    FilterPeriodLabel
  }
})
export default class FilterConditionLabel extends Vue {
  @Prop({ type: FilterCondition, required: true })
  filterCondition!: FilterCondition;

  @Prop({ type: Boolean, default: true })
  wrap!: boolean;

  onClick(condition: FilterCondition) {
    this.$emit("click", condition);
  }

  conversionDefinitions: ConversionDefinition[] = this.$store.state
    .clientSettings.activeConversionDefinitions;

  eventDefinitions: EventDefinition[] = this.$store.state.clientSettings
    .activeEventDefinitions;

  contactDefinitions: ContactDefinition[] = this.$store.state.clientSettings
    .activeContactDefinitions;

  businessEventDefinitions: BusinessEventDefinition[] = this.$store.state
    .clientSettings.activeBusinessEventDefinitions;

  searchEngines: SearchEngine[] = this.$store.state.system.searchEngines;

  iconRight = Icons.CaretRight;
  iconColor = Colors.Base600;

  get filterLabelTextAsSingleString() {
    let periodLabel = "";
    if (this.$refs.periodLabel) {
      const periodLabelRef = this.$refs.periodLabel as Vue;
      periodLabel = (periodLabelRef.$el as HTMLElement).innerText + ",";
    }

    let deviceLabel = "";
    if (this.$refs.deviceLabel) {
      const deviceLabelRef = this.$refs.deviceLabel as Vue;
      deviceLabel = (deviceLabelRef.$el as HTMLElement).innerText + ",";
    }

    const nodeDetailLabel = this.filterCondition.filterNodes
      .map(value => {
        return this.filterTypeLabel(value) + " " + this.nodeDetail(value);
      })
      .join(" -> ");

    return periodLabel + deviceLabel + nodeDetailLabel;
  }

  filterTypeLabel(node: FilterNode): string {
    return this.filterExclusionText(node) + this.filterTypeLabeNodeText(node);
  }

  filterTypeLabeNodeText(node: FilterNode): string {
    if (node.nodeType === FilterNodeType.OrNode) {
      const firstOrNodeName = this.filterNodeTypeLabelTextExceptOrNode(
        node.orActivityNodes[0]
      );
      const firstOrNodeInfo = this.nodeDetail(node.orActivityNodes[0]);
      const lastOrNodeName = this.filterNodeTypeLabelTextExceptOrNode(
        node.orActivityNodes[1]
      );
      const lastOrNodeInfo = this.nodeDetail(node.orActivityNodes[1]);

      return this.$t("or", {
        firstOrNodeName: firstOrNodeName,
        firstOrNodeInfo: firstOrNodeInfo,
        lastOrNodeName: lastOrNodeName,
        lastOrNodeInfo: lastOrNodeInfo,
        nodeInfoColor: "#818599",
        nodeInfoFontWeight: "normal"
      }) as string;
    }

    return this.filterNodeTypeLabelTextExceptOrNode(node);
  }

  filterNodeTypeLabelTextExceptOrNode(node: FilterNode): string {
    switch (node.nodeType) {
      case FilterNodeType.Inflow:
        if (node.condition instanceof ChildFilterNodeForReferrer) {
          return this.$t("referer") as string;
        }
        if (node.condition instanceof ChildFilterNodeForInflowParameter) {
          return this.$t("parameter") as string;
        }
        if (node.condition instanceof ChildFilterNodeForSearchEngine) {
          return this.$t("organicSearch") as string;
        }
        if (node.condition instanceof ChildFilterNodeForAd) {
          return this.$t("advertisement") as string;
        }
        return this.$t("nodeTitleInflow") as string;
      case FilterNodeType.Conversion:
        return this.conversionTypeLabel(node);
      case FilterNodeType.BrowseSite:
        return this.$t("inSite") as string;
      case FilterNodeType.Event:
        return this.eventTypeLabel(node);
      case FilterNodeType.LaunchApp:
        return this.$t("nodeTitleLaunchApp") as string;
      case FilterNodeType.BrowseApp:
        return this.$t("screen") as string;
      case FilterNodeType.Contact:
        return this.contactTypeLabel(node);
      case FilterNodeType.BusinessEvent:
        return this.businessEventTypeLabel(node);
      default:
        return "";
    }
  }

  nodeDetail(node: FilterNode): string {
    switch (node.nodeType) {
      case FilterNodeType.Inflow:
        if (node.condition instanceof ChildFilterNodeForReferrer) {
          return this.referrerDetail(node.condition);
        }
        if (node.condition instanceof ChildFilterNodeForInflowParameter) {
          return this.inflowParameterDetail(node.condition);
        }
        if (node.condition instanceof ChildFilterNodeForSearchEngine) {
          return this.searchEngineDetail(node.condition);
        }
        if (node.condition instanceof ChildFilterNodeForAd) {
          return this.adDetail(node.condition);
        }
        // TODO エラー
        return "";
      case FilterNodeType.Conversion:
        return this.conversionDetail(node);
      case FilterNodeType.BrowseSite:
        return this.browseSiteDetail(node);
      case FilterNodeType.Event:
        return this.eventDetail(node);
      case FilterNodeType.LaunchApp:
        return this.launchAppDetail(node);
      case FilterNodeType.BrowseApp:
        return this.browseAppDetail(node);
      case FilterNodeType.Contact:
        return this.contactDetail(node);
      case FilterNodeType.BusinessEvent:
        return this.businessEventDetail(node);
      default:
        // TODO エラー
        return "";
    }
  }

  conversionTypeLabel(node: FilterNodeForConversion): string {
    let cv = this.conversionDefinitions.find(
      def => def.id === node.conversionDefinitionId
    );
    return cv?.name || "";
  }

  eventTypeLabel(node: FilterNodeForEvent): string {
    let event = this.eventDefinitions.find(
      def => def.id === node.eventDefinitionId
    );
    return event?.name || "";
  }
  contactTypeLabel(node: FilterNodeForContact): string {
    let contact = this.contactDefinitions.find(
      def => def.id === node.condition.contactDefinitionId
    );
    return contact?.name || "";
  }
  businessEventTypeLabel(node: FilterNodeForBusinessEvent): string {
    let bizEvent = this.businessEventDefinitions.find(
      def => def.id === node.businessEventDefinitionId
    );
    return bizEvent?.name || "";
  }

  referrerDetail(node: ChildFilterNodeForReferrer): string {
    let ret = this.urlTitleConditionToString(node.condition);
    node.additionalConditions.forEach(cnd => {
      if (
        cnd instanceof FilterReferrerUrlCondition ||
        cnd instanceof FilterLandingPageUrlCondition ||
        cnd instanceof FilterLandingPageTitleCondition
      ) {
        const ref = this.urlTitleConditionToString(cnd);
        if (ref !== "") {
          ret += ", " + ref;
        }
      }
    });
    return ret;
  }

  adDetail(node: ChildFilterNodeForAd): string {
    let additional = "";
    let hasTimingCondition = false;

    node.additionalConditions.forEach(condition => {
      if (condition instanceof FilterAdTypeCondition) {
        adTypeOptions(false, false).forEach(option => {
          if (option.value === condition.adType) {
            additional += ", " + option.label;
          }
        });
      } else if (condition instanceof FilterAdMediaCondition) {
        additional += ", " + this.cutTooLongText(condition.mediaName);
      } else if (condition instanceof FilterAdInventoryCondition) {
        additional += ", " + this.cutTooLongText(condition.inventoryName);
      } else if (condition instanceof FilterAdCampaignCondition) {
        additional += ", " + this.cutTooLongText(condition.campaignName);
      } else if (condition instanceof FilterAdGroupCondition) {
        additional += ", " + this.cutTooLongText(condition.groupName);
      } else if (
        condition instanceof FilterLandingPageUrlCondition ||
        condition instanceof FilterLandingPageTitleCondition
      ) {
        additional += ", " + this.urlTitleConditionToString(condition);
      } else {
        if (!hasTimingCondition) {
          hasTimingCondition = true;
        }
      }
    });

    let ret = additional.replace(", ", "");
    if (hasTimingCondition) {
      ret += "...";
    }
    return ret;
  }

  inflowParameterDetail(node: ChildFilterNodeForInflowParameter): string {
    return node.parameter;
  }

  searchEngineDetail(node: ChildFilterNodeForSearchEngine) {
    let additional = "";
    let hasTimingCondition = false;

    node.additionalConditions.forEach(condition => {
      if (condition instanceof FilterSearchEngineCondition) {
        this.searchEngines.forEach(engine => {
          if (engine.id === condition.searchEngineId) {
            additional += ", " + engine.name;
          }
        });
      } else if (
        condition instanceof FilterLandingPageUrlCondition ||
        condition instanceof FilterLandingPageTitleCondition
      ) {
        additional += ", " + this.urlTitleConditionToString(condition);
      } else {
        if (!hasTimingCondition) {
          hasTimingCondition = true;
        }
      }
    });

    let ret = additional.replace(", ", "");
    if (hasTimingCondition) {
      ret += "...";
    }
    return ret;
  }

  conversionDetail(node: FilterNodeForConversion): string {
    return 0 < node.additionalConditions.length ? "..." : "";
  }

  browseSiteDetail(node: FilterNodeForBrowseSite): string {
    let ret = this.urlTitleConditionToString(node.condition);
    let additional = "";
    let hasTimingCondition = false;
    node.additionalConditions.forEach(condition => {
      if (
        condition instanceof FilterPageTitleCondition ||
        condition instanceof FilterSiteUrlCondition
      ) {
        additional += ", " + this.urlTitleConditionToString(condition);
      } else {
        if (!hasTimingCondition) {
          hasTimingCondition = true;
        }
      }
    });
    ret += additional;
    if (hasTimingCondition) {
      ret += "...";
    }
    return ret;
  }

  eventDetail(node: FilterNodeForEvent): string {
    return 0 < node.additionalConditions.length ? "..." : "";
  }

  launchAppDetail(node: FilterNodeForLaunchApp): string {
    return 0 < node.additionalConditions.length ? "..." : "";
  }

  browseAppDetail(node: FilterNodeForBrowseApp): string {
    let ret = node.screenName;
    if (0 < node.additionalConditions.length) {
      ret += "...";
    }
    return ret;
  }

  contactDetail(node: FilterNodeForContact): string {
    let ret = "";
    if (node.condition.isCheckTalkTime) {
      ret += this.$t("longerThanMinutes", {
        num: node.condition.talkMin
      }) as string;
    }
    if (0 < node.additionalConditions.length) {
      ret += "...";
    }
    return ret;
  }

  businessEventDetail(node: FilterNodeForBusinessEvent): string {
    return 0 < node.additionalConditions.length ? "..." : "";
  }

  urlTitleConditionToString(
    condition:
      | FilterReferrerUrlCondition
      | FilterLandingPageUrlCondition
      | FilterLandingPageTitleCondition
      | FilterSiteUrlCondition
      | FilterPageTitleCondition
  ): string {
    if (condition instanceof FilterReferrerUrlCondition) {
      return "URL:" + this.cutTooLongText(condition.referrerUrl);
    }
    if (condition instanceof FilterLandingPageUrlCondition) {
      return (
        (this.$t("landingUrl") as string) +
        ":" +
        this.cutTooLongText(condition.pageUrl)
      );
    }
    if (condition instanceof FilterLandingPageTitleCondition) {
      return (
        (this.$t("landingTitle") as string) +
        ":" +
        this.cutTooLongText(condition.pageTitle)
      );
    }
    if (condition instanceof FilterSiteUrlCondition) {
      return "URL:" + this.cutTooLongText(condition.siteUrl);
    }
    if (condition instanceof FilterPageTitleCondition) {
      return (
        (this.$t("title") as string) +
        ":" +
        this.cutTooLongText(condition.pageTitle)
      );
    }
    return "";
  }

  cutTooLongText(text: string): string {
    if (text.length <= 30) {
      return text;
    }
    const head = text.substring(0, 15);
    const tail = text.substring(text.length - 15);
    return head + "..." + tail;
  }

  filterExclusionText(node: FilterNode) {
    if (node.filterExclusion.exclusionType === ExclusionType.Exclude) {
      return `(${this.$t("labelExclude") as string}) `;
    }
    return "";
  }

  showEdgeIcon(node: FilterNode, index: number) {
    return (
      (node.isExcluded &&
        index !== this.filterCondition.filterNodes.length - 1) ||
      node.edge !== null
    );
  }
}
</script>
<style lang="scss" scoped>
.filterConditionLabel__filterConditionContainer {
  position: relative;
  line-height: 1.5;

  &.filterConditionLabel__filterConditionContainer--nowrap {
    white-space: nowrap;
  }
}

.filterConditionLabel__title {
  font-weight: bold;
  font-size: 14px;
}
.filterConditionLabel__detail {
  margin-left: 5px;
  color: $colorBase700;
  font-size: 14px;
}

.filterConditionLabel__edge {
  display: inline-block;
  margin: 0 3px;
}
</style>
