<i18n
  src="@/i18n/components/funnel/funnel-inflow-referrer-or-landing-condition.json"
></i18n>
<template>
  <div class="funnelInflowReferrerOrLandingCondition">
    <div
      v-for="(cond, index) in inflowConditions"
      :key="index"
      class="funnelInflowReferrerOrLandingCondition__Container"
    >
      <SelectBox
        :value="cond.searchField"
        width="180px"
        class="funnelInflowReferrerOrLandingCondition__selectBox dragNotApplicable"
        :options="searchFieldTypeOptions"
        @input="v => onUpdateInflowSearchField(index, v)"
      />
      <InputText
        v-if="cond.searchField === InflowSearchField.LOCATION_REFERRER"
        :value="cond.value"
        class="funnelInflowReferrerOrLandingCondition__input dragNotApplicable"
        @input="v => onUpdateInflowValue(index, v)"
      />
      <InputTextWithSuggestion
        v-else
        :value="cond.value"
        class="funnelInflowReferrerOrLandingCondition__input dragNotApplicable"
        :width="'320px'"
        :input-form-suggestion-type="getSuggestionType(cond.searchField)"
        @input="v => onUpdateInflowValue(index, v)"
      />
      <SelectBox
        :value="cond.wordMatchMethod"
        :options="matchMethodOptions"
        width="140px"
        class="funnelInflowReferrerOrLandingCondition__selectBox dragNotApplicable"
        @input="v => onUpdateInflowWordMatchMethod(index, v)"
      />
      <IconButton
        v-show="inflowConditions.length > 1"
        class="funnelInflowReferrerOrLandingCondition__deleteButton dragNotApplicable"
        :icon="iconDelete"
        :size="18"
        @click="onClickDelete(index)"
      />
    </div>
    <div
      v-if="inflowConditions.length < 3"
      class="funnelInflowReferrerOrLandingCondition__addButtonClickableArea dragNotApplicable"
      @click="onClickAdd"
    >
      <IconButton :icon="iconAdd" :size="18" :button-type="iconButtonType" />
      <div
        v-t="'addCondition'"
        class="funnelInflowReferrerOrLandingCondition__addButtonText"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import { useI18n } from "@/composables/useI18n";
import { InflowSearchField } from "@/const/funnel";
import { MatchMethod } from "@/models/search/MatchMethod";
import { InflowMatchCondition } from "@/models/funnel/FunnelCondition";
import SelectBox from "@/components/form/SelectBox.vue";
import SelectOption from "@/components/form/SelectOption";
import InputTextWithSuggestion from "@/components/input-form-suggestion/InputTextWithSuggestion.vue";
import { InputFormSuggestionType } from "@/const/input-form-suggestion";
import InputText from "@/components/form/InputText.vue";
import IconButton from "@/components/IconButton.vue";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";

const iconAdd = Icons.PlusCircle;
const iconDelete = Icons.ButtonClose;
const iconButtonType = IconButtonType.Weak;

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: Array as PropType<InflowMatchCondition[]>,
    default: () => []
  }
});

const emits = defineEmits<{
  (e: "update:modelValue", inflowConditions: InflowMatchCondition[]): void;
}>();

const inflowConditions = computed(() => props.modelValue);

const matchMethodOptions: SelectOption<MatchMethod>[] = [
  {
    value: MatchMethod.Partial,
    label: t("partialMatch").toString(),
    disabled: false
  },
  {
    value: MatchMethod.Exact,
    label: t("perfectMatch").toString(),
    disabled: false
  },
  {
    value: MatchMethod.Wildcard,
    label: t("wildcardMatch").toString(),
    disabled: false
  }
];

const searchFieldTypeOptions = computed(() => {
  const selectedTypes = [...selectedFieldTypes.value];
  return [
    {
      value: InflowSearchField.LOCATION_REFERRER,
      label: t("locationReferrer").toString(),
      disabled: selectedTypes.includes(InflowSearchField.LOCATION_REFERRER)
    },
    {
      value: InflowSearchField.LOCATION_URI,
      label: t("locationUri").toString(),
      disabled: selectedTypes.includes(InflowSearchField.LOCATION_URI)
    },
    {
      value: InflowSearchField.LOCATION_NAME,
      label: t("locationName").toString(),
      disabled: selectedTypes.includes(InflowSearchField.LOCATION_NAME)
    }
  ] as SelectOption<InflowSearchField>[];
});

const selectedFieldTypes = computed(() => {
  return inflowConditions.value.map(cond => cond.searchField);
});

function getSuggestionType(
  fieldType: InflowSearchField
): InputFormSuggestionType {
  return fieldType === InflowSearchField.LOCATION_NAME
    ? InputFormSuggestionType.WEB_TITLE
    : InputFormSuggestionType.WEB_URL;
}

function onUpdateInflowSearchField(index: number, newValue: number) {
  const oldValues = inflowConditions.value[index];
  const newValues = {
    ...oldValues,
    searchField: newValue
  };
  onUpdate(index, newValues);
}

function onUpdateInflowValue(index: number, newValue: string) {
  const oldValues = inflowConditions.value[index];
  const newValues = {
    ...oldValues,
    value: newValue
  };
  onUpdate(index, newValues);
}

function onUpdateInflowWordMatchMethod(index: number, newValue: number) {
  const oldValues = inflowConditions.value[index];
  const newValues = {
    ...oldValues,
    wordMatchMethod: newValue
  };
  onUpdate(index, newValues);
}

function onUpdate(index: number, newValue: InflowMatchCondition) {
  const newConditions = [...inflowConditions.value];
  newConditions.splice(index, 1, newValue);
  emits("update:modelValue", newConditions);
}

function onClickDelete(index: number) {
  const newConditions = [...inflowConditions.value];
  newConditions.splice(index, 1);
  emits("update:modelValue", newConditions);
}

function onClickAdd() {
  const availableFieldTypes = searchFieldTypeOptions.value.filter(option => {
    return !selectedFieldTypes.value.includes(option.value);
  });

  const newConditions = [...inflowConditions.value];
  newConditions.push({
    value: "",
    wordMatchMethod: MatchMethod.Partial,
    searchField: availableFieldTypes[0].value
  });
  emits("update:modelValue", newConditions);
}
</script>
<style scoped lang="scss">
.funnelInflowReferrerOrLandingCondition {
  margin-top: 15px;
}
.funnelInflowReferrerOrLandingCondition__Container {
  width: 800px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .funnelInflowReferrerOrLandingCondition__input {
    width: 320px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .funnelInflowReferrerOrLandingCondition__deleteButton {
    margin-left: 8px;
  }
}

.funnelInflowReferrerOrLandingCondition__addButtonClickableArea {
  align-items: center;
  display: inline-flex;
  margin-top: 5px;
}
.funnelInflowReferrerOrLandingCondition__addButtonText {
  color: $colorBase900;
  font-size: 14px;
  margin-left: 7px;
  padding-top: 1px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
