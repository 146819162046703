<i18n src="@/i18n/components/home-menu/menu-panel-conversion-trend.json"></i18n>
<template>
  <MenuPanel
    class="menuPanelConversionTrend"
    :label-icon="icons"
    :is-loading="isLoading"
    :clickable="false"
  >
    <template #header>
      {{ title }}
      <span class="menuPanelConversionTrend__beta">BETA</span>
    </template>

    <MenuPanelError v-if="isError" @retry="onRetry" />
    <div v-else class="menuPanelConversionTrend__content">
      <div class="menuPanelConversionTrend__selectCheckBox">
        <SelectCheckBox
          :select-check-box-items="selectCheckBoxItems"
          :select-size="selectCheckBoxSize"
          :pulldown-align="selectCheckBoxAlign"
          :title="selectCheckBoxLabel"
          :selected-parent-ids="orderedConversionIds"
          @updateParent="onShowConversions"
        />
      </div>

      <div class="menuPanelConversionTrend__view">
        <div
          v-if="orderedConversionIds.length === 0"
          v-t="'noSelectConversionText'"
          class="menuPanelConversionTrend__noSelectConversion"
        />
        <div
          v-else
          class="menuPanelConversionTrend__panelContainer customScrollbar"
        >
          <Draggable
            v-model="orderedConversionIds"
            easing="cubic-bezier(0.785, 0.135, 0.15, 0.86)"
            animation="600"
            ghost-class="menuPanelConversionTrend__dragArea--ghost"
            fallback-class="menuPanelConversionTrend__dragArea--fallback"
            :force-fallback="true"
            @start="isDragging = true"
            @end="onDragEnd"
          >
            <div
              v-for="cid in validOrderedConversionIds"
              :key="cid"
              class="menuPanelConversionTrend__panel"
            >
              <ConversionTrendsPanel
                :conversion-trend="mapConversionTrends[cid]"
                :is-dragging="isDragging"
                @click="onClick"
              />
            </div>
          </Draggable>
        </div>
      </div>
    </div>
  </MenuPanel>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { ConversionTrendResult } from "@/models/conversion-trend/ConversionTrendResult";
import { ConversionTrendDailyCount } from "@/models/conversion-trend/ConversionTrendDailyCount";
import { Icons } from "@/const/Icons";
import {
  SelectCheckBoxItem,
  SelectCheckBoxItems,
  FormPartsSize,
  PulldownAlign
} from "@/components/form/SelectCheckBoxItems";
import MenuPanel from "@/components/home-menu/MenuPanel.vue";
import MenuPanelError from "@/components/home-menu/MenuPanelError.vue";
import ConversionTrendsPanel from "@/components/home-menu/conversion-trends/ConversionTrendsPanel.vue";
import SelectCheckBox from "@/components/form/SelectCheckBox.vue";
import Draggable from "vuedraggable";

@Component({
  components: {
    MenuPanel,
    MenuPanelError,
    ConversionTrendsPanel,
    SelectCheckBox,
    Draggable
  }
})
export default class MenuPanelConversionTrend extends Vue {
  @Prop({ type: Array, required: true })
  conversionTrends!: ConversionTrendResult[];

  @Prop({ type: Number, required: true })
  loginUserId!: number;

  @Prop({ type: Boolean, required: true })
  isLoading!: boolean;

  @Prop({ type: Boolean, required: true })
  isError!: boolean;

  onClick(
    result: ConversionTrendResult,
    currentData: ConversionTrendDailyCount
  ) {
    this.$emit("click", result, currentData);
  }

  @Emit("retry")
  onRetry() {}

  @Watch("conversionTrends")
  onUpdate() {
    this.orderedConversionIds = this.getConversionTrendIds();
  }

  icons = Icons.Conversion;
  selectCheckBoxSize = FormPartsSize.Small;
  selectCheckBoxAlign = PulldownAlign.Right;
  selectCheckBoxTitle = "表示するCV";

  orderedConversionIds: number[] = [];
  isDragging = false;

  mounted() {
    this.orderedConversionIds = this.getConversionTrendIds();
  }

  get title(): string {
    return this.$i18n.t("title") as string;
  }

  get selectCheckBoxLabel(): string {
    return this.$i18n.t("selectCheckBoxLabel") as string;
  }

  get selectCheckBoxItems(): SelectCheckBoxItems[] {
    return this.conversionTrends.map(t => {
      const item: SelectCheckBoxItem = {
        name: t.conversionName,
        id: t.conversionId
      };

      return {
        parent: item,
        children: []
      };
    });
  }

  // Create a LocalStorage key for each user because the conversion is different for each user who logs in.
  get keyOfLocalStorage(): string {
    return this.loginUserId + "ConversionTrend";
  }

  get mapConversionTrends(): Record<string, ConversionTrendResult> {
    return this.conversionTrends.reduce(
      (acc, ct) => ({ ...acc, [ct.conversionId]: ct }),
      {}
    );
  }

  get validOrderedConversionIds(): number[] {
    return this.orderedConversionIds.filter(id =>
      Object.keys(this.mapConversionTrends).includes(`${id}`)
    );
  }

  getConversionIdsFromLocalStorage(): number[] {
    const LocalstorageData = localStorage.getItem(this.keyOfLocalStorage);

    return LocalstorageData !== null ? JSON.parse(LocalstorageData) : [];
  }

  setToLocalStorage() {
    localStorage.setItem(
      this.keyOfLocalStorage,
      JSON.stringify(this.validOrderedConversionIds)
    );
  }

  getConversionTrendIds(): number[] {
    const conversionIds = this.getConversionIdsFromLocalStorage();

    if (conversionIds.length) {
      return conversionIds;
    }

    return this.conversionTrends.map(ct => ct.conversionId);
  }

  onShowConversions(ids: number[]) {
    const newIds = ids.filter(id => !this.orderedConversionIds.includes(id));
    this.orderedConversionIds = this.orderedConversionIds
      .filter(id => ids.includes(id))
      .concat(newIds); // 新しく追加されたコンバージョンは後ろに追加する

    this.setToLocalStorage();
  }

  onDragEnd() {
    this.isDragging = false;
    this.setToLocalStorage();
  }
}
</script>

<style lang="scss" scoped>
.menuPanelConversionTrend__beta {
  margin-left: 6px;
  color: $colorBase600;
  font-weight: 500;
  font-size: 12px;
}

.menuPanelConversionTrend__content {
  margin-top: 20px;
}

.menuPanelConversionTrend__selectCheckBox {
  text-align: right;
}

.menuPanelConversionTrend__noSelectConversion {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 265px;
}

.menuPanelConversionTrend__panelContainer {
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 8px;
  height: 265px;
}

.menuPanelConversionTrend__panel {
  &:not(:first-child) {
    margin-top: 8px;
  }
}

.menuPanelConversionTrend__dragArea--ghost {
  .conversionTrendsPanel {
    opacity: 0;
  }
}

.menuPanelConversionTrend__dragArea--fallback {
  .conversionTrendsPanel {
    height: 36px;
    background-color: $colorBlue600;
  }

  ::v-deep .conversionTrendsPanel__label {
    width: auto;
  }

  ::v-deep .conversionTrendsPanel__graph {
    display: none;
  }
}
</style>
