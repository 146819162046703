import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";

export interface ConversionTrendResultJson {
  conversion_id: number;
  conversion_name: string;
  data: {
    date: string;
    count: number;
  }[];
}

export interface ConversionTrendApiResponse {
  id: string;
  status: ConversionTrendStatus;
  result?: ConversionTrendResultJson[];
}

export enum ConversionTrendStatus {
  NOT_STARTED = "NOT_STARTED",
  SUCCEEDED = "SUCCEEDED",
  RUNNING = "RUNNING",
  FAILED = "FAILED"
}

export default class ConversionTrend {
  constructor(private readonly httpClient: HttpClient) {}

  public createNew(): Promise<ConversionTrendApiResponse> {
    const url: string = ApiUrl.CV_TREND;
    const params = {
      app_user_timezone: new Date().getTimezoneOffset()
    };

    return this.httpClient.post<ConversionTrendApiResponse>(url, params);
  }

  public getResult(id: string): Promise<ConversionTrendApiResponse> {
    const url: string = `${ApiUrl.CV_TREND}${id}/`;

    return this.httpClient.get<ConversionTrendApiResponse>(url);
  }
}
