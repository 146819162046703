import { DateFormat, formatDate } from "@/util/date-util";
import { i18n } from "@/i18n";

export function getCsvFileName(): string {
  let filename: string = i18n.t(
    "components.search.search.csvFileNamePrefix"
  ) as string;
  filename += formatDate(DateFormat.yyyysMMsdd, new Date()) + ".csv";

  return filename;
}
