<template>
  <div class="blogPageBalloon">
    <IconButton
      class="blogPageBalloon__closeIcon"
      :icon="icons.ButtonClose"
      @click="onClickClose"
    />

    <a
      class="blogPageBalloon__balloonContainer"
      target="_self"
      href="#"
      rel="noopener"
      @click="onClick"
    >
      <div class="blogPageBalloon__image">
        <slot name="balloonImage"></slot>
      </div>

      <div class="blogPageBalloon__title">{{ balloonTitle }}</div>
      <div class="blogPageBalloon__description">{{ balloonDescription }}</div>
    </a>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {
    IconButton
  }
})
export default class BlogPageBalloon extends Vue {
  colors = Colors;
  icons = Icons;

  @Prop({ type: String, required: true })
  blogPageLink!: string;

  @Prop({ type: String, required: true })
  balloonTitle!: string;

  @Prop({ type: String, required: true })
  balloonDescription!: string;

  @Emit("click")
  onClick() {}

  @Emit("click-close")
  onClickClose() {}
}
</script>

<style lang="scss" scoped>
.blogPageBalloon {
  position: relative;
  width: 144px;
  height: 190px;
}

.blogPageBalloon__balloonContainer {
  display: block;
  text-decoration: none;
  height: 176px;
  width: 144px;
  border-radius: $sizeRadiusOnboarding;
  border: 1px solid $colorBase500;
  background-color: $colorWhite;
  box-shadow: 5px 5px 5px $colorShadow100;
  outline: none;
}

.blogPageBalloon__balloonContainer:after {
  position: absolute;
  top: 173px;
  right: 22px;
  border: 10px solid transparent;
  border-top: 18px solid $colorWhite;
  content: "";
}

.blogPageBalloon__balloonContainer:before {
  position: absolute;
  top: 175px;
  right: 22px;
  border: 10px solid transparent;
  border-top: 18px solid $colorBase500;
  content: "";
}

.blogPageBalloon__closeIcon {
  position: absolute;
  right: 4px;
  top: 4px;
}

.blogPageBalloon__closeIcon::after {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 14px;
  height: 22px;
  z-index: 1;
  content: "";
  background-color: transparent;
}

.blogPageBalloon__image {
  height: 105px;
  border-top-left-radius: $sizeRadiusOnboarding;
  border-top-right-radius: $sizeRadiusOnboarding;
  overflow: hidden;
}

.blogPageBalloon__title {
  font-weight: bold;
  font-size: 12px;
  color: $colorBase900;
  padding: 11px 10px 7px 10px;
}

.blogPageBalloon__description {
  font-size: 11px;
  line-height: 1.3;
  color: $colorBase900;
  padding: 0px 10px 14px 10px;
}
</style>
