import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { AnalysisType, UserTrendDataType } from "@/const/user-trend";
import { UserTrendSearchCondition } from "@/models/user-trend/UserTrendSearchCondition";
import { UserTrendFilterCondition } from "@/models/user-trend/UserTrendFilterCondition";

export interface UserTrendScatterDataResponce {
  data: UserTrendChartPointJson[];
}

export interface UserTrendChartPointJson {
  cv_user_ids: string[];
  aggregate_keys: {
    key: string;
    sub_key?: string;
  }[];
  number_data: UserTrendNumberJsonData;
}

export interface UserTrendResultResponse {
  data: UserTrendRowJsonData[];
  num_rows: number;
}

export interface UserTrendRowJsonData {
  cv_user_ids: string[];
  aggregate_keys: {
    key: string;
    sub_key?: string;
  }[];
  base: UserTrendNumberJsonData;
  comparison?: UserTrendNumberJsonData;
}

export interface UserTrendNumberJsonData {
  count: number;
  visit: number;
  cv_in_visit: number;
  cv_ratio_in_visit: number;
  unique_users?: number;
}

export interface UserTrendQueryHistory {
  query: UserTrendPostParam;
  type: AnalysisType;
}

export interface UserTrendPostParam {
  cv_attr?: {
    id: number;
    values: string[];
    word_match_method: number;
  }[];
  conversion_ids: number[];
  start_date: string;
  end_date: string;
  is_compared: boolean;
  comparison_start_date: string;
  comparison_end_date: string;
  domain?: string;
  app_user_timezone?: number;
}

export enum UserTrendStatus {
  OK = "OK",
  RUNNING = "RUNNING",
  FAILED = "FAILED"
}

export default class UserTrend {
  constructor(private readonly httpClient: HttpClient) {}

  public createNew(
    type: AnalysisType,
    condition: UserTrendSearchCondition,
    domain: string | null
  ): Promise<{ id: number }> {
    const url: string = ApiUrl.USER_TREND_PAGE(type);
    const params: UserTrendPostParam = condition.toJson();

    if (domain !== null) {
      params["domain"] = domain;
    }

    return this.httpClient.post<{ id: number }>(url, params);
  }

  public getStatus(
    type: AnalysisType,
    id: number
  ): Promise<{ status: UserTrendStatus }> {
    const url: string = `${ApiUrl.USER_TREND_PAGE(type)}${String(id)}`;

    return this.httpClient.get<{ status: UserTrendStatus }>(url);
  }

  public getResults(
    type: AnalysisType,
    id: number,
    sort: string = "count",
    order: "asc" | "desc" = "desc",
    page: number = 1,
    perPage: number = 20,
    filter: UserTrendFilterCondition
  ): Promise<UserTrendResultResponse> {
    const url: string = `${ApiUrl.USER_TREND_PAGE(type)}${String(id)}/result`;

    const params: {
      sort: string;
      order: string;
      per_page: number;
      page: number;
      filter: string;
      match_method: number;
    } = {
      sort: sort,
      order: order,
      page: page,
      per_page: perPage,
      filter: filter.value,
      match_method: filter.matchMethod
    };

    return this.httpClient.get<UserTrendResultResponse>(url, params);
  }

  public getScatter(
    type: AnalysisType,
    dataType: UserTrendDataType,
    id: number,
    filter: UserTrendFilterCondition
  ): Promise<UserTrendScatterDataResponce> {
    const url: string = `${ApiUrl.USER_TREND_PAGE(type)}${String(id)}/scatter`;
    const params: {
      data_type: string;
      filter: string;
      match_method: number;
    } = {
      data_type: dataType,
      filter: filter.value,
      match_method: filter.matchMethod
    };

    return this.httpClient.get<UserTrendScatterDataResponce>(url, params);
  }

  public getCsv(
    type: AnalysisType,
    id: number,
    filter: UserTrendFilterCondition
  ): Promise<string> {
    const url: string = `${ApiUrl.USER_TREND_PAGE(type)}${String(id)}/csv`;
    const params: {
      app_user_timezone: number;
      filter: string;
      match_method: number;
    } = {
      app_user_timezone: new Date().getTimezoneOffset(),
      filter: filter.value,
      match_method: filter.matchMethod
    };
    return this.httpClient.get<string>(url, params);
  }

  public fetchSearchHistory(
    limit: number = 1
  ): Promise<UserTrendQueryHistory[]> {
    return this.httpClient.get<UserTrendQueryHistory[]>(
      ApiUrl.USER_TREND_QUERY_HISTORY,
      { limit }
    );
  }

  public fetchSearchHistoryById(id: string): Promise<UserTrendQueryHistory> {
    return this.httpClient.get<UserTrendQueryHistory>(
      `${ApiUrl.USER_TREND_QUERY_HISTORY}${id}`
    );
  }
}
