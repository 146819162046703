<i18n src="@/i18n/components/home-menu/almost-cv.json"></i18n>
<template>
  <div class="menuPanelAlmostCvContent">
    <MenuPanelAlmostCvList :conversions="conversions" />

    <div v-if="hasPeriod" class="menuPanelAlmostCvContent__period">
      ({{ $t("time_period") }}: {{ periodRange }})
    </div>
  </div>
</template>

<script lang="ts">
import { AlmostCvConversion } from "@/models/almost-cv/AlmostCvConversion";
import MenuPanelAlmostCvList from "@/components/home-menu/almost-cv/MenuPanelAlmostCvList.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AlmostCvInfo } from "@/models/almost-cv/AlmostCvInfo";

@Component({ components: { MenuPanelAlmostCvList } })
export default class MenuPanelAlmostCvContent extends Vue {
  @Prop({ type: AlmostCvInfo, required: true })
  almostCvInfo!: AlmostCvInfo;

  get conversions(): AlmostCvConversion[] {
    return this.almostCvInfo.conversions;
  }

  get hasPeriod(): boolean {
    return this.almostCvInfo.period.hasPeriod;
  }

  get periodRange(): string {
    return this.almostCvInfo.period.periodRange;
  }
}
</script>

<style lang="scss" scoped>
.menuPanelAlmostCvContent {
  max-height: 198px;
  padding-bottom: 10px;
}

.menuPanelAlmostCvContent__period {
  margin-top: 10px;
  color: $colorBase700;
  text-align: right;
  font-size: 12px;
}
</style>
