<i18n src="@/i18n/components/home-menu/almost-cv-list.json"></i18n>
<template>
  <div class="menuPanelAlmostCvList customScrollbar">
    <button
      v-for="conversion in conversions"
      :key="conversion.conversionId"
      class="menuPanelAlmostCvList__listItem"
      :disabled="conversion.count <= 0"
      @click="goToAlmostCvView(conversion.conversionId)"
    >
      <span class="menuPanelAlmostCvList__listItemName">
        {{ conversion.conversionName }}
      </span>

      <span
        v-if="conversion.count >= 0"
        class="menuPanelAlmostCvList__listItemCount"
      >
        {{
          $tc("userCount", conversion.count, {
            userCount: conversion.count
          })
        }}
      </span>

      <Tooltip
        v-else
        class="menuPanelAlmostCvList__listItemEmpty"
        :placement="tooltipPlacement"
      >
        <span class="menuPanelAlmostCvList__listItemCount">N/A</span>
        <Icon
          class="menuPanelAlmostCvList__listItemNaIcon"
          :icon="Icons.HelpInvertWhite"
          :color="Colors.Base700"
          :size="10"
        />
        <template #content>
          <span v-t="'tooltip'" />
        </template>
      </Tooltip>
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { AlmostCvConversion } from "@/models/almost-cv/AlmostCvConversion";
import Tooltip from "@/components/Tooltip.vue";
import { TooltipPlacement } from "@/const/tooltip";
import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";

@Component({
  components: {
    Tooltip,
    Icon
  }
})
export default class MenuPanelAlmostCvList extends Vue {
  Icons = Icons;
  Colors = Colors;
  tooltipPlacement = TooltipPlacement.Left;

  @Prop({ type: Array, required: true })
  conversions!: AlmostCvConversion[];

  goToAlmostCvView(conversionId: number) {
    UgTag.pushEvent(UgEventTag.AlmostCv);
    this.$router.push(`almost-cv/${conversionId}`);
  }
}
</script>

<style lang="scss" scoped>
.menuPanelAlmostCvList {
  overflow-y: auto;
  flex: 1;
  /* 5 box, each box height 32px, gap between each box 4px */
  max-height: calc(32px * 5 + 4px * 4);
}

.menuPanelAlmostCvList__listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 6px 12px 6px 10px;
  min-height: 32px;
  width: 100%;
  border-radius: 4px;
  background: $colorWhite;
  color: $colorBase900;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:disabled {
    background: $bgColorDisabled;
    color: $colorDisabled;
    cursor: default;
  }

  &:hover:not(:disabled) {
    background-color: $colorHoverLightForWhite;
    text-decoration: none;
  }
}
.menuPanelAlmostCvList__listItemName {
  margin-right: 10px;
  text-align: left;
  font-size: 14px;
  line-height: 1.2;
}
.menuPanelAlmostCvList__listItemCount {
  color: inherit;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
}
.menuPanelAlmostCvList__listItemEmpty {
  margin-right: -2px;
}
.menuPanelAlmostCvList__listItemNaIcon {
  margin-left: 2px;
}
</style>
