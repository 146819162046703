import { SelectCondition } from "@/models/search/select-condition/SelectCondition";

export const validateDate = (
  selectCondition: SelectCondition | null
): boolean => {
  // 検索条件がない場合は不適
  if (selectCondition === null) {
    return false;
  }

  if ("startDate" in selectCondition && "endDate" in selectCondition) {
    // 2018/09/01以前は集計開始前なので不適
    if (
      selectCondition.startDate !== null &&
      selectCondition.startDate < new Date(2018, 8, 1)
    ) {
      return false;
    }

    // 直近2日間は集計中なので不適
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

    if (
      selectCondition.startDate !== null &&
      selectCondition.startDate > twoDaysAgo
    ) {
      return false;
    }
  }

  return true;
};
