import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";

/**
 * メディア名による条件
 *
 * 広告での絞り込みに、メディア名を条件として付与できる
 *
 */
export class FilterAdMediaCondition {
  public readonly conditionType = FilterNodeConditionType.AdMedia;

  constructor(public readonly mediaName: string) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.mediaName,
      i18n.t("models.search.mediaName") as string
    );
  }
}
