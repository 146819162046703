<i18n src="@/i18n/components/home-menu/lag-cv-list.json"></i18n>
<template>
  <div class="menuPanelLagCvList customScrollbar">
    <router-link
      v-for="conversion in conversionsToRender"
      :key="`${periodType}-${conversion.id}`"
      :class="getItemClass(conversion)"
      :to="getRouteByConversion(conversion.id)"
      :event="conversion.number_of_users === 0 ? '' : 'click'"
      @click.native="
        () => {
          conversion.number_of_users !== 0 && onClick(periodType);
        }
      "
    >
      <span class="menuPanelLagCvList__listItemName">
        {{ conversion.name }}
      </span>

      <span class="menuPanelLagCvList__listItemCount">
        {{
          $tc("userCount", conversion.number_of_users, {
            userCount: conversion.number_of_users
          })
        }}
      </span>
    </router-link>
  </div>
</template>

<script lang="ts">
import LagCvUserCount, {
  LagCvConversion,
  LagCvPeriodType
} from "@/models/lag-cv/LagCvUserCount";
import { Component, Prop, Vue } from "vue-property-decorator";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";

const ALL_CONVERSION_ID = 0;

@Component
export default class MenuPanelLagCvList extends Vue {
  @Prop({ type: Object, required: true })
  userCount!: LagCvUserCount;

  get periodType(): LagCvPeriodType {
    return this.userCount.periodType;
  }

  get conversions(): LagCvConversion[] {
    return this.userCount.conversions;
  }

  get conversionsToRender(): LagCvConversion[] {
    if (this.conversions.length === 1) {
      return this.conversions;
    }
    const allConverionsItem = {
      id: ALL_CONVERSION_ID,
      name: this.$t("all_conversions") as string,
      number_of_users: this.userCount.numberOfUsers
    };
    return [allConverionsItem, ...this.conversions];
  }

  getItemClass(conversion: LagCvConversion): string {
    const baseClass = "menuPanelLagCvList__listItem";
    const classes = [baseClass];

    if (conversion.number_of_users === 0) {
      classes.push(`${baseClass}--disabled`);
    }
    return classes.join(" ");
  }

  getRouteByConversion(conversionId: number): string {
    if (conversionId === ALL_CONVERSION_ID) {
      return `lagcv/${this.periodType}`;
    }
    return `lagcv/${this.periodType}/${conversionId}`;
  }

  onClick(periodType: LagCvPeriodType) {
    let eventTag: UgEventTag = UgEventTag.LagCvYesterday;

    if (periodType === LagCvPeriodType.OneWeek) {
      eventTag = UgEventTag.LagCvOneWeek;
    } else if (periodType === LagCvPeriodType.FourWeeks) {
      eventTag = UgEventTag.LagCvFourWeeks;
    }

    UgTag.pushEvent(eventTag);
  }
}
</script>

<style lang="scss" scoped>
.menuPanelLagCvList {
  overflow-y: auto;
  flex: 1;
  max-height: 160px;
}

.menuPanelLagCvList__listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 4px;
  padding: 6px 12px 6px 10px;
  min-height: 32px;
  width: 100%;
  border-radius: 4px;
  background: $colorWhite;
  color: $colorBase900;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $colorHoverLightForWhite;
    text-decoration: none;
  }

  &--disabled,
  &--disabled:hover {
    background: $bgColorDisabled;
    color: $colorDisabled;
    cursor: default;
  }
}
.menuPanelLagCvList__listItemName {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  margin-right: 10px;
  text-align: left;
  text-overflow: ellipsis;
  font-size: $basicFontSize;
  line-height: 1.2;

  -webkit-line-clamp: 2;
}
.menuPanelLagCvList__listItemCount {
  color: inherit;
  white-space: nowrap;
  font-weight: 600;
  font-size: $basicFontSize;
}
</style>
