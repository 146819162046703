import { MutationTree, GetterTree, ActionTree } from "vuex";
import { i18n } from "@/i18n";
import { RootState } from "@/store/";
import { sleep, generateUniqueId } from "@/util/common-util";

import {
  SegmentedTrendResultJsonData,
  SegmentedTrendStatus,
  AnalysisType
} from "@/api/apis/ApiSegmentedTrends";
import { SegmentedTrendResult } from "@/models/segmented-trends/SegmentedTrendResult";
import { DateFormat, formatDate } from "@/util/date-util";
import { validateDate } from "@/models/segmented-trends/SegmentedTrend";

// 再度APIを叩くまでの待機時間を5秒とする
const FETCH_INTERVAL_MSEC = 5000;
// 120秒(5秒 x 24回)で結果が返らない場合はタイムアウトとする
const TIMEOUT_COUNT = 24;
// デフォルトでは1ページで 20行 表示させる
const DEFAULT_PER_PAGE = 20;
// デフォルトでは 1ページ目 を表示させる
const DEFAULT_PAGE = 1;
// 存在するデータのIDとは被らないデフォルトのID
const SEGMENTED_TRENDS_UNSELECTED_VALUE = -1;

const analysisNameMap = {
  "web-view": "store.segmentedTrends.pageAnalysis",
  landing: "store.segmentedTrends.landingPageAnalysis",
  inflow: "store.segmentedTrends.inflowAnalysis",
  "inflow-detail": "store.segmentedTrends.inflowAnalysis",
  "app-view": "store.segmentedTrends.appViewAnalysis",
  event: "store.segmentedTrends.eventAnalysis",
  conversion: "store.segmentedTrends.conversionAnalysis"
};

// セグメント別利用傾向分析のstateクラス
export class SegmentedTrendBaseState {
  resultJson: SegmentedTrendResultJsonData[] = [];
  isLoading: boolean = false;
  perPage: number = DEFAULT_PER_PAGE;
  currentPage: number = DEFAULT_PAGE;
  currentId: number = SEGMENTED_TRENDS_UNSELECTED_VALUE;
  totalRows: number | null = null;
  domain: string | null = null;
  csvDownloading: boolean = false;
  lastExecuteTimeStamp: string | null = null;

  // page毎に発行される一時的なtokenを保持
  resumeTokens: { [page: number]: string } = {};

  // インスタンス毎に異なるanalysisTypeを持つ
  constructor(readonly analysisType: AnalysisType) {}
}

const mutations = <MutationTree<SegmentedTrendBaseState>>{
  setResults(
    state: SegmentedTrendBaseState,
    resultJson: SegmentedTrendResultJsonData[]
  ) {
    state.resultJson = resultJson;
  },
  setIsLoading(state: SegmentedTrendBaseState, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  setPerPage(state: SegmentedTrendBaseState, perPage: number) {
    state.perPage = perPage;
  },
  setCurrentPage(state: SegmentedTrendBaseState, currentPage: number) {
    state.currentPage = currentPage;
  },
  setCurrentId(state: SegmentedTrendBaseState, id: number) {
    state.currentId = id;
  },
  setDomain(state: SegmentedTrendBaseState, domain: string | null) {
    state.domain = domain;
  },
  setResumeToken(
    state: SegmentedTrendBaseState,
    pageToken: { page: number; token: string }
  ) {
    const resumeTokens = { ...state.resumeTokens };
    resumeTokens[pageToken.page] = pageToken.token;

    state.resumeTokens = resumeTokens;
  },
  resetResumeToken(state: SegmentedTrendBaseState) {
    state.resumeTokens = {};
  },
  resetData(state: SegmentedTrendBaseState) {
    state.resultJson = [];
    state.isLoading = false;
    state.currentPage = DEFAULT_PAGE;
    state.domain = null;
    state.totalRows = null;
    state.resumeTokens = {};
    state.lastExecuteTimeStamp = null;
  },
  setTotalRows(state: SegmentedTrendBaseState, totalRows: number) {
    state.totalRows = totalRows;
  },
  setCsvDownloading(state: SegmentedTrendBaseState, csvDownloading: boolean) {
    state.csvDownloading = csvDownloading;
  },
  setLastExecuteTimeStamp(state, timeStamp: string) {
    state.lastExecuteTimeStamp = timeStamp;
  }
};

const getters = <GetterTree<SegmentedTrendBaseState, RootState>>{
  results(state): SegmentedTrendResult[] {
    return state.resultJson.map(SegmentedTrendResult.fromJson);
  }
};

const actions = <ActionTree<SegmentedTrendBaseState, RootState>>{
  async executeAnalysis({ dispatch, commit, state, rootState }) {
    const isFilterMode: boolean = rootState.filter.isFilterMode;
    const historyId: number | null = isFilterMode
      ? rootState.filter.filterHistoryId
      : rootState.search.historyId;
    const selectCondition = rootState.search.selectCondition;

    const isClusteringMode: boolean = rootState.clustering.isClusteringMode;
    const loginUser = rootState.auth.user;

    // ユーザ検索後の historyId が必ず必要
    // すでにデータがある場合は新しいデータを取得しない
    // クラスタリング時、UIのみではなくstore側もロック
    // ロード中は再度フェッチさせない
    // ゲストユーザーの場合はフェッチさせない
    if (
      historyId === null ||
      state.resultJson.length > 0 ||
      isClusteringMode ||
      state.isLoading ||
      !loginUser ||
      !loginUser.permission.isAvailableUserList ||
      !validateDate(selectCondition)
    ) {
      return;
    }

    const executeTimeStamp = generateUniqueId();
    commit("setLastExecuteTimeStamp", executeTimeStamp);

    await dispatch("initialize");

    const response = await rootState.api.segmentedTrends.createNew(
      state.analysisType,
      historyId,
      state.domain
    );

    // 新しい解析が実行された場合はキャンセル
    if (executeTimeStamp != state.lastExecuteTimeStamp) {
      return;
    }

    commit("setCurrentId", response.id);

    const isValid: boolean = await dispatch("checkStatus", executeTimeStamp);

    if (isValid) {
      await dispatch("fetchResults");
    } else {
      commit("setIsLoading", false);
    }
  },
  async initialize({ commit }) {
    commit("setResults", []);
    commit("setIsLoading", true);
  },
  async checkStatus({ state, rootState }, timeStamp: string) {
    const currentId = state.currentId;
    let count = 0;

    let { status } = await rootState.api.segmentedTrends.getStatus(
      state.analysisType,
      currentId
    );

    // 結果データが作成されるまで FETCH_INTERVAL_MSEC おきに status を確認
    while (status === SegmentedTrendStatus.RUNNING) {
      // タイムアウト処理
      if (count > TIMEOUT_COUNT) {
        throw new Error(i18n.t("store.segmentedTrends.apiTimeout") as string);
      }

      await sleep(FETCH_INTERVAL_MSEC);

      ({ status } = await rootState.api.segmentedTrends.getStatus(
        state.analysisType,
        currentId
      ));
      count++;

      // 新しい解析が実行された場合はキャンセル
      if (timeStamp != state.lastExecuteTimeStamp) {
        return false;
      }
    }

    if (timeStamp != state.lastExecuteTimeStamp) {
      return false;
    }

    return status === SegmentedTrendStatus.OK;
  },
  async fetchResults({ dispatch, commit, state, rootState }) {
    dispatch("initialize");

    // 1ページ目は resumeToken を持たない
    const resumeToken: string | null =
      state.currentPage === 1 ? null : state.resumeTokens[state.currentPage];

    const results: {
      result: SegmentedTrendResultJsonData[];
      resume_token: string;
      total_rows: number;
    } = await rootState.api.segmentedTrends.getResults(
      state.analysisType,
      state.currentId,
      resumeToken,
      state.perPage
    );

    commit("setResults", results.result);
    commit("setResumeToken", {
      page: state.currentPage + 1,
      token: results.resume_token
    });
    if (state.totalRows === null) {
      commit("setTotalRows", results.total_rows);
    }
    commit("setIsLoading", false);
  },
  async downloadCsv({ commit, state, rootState }) {
    commit("setCsvDownloading", true);

    const response: string = await rootState.api.segmentedTrends.getCsv(
      state.analysisType,
      state.currentId
    );

    let filename: string = i18n.t(
      "store.segmentedTrends.csvFileNamePrefix"
    ) as string;
    filename += (i18n.t(analysisNameMap[state.analysisType]) as string) + "_";
    filename += formatDate(DateFormat.yyyyMMdd_HHmmss, new Date()) + ".csv";

    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(
      new Blob(["\ufeff", response], { type: "text/csv;" })
    );

    link.download = filename;

    document.body.appendChild(link); // for Firefox etc.
    link.click();
    document.body.removeChild(link); // for Firefox etc.

    commit("setCsvDownloading", false);
  }
};

export const webView = {
  namespaced: true,
  state: new SegmentedTrendBaseState(AnalysisType.WebView),
  mutations,
  getters,
  actions
};

export const landing = {
  namespaced: true,
  state: new SegmentedTrendBaseState(AnalysisType.Landing),
  mutations,
  getters,
  actions
};

export const inflow = {
  namespaced: true,
  state: new SegmentedTrendBaseState(AnalysisType.Inflow),
  mutations,
  getters,
  actions
};

export const inflowDetail = {
  namespaced: true,
  state: new SegmentedTrendBaseState(AnalysisType.InflowDetail),
  mutations,
  getters,
  actions
};

export const appView = {
  namespaced: true,
  state: new SegmentedTrendBaseState(AnalysisType.AppView),
  mutations,
  getters,
  actions
};

export const event = {
  namespaced: true,
  state: new SegmentedTrendBaseState(AnalysisType.Event),
  mutations,
  getters,
  actions
};

export const conversion = {
  namespaced: true,
  state: new SegmentedTrendBaseState(AnalysisType.Conversion),
  mutations,
  getters,
  actions
};
