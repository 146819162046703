<i18n src="@/i18n/views/funnel.json"></i18n>
<template>
  <div class="funnel">
    <FunnelHeader :title="$t('funnelAnalysis')" />
    <FunnelPrimaryContent class="funnel__content">
      <Button v-t="'createNew'" width="350px" @click="createNewFunnel" />
    </FunnelPrimaryContent>

    <div class="funnel__history">
      <FunnelInputTitle
        :title="$t('funnelHistory')"
        :note="$t('latestCases')"
        class="funnel__historyTitle"
      />

      <Loading v-if="!isHistoryFetched" height="80px" />
      <div v-else-if="funnelHistory.length === 0" class="funnel__noHistoryArea">
        <div v-t="'noHistory'" class="funnel__noHistoryDescription" />
      </div>
      <div v-else class="funnel__historyContainer">
        <FunnelSearchHistoryItem
          v-for="(funnel, i) in funnelHistory"
          :key="'funnel' + i"
          class="funnel__historyItem"
          :analysis-condition="funnel"
          :all-definitions="funnelDataDefinitions"
          :user-attribute-text="getUserAttributeText(funnel.userAttributes)"
          :user-activity-text="getUserActivityText(funnel)"
          @edit="onClickHistoryItem(i)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { FunnelAnalysisHistory } from "@/models/funnel/FunnelAnalysisCondition";
import { FunnelConditionAttributeType } from "@/models/funnel/FunnelConditionAttribute";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import Loading from "@/components/Loading.vue";
import FunnelHeader from "@/components/funnel/FunnelHeader.vue";
import FunnelPrimaryContent from "@/components/funnel/FunnelPrimaryContent.vue";
import FunnelInputTitle from "@/components/funnel/FunnelInputTitle.vue";
import FunnelSearchHistoryItem from "@/components/funnel/FunnelSearchHistoryItem.vue";
import Button from "@/components/Button.vue";
import { handleError } from "@/util/error-util";
import {
  FunnelDataDefinitionType,
  getActivityString
} from "@/util/funnel-util";
import { FunnelStatus } from "@/const/funnel";

@Component({
  components: {
    Loading,
    FunnelHeader,
    FunnelPrimaryContent,
    FunnelInputTitle,
    FunnelSearchHistoryItem,
    Button
  }
})
export default class Funnel extends Vue {
  isHistoryFetched = false;

  mounted() {
    this.$store.commit("funnel/setStatus", FunnelStatus.NOT_STARTED);
    this.$store
      .dispatch("funnel/fetchFunnelHistory")
      .then(() => {
        this.isHistoryFetched = true;
      })
      .catch(handleError);
  }

  createNewFunnel() {
    this.$store.dispatch("funnel/resetFunnel");
    this.$router.push({
      name: "funnel-analysis-create"
    });
  }

  onClickHistoryItem(index: number) {
    this.$store.dispatch("funnel/resetFunnel").then(() => {
      const funnelHistory: FunnelAnalysisHistory = this.funnelHistory[index];
      this.$store.commit("funnel/setCurrentId", funnelHistory.id);

      const condition = funnelHistory.toFunnelCondition();
      this.$store.commit("funnel/setCondition", condition);
      this.$store.commit("funnel/setUserAttributes", condition.userAttributes);
      this.$store.commit("funnel/setUserActivities", condition.userActivities);
    });
  }

  get funnelHistory(): FunnelAnalysisHistory[] {
    return this.$store.state.funnel.funnelHistory;
  }

  get funnelDataDefinitions(): FunnelDataDefinitionType[] {
    return this.$store.getters["funnel/funnelDataDefinitions"];
  }

  get userAttributesNames(): { [key: number]: string } {
    const activeUserAttributes: UserAttributeDefinition[] = this.$store.state
      .clientSettings.activeUserAttributeDefinitions;

    const userAttributesNames: { [key: number]: string } = {};
    activeUserAttributes.forEach(ua => {
      userAttributesNames[ua.id] = ua.name;
    });
    return userAttributesNames;
  }

  get conversionAttributeDefinitions() {
    return this.$store.state.clientSettings.conversionAttributeDefinitions;
  }

  getUserAttributeText(userAttributes: FunnelConditionAttributeType[]): string {
    const attributes = userAttributes.map(
      a => `${this.userAttributesNames[a.attributeId]}: ${a.displayValue}`
    );
    return attributes.join(" / ");
  }

  getUserActivityText(condition: FunnelAnalysisHistory): string {
    return getActivityString(
      condition,
      this.funnelDataDefinitions,
      this.conversionAttributeDefinitions
    );
  }
}
</script>

<style lang="scss" scoped>
.funnel {
  padding: 0 $appContentPadding;
}
.funnel__content {
  margin-top: 35px;
  padding: 25px 20px;
  text-align: center;
}
.funnel__history {
  margin-top: 30px;
}
.funnel__historyTitle {
  margin-bottom: 5px;
}
.funnel__noHistoryArea {
  border-radius: 5px;
  background-color: $colorGray100;
}
.funnel__noHistoryDescription {
  padding-top: 40px;
  padding-bottom: 40px;
  color: $colorBase900;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
}
.funnel__historyItem {
  margin-bottom: 15px;
}
</style>
