import { Colors } from "@/const/Colors";
import { groupBy, GroupingResult } from "@/util/list-util";
import {
  DateFormat,
  formatDate,
  translateMonthToString,
  translateMonthAndYearToString
} from "@/util/date-util";
import { BusinessEventOverview } from "@/models/overview/BusinessEventOverview";
import { ContactOverview } from "@/models/overview/ContactOverview";
import { NpsOverview } from "@/models/overview/NpsOverview";
import { EnqueteOverview } from "@/models/overview/EnqueteOverview";
import { VisitOverview } from "@/models/overview/VisitOverview";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { SelectByContactCondition } from "@/models/search/select-condition/SelectByContactCondition";
import { SelectByBusinessEventCondition } from "@/models/search/select-condition/SelectByBusinessEventCondition";
import { SelectByConversionCondition } from "@/models/search/select-condition/SelectByConversionCondition";
import { SelectByNpsCondition } from "@/models/search/select-condition/SelectByNpsCondition";
import { SelectByAppCondition } from "@/models/search/select-condition/SelectByAppCondition";
import { SelectByEngagementCondition } from "@/models/search/select-condition/SelectByEngagementCondition";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { Msec } from "@/util/date-util";

export const CHART_BALLOON_OFFSET = 5;
export const CHART_BALLOON_ADJUST_X = 1;

export const CONTACT_ICON_SIZE: number = 16;
export const CONTACT_ICON_Y: number = 35;

export const BUSINESS_EVENT_WIDTH: number = 42;
export const BUSINESS_EVENT_HEIGHT: number = 26;
export const BUSINESS_EVENT_Y: number = 8;
export const BUSINESS_EVENT_BACK_COLOR = Colors.White;
export const BUSINESS_EVENT_TEXT_COLOR_MATCHED = Colors.White;
export const BUSINESS_EVENT_STROKE_COLOR = Colors.Base500;

export const SCROLLBAR_CHART_SPACE: number = 10;

export const PAPER_HEIGHT_DEFAULT: number = 120;
export const PAPER_HEIGHT_OMO: number = 140;

export const BAR_MONTH_WIDTH: number = 12;
export const BAR_YEAR_WIDTH: number = 4;

export const BAR_MAX_HEIGHT: number = 46;
export const BAR_MIN_HEIGHT: number = 8;
export const BAR_PV_0_HEIGHT: number = 3;

export const COLOR_DEFAULT = Colors.Base600;
export const COLOR_MATCHED = Colors.Base900;

export const ALL_PLOT_PERIOD: number = 24;
export const START_LINE_X: number = 10;
export const BASELINE_EDGE_LENGTH: number = 25;
export const SIX_MONTH_BASELINE_EDGE_LENGTH: number = 62;
export const DAYS_IN_SHOWN_BASELINE_LENHTH: number = 40;

export const BASELINE_Y_DEFAULT: number = 85;
export const BASELINE_Y_OMO: number = 105;
export const SCOREBOX_Y: number = BASELINE_Y_OMO + 17;
export const SCOREBOX_HEIGHT: number = 17;
export const SCOREBOX_FONT_SIZE: number = 10;
export const SCOREBOX_DEFAULT_SCALE: number = 1;

export const MAGNIFICATION_HOVER_EFFECT: number = 1.2;

export const GRIDLINE_HEIGHT = 10;
export const GRIDLINE_GROUP_HEIGHT = GRIDLINE_HEIGHT + 17;

export const CHART_COLORED_PERIOD_STROKE_WIDTH = 1;

export function getBaselineY(isOmo: boolean) {
  return isOmo ? BASELINE_Y_OMO : BASELINE_Y_DEFAULT;
}

export function getPaperHeight(isOmo: boolean) {
  return isOmo ? PAPER_HEIGHT_OMO : PAPER_HEIGHT_DEFAULT;
}

export function getBarWidth(isYear: boolean) {
  return isYear ? BAR_YEAR_WIDTH : BAR_MONTH_WIDTH;
}

export function getBarHeight(allPv: number): number {
  if (allPv === 0) {
    return BAR_PV_0_HEIGHT;
  }

  const height = 2 * allPv;
  if (height > BAR_MAX_HEIGHT) {
    return BAR_MAX_HEIGHT;
  } else if (height < BAR_MIN_HEIGHT) {
    return BAR_MIN_HEIGHT;
  } else {
    return height;
  }
}

export function isInList(
  overview: ContactOverview | VisitOverview | BusinessEventOverview,
  gramIds: string[]
): boolean {
  return gramIds.some(id => id === overview.gramId);
}

export function convertTimeToX(
  firstScaleDate: Date,
  lastScaleDate: Date,
  lastScaleX: number,
  baselineLength: number,
  unixtime: number
): number {
  // sclae barの表示期間とscale barの長さの比を利用してx座標を求める。

  const daysInScale: number = getDiffDays(firstScaleDate, lastScaleDate);
  const diffTime: number = lastScaleDate.getTime() - unixtime;
  // unixtimeとlastScaleDateの時間差をlastScaleXとの距離の差に変換する。
  const diffX: number =
    (baselineLength / (daysInScale * 24 * 60 * 60 * 1000)) * diffTime;

  return lastScaleX - diffX;
}

export function getXAdjustWidth(x: number, width: number): number {
  // return x point adjusted in response to object width
  return x - width / 2;
}

export function getDiffDays(formerDate: Date, latterDate: Date): number {
  const diffUnixtime: number = latterDate.getTime() - formerDate.getTime();
  const diffDays: number = Math.floor(diffUnixtime / (1000 * 60 * 60 * 24));
  return diffDays;
}

/**
 * return a list like [ { "date1": [contact1, contact2,] }, { "date2": [contact10, ] } ]
 */
export function getListGroupedByDate<
  T extends ContactOverview | BusinessEventOverview
>(overviews: T[]): GroupingResult<T> {
  return groupBy(overviews, (e: T) =>
    formatDate(DateFormat.yyyy年M月d日E, e.date)
  );
}

export function getEndDate(selectCondition: SelectCondition | null): Date {
  if (selectCondition === null) {
    return new Date();
  }

  if (
    (selectCondition instanceof SelectByContactCondition ||
      selectCondition instanceof SelectByBusinessEventCondition ||
      selectCondition instanceof SelectByConversionCondition ||
      selectCondition instanceof SelectByAppCondition ||
      selectCondition instanceof SelectByUserIdCondition) &&
    selectCondition.endDate !== null
  ) {
    return selectCondition.endDate;
  } else if (selectCondition instanceof SelectByNpsCondition) {
    return selectCondition.date;
  } else if (selectCondition instanceof SelectByEngagementCondition) {
    return selectCondition.period.max;
  } else {
    return new Date();
  }
}

/**
 * Rearrange components in a list.
 * Put filterMatched gram behind.
 */
export function getOverviewsMatchedBehind<
  T extends ContactOverview | BusinessEventOverview
>(overviews: T[], filterMatchedIds: string[]): T[] {
  const matchedOverviews: T[] = [];
  const notmatchedOverviews: T[] = [];
  for (const overview of overviews) {
    if (isInList(overview, filterMatchedIds)) {
      matchedOverviews.push(overview);
    } else {
      notmatchedOverviews.push(overview);
    }
  }
  return notmatchedOverviews.concat(matchedOverviews);
}

/**
 * Rearrange components in a list.
 * Put funnelMatched gram behind.
 */
export function getOverviewsFunnelMatchedBehind<
  T extends ContactOverview | BusinessEventOverview
>(
  overviews: T[],
  funnelMatchedSelectedGramIds: string[],
  funnelMatchedGramIds: string[]
): T[] {
  const matchedOverviews: T[] = [];
  const notmatchedOverviews: T[] = [];
  const selectedOverviews: T[] = [];
  for (const overview of overviews) {
    if (isInList(overview, funnelMatchedSelectedGramIds)) {
      selectedOverviews.push(overview);
    } else if (isInList(overview, funnelMatchedGramIds)) {
      matchedOverviews.push(overview);
    } else {
      notmatchedOverviews.push(overview);
    }
  }
  return notmatchedOverviews.concat(matchedOverviews).concat(selectedOverviews);
}

export function getNextMonthFirst(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0);
}

/**
 * 全ての1月には年をつける
 * 最初のラベルにも年をつけるが、12月の場合はつけない。隣の文字列と被ってしまうから。
 */
export function getMonthlyDateStrings(dates: Date[]): string[] {
  const dateTexts: string[] = [];

  if (dates.length == 0) {
    return dateTexts;
  }

  const firstDate: Date = dates[0];
  if (firstDate.getMonth() === 11) {
    dateTexts.push(translateMonthToString(firstDate.getMonth() + 1));
  } else {
    dateTexts.push(
      translateMonthAndYearToString(
        firstDate.getMonth() + 1,
        firstDate.getFullYear()
      )
    );
  }

  for (let i = 1; i < dates.length; i++) {
    dateTexts.push(translateDateToString(dates[i]));
  }

  return dateTexts;
}

// 1月だったら年をつける。それ以外は月のみ。
export function translateDateToString(date: Date): string {
  const month: number = date.getMonth() + 1; // add 1 because january == 0

  if (month === 1) {
    const year: number = date.getFullYear();
    return translateMonthAndYearToString(month, year);
  }

  return translateMonthToString(month);
}

export function getMonthlyDates(dateStart: Date, allPlotMonth: number): Date[] {
  const elapsedMonths: number[] = [];
  for (let i = 0; i < new Array(allPlotMonth + 1).length; i++) {
    elapsedMonths.push(i);
  }

  return elapsedMonths.map(elapsedMonth => {
    const tmpDate = new Date(dateStart.getTime());
    tmpDate.setMonth(tmpDate.getMonth() + elapsedMonth);
    return tmpDate;
  });
}

export function getOneMonthDateStrings(
  firstScaleDate: Date,
  lastScaleDate: Date
): string[] {
  const date = new Date(firstScaleDate);
  const dateRange: string[] = [];
  const gradations: number = getDiffDays(firstScaleDate, lastScaleDate);

  for (let i = 0; i <= gradations; i++) {
    dateRange.push(`${date.getMonth() + 1}/${date.getDate()}`);
    date.setDate(date.getDate() + 1);
  }

  return dateRange;
}

/**
 * return array of ColorPeriods if arg is instance of SelectByEngagementCondition
 * This method is used for GamChart to draw rectangles which is shown to indicate the period.
 *
 * @param selectCondition
 * @return array of ColorPeriods
 */
export function getColoredPeriods(selectCondition: SelectCondition) {
  if (
    selectCondition instanceof SelectByEngagementCondition ||
    selectCondition instanceof SelectByContactCondition ||
    selectCondition instanceof SelectByBusinessEventCondition ||
    selectCondition instanceof SelectByConversionCondition ||
    selectCondition instanceof SelectByAppCondition
  ) {
    return selectCondition.coloredPeriods;
  }
  return [];
}

export function formatPeriodOfColoredPeriod(
  coloredPeriod: ColoredPeriod
): string {
  return (
    formatDate(DateFormat.yyyysMsd, coloredPeriod.startDate) +
    " - " +
    formatDate(DateFormat.yyyysMsd, coloredPeriod.endDate)
  );
}

/**
 * generate a list of gram ids included in sortedDescVisitOverviews from a list of gram ids
 * gramIdsを元にvisitOverviewsの中の該当するgramIdの配列を返す。
 * visitOverviewsのgramIdは訪問先頭のgramIdだか、関数に渡すgramIdsは訪問の間のgramId場合がある
 * なのでvisitOverviewのgramIdより大きければ、その訪問に含まれると判断し、そのgramIdを返す
 * 返したgramIdは訪問バーに色をつけるかの判断に利用される
 * @param gramIds string[] array of gram id
 * @param visitOverviews VisitOverview[] this param sorted by new date.
 * @returns string[] array of gram id in sortedDescVisitOverviews
 */
export function getGramIdsInVisitOverviews(
  gramIds: string[],
  visitOverviews: VisitOverview[]
): string[] {
  const overviewGramIds: string[] = [];
  for (const gramId of gramIds) {
    // gramId has time when gram generated. "1545526861000000:VID-B-3001-01:0"
    const elements = gramId.split(":");
    const matchedTime = Number(elements[0]);
    if (elements.length !== 3 || matchedTime === 0) continue;

    for (const visitOverview of visitOverviews) {
      const visitTime = Number(visitOverview.gramId.split(":")[0]);
      if (visitTime <= matchedTime) {
        overviewGramIds.push(visitOverview.gramId);
        break;
      }
    }
  }
  return overviewGramIds;
}

export function getMaxUnixtimeInOverviews(
  overviews:
    | VisitOverview[]
    | NpsOverview[]
    | BusinessEventOverview[]
    | EnqueteOverview[]
    | ContactOverview[]
): Msec {
  if (overviews.length === 0) {
    return -Infinity;
  }
  return overviews[overviews.length - 1].date.getTime();
}

export function getMinUnixtimeInOverviews(
  overviews:
    | VisitOverview[]
    | NpsOverview[]
    | BusinessEventOverview[]
    | EnqueteOverview[]
    | ContactOverview[]
): Msec {
  if (overviews.length === 0) {
    return Infinity;
  }
  return overviews[0].date.getTime();
}
