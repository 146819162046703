import { render, staticRenderFns } from "./NotificationNav.vue?vue&type=template&id=188025e2&scoped=true"
import script from "./NotificationNav.vue?vue&type=script&lang=ts"
export * from "./NotificationNav.vue?vue&type=script&lang=ts"
import style0 from "./NotificationNav.vue?vue&type=style&index=0&id=188025e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "188025e2",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/notification.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fviews%2FNotificationNav.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports