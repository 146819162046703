<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterBaseInputText
    v-model="value"
    :prefix="$t('containsPrefix')"
    :suffix="$te('containsSuffix') ? $t('containsSuffix') : ''"
    class="filter-ad-campaign-form"
  />
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterAdCampaignCondition } from "@/models/search/filter-node-condition/FilterAdCampaignCondition";
import FilterBaseInputText from "@/components/filter/form/FilterBaseInputText.vue";

@Component({
  components: {
    FilterBaseInputText
  }
})
export default class FilterAdCampaignField extends Vue {
  @Model("input", { type: FilterAdCampaignCondition, required: true })
  condition!: FilterAdCampaignCondition;

  onInput(condition: FilterAdCampaignCondition) {
    this.$emit("input", condition);
  }

  get value(): string {
    return this.condition.campaignName;
  }
  set value(campaignName: string) {
    this.onInput(new FilterAdCampaignCondition(campaignName));
  }
}
</script>
