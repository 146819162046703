import HttpClient from "@/api/HttpClient";
import { ApiUrl } from "@/api/api-url";
import { ApiRes } from "@/api/api-res";
import { ApiParam } from "@/api/api-param";

export default class ApiUserdata {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * 指定した複数のユーザIDから複数ユーザ情報を取得
   */
  async getUserInfoList(userIds: string[]): Promise<ApiRes.UserInfo[]> {
    const res = await this.httpClient.post<{ users: ApiRes.UserInfo[] }>(
      ApiUrl.USER_INFO_LIST,
      { user_ids: userIds }
    );

    return res.users;
  }

  /**
   * プロフィールオーダーリストを取得
   */
  public getProfileOrderList = (): Promise<ApiRes.ProfileOrders> => {
    return this.httpClient.get<ApiRes.ProfileOrders>(ApiUrl.PROFILE_ORDER_LIST);
  };

  /**
   * お気に入りリストを取得
   */
  public getFavoriteList = (): Promise<ApiRes.FavoriteList> => {
    return this.httpClient.get<ApiRes.FavoriteList>(ApiUrl.FAVORITE_LIST);
  };

  /**
   * お気に入りを保存
   */
  public saveFavorite = (
    favorite: ApiParam.SaveFavorite
  ): Promise<ApiRes.SaveFavorite> => {
    return this.httpClient.post<ApiRes.SaveFavorite>(
      ApiUrl.SAVE_FAVORITE,
      favorite
    );
  };

  /**
   * お気に入りを削除
   */
  public deleteFavorites = (
    userIds: ApiParam.DeleteFavorites
  ): Promise<ApiRes.DeleteFavorites> => {
    return this.httpClient.post<ApiRes.DeleteFavorites>(
      ApiUrl.DELETE_FAVORITES,
      userIds
    );
  };
}
