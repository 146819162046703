import {
  VisitSummaryJsonData,
  VisitSummaryInflowType,
  VisitSummaryType,
  VisitSummaryWithdrawalType
} from "@/api/apis/ApiVisits";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { i18n } from "@/i18n";
import { getVisitSummaryStayTime } from "@/util/date-util";
import { getConversionNameFromConversionId } from "@/util/definition-util";

export class VisitSummaryRowData {
  constructor(
    public readonly type: VisitSummaryType,
    public readonly count: number,
    public readonly url: string,
    public readonly name: string,
    public readonly stayTimeSec: number
  ) {}

  public get stayTime(): string {
    if (this.type !== VisitSummaryType.PV) {
      return "";
    }
    return getVisitSummaryStayTime(this.stayTimeSec);
  }

  private static getName(
    json: VisitSummaryJsonData,
    cvDefs: ConversionDefinition[],
    gCvDefs: ConversionDefinition[]
  ): string {
    switch (json.summary_type) {
      case VisitSummaryType.CV:
        return VisitSummaryRowData.getCvName(json.cv_id, cvDefs, gCvDefs);
      case VisitSummaryType.INFLOW:
        return VisitSummaryRowData.getInflowName(
          json.summary_sub_type,
          json.name
        );
      case VisitSummaryType.EVENT:
      case VisitSummaryType.PV:
        return VisitSummaryRowData.getRawName(json.name);
      case VisitSummaryType.WITHDRAWAL:
        if (json.summary_sub_type === VisitSummaryWithdrawalType.CV) {
          return VisitSummaryRowData.getCvName(json.cv_id, cvDefs, gCvDefs);
        }
        return VisitSummaryRowData.getRawName(json.name);
      default:
        throw new Error("failed getName.");
    }
  }

  private static getRawName(name: string | null): string {
    if (name === null) {
      throw new Error("invalid params. name is null.");
    }
    return name;
  }

  private static getCvName(
    cvId: number | null,
    cvDefs: ConversionDefinition[],
    gCvDefs: ConversionDefinition[]
  ): string {
    if (cvId === null) {
      throw new Error("invalid params. cvId is null.");
    }
    return getConversionNameFromConversionId(cvId, cvDefs, gCvDefs);
  }

  private static getInflowName(
    inflowType: VisitSummaryInflowType | null,
    name: string | null
  ): string {
    if (name === null) {
      throw new Error("invalid params. name is null.");
    }
    switch (inflowType) {
      case VisitSummaryInflowType.AD:
        return i18n.t("models.visitSummary.visitSummaryRowData.inflowAd", {
          name
        }) as string;
      case VisitSummaryInflowType.SEARCH_ENGINE:
        return name;
      case VisitSummaryInflowType.DOMAIN:
        return i18n.t("models.visitSummary.visitSummaryRowData.inflowDomain", {
          name
        }) as string;
      default:
        throw new Error(
          "invalid params. summary_sub_type is null or unexpected."
        );
    }
  }

  private static getUrl(
    type: VisitSummaryType,
    subType: VisitSummaryWithdrawalType | null,
    url: string | null
  ): string {
    const isUrlRequired: boolean =
      type === VisitSummaryType.PV ||
      (type === VisitSummaryType.WITHDRAWAL &&
        subType === VisitSummaryWithdrawalType.PV);

    if (isUrlRequired) {
      if (url === null) {
        throw new Error(`invalid params. url is null but type is ${type}.`);
      }
      return url;
    }
    return "";
  }

  private static getStayTimeSec(
    type: VisitSummaryType,
    stayTimeSec: number | null
  ): number {
    if (type === VisitSummaryType.PV) {
      if (stayTimeSec === null) {
        throw new Error(`invalid params. stay is null but type is ${type}.`);
      }
    }
    return stayTimeSec || 0;
  }

  public static build(
    json: VisitSummaryJsonData,
    conversionDefinitions: ConversionDefinition[],
    globalConversionDefinitions: ConversionDefinition[]
  ): VisitSummaryRowData {
    const name = VisitSummaryRowData.getName(
      json,
      conversionDefinitions,
      globalConversionDefinitions
    );
    const url = VisitSummaryRowData.getUrl(
      json.summary_type,
      json.summary_sub_type,
      json.url
    );
    const stay = VisitSummaryRowData.getStayTimeSec(
      json.summary_type,
      json.stay_time_sec
    );
    return new VisitSummaryRowData(
      json.summary_type,
      json.count,
      url,
      name,
      stay
    );
  }
}
