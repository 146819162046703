<i18n src="@/i18n/components/funnel/funnel-edit-link.json"></i18n>
<template>
  <div class="funnelEditLink" @click="onClickFunnelEditLink">
    <Icon :icon="icon" :color="color" :size="size" />

    <div
      v-t="'editCondition'"
      class="funnelEditLink__text"
      :class="{ 'funnelEditLink__text--hover': enableTextUnderline }"
      :style="textStyle"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { FunnelEditLinkType, FunnelEditLinkSize } from "@/const/funnel";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";

@Component({
  components: {
    Icon
  }
})
export default class FunnelEditLink extends Vue {
  @Prop({ type: String, default: FunnelEditLinkType.DEFAULT })
  color!: FunnelEditLinkType;

  @Prop({ type: String, default: FunnelEditLinkSize.REGULAR })
  size!: FunnelEditLinkSize;

  @Prop({ type: Boolean, default: true })
  enableTextUnderline!: boolean;

  @Emit("edit")
  onClickFunnelEditLink() {
    this.$router.push("/funnel/create");
  }

  icon = Icons.Memo;

  get textStyle() {
    return {
      color: this.color,
      fontSize: this.size
    };
  }
}
</script>

<style lang="scss" scoped>
.funnelEditLink {
  display: inline-flex;
  cursor: pointer;

  &:hover {
    text-decoration: none;

    .funnelEditLink__text--hover {
      text-decoration: underline;
    }
  }
}

.funnelEditLink__text {
  margin-left: 6px;
}
</style>
