import { MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { ApiParam } from "@/api/api-param";
import { Msec, msecToUsec } from "@/util/date-util";
import * as Highcharts from "highcharts";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { getEndDate } from "@/components/chart/chart-util";

interface WordcloudUserResult {
  data: Highcharts.PointOptionsObject[];
  beginDate: Date;
  endDate: Date;
}

interface WordcloudResult {
  [userId: string]: WordcloudUserResult;
}

interface WordcloudLoading {
  [userId: string]: boolean;
}

export class WordcloudState {
  wordcloudResult: WordcloudResult = {};
  wordcloudLoading: WordcloudLoading = {};
}

// ある日付から30日間のデータを使用する
const WORDCLOUD_DATA_SPAN: Msec = 30 * 24 * 60 * 60 * 1000;

const mutations: MutationTree<WordcloudState> = {
  setWordcloudResult(
    state: WordcloudState,
    userResult: {
      userId: string;
      data: Highcharts.PointOptionsObject[];
      beginDate: Date;
      endDate: Date;
    }
  ) {
    const obj: WordcloudResult = { ...state.wordcloudResult };
    obj[userResult.userId] = {
      data: userResult.data,
      beginDate: userResult.beginDate,
      endDate: userResult.endDate
    };
    state.wordcloudResult = obj;
  },
  setWordcloudLoading(
    state: WordcloudState,
    userStatus: {
      userId: string;
      isLoading: boolean;
    }
  ) {
    const obj: WordcloudLoading = { ...state.wordcloudLoading };
    obj[userStatus.userId] = userStatus.isLoading;
    state.wordcloudLoading = obj;
  },
  initUserData(state: WordcloudState, userId: string) {
    state.wordcloudLoading[userId] = false;
    state.wordcloudResult[userId] = {
      data: [],
      beginDate: new Date(),
      endDate: new Date()
    };
  },
  // store/modules/search.tsのexecuteSelect時に呼ばれる
  resetWordcloudData(state: WordcloudState) {
    state.wordcloudLoading = {};
    state.wordcloudResult = {};
  }
};

const actions: ActionTree<WordcloudState, RootState> = {
  // 'startDate' & 'endDate' in input are for future extends.
  async fetchWordcloudData(
    { commit, state, rootState },
    userId: string,
    startDate: Date | null = null,
    endDate: Date | null = null
  ) {
    // ユーザのデータがあればAPIを呼ばずにreturn
    if (state.wordcloudResult[userId]) {
      return;
    } else {
      commit("initUserData", userId);
    }

    // 検索または絞り込みのhistoryIdを取得
    const historyId: number | null = rootState.filter.isFilterMode
      ? rootState.filter.filterHistoryId
      : rootState.search.historyId;

    // historyIdが取得できない場合は処理しない
    if (historyId === null) {
      return;
    }

    commit("setWordcloudLoading", { userId, isLoading: true });

    // 選定の日付がある場合はその日付までのデータを、ない場合は今日までのデータを使用する
    // 入力値 endDate があればそれを優先して使用
    let targetEndDate: Date;
    const selectCondition: SelectCondition | null =
      rootState.search.selectCondition;
    if (endDate) {
      targetEndDate = endDate;
    } else {
      targetEndDate = getEndDate(selectCondition);
    }

    const endTimeMsec: Msec = getAggregationPeriodEnd(targetEndDate);

    // 固定で WORDCLOUD_DATA_SPAN 日前までのデータを使用する
    // 入力値 startDate があればそれを優先して使用
    let startTimeMsec: Msec = endTimeMsec - WORDCLOUD_DATA_SPAN;
    if (startDate) {
      startTimeMsec = startDate.getTime();
    }

    const condition: ApiParam.FetchWordcloudData = {
      history_id: historyId,
      user_id: userId,
      begin_time_usec: msecToUsec(startTimeMsec),
      end_time_usec: msecToUsec(endTimeMsec)
    };

    const data: Highcharts.PointOptionsObject[] = await rootState.api.wordcloud.fetchWordcloudData(
      condition
    );

    commit("setWordcloudLoading", { userId, isLoading: false });
    commit("setWordcloudResult", {
      userId,
      data,
      beginDate: new Date(startTimeMsec),
      endDate: new Date(endTimeMsec)
    });
  }
};

export const wordcloud = {
  namespaced: true,
  state: new WordcloudState(),
  mutations: mutations,
  actions: actions
};

// 同日中のデータを含めるため、翌日の0:00:00の直前の時刻を取得する
function getAggregationPeriodEnd(date: Date): Msec {
  const nextDate = new Date(date.getTime() + 24 * 60 * 60 * 1000);
  nextDate.setHours(0);
  nextDate.setMinutes(0);
  nextDate.setSeconds(0);
  return nextDate.getTime() - 1;
}
