<template>
  <div class="header" data-cy="header">
    <div class="header_edge">
      <div
        v-if="!isClientSettingsPage"
        class="header_burgerMemu"
        data-cy="header_burgerMenu"
        @click="onClickGlobalNav"
      >
        <IconButton
          :icon="iconBurger"
          :button-type="iconButtonType"
          :background="true"
          :size="iconButtonSize"
          :icon-scale="iconSize"
          is-hamburger-svg-icon
        />
      </div>

      <div v-else @click="onClickBackButton">
        <Icon
          class="header__backButton"
          :icon="iconBack"
          size="20px"
          :color="colorWhite"
        />
      </div>

      <div class="header_logo" data-cy="header_logo">
        <RouterLink to="/">
          <UsergramLogo class="header_logo-img" />
        </RouterLink>
      </div>
    </div>
    <div
      v-if="isAvailableUserList && !isClientSettingsPage"
      class="header_central"
    >
      <SearchNavigation
        ref="searchNavigation"
        class="header_search-navigation"
        data-cy="header_search-navigation"
        :show-select-condition="showSelectCondition"
        :show-filter-condition="showFilterCondition"
        :show-tooltip="showTooltip"
        :show-onboarding="showOnboarding"
        :is-disabled="isSearchNavigationDisabled"
        @click-search="openSearchForm"
        @click-filter="openFilterForm"
      >
        <template #selectConditionTag>
          <SelectConditionLabel
            :select-condition="selectCondition"
            :wrap="false"
          />
        </template>
        <template #selectConditionTagToolTip>
          <SelectConditionLabel :select-condition="selectCondition" />
        </template>
        <template #filterConditionTag>
          <FilterConditionLabel
            :filter-condition="filterCondition"
            :wrap="false"
          />
        </template>
        <template #filterConditionTagTooltip>
          <FilterConditionLabel :filter-condition="filterCondition" />
        </template>
      </SearchNavigation>
      <SearchConditionBalloon
        v-show="showSearchForm"
        :show-search-form="showSearchForm"
        :search-navigation="searchNavigation"
        :show-select-condition="showSelectCondition"
        @click-close="closeSearchForm"
      />
      <FilterConditionBalloon
        v-show="showFilterForm"
        :search-navigation-width="searchNavigation.width"
        @click-close="closeFilterForm"
      />
    </div>
    <div class="header_edge header_end" data-cy="header_end">
      <div class="header_user" data-cy="header_user">
        <NotificationIconButton :count="unreadNotificationCount">
          <IconButton
            v-if="notificationItems.length > 0 && !isClientSettingsPage"
            data-cy="notification-icon-button"
            class="header__notificationIcon"
            :icon="iconBell"
            :button-type="iconButtonType"
            :background="true"
            :size="iconButtonSize"
            :icon-scale="iconSize"
            @mouseenter="onMouseEnterNotificationNav"
            @mouseleave="onMouseLeaveNotificationNav"
          />
        </NotificationIconButton>

        <IconButton
          v-if="!isClientSettingsPage"
          class="header__helpIcon"
          :icon="iconHelp"
          :button-type="iconButtonType"
          :background="true"
          :size="iconButtonSize"
          :icon-scale="iconSize"
          @mouseenter="onMouseEnterHelpNav"
          @mouseleave="onMouseLeaveHelpNav"
        />

        <IconButton
          class="header__userIcon"
          :icon="iconUser"
          :button-type="iconButtonType"
          :background="true"
          :size="iconButtonSize"
          :icon-scale="iconSize"
          @mouseenter="onMouseEnterSettingNav"
          @mouseleave="onMouseLeaveSettingNav"
        />

        <div
          v-show="showSettingNav"
          class="header_user-nav"
          @mouseenter="onMouseEnterSettingNav"
          @mouseleave="onMouseLeaveSettingNav"
        >
          <SettingNav v-if="isAuthenticated" />
        </div>

        <div
          v-show="showHelpNav"
          class="header_help-nav"
          @mouseenter="onMouseEnterHelpNav"
          @mouseleave="onMouseLeaveHelpNav"
        >
          <Help v-if="isAuthenticated" />
        </div>

        <div
          v-show="notificationItems.length > 0 && showNotificationNav"
          class="header__notificationNav"
          data-cy="header-notification-nav"
          @mouseenter="onMouseEnterNotificationNav"
          @mouseleave="onMouseLeaveNotificationNav"
        >
          <NotificationNav
            v-if="isAuthenticated"
            :is-available-user-trend="isAvailableUserTrend"
            @button-clicked="onMouseLeaveNotificationNav"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-property-decorator";
import SearchConditionBalloon, {
  SearchNavigationStyle
} from "@/views/SearchConditionBalloon.vue";
import FilterConditionBalloon from "@/views/FilterConditionBalloon.vue";
import SettingNav from "@/views/SettingNav.vue";
import SearchNavigation from "@/components/search/SearchNavigation.vue";
import UsergramLogo from "@/assets/svg/logo/usergram-logo.svg";
import Button from "@/components/Button.vue";
import IconButton from "@/components/IconButton.vue";
import Icon from "@/components/Icon.vue";
import Balloon from "@/components/Balloon.vue";

import SelectConditionLabel from "@/views/SelectConditionLabel.vue";
import FilterConditionLabel from "@/views/FilterConditionLabel.vue";

import { LoginUser } from "@/models/auth/UgUser";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { FilterCondition } from "@/models/search/filter-condition/FilterCondition";
import { stopWindowScroll, startWindowScroll } from "@/util/window-util";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import Help from "@/components/Help.vue";
import NotificationNav from "@/views/NotificationNav.vue";
import NotificationIconButton from "@/components/NotificationIconButton.vue";

const ICON_BUTTON_SIZE: number = 36;
const ICON_SIZE: number = 0.45;
const DELAY_SHOW_BALLOON_TIME = 100;
const DELAY_HIDE_BALLOON_TIME = 100;

@Component({
  components: {
    Help,
    UsergramLogo,
    Button,
    IconButton,
    Icon,
    SearchConditionBalloon,
    FilterConditionBalloon,
    Balloon,
    SearchNavigation,
    SettingNav,
    SelectConditionLabel,
    FilterConditionLabel,
    NotificationNav,
    NotificationIconButton
  }
})
export default class Header extends Vue {
  @Emit("click-burger")
  onClickGlobalNav() {}

  onShowSearchForm(showSearchForm: boolean) {
    this.$emit("show-search-form", showSearchForm);
  }

  onShowFilterForm(showFilterForm: boolean) {
    this.$emit("show-filter-form", showFilterForm);
  }

  showSearchForm = false;
  showFilterForm = false;
  showTooltip = true;
  searchNavigation: SearchNavigationStyle = {
    width: 0,
    left: 0
  };

  showSettingNav: boolean = false;
  showSettingNavTimer: number | null = null;
  hideSettingNavTimer: number | null = null;

  showHelpNav: boolean = false;
  showHelpNavTimer: number | null = null;
  hideHelpNavTimer: number | null = null;

  showNotificationNav: boolean = false;
  showNotificationNavTimer: number | null = null;
  hideNotificationNavTimer: number | null = null;

  colorWhite = Colors.White;

  iconUser = Icons.User;
  iconBurger = Icons.Burger;
  iconBack = Icons.ArrowLeft;
  iconSave = Icons.Save;
  iconHelp = Icons.Help;
  iconBell = Icons.Bell;
  iconSize = ICON_SIZE;
  iconButtonSize: number = ICON_BUTTON_SIZE;
  iconButtonType = IconButtonType.Default;

  mounted() {
    this.searchNavigation = this.getSearchNavigation();
    window.addEventListener("resize", this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize() {
    this.searchNavigation = this.getSearchNavigation();
  }

  onClickBackButton() {
    history.back();
  }

  getSearchNavigation(): SearchNavigationStyle {
    let width = 0;
    let left = 0;

    if (this.$refs.searchNavigation) {
      const searchNavigation = this.$refs.searchNavigation as Vue;
      const searchNavigationDom = searchNavigation.$el.getBoundingClientRect() as ClientRect;
      width = searchNavigationDom.width;
      left = searchNavigationDom.left;
    }

    return { width, left };
  }

  get isClientSettingsPage(): boolean {
    return this.$route.path.includes("client-settings");
  }

  get isAuthenticated() {
    return this.$store.state.auth.isAuthenticated;
  }

  get loginUser(): LoginUser {
    return this.$store.state.auth.user;
  }

  get isAvailableUserList(): boolean {
    return this.loginUser.permission.isAvailableUserList;
  }

  // TODO:user_trendの告知期間が終了後に削除する
  get isAvailableUserTrend(): boolean {
    return this.loginUser.permission.isAvailableUserTrend;
  }

  get isFilterMode(): boolean {
    return this.$store.state.filter.isFilterMode;
  }

  get showSelectCondition(): boolean {
    const condition = this.$store.state.search.selectCondition;
    return condition !== null;
  }

  get showFilterCondition(): boolean {
    return (
      this.filterCondition.filterNodes.length > 0 ||
      this.filterCondition.deviceTypes.length > 0
    );
  }

  get selectCondition(): SelectCondition | null {
    return this.$store.state.search.selectCondition;
  }

  get filterCondition(): FilterCondition {
    return this.$store.state.filter.filterCondition;
  }

  get showOnboarding(): boolean {
    return (
      // ホーム画面のみ
      this.$route.name === "home" &&
      // 検索フォーム表示中は表示しない
      !this.showSearchForm &&
      // ホームのオンボーディングを表示中は表示しない
      !this.$store.state.onboarding.showHomeOnboarding &&
      // すでに検索フォームから検索していたらもうださない
      !this.$store.state.preference.noMoreShowSearchOnboarding
    );
  }

  get notificationItems() {
    return this.$store.getters["notification/items"];
  }

  get unreadNotificationCount() {
    return this.$store.getters["notification/unreadNotificationCount"];
  }

  get isSearchNavigationDisabled(): boolean {
    const routeName: string | null | undefined = this.$route.name;
    if (routeName === undefined) {
      return false;
    }
    return routeName === "tour-detail";
  }

  onMouseEnterSettingNav() {
    if (this.showSettingNavTimer !== null) {
      clearTimeout(this.showSettingNavTimer);
    }

    this.showSettingNavTimer = window.setTimeout(() => {
      this.showSettingNav = true;
    }, DELAY_SHOW_BALLOON_TIME);

    if (this.hideSettingNavTimer !== null) {
      clearTimeout(this.hideSettingNavTimer);
      this.hideSettingNavTimer = null;
    }
  }

  onMouseLeaveSettingNav() {
    if (this.showSettingNavTimer !== null) {
      clearTimeout(this.showSettingNavTimer);
      this.showSettingNavTimer = null;
    }

    if (this.hideSettingNavTimer !== null) {
      clearTimeout(this.hideSettingNavTimer);
      this.hideSettingNavTimer = null;
    }

    this.hideSettingNavTimer = window.setTimeout(() => {
      this.showSettingNav = false;
    }, DELAY_HIDE_BALLOON_TIME);
  }

  onMouseEnterHelpNav() {
    if (this.showHelpNavTimer !== null) {
      clearTimeout(this.showHelpNavTimer);
    }

    this.showHelpNavTimer = window.setTimeout(() => {
      this.showHelpNav = true;
    }, DELAY_SHOW_BALLOON_TIME);

    if (this.hideHelpNavTimer !== null) {
      clearTimeout(this.hideHelpNavTimer);
      this.hideHelpNavTimer = null;
    }
  }

  onMouseLeaveHelpNav() {
    if (this.showHelpNavTimer !== null) {
      clearTimeout(this.showHelpNavTimer);
      this.showHelpNavTimer = null;
    }

    if (this.hideHelpNavTimer !== null) {
      clearTimeout(this.hideHelpNavTimer);
      this.hideHelpNavTimer = null;
    }

    this.hideHelpNavTimer = window.setTimeout(() => {
      this.showHelpNav = false;
    }, DELAY_HIDE_BALLOON_TIME);
  }

  onMouseEnterNotificationNav() {
    if (this.showNotificationNavTimer !== null) {
      clearTimeout(this.showNotificationNavTimer);
    }

    this.showNotificationNavTimer = window.setTimeout(() => {
      this.showNotificationNav = true;
    }, DELAY_SHOW_BALLOON_TIME);

    if (this.hideNotificationNavTimer !== null) {
      clearTimeout(this.hideNotificationNavTimer);
      this.hideNotificationNavTimer = null;
    }
  }

  onMouseLeaveNotificationNav() {
    if (this.showNotificationNavTimer !== null) {
      clearTimeout(this.showNotificationNavTimer);
      this.showNotificationNavTimer = null;
    }

    if (this.hideNotificationNavTimer !== null) {
      clearTimeout(this.hideNotificationNavTimer);
      this.hideNotificationNavTimer = null;
    }

    this.hideNotificationNavTimer = window.setTimeout(() => {
      this.showNotificationNav = false;
    }, DELAY_HIDE_BALLOON_TIME);
  }

  openSearchForm() {
    if (this.showSearchForm) {
      return false;
    }

    if (this.showFilterForm) {
      this.closeFilterForm();
    }

    this.showSearchForm = true;
    this.onShowSearchForm(this.showSearchForm);
    this.hideFormTooltip();
    stopWindowScroll();
  }

  closeSearchForm() {
    startWindowScroll();
    this.showSearchForm = false;
    this.onShowSearchForm(this.showSearchForm);
    this.showFormTooltip();
  }

  openFilterForm() {
    if (this.showFilterForm) {
      return false;
    }

    if (this.showSearchForm) {
      this.closeSearchForm();
    }

    this.showFilterForm = true;
    this.onShowFilterForm(this.showFilterForm);
    this.hideFormTooltip();
    stopWindowScroll();
  }

  closeFilterForm() {
    startWindowScroll();
    this.showFilterForm = false;
    this.onShowFilterForm(this.showFilterForm);
    this.showFormTooltip();
  }

  showFormTooltip() {
    this.showTooltip = true;
  }

  hideFormTooltip() {
    this.showTooltip = false;
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 14px;
  width: 100%;
  height: $headerHeight;
  border-bottom: 1px solid $colorBase500;
  background-color: $colorWhite;
}

.header_edge {
  display: flex;
  align-items: center;
  min-width: 210px;
}

.header__backButton {
  background-color: $colorBase900;
  border-radius: 20px;
  padding: 10px;
  margin: 0 11px 0 20px;
  cursor: pointer;
}
.header__backButton:hover {
  background-color: $colorBase1000;
}

.header_end {
  justify-content: flex-end;
  padding-right: 20px;
}

.header_central {
  position: relative;
  margin: 0 30px;
  min-width: 520px;
  max-width: 980px;
  width: 100%;
}

.header_burgerMemu {
  display: flex;
  justify-content: center;
  width: $globalNaviCondensedWidth;
  height: 36px;
}

.header_logo {
  width: 147px;
  height: 30px;

  svg {
    width: 147px;
    height: 100%;
  }
}

.header_search-navigation {
  position: relative;
}

.header_user {
  position: relative;
  display: flex;
}

.header_user-nav {
  position: absolute;
  top: 40px;
  right: -8px;
}

.header_help-nav {
  position: absolute;
  top: 40px;
  right: 28px;
}

.header__notificationNav {
  position: absolute;
  top: 40px;
  right: 64px;
}
</style>
