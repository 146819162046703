import { i18n } from "@/i18n";
import { showAlert } from "@/util/modal-util";
import router from "@/router";

export function handleError(error: any) {
  if (error.response) {
    if (error.response.status === 403) {
      handle403(error);
    } else {
      showAlert(getApiErrorMessage(error.response.data));
    }
  } else {
    showAlert(i18n.t("util.errorUtil.common-error-message") as string);
  }
}

export function handle403(error: any) {
  const errMsg: string | undefined = error.response?.data?.detail;

  if (errMsg?.toLowerCase().startsWith("csrf failed:")) {
    showAlert(i18n.t("util.errorUtil.csrf-error") as string);
    window.setTimeout(() => location.reload(), 3000);
  } else if (location.pathname !== "/login") {
    showAlert(i18n.t("util.errorUtil.session-expired") as string);
    window.setTimeout(() => location.assign("/login"), 3000);
  } else {
    showAlert(getApiErrorMessage(error.response.data));
  }
}

export function handleNoQueryCacheError(error: any) {
  if (isNoQueryCacheError(error)) {
    showAlert(i18n.t("util.errorUtil.no-query-cache") as string);
    router.push("/");
  } else {
    handleError(error);
  }
}

export function handleNotFoundError(
  error: any,
  replaceTo: string,
  message: string
) {
  if (error.response?.status === 404) {
    router.replace({ name: replaceTo }, () => showAlert(message));
  } else {
    handleError(error);
  }
}

export function handleMfaVerificationError() {
  showAlert(i18n.t("util.errorUtil.otp-code-setup-page-error") as string);
}

export function handleMfaErrorOnTotpInputPage() {
  showAlert(i18n.t("util.errorUtil.otp-code-totp-input-page-error") as string);
}

export function isNoQueryCacheError(error: any) {
  return (
    error.response &&
    error.response.status === 500 &&
    error.response.data.error === "NoQueryCache"
  );
}
export function getApiErrorMessage(errorData: any): string {
  // 以下の3通りのエラーレスポンスの型に対して対応をする
  // ▼ 認証ユニットが自分で作るエラーレスポンス
  // {"success":boolean,"errors":{field_name:[errorMessage]}}
  //
  // ▼ 認証ユニットが、バルクでINSERTするようなリクエストの際に自分で作るエラーレスポンス
  // {"success":boolean,"errors":{[field_name:[errorMessage]}]}
  //
  // ▼ server-sideから受けたエラーレスポンス
  // {"detail": errorMessage}

  const hasFieldError = errorData && errorData.errors;
  const hasErrorDetail = errorData && errorData.detail;
  if (hasFieldError) {
    const errorMessages = errorData.errors;
    // {field_name:[errorMsg]}の配列の場合
    if (Array.isArray(errorMessages)) {
      for (const errorMsg of errorMessages) {
        Object.keys(errorMsg).forEach(fieldName => {
          if (errorMsg[fieldName] !== undefined) {
            return errorMsg[fieldName][0];
          }
        });
      }
    }
    //
    // 単一の {fieldName:[errorMsg]} の場合
    for (const fieldName in errorMessages) {
      if (errorMessages[fieldName] !== undefined) {
        return errorMessages[fieldName][0];
      }
    }
  } else if (hasErrorDetail) {
    return errorData.detail;
  }
  // どれにも合致しない場合は汎用エラーメッセージを返す
  return i18n.t("util.errorUtil.common-error-message") as string;
}

export class TimeoutError extends Error {
  constructor(msg: string) {
    super(msg);

    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}
