import { render, staticRenderFns } from "./AlmostCvUser.vue?vue&type=template&id=206b85bc&scoped=true"
import script from "./AlmostCvUser.vue?vue&type=script&lang=ts"
export * from "./AlmostCvUser.vue?vue&type=script&lang=ts"
import style0 from "./AlmostCvUser.vue?vue&type=style&index=0&id=206b85bc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206b85bc",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/views/almost-cv-user.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fviews%2FAlmostCvUser.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports