import { ActionTree, GetterTree, MutationTree } from "vuex";
import { ApiRes } from "@/api/api-res";
import {
  FilterCondition,
  deepCopyFilterCondition,
  convertFilterConditionToJson,
  updateFilterNodes
} from "@/models/search/filter-condition/FilterCondition";
import {
  FilterNode,
  FilterNodeType
} from "@/models/search/filter-node/FilterNode";
import { Msec } from "@/util/date-util";
import { RootState } from "@/store/";
import { sleep } from "@/util/common-util";
import { User, UserMemo } from "@/models/User";
import { FilterApiParams } from "@/api/apis/ApiSearch";
import { TOUR_DETAIL_MAX_USER_COUNT } from "@/store/modules/tour";
import {
  FilterHistory,
  buildFilterHistory
} from "@/models/search/FilterHistory";

// 初期取得時の最大件数
const INITIAL_USER_FETCH_COUNT: number = 50;

// 件数取得のインターバル
const FETCH_COUNT_INTERVAL_MSEC: Msec = 2000;

// ユーザー取得のインターバル
const FETCH_USER_INTERVAL_MSEC: Msec = 2000;

// 履歴IDが変わった際に返すエラー
const ERROR_HISTORY_ID_IS_CHANGED: string = "ERROR_HISTORY_ID_IS_CHANGED";

// プログレス途中にマッチじた割合やユーザ数を表示していいかの判断に使う、この秒数経過しないと表示させない
const MSEC_TO_SHOW_FILTER_PROGRESS_PERCENTAGE = 20000; // 20秒

// プログレス途中にマッチじた割合やユーザ数を表示していいかの判断に使う、この人数チェック済みでないと表示させない
// 数値はこの人数みていたら割合に妥当性があると言う理由で決定された。
const FLITERED_USER_NUMBER_TO_SHOW_FILTER_PROGRESS_PERCENTAGE = 385;

export type NodeToDeleteProps = {
  childIndex: number;
  depth: number;
  index: number;
};

export class FilterState {
  // 絞り込み検索中かどうか
  isFilterMode: boolean = false;

  // 絞り込み結果
  users: User[] = [];

  // 絞り込みにマッチした件数
  userCount: number = 0;

  // 絞り込み結果が取得されたかどうか
  isFetched: boolean = false;

  // 絞り込み結果の件数が取得されたかどうか
  isCountFetched: boolean = false;

  isDragging: boolean = false;

  draggingNodeType: FilterNodeType | null = null;

  // プログレス
  progress: number = 0;

  // プログレス中に、マッチした割合やユーザ数を表示してよくなったか
  isProgressUserCountDisplayable: boolean = false;

  // プログレス中にマッチした情報をだしていいのか判断に使う、絞り込み実行経過時間
  filterExecutionElapsedTime: number = 0;

  // プログレス中にだすマッチした人数
  userCountForProgress: number = 0;

  // プログレス中にだすマッチした割合
  matchedPercentForProgress: number = 0;

  // 現在実行中の絞り込み条件
  filterCondition: FilterCondition = new FilterCondition([]);

  // フォーム用の絞り込み条件
  filterConditionForForm: FilterCondition = new FilterCondition([]);

  // 絞り込み結果を返すイテレーター
  filterResultIterator: AsyncIterableIterator<
    ApiRes.FilterSearchResult
  > | null = null;

  // 絞り込み結果の件数を返すイテレーター
  countIterator: AsyncIterableIterator<ApiRes.FilterCountResult> | null = null;

  filterHistoryId: number | null = null;

  indexOfEnableExcludeCondition: number | null = null;
}

const mutations: MutationTree<FilterState> = {
  setDraggingNodeType(state: FilterState, draggingNodeType: FilterNodeType) {
    state.draggingNodeType = draggingNodeType;
  },
  setIsFilterMode(state: FilterState, isFilterMode: boolean) {
    state.isFilterMode = isFilterMode;
  },
  setFilterCondition(state: FilterState, filterCondition: FilterCondition) {
    state.filterCondition = filterCondition;
  },
  setFilterConditionForForm(
    state: FilterState,
    filterCondition: FilterCondition
  ) {
    state.filterConditionForForm = filterCondition;
  },
  setFilterHistoryId(state: FilterState, filterHistoryId: number | null) {
    state.filterHistoryId = filterHistoryId;
  },
  setIsFetched(state: FilterState, isUserFetched: boolean) {
    state.isFetched = isUserFetched;
  },
  setFilterResultIterator(
    state: FilterState,
    iterator: AsyncIterableIterator<ApiRes.FilterSearchResult>
  ) {
    state.filterResultIterator = iterator;
  },
  setCountIterator(
    state: FilterState,
    iterator: AsyncIterableIterator<ApiRes.FilterCountResult> | null
  ) {
    state.countIterator = iterator;
  },
  initializeFilterResults(state: FilterState) {
    state.draggingNodeType = null;
    state.progress = 0;
    state.users = [];
    state.userCount = 0;
    state.isFetched = false;
    state.isCountFetched = false;
    state.isDragging = false;
    state.filterHistoryId = null;
    state.isProgressUserCountDisplayable = false;
    state.filterExecutionElapsedTime = Date.now();
    state.userCountForProgress = 0;
    state.matchedPercentForProgress = 0;
  },
  addFilterResult(state: FilterState, users: User[]) {
    state.users.push(...users);
  },
  setUserCount(state: FilterState, userCount: number) {
    state.userCount = userCount;
    state.isCountFetched = true;
  },
  toggleDragging(state: FilterState) {
    state.isDragging = !state.isDragging;
  },
  updateProgress(
    state: FilterState,
    result: ApiRes.FilterSearchResult | ApiRes.FilterCountResult
  ) {
    // 検索のプログレスを%で計算
    state.progress =
      Math.floor((result.checked_num * 1000) / result.total_num_to_check) / 10;

    // プログレス中に表示するマッチ割合
    state.matchedPercentForProgress =
      Math.floor((1000 * result.num_filtered_users) / result.checked_num) / 10;

    // プログレス中に表示するマッチ割合人数
    state.userCountForProgress = Math.floor(
      (result.num_filtered_users * result.total_num_to_check) /
        result.checked_num
    );

    // 絞り込み経過時間
    const now = Date.now();
    const elapsedTime = now - state.filterExecutionElapsedTime;

    // プログレス中の途中経過を表示していいか
    state.isProgressUserCountDisplayable =
      result.checked_num >=
        FLITERED_USER_NUMBER_TO_SHOW_FILTER_PROGRESS_PERCENTAGE &&
      elapsedTime >= MSEC_TO_SHOW_FILTER_PROGRESS_PERCENTAGE;
  },
  removeNode(
    state: FilterState,
    { childIndex, depth, index }: NodeToDeleteProps
  ) {
    let newNodes: FilterNode[] = state.filterConditionForForm.filterNodes;
    const isNextNodeExcludedFromRemovedNode = newNodes[index + 1]?.isExcluded;
    const isPrevNodeExcludedFromRemovedNode = newNodes[index - 1]?.isExcluded;
    const isRemovingLastNode = index === newNodes.length - 1;

    if (depth === 1) {
      const oppositeChildIndex = childIndex === 0 ? 1 : 0;
      const newParentNode =
        // @ts-ignore
        state.filterConditionForForm.filterNodes[index].orActivityNodes[
          oppositeChildIndex
        ] as FilterNode;
      newParentNode.childIndex = 0;
      newParentNode.depth = 0;
      newParentNode.edge = state.filterConditionForForm.filterNodes[index].edge;
      // @ts-ignore
      if (newParentNode.condition) {
        // @ts-ignore
        newParentNode.condition.parentDepth = 0;
      }

      newNodes[index] = newParentNode;
    } else {
      const removeIndices = [index];

      // removeNextNode
      const nextIsLastNode = newNodes.length - 1 === index + 1;
      if (
        newNodes[index + 1]?.isExcluded &&
        newNodes.length > 2 &&
        !nextIsLastNode
      )
        removeIndices.push(index + 1);

      // removePreviousNode
      const isLastNode = newNodes.length - 1 === index;
      if (newNodes[index - 1]?.isExcluded && newNodes.length > 2 && isLastNode)
        removeIndices.push(index - 1);

      newNodes = newNodes.filter((_, i) => !removeIndices.includes(i));
    }

    state.filterConditionForForm = new FilterCondition(
      updateFilterNodes(newNodes),
      state.filterCondition.deviceTypes,
      state.filterCondition.periodDays
    );

    // Logic related to indexOfEnableExcludeCondition
    if (depth !== 0) {
      return;
    }

    const { indexOfEnableExcludeCondition } = state;
    const hasMultipleNodes = newNodes.length > 1;
    const excludedIndex = state.indexOfEnableExcludeCondition;

    const isExclusionConditionMet =
      excludedIndex !== null &&
      hasMultipleNodes &&
      !isNextNodeExcludedFromRemovedNode;

    const isRemoveLastAndExcludePrev =
      (index - 1 === excludedIndex || isPrevNodeExcludedFromRemovedNode) &&
      isRemovingLastNode;

    const isRemoveFirstNodeAndExcludeNext =
      (index === excludedIndex || isNextNodeExcludedFromRemovedNode) &&
      index === 0;

    if (
      isExclusionConditionMet &&
      !isRemoveLastAndExcludePrev &&
      !isRemoveFirstNodeAndExcludeNext
    ) {
      state.indexOfEnableExcludeCondition =
        index <= excludedIndex
          ? excludedIndex - 1
          : indexOfEnableExcludeCondition;

      return;
    }

    state.indexOfEnableExcludeCondition = null;
  },
  setIsProgressUserCountDisplayable(
    state: FilterState,
    isDisplayable: boolean
  ) {
    state.isProgressUserCountDisplayable = isDisplayable;
  },
  setFilterExecutionElapsedTime(state: FilterState, elapsedTime: number) {
    state.filterExecutionElapsedTime = elapsedTime;
  },
  setUserCountForProgress(state: FilterState, userCount: number) {
    state.userCountForProgress = userCount;
  },
  setMatchedPercentForProgress(state: FilterState, matchedPercent: number) {
    state.matchedPercentForProgress = matchedPercent;
  },
  updateMemo(state: FilterState, { userId, memo, isMemoPage }: UserMemo) {
    const memoUser = state.users.find(u => u.id === userId);
    if (memoUser !== undefined) {
      let users: User[] = [];
      if (isMemoPage) {
        users = state.users.filter(u => u.id !== userId);
        users.unshift(memoUser.updateMemo(memo));
      } else {
        users = [...state.users];
        const index = users.findIndex(u => u.id === userId);
        users[index] = users[index].updateMemo(memo);
      }
      state.users = users;
    }
  },
  setIndexOfEnableExcludeCondition(state: FilterState, index: number | null) {
    state.indexOfEnableExcludeCondition = index;
  }
};

const getters: GetterTree<FilterState, RootState> = {
  tourUsers(state): User[] {
    return [...state.users.slice(0, 5)];
  }
};

const actions: ActionTree<FilterState, RootState> = {
  /**
   * 絞り込み検索を実行する
   * 検索結果は少しずつ返ってくる。
   * 検索結果が返ってきたら、ユーザ状態を更新する
   */
  async executeFilter(
    { commit, state, rootState, dispatch, rootGetters },
    {
      filterConditionArg,
      setHistoryIdToUrl
    }: {
      filterConditionArg?: FilterCondition;
      setHistoryIdToUrl?: (historyId: number, filterId: number) => void;
    }
  ) {
    const historyId = rootState.search.historyId;

    const client = rootState.client.client;
    const canUseWebdataFeatures = rootState.app.canUseWebdataFeatures;
    const isContractApp = client !== null ? client.isContractApp : false;
    const filterCondition: FilterCondition | null = filterConditionArg
      ? filterConditionArg
      : deepCopyFilterCondition(
          state.filterConditionForForm,
          canUseWebdataFeatures,
          isContractApp,
          rootGetters["clientSettings/activeDefinitions"],
          rootGetters["system/activeGlobalConversionDefinitions"],
          rootState.system.globalConversionAttributeDefinitions
        );
    if (historyId === null || filterCondition === null) {
      return;
    }

    // 履歴IDをリセット
    commit("setFilterHistoryId", -1);

    commit("setFilterCondition", filterCondition);
    commit("initializeFilterResults");
    commit("setIsFilterMode", true);

    const indexOfEnableExcludeCondition = filterCondition.filterNodes.findIndex(
      (node, index) => {
        return (
          // first node and last node should be ignored
          index !== 0 &&
          index !== filterCondition.filterNodes.length - 1 &&
          node.isExcluded
        );
      }
    );

    commit(
      "setIndexOfEnableExcludeCondition",
      indexOfEnableExcludeCondition !== -1
        ? indexOfEnableExcludeCondition - 1
        : null
    );
    // クラスタリングを解除
    dispatch("clustering/resetClustering", null, { root: true });

    // セグメント別分析の結果をリセット
    dispatch("segmentedTrends/resetSegmentedTrendsData", null, { root: true });

    // サーバーに検索タスクを投げて、タスク情報を受け取る
    const filterApiParams: FilterApiParams = convertFilterConditionToJson(
      filterCondition,
      rootGetters["system/activeGlobalConversionDefinitions"]
    );
    const { hid, fid } = await rootState.api.search.filterUser(
      historyId,
      filterApiParams
    );

    // URLにつけるIdを更新
    if (setHistoryIdToUrl) {
      setHistoryIdToUrl(hid, fid);
    }

    // stateに保存する
    commit("setFilterHistoryId", hid);

    const isTourMode: boolean = rootState.tour.isTourMode;
    // 検索結果を返すIteratorを取得
    let userIterator: AsyncIterableIterator<ApiRes.FilterSearchResult>;
    if (isTourMode) {
      userIterator = rootState.api.tour.getFilterUserResult(hid);
    } else {
      userIterator = rootState.api.search.getFilterUserResult(hid);
    }

    // 件数を返すIteratorを取得x
    const countIterator: AsyncIterableIterator<ApiRes.FilterCountResult> = rootState.api.search.getFilterCount(
      hid
    );

    commit("setFilterResultIterator", userIterator);
    commit("setCountIterator", countIterator);

    await Promise.all([
      // 件数を取得する
      dispatch("fetchUserCount"),
      // 最初の結果を取得する
      dispatch("fetchInitialFilterResult", hid)
    ]).catch((e: Error) => {
      // histpryIDが変わった時はエラーではないので、以降の処理をキャンセル
      if (
        e.message === ERROR_HISTORY_ID_IS_CHANGED ||
        state.filterHistoryId !== hid
      ) {
        return;
      }
      throw e;
    });

    if (!isTourMode) {
      dispatch(
        "searchHistory/addFilterHistoryFromFilterCondition",
        filterCondition,
        { root: true }
      );
    }

    // filterHistoryIdが更新された（違う検索が実行された）場合はキャンセル
    if (state.filterHistoryId !== hid) {
      return;
    }

    // 実行するdispatchType
    const dispatches: string[] = [];

    // 検索条件が追加されているので、historyを更新する
    dispatches.push("searchHistory/updateSearchHistoriesAndUserInfo");

    // ユーザ数が0場合はクラスタリングリストとセグメント別分析の結果は取得しない
    if (state.userCount > 0) {
      // 読み込みが一旦できた段階で、クラスタリングリストとセグメント別分析の結果を取得
      dispatches.push("clustering/fetchClusteringList");
      dispatches.push("segmentedTrends/executeAnalysisOfActiveTab");
    }

    await Promise.all(
      dispatches.map(type => dispatch(type, null, { root: true }))
    );
  },
  /**
   * 現在指定されている検索条件にマッチするユーザの件数を取得してセットする
   *
   * 件数が取得できていない場合は数秒(FETCH_COUNT_INTERVAL_MSEC)待機したのち
   * もう一度問い合わせる。
   *
   * ユーザ取得のレスポンスでも件数は取得される可能性があり
   * そちらでセットされている場合は、問い合わせを終了する。
   */
  async fetchUserCount({ commit, state }) {
    if (state.filterHistoryId !== null && state.countIterator !== null) {
      // 取得する前に履歴IDを保存する
      const filterHistoryId = state.filterHistoryId;

      for await (const countResult of state.countIterator) {
        // 取得後に検索が変更される可能性があるので、変更後に変わっていないか確認する
        if (
          countResult.search_finished &&
          state.filterHistoryId === filterHistoryId
        ) {
          commit("setUserCount", countResult.num_filtered_users);
        }

        // すでに件数がセットされている、もしくは違う検索なら抜ける
        if (state.isCountFetched) {
          break;
        }

        // 違う検索ならエラーを返す
        if (state.filterHistoryId !== filterHistoryId) {
          throw new Error(ERROR_HISTORY_ID_IS_CHANGED);
        }

        // 絞り込みプログレス・表示途中経過更新
        commit("updateProgress", countResult);

        await sleep(FETCH_COUNT_INTERVAL_MSEC);
      }
    }

    return;
  },
  /**
   * ユーザを全部取得できるまで、検索結果を取得する
   */
  async fetchInitialFilterResult(
    { commit, state, rootState, dispatch },
    filterHistoryId: number
  ) {
    commit("setIsFetched", false);
    const initialUserFetchCount = getInitialUserFetchCount(rootState);
    // INITIAL_USER_FETCH_COUNT 分ユーザを取得するか、ユーザを全取得するまで問い合わせる
    while (
      state.users.length < initialUserFetchCount &&
      state.filterHistoryId === filterHistoryId &&
      (!state.isCountFetched || state.users.length < state.userCount)
    ) {
      await dispatch("fetchFilterResultOnce").catch(e => {
        if (e.message !== ERROR_HISTORY_ID_IS_CHANGED) {
          throw e;
        }
      });
      await sleep(FETCH_USER_INTERVAL_MSEC);
    }
    commit("setIsFetched", true);
  },
  /**
   * 現在指定している検索の結果を一度だけ問い合わせる
   */
  async fetchFilterResultOnce({ commit, state, rootState, rootGetters }) {
    // 検索の情報がない場合は無視
    if (state.filterHistoryId === null || state.filterResultIterator === null) {
      return;
    }

    // 取得する前に履歴IDを保存する
    const historyId = state.filterHistoryId;
    const iteratorResult: IteratorResult<ApiRes.FilterSearchResult> = await state.filterResultIterator.next();

    // 違う絞り込みならエラーを返す
    if (state.filterHistoryId !== historyId) {
      throw new Error(ERROR_HISTORY_ID_IS_CHANGED);
    }

    // 取得後に履歴IDが変わっていないか確認する
    if (!iteratorResult.done) {
      // レスポンスを取得
      const userFilterResult: ApiRes.FilterSearchResult = iteratorResult.value;

      // 絞り込みプログレス・表示途中経過更新
      commit("updateProgress", userFilterResult);

      // ウェブとアプリ両方の契約があるか
      const canUseWebdataFeaturesAndIsContractApp = rootState.client.client
        ? rootState.app.canUseWebdataFeatures &&
          rootState.client.client.isContractApp
        : false;

      // ユーザモデルのビルド
      const users: User[] = userFilterResult.users.map(user =>
        User.build(
          user,
          rootGetters["clientSettings/allConversionDefinitions"],
          rootState.clientSettings.npsDefinitions,
          rootState.clientSettings.businessEventDefinitions,
          rootState.clientSettings.enqueteDefinitions,
          rootState.clientSettings.businessIndexDefinitions,
          canUseWebdataFeaturesAndIsContractApp
        )
      );

      // 取得したユーザ情報を保存
      commit("addFilterResult", users);
    }

    return;
  },
  /**
   * 最後に実行した絞り込みの次の結果を取得する
   */
  async fetchNextFilterResult({ commit, dispatch }) {
    commit("setIsFetched", false);
    await dispatch("fetchFilterResultOnce").catch(e => {
      if (e.message !== ERROR_HISTORY_ID_IS_CHANGED) {
        throw e;
      }
    });
    commit("setIsFetched", true);
  },
  /**
   * 絞り込みを解除して、検索画面を表示させる
   */
  clearFilter({ commit, dispatch, rootState }) {
    commit("setIsFilterMode", false);
    commit("initializeFilterResults");
    commit("setFilterCondition", new FilterCondition([]));
    commit("setFilterConditionForForm", new FilterCondition([]));
    commit("setIndexOfEnableExcludeCondition", null);
    // セグメント別分析の結果をリセット
    dispatch("segmentedTrends/resetSegmentedTrendsData", null, { root: true });
    // 絞り込み解除時に、絞り込みのクラスタリングも解除
    dispatch("clustering/resetClustering", null, { root: true });
    if (rootState.search.userCount > 0) {
      // 絞り込み解除時に、検索のhistoryIdで再度クラスタリングリストとセグメント別分析の結果を取得
      dispatch("clustering/fetchClusteringList", null, { root: true });
      // セグメント別分析の取得はクラスタリングの取得と被らないようにする
      dispatch("segmentedTrends/executeAnalysisOfActiveTab", null, {
        root: true
      });
    }
  },
  /**
   * 検索実行時に絞り込み情報をリセットする
   */
  resetFilter({ commit }) {
    commit("setIsFilterMode", false);
    commit("initializeFilterResults");
    commit("setFilterCondition", new FilterCondition([]));
    commit("setFilterConditionForForm", new FilterCondition([]));
    commit("setIndexOfEnableExcludeCondition", null);
  },

  async fetchFilterConditionById(
    { commit, rootState, rootGetters },
    filterId: number
  ) {
    let history!: FilterHistory | null;

    try {
      const historyRes = await rootState.api.searchHistory.getFilterHistoryById(
        filterId
      );
      const client = rootState.client.client;
      const canUseWebdataFeatures = rootState.app.canUseWebdataFeatures;
      const isContractApp = client !== null ? client.isContractApp : false;
      history = buildFilterHistory(
        historyRes,
        canUseWebdataFeatures,
        isContractApp,
        rootGetters["clientSettings/activeDefinitions"],
        rootState.system.globalConversionAttributeDefinitions
      );

      if (history === null) {
        throw new Error();
      }
    } catch (e) {
      throw new Error("ERROR_CREATE_CONDITION_FROM_HISTORY_ID");
    }

    commit("setFilterCondition", history.filterCondition);
  }
};

export const filter = {
  namespaced: true,
  state: new FilterState(),
  mutations,
  actions,
  getters
};

function getInitialUserFetchCount(rootState: RootState): number {
  return rootState.tour.isTourMode
    ? TOUR_DETAIL_MAX_USER_COUNT
    : INITIAL_USER_FETCH_COUNT;
}
