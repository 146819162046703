<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterNode
    :index="index"
    :depth="condition.depth"
    :show-or-drop-area="condition.depth === 0 && isDragging"
    :title="$t('nodeTitleContact')"
    class="filter-node-contact"
    :show-add-condition="condition.isConditionAddable()"
    :exclusion="exclusion"
    :has-all-additional-conditions="checkAdditionalConditions"
    :select-exclusion-type-value="exclutionTypeValue"
    @on-select-exclusion-type="onSelectExclusionType"
    @add-condition="onClickAddCondition"
    @add-or-node="$emit('add-or-node', $event)"
    @remove-node="$emit('remove-node')"
  >
    <FilterContactField
      v-model="contactCondition"
      :contact-definitions="contactDefinitions"
    />

    <FilterNodeItem
      v-for="(additionalCondition, index) in condition.additionalConditions"
      :key="index"
      :options="
        condition.selectOption(
          additionalCondition,
          contactDefinitionType,
          false,
          isFirstNode
        )
      "
      :condition-type="additionalCondition.conditionType"
      @input="
        type => {
          onInputItem(type, index);
        }
      "
      @remove="onRemoveAdditionalCondition(index)"
    >
      <FilterContactPurposeField
        v-if="additionalCondition.conditionType === contactPurpose"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterContactEmployeeField
        v-if="additionalCondition.conditionType === contactEmployee"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterContactTitleField
        v-if="additionalCondition.conditionType === contactTitle"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterContactShopNameField
        v-if="additionalCondition.conditionType === contactShopName"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterContactContentField
        v-if="additionalCondition.conditionType === contactContent"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterDateHourField
        v-if="additionalCondition.conditionType === dateHour"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterPeriodField
        v-if="additionalCondition.conditionType === period"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
    </FilterNodeItem>
  </FilterNode>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import { FilterNodeForContact } from "@/models/search/filter-node/FilterNodeForContact";
import {
  ContactDefinition,
  ContactDefinitionType
} from "@/models/client-settings/ContactDefinition";

import { ExclusionProps } from "@/models/search/filter-node/FilterExclusion";
import { FilterAdditionalTimingCondition } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterContactTitleCondition } from "@/models/search/filter-node-condition/FilterContactTitleCondition";
import { FilterContactContentCondition } from "@/models/search/filter-node-condition/FilterContactContentCondition";
import { FilterContactPurposeCondition } from "@/models/search/filter-node-condition/FilterContactPurposeCondition";
import { FilterContactEmployeeCondition } from "@/models/search/filter-node-condition/FilterContactEmployeeCondition";
import { FilterContactShopNameCondition } from "@/models/search/filter-node-condition/FilterContactShopNameCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { FilterContactCondition } from "@/models/search/filter-node-condition/FilterContactCondition";

import FilterNode from "@/components/filter/FilterNode.vue";
import FilterNodeItem from "@/components/filter/FilterNodeItem.vue";
import FilterDateHourField from "@/components/filter/form/FilterDateHourField.vue";
import FilterPeriodField from "@/components/filter/form/FilterPeriodField.vue";
import FilterContactField from "@/components/filter/form/FilterContactField.vue";
import FilterContactTitleField from "@/components/filter/form/FilterContactTitleField.vue";
import FilterContactContentField from "@/components/filter/form/FilterContactContentField.vue";
import FilterContactPurposeField from "@/components/filter/form/FilterContactPurposeField.vue";
import FilterContactEmployeeField from "@/components/filter/form/FilterContactEmployeeField.vue";
import FilterContactShopNameField from "@/components/filter/form/FilterContactShopNameField.vue";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";

import {
  ExclusionType,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";

@Component({
  name: "FilterNodeContact",
  components: {
    FilterNode,
    FilterNodeItem,
    FilterDateHourField,
    FilterPeriodField,
    FilterContactField,
    FilterContactTitleField,
    FilterContactPurposeField,
    FilterContactEmployeeField,
    FilterContactShopNameField,
    FilterContactContentField
  }
})
export default class FilterNodeContact extends Vue {
  dateHour = FilterNodeConditionType.DateHour;
  period = FilterNodeConditionType.Period;
  contactDefinitions: ContactDefinition[] = this.$store.state.clientSettings
    .activeContactDefinitions;
  contactTitle = FilterNodeConditionType.ContactTitle;
  contactPurpose = FilterNodeConditionType.ContactPurpose;
  contactEmployee = FilterNodeConditionType.ContactEmployee;
  contactShopName = FilterNodeConditionType.ContactShopName;
  contactContent = FilterNodeConditionType.ContactContent;

  @Model("input", { type: FilterNodeForContact, required: true })
  condition!: FilterNodeForContact;

  @Prop({ type: Object, required: true })
  exclusion!: ExclusionProps;

  @Prop({ type: Boolean, required: true })
  isDragging!: boolean;

  @Prop({ type: Boolean, required: true })
  isFirstNode!: boolean;

  @Prop({ type: Number, required: true })
  index!: number;

  onInput(condition: FilterNodeForContact) {
    this.$emit("input", condition);
  }

  get contactCondition(): FilterContactCondition {
    return this.condition.condition;
  }
  set contactCondition(condition: FilterContactCondition) {
    // to reset additionalCondittions
    this.onInput(
      new FilterNodeForContact(
        condition,
        [],
        this.condition.childIndex,
        this.condition.depth,
        null,
        this.condition.filterExclusion
      )
    );
  }

  get contactDefinitionType(): ContactDefinitionType {
    const contactDefinition:
      | ContactDefinition
      | undefined = this.contactDefinitions.find(
      contactDefinition =>
        contactDefinition.id === this.contactCondition.contactDefinitionId
    );

    if (contactDefinition) {
      return contactDefinition.type;
    }

    return ContactDefinitionType.VISIT; // Formal return to prevent errors
  }

  // 条件を追加
  onClickAddCondition() {
    const additionalConditions = this.condition.additionalConditions;
    const addCondition = this.addedCondition();

    additionalConditions.push(addCondition);
    this.onInput(this.createCondition(null, additionalConditions));
  }

  addedCondition():
    | FilterAdditionalTimingCondition
    | FilterContactTitleCondition
    | FilterContactContentCondition
    | FilterContactPurposeCondition
    | FilterContactEmployeeCondition
    | FilterContactShopNameCondition {
    if (
      (this.contactDefinitionType === ContactDefinitionType.MAIL ||
        this.contactDefinitionType === ContactDefinitionType.DM ||
        this.contactDefinitionType === ContactDefinitionType.APP) &&
      !this.condition.hasContactTitleCondition
    ) {
      return new FilterContactTitleCondition("");
    }

    if (
      this.contactDefinitionType === ContactDefinitionType.TEL &&
      !this.condition.hasContactContentCondition
    ) {
      return new FilterContactContentCondition("");
    }

    if (this.contactDefinitionType === ContactDefinitionType.VISIT) {
      if (!this.condition.hasContactPurposeCondition) {
        return new FilterContactPurposeCondition("");
      }
      if (!this.condition.hasContactEmployeeCondition) {
        return new FilterContactEmployeeCondition("");
      }
    }

    if (this.contactDefinitionType === ContactDefinitionType.SHOP) {
      if (!this.condition.hasContactShopNameCondition) {
        return new FilterContactShopNameCondition("");
      }
      if (!this.condition.hasContactPurposeCondition) {
        return new FilterContactPurposeCondition("");
      }
    }

    if (this.isFirstNode && !this.condition.hasFirstTime) {
      return FilterFirstTimeCondition.buildDefaultData();
    }
    return FilterPeriodCondition.buildDefaultData();
  }

  onRemoveAdditionalCondition(index: number) {
    const additionalConditions = this.condition.additionalConditions.filter(
      (cnd, idx) => idx !== index
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  onInputItem(type: FilterNodeConditionType, index: number) {
    let additionalCondition!:
      | FilterContactTitleCondition
      | FilterContactContentCondition
      | FilterContactPurposeCondition
      | FilterContactEmployeeCondition
      | FilterContactShopNameCondition
      | FilterAdditionalTimingCondition;
    if (type === FilterNodeConditionType.ContactTitle) {
      additionalCondition = new FilterContactTitleCondition("");
    } else if (type === FilterNodeConditionType.ContactContent) {
      additionalCondition = new FilterContactContentCondition("");
    } else if (type === FilterNodeConditionType.ContactPurpose) {
      additionalCondition = new FilterContactPurposeCondition("");
    } else if (type === FilterNodeConditionType.ContactEmployee) {
      additionalCondition = new FilterContactEmployeeCondition("");
    } else if (type === FilterNodeConditionType.ContactShopName) {
      additionalCondition = new FilterContactShopNameCondition("");
    } else if (type === FilterNodeConditionType.FirstTime) {
      additionalCondition = FilterFirstTimeCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.DateHour) {
      additionalCondition = FilterDateHourCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.Period) {
      additionalCondition = FilterPeriodCondition.buildDefaultData();
    }

    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? additionalCondition : cnd)
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  onInputAdditionalCondition(
    condition:
      | FilterContactTitleCondition
      | FilterContactContentCondition
      | FilterContactPurposeCondition
      | FilterContactEmployeeCondition
      | FilterContactShopNameCondition
      | FilterAdditionalTimingCondition,
    index: number
  ) {
    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(this.createCondition(null, additionalConditions));
  }

  createCondition(
    condition: FilterContactCondition | null,
    conditions:
      | (
          | FilterContactTitleCondition
          | FilterContactContentCondition
          | FilterContactPurposeCondition
          | FilterContactEmployeeCondition
          | FilterContactShopNameCondition
          | FilterAdditionalTimingCondition
        )[]
      | null
  ): FilterNodeForContact {
    const setCondition =
      condition === null ? this.condition.condition : condition;

    const setConditions =
      conditions === null ? this.condition.additionalConditions : conditions;
    return new FilterNodeForContact(
      setCondition,
      setConditions,
      this.condition.childIndex,
      this.condition.depth,
      this.condition.edge,
      this.condition.filterExclusion
    );
  }

  onSelectExclusionType(exclutionTypeValue: ExclusionType) {
    return this.onInput(
      new FilterNodeForContact(
        this.condition.condition,
        this.condition.additionalConditions,
        this.condition.childIndex,
        this.condition.depth,
        this.condition.edge,
        new FilterExclusion(exclutionTypeValue)
      )
    );
  }
  get exclutionTypeValue() {
    return this.condition.filterExclusion.exclusionType;
  }

  get checkAdditionalConditions(): boolean {
    const conditionsMax = 2;
    const conditions = this.$props.condition;

    return (
      conditions.additionalConditions &&
      conditions.additionalConditions.length === conditionsMax
    );
  }
}
</script>
