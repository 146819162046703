import { GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { Memo } from "@/models/userdata/Memo";
import { UserHistory } from "@/models/userdata/UserHistory";
import { Memo as UserMemo, User } from "@/models/User";

export class HistoryState {
  memos: Memo[] = [];
  userHistories: UserHistory[] = [];
}

const mutations: MutationTree<HistoryState> = {
  setMemos(state: HistoryState, memos: Memo[]) {
    state.memos = memos;
  },
  addMemo(
    state: HistoryState,
    { userId, memo }: { userId: string; memo: UserMemo }
  ) {
    const list = state.memos.filter(m => m.userId !== userId);
    list.unshift(new Memo(userId, memo.content, memo.updatedDate));
    state.memos = list;
  },
  setUserHistories(state: HistoryState, userHistories: UserHistory[]) {
    state.userHistories = userHistories;
  },
  addUserHistory(state: HistoryState, user: User) {
    const list = state.userHistories.filter(h => h.userId !== user.id);
    list.unshift(new UserHistory(user.id, new Date()));
    state.userHistories = list;
  }
};

const getters: GetterTree<HistoryState, RootState> = {
  userIds(state) {
    return state.userHistories.map(f => f.userId);
  }
};

const actions: ActionTree<HistoryState, RootState> = {
  async fetchMemos({ commit, rootState }) {
    const body = await rootState.api.memo.getMemoList();
    const memos: Memo[] = body.memos.map(Memo.fromJson);
    commit(
      "setMemos",
      memos.filter(m => m.content !== "")
    );
  },
  async fetchHistories({ commit, rootState }) {
    const body = await rootState.api.userHistory.getUserHistoryList();
    const userHistories: UserHistory[] = body.histories.map(
      UserHistory.fromJson
    );
    commit("setUserHistories", userHistories);
  }
};

export const history = {
  namespaced: true,
  state: new HistoryState(),
  mutations: mutations,
  getters: getters,
  actions: actions
};
