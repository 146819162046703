<template>
  <Tooltip effect="light" :value="value" :placement="placement">
    <slot></slot>
    <template #content>
      <slot name="content"></slot>
    </template>
  </Tooltip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Tooltip } from "element-ui";

@Component({
  components: { Tooltip }
})
export default class ChartBalloonBase extends Vue {
  @Prop({ type: String, default: "top" })
  placement!: string;
  @Prop({ type: Boolean, default: false })
  value!: boolean;
}
</script>
