import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import SelectOption from "@/components/form/SelectOption";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";

/**
 * 広告タイプ
 */
export enum AdType {
  Banner = 1,
  Text = 2,
  Mail = 3,
  Listing = 4,
  Affiliate = 5
}

/**
 * 広告タイプによる条件
 *
 * 広告での絞り込みに、広告タイプを条件として付与できる
 *
 */
export class FilterAdTypeCondition {
  public readonly conditionType = FilterNodeConditionType.AdType;

  constructor(public readonly adType: AdType) {}

  // validateがfalseになる条件がない
  get validate(): ValidationResult {
    return { isValid: true };
  }
}

// 「広告枠名」「広告グループ名」が選択されているかによって選択できるものが変わる
export function selectOption(
  isSelectedAdInverntory: boolean,
  isSelectedAdGroup: boolean
): SelectOption[] {
  // AdInverntoryとAdGroupは同時に選択できないので両方trueはない
  // どちらも選ばれないときは両方false
  let bnaner = false;
  let text = false;
  let mail = false;
  let listing = false;
  let affiliate = false;
  //「広告枠名」が選択されている場合、「リスティング」「アフィリエイト」は選択不可
  if (isSelectedAdInverntory) {
    listing = true;
    affiliate = true;
  }
  // 「広告グループ名」が選択されている場合、「リスティング」のみ選択可能
  else if (isSelectedAdGroup) {
    bnaner = true;
    text = true;
    mail = true;
    affiliate = true;
  }

  return [
    {
      label: i18n.t("models.search.banner") as string,
      value: AdType.Banner,
      disabled: bnaner
    },
    {
      label: i18n.t("models.search.text") as string,
      value: AdType.Text,
      disabled: text
    },
    {
      label: i18n.t("models.search.mail") as string,
      value: AdType.Mail,
      disabled: mail
    },
    {
      label: i18n.t("models.search.listing") as string,
      value: AdType.Listing,
      disabled: listing
    },
    {
      label: i18n.t("models.search.affiliate") as string,
      value: AdType.Affiliate,
      disabled: affiliate
    }
  ];
}

// 「広告枠名」「広告グループ名」が選択されているかによって選択できるものが変わるため、
// デフォルトでセットできる値が変わる
export function defaultAdType(
  isSelectedAdInverntory: boolean,
  isSelectedAdGroup: boolean
): AdType {
  return selectOption(isSelectedAdInverntory, isSelectedAdGroup).filter(
    opt => !opt.disabled
  )[0].value as AdType;
}
