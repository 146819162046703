import { render, staticRenderFns } from "./FunnelSearchHistoryItem.vue?vue&type=template&id=4c0d7bb6&scoped=true"
import script from "./FunnelSearchHistoryItem.vue?vue&type=script&lang=ts"
export * from "./FunnelSearchHistoryItem.vue?vue&type=script&lang=ts"
import style0 from "./FunnelSearchHistoryItem.vue?vue&type=style&index=0&id=4c0d7bb6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c0d7bb6",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/funnel/funnel-search-history-item.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Ffunnel%2FFunnelSearchHistoryItem.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports