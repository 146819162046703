<i18n src="@/i18n/components/observation/visit/gram-inflow.json"></i18n>

<template>
  <div
    class="ud-Visit_Gram ud-Visit_Gram_Inflow"
    :class="{ 'ud-Visit_Gram_Omo': isOmo }"
  >
    <div class="ud-Visit_GramIcon">
      <div class="ud-Visit_GramIcon_Circle">
        <Icon :icon="inflowRight" :size="17" :color="iconColor" />
      </div>
    </div>
    <div class="ud-Visit_GramBody">
      <div class="ud-Visit_InflowTitle">{{ title }}</div>

      <div class="ud-Visit_InflowAttrs">
        <div class="ud-Visit_InflowAttr">
          <div class="ud-Visit_InflowAttrTitle">{{ $t("adType") }}</div>
          <div class="ud-Visit_InflowAttrValue">{{ adTypeTitle }}</div>
        </div>
        <div class="ud-Visit_InflowAttr">
          <div class="ud-Visit_InflowAttrTitle">{{ $t("medium") }}</div>
          <div class="ud-Visit_InflowAttrValue">{{ adMedia }}</div>
        </div>
        <div v-if="adInventory" class="ud-Visit_InflowAttr">
          <div class="ud-Visit_InflowAttrTitle">{{ $t("adPlacement") }}</div>
          <div class="ud-Visit_InflowAttrValue">{{ adInventory }}</div>
        </div>
        <div v-if="adGroup" class="ud-Visit_InflowAttr">
          <div class="ud-Visit_InflowAttrTitle">{{ $t("adGroup") }}</div>
          <div class="ud-Visit_InflowAttrValue">{{ adGroup }}</div>
        </div>
        <div v-if="adCampaign" class="ud-Visit_InflowAttr">
          <div class="ud-Visit_InflowAttrTitle">{{ $t("campaign") }}</div>
          <div class="ud-Visit_InflowAttrValue">{{ adCampaign }}</div>
        </div>
        <div v-if="adCreative" class="ud-Visit_InflowAttr">
          <div class="ud-Visit_InflowAttrTitle">{{ $t("creative") }}</div>
          <div class="ud-Visit_InflowAttrValue">{{ adCreative }}</div>
        </div>
        <div v-if="locationReferrer" class="ud-Visit_InflowAttr">
          <div class="ud-Visit_InflowAttrTitle">{{ $t("referrer") }}</div>
          <div class="ud-Visit_InflowAttrValue">{{ locationReferrer }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import GramBase from "@/components/observation/visit/gram/GramBase.vue";
import Icon from "@/components/Icon.vue";
import { Gram } from "@/models/Gram";
import { Colors } from "@/const/Colors";

// 広告種別
const AD_TYPE = {
  BANNER: 1,
  TEXT: 2,
  MAIL: 3,
  LISTING: 4,
  AFFILIATE: 5
};

@Component({
  components: { Icon }
})
export default class GramInflowAd extends GramBase {
  @Prop({ type: Gram, required: true })
  gram!: Gram;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  inflowRight = Icons.InflowRight;
  iconColor = Colors.Blue900;

  get title(): string {
    return this.$i18n.t("adInflowFrom_MEDIA", {
      media: this.adMedia
    }) as string;
  }

  get adTypeTitle(): string {
    switch (this.gram.pvAdType) {
      case AD_TYPE.BANNER:
        return this.$i18n.t("banner") as string;
      case AD_TYPE.TEXT:
        return this.$i18n.t("text") as string;
      case AD_TYPE.MAIL:
        return this.$i18n.t("mail") as string;
      case AD_TYPE.LISTING:
        return this.$i18n.t("listing") as string;
      case AD_TYPE.AFFILIATE:
        return this.$i18n.t("affiliate") as string;
      default:
        return "";
    }
  }

  get adMedia(): string {
    return this.gram.pvAdMedia;
  }

  get adInventory(): string {
    return this.gram.pvAdMenu;
  }

  get adGroup(): string {
    return this.gram.pvAdAdvertiseGroup;
  }

  get adCampaign(): string {
    return this.gram.pvAdAdvertise;
  }

  get adCreative(): string {
    // リスティングの場合のみcreativeを用いる
    if (this.gram.pvAdType === AD_TYPE.LISTING) {
      return this.gram.pvAdCreative;
    }
    return this.gram.pvAdCreativeName;
  }

  get locationReferrer(): string {
    return this.gram.pvLocationReferrer;
  }
}
</script>
