import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { MINUTES_IN_HOUR, SECONDS_IN_MINUTE, Sec } from "@/util/date-util";

/**
 * 日付と時間帯による条件
 *
 * 絞り込み条件に追加で、日付と時間帯を条件として付与できる
 */
export class FilterDateHourCondition {
  public readonly conditionType = FilterNodeConditionType.DateHour;

  constructor(
    // 日付
    public readonly date: Date,
    /**
     * 時間帯
     *
     * 例えば1が指定されていれば
     * 午前1時〜2時までを取得する（2時ちょうどは含めない）
     */
    public readonly hour: number
  ) {}

  // date pickerが不正な日付は弾いてくれるので
  get validate(): ValidationResult {
    return { isValid: true };
  }

  public static buildDefaultData(): FilterDateHourCondition {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return new FilterDateHourCondition(date, 0);
  }
}

/**
 * 時間のスパンが1時間以内なら、○時台と指定して検索した、と判定する。
 */
export function isDateHourCondition(startSec: Sec, endSec: Sec): boolean {
  return endSec - startSec < MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
}
