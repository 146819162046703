<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterBaseInputText
    v-model="value"
    :prefix="$t('containsPrefix')"
    :suffix="$te('containsSuffix') ? $t('containsSuffix') : ''"
    class="filter-ad-group-form"
  />
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterAdGroupCondition } from "@/models/search/filter-node-condition/FilterAdGroupCondition";
import FilterBaseInputText from "@/components/filter/form/FilterBaseInputText.vue";

@Component({
  components: {
    FilterBaseInputText
  }
})
export default class FilterAdGroupField extends Vue {
  @Model("input", { type: FilterAdGroupCondition, required: true })
  condition!: FilterAdGroupCondition;

  onInput(condition: FilterAdGroupCondition) {
    this.$emit("input", condition);
  }

  get value(): string {
    return this.condition.groupName;
  }
  set value(groupName: string) {
    this.onInput(new FilterAdGroupCondition(groupName));
  }
}
</script>
