import { ActionTree, MutationTree } from "vuex";
import { RootState } from "@/store/";

export class AppState {
  isInitialized: boolean = false;
  isSettingsInitialized: boolean = false;
  isImportValidationEnabled: boolean = false;
  canUseBetaUI: boolean = false;
  canUseIntermediateJourney: boolean = false;
  canUseNewSideBar: boolean = false;
  canUseWebdataFeatures: boolean = false;
}

const mutations: MutationTree<AppState> = {
  setIsInitialized(state: AppState, isInitialized: boolean) {
    state.isInitialized = isInitialized;
  },
  setIsSettingsInitialized(state: AppState, isInitialized: boolean) {
    state.isSettingsInitialized = isInitialized;
  },
  setIsImportValidationEnabled(state: AppState, enabled: boolean) {
    state.isImportValidationEnabled = enabled;
  },
  setCanUseBetaUI(state: AppState, canUseBetaUI: boolean) {
    state.canUseBetaUI = canUseBetaUI;
  },
  setCanUseIntermediateJourney(
    state: AppState,
    canUseIntermediateJourney: boolean
  ) {
    state.canUseIntermediateJourney = canUseIntermediateJourney;
  },
  setCanUseNewSideBar(state: AppState, canUseNewSideBar: boolean) {
    state.canUseNewSideBar = canUseNewSideBar;
  },
  setCanUseWebdataFeatures(state: AppState, canUseWebdataFeatures: boolean) {
    state.canUseWebdataFeatures = canUseWebdataFeatures;
  }
};

export const actions = <ActionTree<AppState, RootState>>{
  async initializePostLogin({ dispatch }) {
    await Promise.all([
      dispatch("inputFormSuggestion/create", null, { root: true })
    ]);
  },

  async initialize({ commit, dispatch, rootState }) {
    await Promise.all([
      dispatch("fetchConfig"),

      // Definitions系のfetch
      dispatch("client/fetchClient", null, { root: true }),
      dispatch("system/fetchConversionDefinitions", null, { root: true }),
      dispatch("system/fetchConversionAttributeDefinitions", null, {
        root: true
      }),
      dispatch("clientSettings/fetchConversionDefinitions", null, {
        root: true
      }),
      dispatch("clientSettings/fetchConversionAttributeDefinitions", null, {
        root: true
      }),
      dispatch("clientSettings/fetchEventDefinitions", null, { root: true }),
      dispatch("clientSettings/fetchNpsDefinitions", null, { root: true }),
      dispatch("clientSettings/fetchEnqueteDefinitions", null, { root: true }),
      dispatch("clientSettings/fetchBusinessEventDefinitions", null, {
        root: true
      }),
      dispatch("clientSettings/fetchBusinessIndexDefinitions", null, {
        root: true
      }),
      dispatch("clientSettings/fetchContactDefinitions", null, { root: true }),
      dispatch("clientSettings/fetchUserAttributeDefinitions", null, {
        root: true
      }),
      dispatch("clientSettings/fetchMeasurementTargetSites", null, {
        root: true
      }),
      dispatch("client/fetchUsers", null, { root: true }),

      // 絞り込みで使用するサーチエンジン一覧
      dispatch("system/fetchSearchEngines", null, { root: true }),
      // ユーザアイコン出し分けのためのPV閾値
      dispatch("system/fetchPvStats", null, { root: true })
    ]);

    await Promise.all([
      // fetchUserInfo生成のため必要
      dispatch("history/fetchHistories", null, { root: true }),

      // 過去の検索条件のfetch
      dispatch("searchHistory/fetchSearchHistories", null, { root: true }),
      dispatch("searchHistory/fetchFavoriteSearches", null, { root: true }),
      dispatch("searchHistory/fetchFilterHistories", null, { root: true }),

      // お気に入り許容人数をオーバしていないか、ユーザが既にお気に入りに追加済みかの判断のために必須
      dispatch("favorite/fetchFavorite", null, { root: true }),

      // 検索履歴から検索条件を復活させる（なければデフォルト値をセット）
      dispatch("searchForm/setDefaultToConversionCondition", null, {
        root: true
      }),
      dispatch("searchForm/setDefaultToServiceIdCondition", null, {
        root: true
      }),
      dispatch("searchForm/setDefaultToBusinessEventCondition", null, {
        root: true
      }),
      dispatch("searchForm/setDefaultToContactCondition", null, { root: true }),
      dispatch("searchForm/setDefaultToNpsCondition", null, { root: true }),
      dispatch("searchForm/setDefaultToAppCondition", null, { root: true }),
      dispatch("searchForm/setDefaultToEngagementCondition", null, {
        root: true
      })
    ]);

    // OMO 企業側の行動表示・非表示フラグを初期化
    dispatch(
      "companyActivityDisplaySetting/setUp",
      rootState.clientSettings.activeContactDefinitions,
      { root: true }
    );

    commit("setIsInitialized", true);
  },

  async initializeSettings({ commit, dispatch }) {
    await Promise.all([
      dispatch("fetchConfig"),
      dispatch("client/fetchClient", null, { root: true }),
      dispatch("clientSettings/fetchCoordinations", null, { root: true })
    ]);

    commit("setIsSettingsInitialized", true);
  },

  async fetchConfig({ commit, rootState }) {
    const response = await rootState.api.config.getConfig();

    commit(
      "setIsImportValidationEnabled",
      response.is_import_validation_enabled || false
    );
    commit("setCanUseBetaUI", response.can_use_beta_ui || false);
    commit(
      "funnel/setCanUseLongTermFunnel",
      response.can_use_long_term_funnel || false,
      { root: true }
    );
    commit(
      "setCanUseIntermediateJourney",
      response.can_use_intermediate_journey || false
    );
    commit("setCanUseNewSideBar", response.can_use_new_side_bar || false);
    commit(
      "setCanUseWebdataFeatures",
      response.can_use_webdata_features || false
    );
  }
};

export const app = {
  namespaced: true,
  state: new AppState(),
  actions: actions,
  mutations: mutations
};
