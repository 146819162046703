<template>
  <div class="funnelAddableConditionWrapper">
    <div
      v-if="addedConditions.length > 0"
      :class="{
        'funnelAddableConditionWrapperLabels--beforeAddButton': canAdd
      }"
    >
      <FunnelAddedConditionLabel
        v-for="(c, i) in addedConditions"
        :key="i"
        class="funnelAddableConditionWrapper__addedCondition dragNotApplicable"
        :display-value="c.displayValue"
        :label="c.label"
        @edit="emit('edit', i)"
        @delete="emit('delete', i)"
      />
    </div>

    <div
      v-if="canAdd"
      class="funnelAddableConditionWrapper__addPreFilterButton"
    >
      <div
        class="funnelAddableConditionWrapper__addButtonClickableArea dragNotApplicable"
        @click="emit('add')"
      >
        <IconButton
          :icon="ICONS.PlusCircle"
          :size="18"
          :button-type="iconButtonType"
        />
        <div
          class="funnelAddableConditionWrapper__addButtonText"
          v-text="buttonText"
        />
      </div>
      <div
        v-if="buttonNote"
        class="funnelAddableConditionWrapper__addButtonNote"
        v-text="buttonNote"
      />
    </div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import FunnelAddedConditionLabel from "@/components/funnel/FunnelAddedConditionLabel.vue";
import IconButton from "@/components/IconButton.vue";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";

export interface AddedCondition {
  label: string;
  displayValue: string;
}

const ICONS = Icons;
const iconButtonType = IconButtonType.Weak;

defineProps<{
  buttonText: string;
  buttonNote?: string;
  canAdd: boolean;
  addedConditions: AddedCondition[];
}>();

const emit = defineEmits<{
  (e: "add"): void;
  (e: "edit", index: number): void;
  (e: "delete", index: number): void;
}>();
</script>

<style lang="scss" scoped>
.funnelAddableConditionWrapper__addedCondition {
  margin-top: 10px;
}

.funnelAddableConditionWrapperLabels--beforeAddButton {
  margin-bottom: 25px;
}

.funnelAddableConditionWrapper__addPreFilterButton {
  align-items: center;
  display: flex;
  color: $colorBase500;
  font-size: 18px;

  &:hover {
    color: $colorBase600;
  }
}

.funnelAddableConditionWrapper__addButtonClickableArea {
  align-items: center;
  display: flex;
  cursor: pointer;
}

.funnelAddableConditionWrapper__addButtonText {
  color: $colorBase900;
  font-size: 14px;
  margin-left: 7px;
  padding-top: 1px;

  &:hover {
    text-decoration: underline;
  }
}

.funnelAddableConditionWrapper__addButtonNote {
  color: $colorBase900;
  cursor: default;
  font-size: 12px;
  margin-left: 5px;
  padding-top: 1px;
}
</style>
