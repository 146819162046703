<template>
  <div class="child-filter-node-referrer">
    <FilterNodeItem
      v-model="conditionType"
      :is-deletable="false"
      :options="condition.selectOption(condition.condition, true, isFirstNode)"
    >
      <FilterReferrerUrlField
        v-if="conditionType === referrerUrl"
        v-model="mainCondition"
      />
      <FilterLandingPageUrlField
        v-if="conditionType === landingPageUrl"
        v-model="mainCondition"
      />
      <FilterLandingPageTitleField
        v-if="conditionType === landingPageTitle"
        v-model="mainCondition"
      />
    </FilterNodeItem>

    <FilterNodeItem
      v-for="(additionalCondition, index) in condition.additionalConditions"
      :key="index"
      :options="condition.selectOption(additionalCondition, false, isFirstNode)"
      :condition-type="additionalCondition.conditionType"
      @input="
        type => {
          onInputItem(type, index);
        }
      "
      @remove="onRemoveAdditionalCondition(index)"
    >
      <FilterReferrerUrlField
        v-if="additionalCondition.conditionType === referrerUrl"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterLandingPageUrlField
        v-if="additionalCondition.conditionType === landingPageUrl"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterLandingPageTitleField
        v-if="additionalCondition.conditionType === landingPageTitle"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterDateHourField
        v-if="additionalCondition.conditionType === dateHour"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterPeriodField
        v-if="additionalCondition.conditionType === period"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
    </FilterNodeItem>
    <FilterAddConditionButton
      v-if="condition.isConditionAddable"
      @click="onClickAddCondition"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";

import FilterNodeItem from "@/components/filter/FilterNodeItem.vue";
import FilterAddConditionButton from "@/components/filter/FilterAddConditionButton.vue";
import { ChildFilterNodeForReferrer } from "@/models/search/filter-node/ChildFilterNodeForReferrer";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterReferrerUrlCondition } from "@/models/search/filter-node-condition/FilterReferrerUrlCondition";
import { FilterLandingPageUrlCondition } from "@/models/search/filter-node-condition/FilterLandingPageUrlCondition";
import { FilterLandingPageTitleCondition } from "@/models/search/filter-node-condition/FilterLandingPageTitleCondition";
import { FilterAdditionalTimingCondition } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import FilterReferrerUrlField from "@/components/filter/form/FilterReferrerUrlField.vue";
import FilterLandingPageUrlField from "@/components/filter/form/FilterLandingPageUrlField.vue";
import FilterLandingPageTitleField from "@/components/filter/form/FilterLandingPageTitleField.vue";
import FilterDateHourField from "@/components/filter/form/FilterDateHourField.vue";
import FilterPeriodField from "@/components/filter/form/FilterPeriodField.vue";
import { MatchMethod } from "@/models/search/MatchMethod";

@Component({
  components: {
    FilterNodeItem,
    FilterReferrerUrlField,
    FilterLandingPageUrlField,
    FilterLandingPageTitleField,
    FilterDateHourField,
    FilterPeriodField,
    FilterAddConditionButton
  }
})
export default class ChildFilterNodeReferrer extends Vue {
  referrerUrl = FilterNodeConditionType.ReferrerUrl;
  landingPageUrl = FilterNodeConditionType.LandingPageUrl;
  landingPageTitle = FilterNodeConditionType.LandingPageTitle;

  dateHour = FilterNodeConditionType.DateHour;
  period = FilterNodeConditionType.Period;

  @Model("input", { type: ChildFilterNodeForReferrer, required: true })
  condition!: ChildFilterNodeForReferrer;

  @Prop({ type: Number, required: true })
  depth!: number;

  @Prop({ type: Boolean, required: true })
  isFirstNode!: boolean;

  onInput(condition: ChildFilterNodeForReferrer) {
    this.$emit("input", condition);
  }

  get conditionType():
    | FilterNodeConditionType.ReferrerUrl
    | FilterNodeConditionType.LandingPageUrl
    | FilterNodeConditionType.LandingPageTitle {
    return this.condition.condition.conditionType;
  }
  set conditionType(
    type:
      | FilterNodeConditionType.ReferrerUrl
      | FilterNodeConditionType.LandingPageUrl
      | FilterNodeConditionType.LandingPageTitle
  ) {
    let condition!:
      | FilterReferrerUrlCondition
      | FilterLandingPageUrlCondition
      | FilterLandingPageTitleCondition;
    if (type === FilterNodeConditionType.ReferrerUrl) {
      condition = new FilterReferrerUrlCondition("", MatchMethod.Partial);
    } else if (type === FilterNodeConditionType.LandingPageUrl) {
      condition = new FilterLandingPageUrlCondition("", MatchMethod.Partial);
    } else if (type === FilterNodeConditionType.LandingPageTitle) {
      condition = new FilterLandingPageTitleCondition("", MatchMethod.Partial);
    }
    this.onInput(
      new ChildFilterNodeForReferrer(
        condition,
        this.condition.additionalConditions,
        this.depth
      )
    );
  }

  get mainCondition():
    | FilterReferrerUrlCondition
    | FilterLandingPageUrlCondition
    | FilterLandingPageTitleCondition {
    return this.condition.condition;
  }
  set mainCondition(
    condition:
      | FilterReferrerUrlCondition
      | FilterLandingPageUrlCondition
      | FilterLandingPageTitleCondition
  ) {
    this.onInput(
      new ChildFilterNodeForReferrer(
        condition,
        this.condition.additionalConditions,
        this.depth
      )
    );
  }

  onRemoveAdditionalCondition(index: number) {
    const additionalConditions = this.condition.additionalConditions.filter(
      (cnd, idx) => idx !== index
    );
    this.onInput(
      new ChildFilterNodeForReferrer(
        this.condition.condition,
        additionalConditions,
        this.depth
      )
    );
  }

  onInputItem(type: FilterNodeConditionType, index: number) {
    let condition!:
      | FilterReferrerUrlCondition
      | FilterLandingPageUrlCondition
      | FilterLandingPageTitleCondition
      | FilterAdditionalTimingCondition;

    if (type === FilterNodeConditionType.ReferrerUrl) {
      condition = new FilterReferrerUrlCondition("", MatchMethod.Partial);
    } else if (type === FilterNodeConditionType.LandingPageUrl) {
      condition = new FilterLandingPageUrlCondition("", MatchMethod.Partial);
    } else if (type === FilterNodeConditionType.LandingPageTitle) {
      condition = new FilterLandingPageTitleCondition("", MatchMethod.Partial);
    } else if (type === FilterNodeConditionType.FirstTime) {
      condition = FilterFirstTimeCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.DateHour) {
      condition = FilterDateHourCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.Period) {
      condition = FilterPeriodCondition.buildDefaultData();
    }

    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(
      new ChildFilterNodeForReferrer(
        this.condition.condition,
        additionalConditions,
        this.depth
      )
    );
  }

  onInputAdditionalCondition(
    condition: FilterAdditionalTimingCondition,
    index: number
  ) {
    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(
      new ChildFilterNodeForReferrer(
        this.condition.condition,
        additionalConditions,
        this.depth
      )
    );
  }

  onClickAddCondition() {
    const additionalConditions = this.condition.additionalConditions;
    let condition!:
      | FilterReferrerUrlCondition
      | FilterLandingPageUrlCondition
      | FilterLandingPageTitleCondition
      | FilterAdditionalTimingCondition;

    const hasFirstTime = additionalConditions.some(
      cnd => cnd instanceof FilterFirstTimeCondition
    );

    if (!this.condition.hasReferrerUrlCondition) {
      condition = new FilterReferrerUrlCondition("", MatchMethod.Partial);
    } else if (!this.condition.hasLandingPageUrlCondition) {
      condition = new FilterLandingPageUrlCondition("", MatchMethod.Partial);
    } else if (!this.condition.hasLandingPageTitleCondition) {
      condition = new FilterLandingPageTitleCondition("", MatchMethod.Partial);
    } else if (this.isFirstNode && !hasFirstTime) {
      condition = FilterFirstTimeCondition.buildDefaultData();
    } else {
      condition = FilterPeriodCondition.buildDefaultData();
    }

    additionalConditions.push(condition);
    this.onInput(
      new ChildFilterNodeForReferrer(
        this.condition.condition,
        additionalConditions,
        this.depth
      )
    );
  }
}
</script>
