<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <div
    ref="SearchResultContainer"
    class="SearchResultContainer"
    data-cy="search-result-helper"
    :tabindex="0"
    @keyup.right="onArrowRight"
    @keyup.left="onArrowLeft"
  >
    <SearchResultHeader
      v-if="!isTourDetail"
      :can-save-condition="canSaveCondition"
      :favorite-search-label="favoriteSearchLabel"
      :icon-title="icon"
      :title="label"
      @click-save-condition="showSaveSearchConditionModal"
    >
      <template #selectCondition>
        <slot name="conditions"></slot>
      </template>
      <template #selectDetailedCondition>
        <slot name="detailedConditions"></slot>
      </template>
    </SearchResultHeader>

    <SearchResultContent
      :class="{
        'SearchResultContainer--hideInPrintMode': showUserDetail
      }"
      :view="view"
      :loading="!isFetched"
      :show-more-button="showMoreButton"
      :colored-periods="coloredPeriods"
      :almost-cv-user-list="almostCvUserList"
      @view-change="onViewChange"
      @click-user="openUserDetail"
      @show-favorite-form="onShowFavoriteForm"
      @close-favorite-form="onCloseFavoriteForm"
      @click-more="fetchNextUsers"
    />

    <div
      v-if="showUserDetail"
      class="SearchResultContainer__overlay"
      @click="closeUserDetail"
    />

    <UserDetail
      v-if="showUserDetail"
      :colored-periods="coloredPeriods"
      class="SearchResultContainer__userDetail"
      @keydown-esc="closeUserDetail"
    />

    <SaveSearchConditionModal
      v-if="isShowSaveSearchConditionModal"
      v-model="editingFavoriteSearchLabel"
      :can-save="canSaveFavoriteSearch"
      @close="closeSaveSearchConditionModal"
      @save="saveFavoriteSearchCondition"
    />

    <div v-if="showEmptyDescription" class="SearchResultContainer__noResult">
      <div class="SearchResultContainer__noResultText">{{
        $t("noSearchResult")
      }}</div>
      <NoResultImage class="SearchResultContainer__noResultImage" />
    </div>

    <TourSorryNoUser v-if="isNoTourUser" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-property-decorator";
import UserDetail from "@/views/UserDetail.vue";
import SearchResultHeader from "@/components/search/SearchResultHeader.vue";
import SearchResultContent from "@/views/SearchResultContent.vue";
import SaveSearchConditionModal from "@/components/search/SaveSearchConditionModal.vue";
import TourSorryNoUser from "@/components/tours/TourSorryNoUser.vue";

import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { User } from "@/models/User";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";

import { handleError, handleNoQueryCacheError } from "@/util/error-util";
import { msecToUsec, Usec, getConditionMaxDate } from "@/util/date-util";
import { Icons } from "@/const/Icons";
import { stopWindowScroll, startWindowScroll } from "@/util/window-util";

import { SearchResultViews } from "@/const/SearchResultViews";
import { UserInfo } from "@/models/UserInfo";
import NoResultImage from "@/assets/svg/no-result.svg";
import { AlmostCvUserList } from "@/models/almost-cv/AlmostCvUserList";
import * as Sentry from "@sentry/browser";

@Component({
  components: {
    UserDetail,
    NoResultImage,
    SearchResultHeader,
    SearchResultContent,
    SaveSearchConditionModal,
    TourSorryNoUser
  }
})
export default class SearchResultContainer extends Vue {
  editingFavoriteSearchLabel: string = "";
  conditionQueryParameter: string = "";
  showingFavoriteForm: boolean = false;
  isShowSaveSearchConditionModal: boolean = false;

  @Prop({ type: String, required: true })
  icon!: Icons;

  @Model("view-change", { type: String, required: true })
  view!: SearchResultViews;

  @Prop({ type: String })
  label?: string;

  @Prop({ type: Boolean, default: false })
  canSaveCondition!: boolean;

  @Prop({ type: Boolean, default: false })
  isShowEmptyDescription!: boolean;

  @Prop({ type: Date, default: null })
  preferentiallyDisplayPositionDate!: Date;

  @Prop({ type: Array, default: () => [] })
  coloredPeriods!: ColoredPeriod[];

  @Prop({ type: Object, default: null })
  almostCvUserList!: AlmostCvUserList;

  onViewChange(view: SearchResultViews) {
    this.$emit("view-change", view);
  }

  get canSaveFavoriteSearch(): boolean {
    return !!this.$store.state.search.historyId;
  }

  onShowFavoriteForm() {
    this.showingFavoriteForm = true;
  }
  onCloseFavoriteForm() {
    this.showingFavoriteForm = false;
  }
  onArrowRight() {
    // 観察画面が開いているときは反応させない
    if (this.isFetched && !this.showUserDetail && !this.showingFavoriteForm) {
      switch (this.view) {
        case SearchResultViews.Attribute:
          this.onViewChange(SearchResultViews.Overview);
          break;
        case SearchResultViews.Overview:
          this.onViewChange(SearchResultViews.Memo);
          break;
        default:
          this.onViewChange(SearchResultViews.Attribute);
      }
    }
  }

  onArrowLeft() {
    // 観察画面が開いているときは反応させない
    if (this.isFetched && !this.showUserDetail && !this.showingFavoriteForm) {
      switch (this.view) {
        case SearchResultViews.Attribute:
          this.onViewChange(SearchResultViews.Memo);
          break;
        case SearchResultViews.Memo:
          this.onViewChange(SearchResultViews.Overview);
          break;
        default:
          this.onViewChange(SearchResultViews.Attribute);
      }
    }
  }

  get favoriteSearchLabel(): string {
    return this.$store.state.search.favoriteSearchLabel;
  }

  get showUserDetail(): boolean {
    return this.$store.state.user.showUserDetail;
  }

  get isFilterMode(): boolean {
    return this.$store.state.filter.isFilterMode;
  }

  get isClusteringMode(): boolean {
    return this.$store.state.clustering.isClusteringMode;
  }

  get userCount(): number {
    if (this.isClusteringMode) {
      return this.$store.state.clustering.userCount;
    }

    if (this.isFilterMode) {
      return this.$store.state.filter.userCount;
    }

    return this.$store.state.search.userCount;
  }

  get isFetched(): boolean {
    if (this.isClusteringMode) {
      return this.$store.state.clustering.isFetched;
    }

    if (this.isFilterMode) {
      return this.$store.state.filter.isFetched;
    }

    return this.$store.state.search.isFetched;
  }

  get isCountFetched(): boolean {
    return this.isFilterMode
      ? this.$store.state.filter.isCountFetched
      : this.$store.state.search.isCountFetched;
  }

  get users(): User[] {
    if (this.isClusteringMode) {
      return this.$store.state.clustering.users;
    }

    if (this.isFilterMode) {
      return this.$store.state.filter.users;
    }

    return this.$store.state.search.users;
  }

  get selectCondition(): SelectCondition | null {
    return this.$store.state.search.selectCondition;
  }

  get showEmptyDescription(): boolean {
    return (
      this.isShowEmptyDescription &&
      this.isFetched &&
      this.isCountFetched &&
      this.users.length === 0
    );
  }

  get isTourDetail(): boolean {
    return this.$route.name === "tour-detail";
  }

  get isNoTourUser(): boolean {
    return (
      this.isFetched &&
      this.isCountFetched &&
      this.users.length === 0 &&
      this.isTourDetail
    );
  }

  get showMoreButton(): boolean {
    return (
      this.isCountFetched &&
      this.users.length < this.userCount &&
      !this.isTourDetail
    );
  }

  fetchNextUsers() {
    if (this.isClusteringMode) {
      this.$store
        .dispatch("clustering/fetchNextClusteringResult")
        .catch(error => {
          handleNoQueryCacheError(error);
          throw new Error(error);
        });
    } else if (this.isFilterMode) {
      this.$store.dispatch("filter/fetchNextFilterResult").catch(error => {
        handleNoQueryCacheError(error);
        throw new Error(error);
      });
    } else {
      this.$store.dispatch("search/fetchNextSelectResult").catch(error => {
        handleNoQueryCacheError(error);
        throw new Error(error);
      });
    }
  }

  mounted() {
    if (this.$route.name !== "funnel-analysis-detail") {
      (this.$refs.SearchResultContainer as HTMLElement).focus();
    }
  }
  destroyed() {
    this.$store.commit("filter/setFilterHistoryId", null);
    this.$store.commit("search/setHistoryId", null);
  }

  openUserDetail(user: User) {
    stopWindowScroll();
    this.saveUrlStateForUserToComeBack(user);
    this.openUserDetailBalloon(user);
    this.saveUserToBrowseHistory(user);
  }

  getUserDetailMaxDate(): Date {
    if (this.preferentiallyDisplayPositionDate !== null) {
      return this.preferentiallyDisplayPositionDate;
    }

    return getConditionMaxDate(this.selectCondition);
  }

  saveUrlStateForUserToComeBack(user: User) {
    // ユーザ詳細を閉じたときのURLとタイトルを保存しておく
    this.$store.commit(
      "user/setPathBeforeShowUserDetail",
      this.$route.fullPath
    );
    this.$store.commit("user/setTitleBeforeShowUserDetail", document.title);

    // this.$router.push で移動するとtableがAttributeになってしまうので、URLのみだけ書き換える
    history.pushState(null, "", "/users/" + user.id);
  }

  openUserDetailBalloon(user: User) {
    this.$store.dispatch("user/showUserDetail", {
      user: user,
      startBaseTimeUsec: this.getStartBaseTimeUsec(user.id)
    });
  }

  getStartBaseTimeUsec(userId: string): Usec {
    if (this.almostCvUserList !== null) {
      const firstGramIdUsec = this.almostCvUserList.getUsecOfFirstGramIdByUserId(
        userId
      );

      if (firstGramIdUsec > 0) {
        return firstGramIdUsec;
      }
    }
    return msecToUsec(this.getUserDetailMaxDate().getTime());
  }

  saveUserToBrowseHistory(user: User) {
    this.$store.commit("history/addUserHistory", user);
    this.$store.commit("userInfo/addUserInfo", UserInfo.fromUser(user));
  }

  async saveFavoriteSearchCondition() {
    try {
      this.isShowSaveSearchConditionModal = false;
      await this.$store.dispatch(
        "searchHistory/createFavoriteSearch",
        this.editingFavoriteSearchLabel
      );

      this.$store.commit(
        "search/setFavoriteSearchLabel",
        this.editingFavoriteSearchLabel
      );

      scrollTo(0, 0);
      await this.$store.dispatch("searchHistory/fetchSearchHistories");
      await this.$store.dispatch("searchHistory/fetchFavoriteSearches");
      this.$store.dispatch("searchHistory/updateUserInfo");
      this.editingFavoriteSearchLabel = "";
    } catch (e) {
      handleError(e);
      Sentry.captureException(e);
    }
  }

  closeUserDetail() {
    // When open user detail directly pathBeforeShowUserDetail and titleBeforeShowUserDetail are empty.
    if (this.$store.state.user.pathBeforeShowUserDetail) {
      this.$router.push(this.$store.state.user.pathBeforeShowUserDetail);
      document.title = this.$store.state.user.titleBeforeShowUserDetail;
    } else {
      const hid = this.$route.query.hid;
      const hidParam = hid ? "?hid=" + hid : "";
      this.$router.push("/users" + hidParam);
    }

    this.$store.commit("user/setShowUserDetail", false);
    startWindowScroll();
  }

  showSaveSearchConditionModal() {
    this.isShowSaveSearchConditionModal = true;
  }

  closeSaveSearchConditionModal() {
    this.isShowSaveSearchConditionModal = false;
  }
}
</script>

<style scoped lang="scss">
.SearchResultContainer {
  padding: 0 20px 20px;
}

.SearchResultContainer__userDetail {
  position: fixed;
  top: 0;
  left: 100px;
  width: calc(100% - 200px);
  height: 100vh;
}
.SearchResultContainer__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
}

.SearchResultContainer__noResult {
  padding: 60px;
  text-align: center;
}

.SearchResultContainer__noResultText {
  margin-bottom: 42px;
  color: $colorBase900;
  font-weight: bold;
  font-size: 16px;
}

.SearchResultContainer__noResultImage {
  width: 192px;
  height: 86px;
}

@media print {
  .SearchResultContainer {
    padding: 0;
  }

  .SearchResultContainer--hideInPrintMode {
    display: none;
  }

  .SearchResultContainer__userDetail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  .SearchResultContainer__overlay {
    display: none;
  }
}
</style>
