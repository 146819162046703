<i18n src="@/i18n/components/funnel/funnel-user-attribute-condition.json" />
<template>
  <FunnelAddableConditionWrapper
    class="funnelUserAttributeCondition"
    :class="{
      'funnelUserAttributeCondition--hasLabel': addedConditions.length
    }"
    :style="{ marginTop: topMargin }"
    :button-text="$t('addUserAttribute').toString()"
    :button-note="$t('addUserAttributeNote').toString()"
    :can-add="canAddUserAttribute"
    :added-conditions="addedConditions"
    @add="addUserAttribute"
    @edit="editUserAttribute"
    @delete="deleteUserAttribute"
  >
    <FunnelUserAttributeDialog
      :edit-condition="editingUserAttribute"
      :user-attribute-definitions="availableUserAttributes"
      :visible="showUserAttributeDialog"
      @submit="onSubmitUserAttribute"
      @cancel="onCancelUserAttribute"
    />
  </FunnelAddableConditionWrapper>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "@/composables/useI18n";
import FunnelAddableConditionWrapper, {
  AddedCondition
} from "@/components/funnel/FunnelAddableConditionWrapper.vue";
import FunnelUserAttributeDialog from "@/components/funnel/FunnelUserAttributeDialog.vue";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { FunnelConditionAttributeType } from "@/models/funnel/FunnelConditionAttribute";

const { t } = useI18n();

const props = defineProps<{
  userAttributes: FunnelConditionAttributeType[];
  userAttributeDefinitions: UserAttributeDefinition[];
  topMargin: string;
}>();

const emit = defineEmits<{
  (e: "change", userAttributes: FunnelConditionAttributeType[]): void;
}>();

const showUserAttributeDialog = ref(false);
const editingUserAttributeIndex = ref(0);

const editingUserAttribute = computed(
  (): FunnelConditionAttributeType | null => {
    return props.userAttributes[editingUserAttributeIndex.value] || null;
  }
);

const canAddUserAttribute = computed(() => {
  const userAttributeLimit = Math.min(props.userAttributeDefinitions.length, 5);
  return props.userAttributes.length < userAttributeLimit;
});

const availableUserAttributes = computed(() => {
  const usedUserAttributeIds = props.userAttributes
    .map(ua => ua.attributeId)
    .filter(id => id !== editingUserAttribute?.value?.attributeId);

  return props.userAttributeDefinitions.filter(
    def => !usedUserAttributeIds.includes(def.id)
  );
});

const addedConditions = computed(() => {
  return props.userAttributes.map<AddedCondition>(ua => ({
    label: getUserAttributeLabel(ua.attributeId),
    displayValue: ua.displayValue
  }));
});

function getUserAttributeLabel(id: number) {
  const attributeType = t("userAttribute").toString();
  const userAttribute = props.userAttributeDefinitions.find(ua => ua.id === id);
  if (userAttribute) {
    return `${attributeType}: ${userAttribute.name}`;
  }
  return attributeType;
}

function addUserAttribute() {
  editingUserAttributeIndex.value = props.userAttributes.length;
  showUserAttributeDialog.value = true;
}

function editUserAttribute(index: number) {
  editingUserAttributeIndex.value = index;
  showUserAttributeDialog.value = true;
}

function deleteUserAttribute(index: number) {
  const newAttributes = props.userAttributes.filter((_, i) => i !== index);
  emit("change", newAttributes);
}

function onSubmitUserAttribute(attribute: FunnelConditionAttributeType) {
  showUserAttributeDialog.value = false;
  const newAttributes = [...props.userAttributes];

  if (props.userAttributes.length === editingUserAttributeIndex.value) {
    newAttributes.push(attribute);
  } else {
    newAttributes.splice(editingUserAttributeIndex.value, 1, attribute);
  }

  emit("change", newAttributes);
}

function onCancelUserAttribute() {
  showUserAttributeDialog.value = false;
}
</script>
