import { render, staticRenderFns } from "./CoordinationConversionEditTable.vue?vue&type=template&id=53d8a021&scoped=true"
import script from "./CoordinationConversionEditTable.vue?vue&type=script&lang=ts"
export * from "./CoordinationConversionEditTable.vue?vue&type=script&lang=ts"
import style0 from "./CoordinationConversionEditTable.vue?vue&type=style&index=0&id=53d8a021&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53d8a021",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/client-settings/coordination/conversion/coordination-conversion-edit-table.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fclient-settings%2Fcoordination%2Fconversion%2FCoordinationConversionEditTable.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports