<i18n
  src="@/i18n/components/funnel/funnel-inflow-organic-condition.json"
></i18n>
<template>
  <div class="funnelInflowOrganicCondition">
    <div
      v-for="(cond, index) in inflowConditions"
      :key="index"
      class="funnelInflowOrganicCondition__Container"
    >
      <SelectBox
        :value="cond.searchField"
        width="180px"
        class="funnelInflowOrganicCondition__selectBox dragNotApplicable"
        :options="searchFieldTypeOptions"
        @input="v => onUpdateInflowSearchField(index, v)"
      />
      <SelectBox
        v-if="cond.searchField === InflowOrganicSearchField.SEARCH_ENGINE"
        :value="cond.searchEngineId"
        :options="searchEngineOptions"
        width="240px"
        class="funnelInflowOrganicCondition__searchEngine dragNotApplicable"
        @input="v => onUpdateSearchEngine(index, v)"
      />
      <InputTextWithSuggestion
        v-else
        :value="cond.value"
        class="funnelInflowOrganicCondition__input dragNotApplicable"
        :width="'320px'"
        :input-form-suggestion-type="getSuggestionType(cond.searchField)"
        @input="v => onUpdateInflowValue(index, v)"
      />
      <SelectBox
        v-if="cond.searchField !== InflowOrganicSearchField.SEARCH_ENGINE"
        :value="cond.wordMatchMethod"
        :options="matchMethodOptions"
        width="140px"
        class="funnelInflowOrganicCondition__selectBox dragNotApplicable"
        @input="v => onUpdateInflowWordMatchMethod(index, v)"
      />
      <IconButton
        v-show="inflowConditions.length > 0"
        class="funnelInflowOrganicCondition__deleteButton dragNotApplicable"
        :icon="iconDelete"
        :size="18"
        @click="onClickDelete(index)"
      />
    </div>
    <div
      v-if="inflowConditions.length < 3"
      class="funnelInflowOrganicCondition__addButtonClickableArea dragNotApplicable"
      @click="onClickAdd"
    >
      <IconButton :icon="iconAdd" :size="18" :button-type="iconButtonType" />
      <div
        v-t="'addCondition'"
        class="funnelInflowOrganicCondition__addButtonText"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import { useI18n } from "@/composables/useI18n";
import { InflowOrganicSearchField } from "@/const/funnel";
import { MatchMethod } from "@/models/search/MatchMethod";
import {
  InflowOrganicMatchCondition,
  InflowOrganicLocationCondition,
  InflowOrganicSearchEngineCondition
} from "@/models/funnel/FunnelCondition";
import SelectBox from "@/components/form/SelectBox.vue";
import SelectOption from "@/components/form/SelectOption";
import InputTextWithSuggestion from "@/components/input-form-suggestion/InputTextWithSuggestion.vue";
import { InputFormSuggestionType } from "@/const/input-form-suggestion";
import IconButton from "@/components/IconButton.vue";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import { SearchEngine } from "@/models/system/SearchEngine";

const iconAdd = Icons.PlusCircle;
const iconDelete = Icons.ButtonClose;
const iconButtonType = IconButtonType.Weak;

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: Array as PropType<InflowOrganicMatchCondition[]>,
    default: () => []
  },
  searchEngines: {
    type: Array as PropType<SearchEngine[]>,
    required: true
  }
});

const emits = defineEmits<{
  (
    e: "update:modelValue",
    inflowConditions: InflowOrganicMatchCondition[]
  ): void;
}>();

const inflowConditions = computed(() => props.modelValue);

const matchMethodOptions: SelectOption<MatchMethod>[] = [
  {
    value: MatchMethod.Partial,
    label: t("partialMatch").toString(),
    disabled: false
  },
  {
    value: MatchMethod.Exact,
    label: t("perfectMatch").toString(),
    disabled: false
  },
  {
    value: MatchMethod.Wildcard,
    label: t("wildcardMatch").toString(),
    disabled: false
  }
];

const searchFieldTypeOptions = computed(() => {
  const selectedTypes = [...selectedFieldTypes.value];
  return [
    {
      value: InflowOrganicSearchField.SEARCH_ENGINE,
      label: t("searchEngine").toString(),
      disabled: selectedTypes.includes(InflowOrganicSearchField.SEARCH_ENGINE)
    },
    {
      value: InflowOrganicSearchField.LOCATION_URI,
      label: t("locationUri").toString(),
      disabled: selectedTypes.includes(InflowOrganicSearchField.LOCATION_URI)
    },
    {
      value: InflowOrganicSearchField.LOCATION_NAME,
      label: t("locationName").toString(),
      disabled: selectedTypes.includes(InflowOrganicSearchField.LOCATION_NAME)
    }
  ] as SelectOption<InflowOrganicSearchField>[];
});

const searchEngineOptions = computed(() => {
  return props.searchEngines.map(engine => {
    return {
      value: engine.id,
      label: engine.name,
      disabled: false
    };
  });
});

const selectedFieldTypes = computed(() => {
  return inflowConditions.value.map(cond => cond.searchField);
});

function getSuggestionType(
  fieldType: InflowOrganicSearchField
): InputFormSuggestionType {
  return fieldType === InflowOrganicSearchField.LOCATION_NAME
    ? InputFormSuggestionType.WEB_TITLE
    : InputFormSuggestionType.WEB_URL;
}

function onUpdateInflowSearchField(index: number, newValue: number) {
  const oldValues = inflowConditions.value[index];
  let newValues = {};

  if (newValue !== InflowOrganicSearchField.SEARCH_ENGINE) {
    newValues = {
      searchField: newValue,
      value: (oldValues as InflowOrganicLocationCondition).value ?? "",
      wordMatchMethod:
        (oldValues as InflowOrganicLocationCondition).wordMatchMethod ??
        MatchMethod.Partial
    };
  } else {
    newValues = {
      searchField: newValue,
      searchEngineId: 1
    };
  }
  onUpdate(index, newValues as InflowOrganicMatchCondition);
}

function onUpdateInflowValue(index: number, newValue: string) {
  const oldValues = inflowConditions.value[
    index
  ] as InflowOrganicLocationCondition;

  const newValues = {
    ...oldValues,
    value: newValue
  };
  onUpdate(index, newValues);
}

function onUpdateInflowWordMatchMethod(index: number, newValue: number) {
  const oldValues = inflowConditions.value[
    index
  ] as InflowOrganicLocationCondition;

  const newValues = {
    ...oldValues,
    wordMatchMethod: newValue
  };
  onUpdate(index, newValues);
}

function onUpdateSearchEngine(index: number, newValue: number) {
  const oldValues = inflowConditions.value[
    index
  ] as InflowOrganicSearchEngineCondition;

  const newValues = {
    ...oldValues,
    searchEngineId: newValue
  };
  onUpdate(index, newValues);
}

function onUpdate(index: number, newValue: InflowOrganicMatchCondition) {
  const newConditions = [...inflowConditions.value];
  newConditions.splice(index, 1, newValue);
  emits("update:modelValue", newConditions);
}

function onClickDelete(index: number) {
  const newConditions = [...inflowConditions.value];
  newConditions.splice(index, 1);
  emits("update:modelValue", newConditions);
}

function onClickAdd() {
  const availableFieldTypes = searchFieldTypeOptions.value.filter(
    option => !selectedFieldTypes.value.includes(option.value)
  );
  const availableField = availableFieldTypes[0].value;

  const newConditions = [...inflowConditions.value];
  if (availableField !== InflowOrganicSearchField.SEARCH_ENGINE) {
    newConditions.push({
      value: "",
      wordMatchMethod: MatchMethod.Partial,
      searchField: availableField
    });
  } else {
    newConditions.push({
      searchField: availableField,
      searchEngineId: 1
    });
  }
  emits("update:modelValue", newConditions);
}
</script>
<style scoped lang="scss">
.funnelInflowOrganicCondition {
  margin-top: 15px;
}
.funnelInflowOrganicCondition__Container {
  width: 800px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .funnelInflowOrganicCondition__input {
    width: 320px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .funnelInflowOrganicCondition__deleteButton {
    margin-left: 8px;
  }
  .funnelInflowOrganicCondition__searchEngine {
    width: 240px;
    margin-left: 15px;
  }
}

.funnelInflowOrganicCondition__addButtonClickableArea {
  align-items: center;
  display: inline-flex;
  margin-top: 5px;
}
.funnelInflowOrganicCondition__addButtonText {
  color: $colorBase900;
  font-size: 14px;
  margin-left: 7px;
  padding-top: 1px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
