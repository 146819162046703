import { AnalysisType } from "@/const/user-trend";
import { LagCvPeriodType } from "@/models/lag-cv/LagCvUserCount";

export namespace ApiUrl {
  export const GET_TOKEN = "/api/auth/get_token/";
  export const LOGIN = "/api/auth/login/";
  export const LOGOUT = "/api/auth/logout/";
  export const GET_UG_USER = "/api/auth/get_user/";
  export const SET_LANG = "/api/auth/set_language/";

  export const GET_CONFIG = "/api/config/";

  export const CLIENT_UG_USER_LIST = "/api/clients/self/users/list/";
  export const GET_CLIENT = "/api/clients/self/detail/";
  export const CV_LIST = "/api/clients/self/conversions/list/";
  export const CV_ATTR_LIST = "/api/clients/self/conversion_attributes/list/";
  export const EVENT_LIST = "/api/clients/self/events/list/";
  export const NPS_DEF_LIST = "/api/clients/self/nps-defs/list/";
  export const ENQT_DEF_LIST = "/api/clients/self/enqt-defs/list/";
  export const BIZ_INDEX_DEF_LIST = "/api/clients/self/biz-index-defs/list/";
  export const BE_DEF_LIST = "/api/clients/self/be-defs/list/";
  export const USER_ATTR_DEF_LIST = "/api/clients/self/user-attr-defs/list/";
  export const CONTACT_DEF_LIST = "/api/clients/self/contact-defs/list/";
  export const SITE_URL_LIST = "/api/clients/self/site_urls/list/";
  export const COORDINATION_LIST = "/api/clients/self/coordinations/list/";

  export const INFOMATION_LIST = "/api/system/messages/list/";
  export const GLOBAL_CV_LIST = "/api/system/global_conversions/list/";
  export const GLOBAL_CV_ATTR_LIST =
    "/api/system/global_conversion_attributes/list/";
  export const SEARCH_ENGINE_LIST = "/api/system/search_engines/list/";

  export const PROFILE_ORDER_LIST = "/api/userdata/profile_order/load/";
  export const FAVORITE_LIST = "/api/userdata/favorite/list/";
  export const SAVE_FAVORITE = "/api/userdata/favorite/save/";
  export const DELETE_FAVORITES = "/api/userdata/favorite/remove/";
  export const FAVORITE_SEARCH = "/api/userdata/favorite-search/";
  export const FAVORITE_SEARCH_LIST = FAVORITE_SEARCH + "list/";
  export const FAVORITE_SEARCH_SAVE = FAVORITE_SEARCH + "save/";

  export const FAVORITE_SEARCH_REMOVE = (id: number) =>
    FAVORITE_SEARCH + String(id) + "/remove/";

  export const CLIENT_PV_STATS = "/api/emoticon/client-pv/";

  export const VISITS = "/api/visits/";

  export const USER_INFO_LIST = "/api/userdata/user_info/list/";
  export const SELECT_USER = "/api/select-user/";
  export const NUM_FOUND_USERS = "/api/num-found-users/";
  export const USER = "/api/user/";
  export const CLUSTER_LIST = "/api/cluster/list/";
  export const CLUSTER_USER = "/api/cluster/user/";
  export const FILTER_USER = "/api/filter/";
  export const USER_LIST_CSV_URL = "/api/user-list/csv/";
  export const SAVE_MEMO = "/api/memo/";
  export const MEMO_LIST = "/api/userdata/memo/list/";
  export const SERVER_CALL_HISTORY = "/api/userdata/history/selection/";
  export const SAVE_PROFILE_ORDER = "/api/userdata/profile_order/save/";
  export const OBSERVE_HISTORY_LIST = "/api/userdata/history/observation/";
  export const SERVER_CALL_FILTER_HISTORY = "/api/userdata/history/filter/";

  export const ENGAGEMENT_SEARCH =
    "/api/advanced-searches/engagement-searches/";
  export const ENGAGEMENT_SEARCH_STATUS = (id: number) =>
    ENGAGEMENT_SEARCH + String(id) + "/status/";
  export const ENGAGEMENT_SEARCH_RESULT = (id: number) =>
    ENGAGEMENT_SEARCH + String(id) + "/result/";

  export const WORDCLOUD_DATA = "/api/wordcloud/user/";

  export const SEGMENTED_TREND_WEB_VIEW = "/api/segmented-trends/web-views/";
  export const SEGMENTED_TREND_LANDING = "/api/segmented-trends/landings/";
  export const SEGMENTED_TREND_INFLOW = "/api/segmented-trends/inflows/";
  export const SEGMENTED_TREND_INFLOW_DETAIL =
    "/api/segmented-trends/inflow-details/";
  export const SEGMENTED_TREND_APP_VIEW = "/api/segmented-trends/app-views/";
  export const SEGMENTED_TREND_EVENT = "/api/segmented-trends/events/";
  export const SEGMENTED_TREND_CONVERSION =
    "/api/segmented-trends/conversions/";
  export const LAG_CV = "/api/lagcv/";
  export const LAG_CV_BY_PERIOD = (period: LagCvPeriodType) =>
    `${LAG_CV}${period}/`;

  export const LAG_CV_USER_COUNT = "/api/lagcv/user-count/";
  export const LAG_CV_USER_LIST = "/api/lagcv/user-list/";
  export const LAG_CV_USER_LIST_BY_PERIOD = (period: LagCvPeriodType) =>
    `${LAG_CV_USER_LIST}${period}/`;
  export const LAG_CV_USER_LIST_BY_CONVERSION_ID = (
    period: LagCvPeriodType,
    conversionId: number
  ) => `${LAG_CV_USER_LIST}${period}/${conversionId}/`;

  export const ALMOST_CV = "/api/almostcv/";
  export const ALMOST_CV_USER_LIST_BY_CV_ID = (conversionId: number) =>
    `${ALMOST_CV}${conversionId}/`;

  export const USER_TREND = "/api/user-trends/";
  export const USER_TREND_PAGE = (type: AnalysisType) =>
    `${USER_TREND}${type}/`;
  export const USER_TREND_QUERY_HISTORY = "/api/user-trends/history/";

  export const RESET_PASSWORD_REQUEST_TOKEN =
    "/api/reset-password/request_token/";
  export const RESET_PASSWORD_VALIDATE_TOKEN =
    "/api/reset-password/validate_token/";
  export const RESET_PASSWORD_UPDATE_PASSWORD =
    "/api/reset-password/update_password/";

  export const CV_TREND = "/api/cv-trends/";

  export const FUNNEL = "/api/funnels/";

  export const TOUR_SELECT_USER = "/api/tour/select-user/";
  export const TOUR_USER = "/api/tour/user/";

  // For client-setting page
  export const CONVERSION = "/api/client-settings/conversions/";
  export const APP_CONVERSION = "/api/client-settings/app-conversions/";
  export const MAP_CONVERSION_AND_CV_REGISTER = (coordinationId: number) =>
    `/api/client-settings/coordinations/${coordinationId}/conversions/`;
  export const MAP_CONVERSION_REGISTER = (
    coordinationId: number,
    conversionId: number
  ) =>
    `/api/client-settings/coordinations/${coordinationId}/conversions/${conversionId}/`;
  export const MAP_EVENT_AND_EVENT_REGISTER = (coordinationId: number) =>
    `/api/client-settings/coordinations/${coordinationId}/events/`;
  export const MAP_EVENT_REGISTER = (coordinationId: number, eventId: number) =>
    `/api/client-settings/coordinations/${coordinationId}/events/${eventId}/`;

  export const MAP_CV_ATTRIBUTE_WITH_CV_ATTRIBUTE_REGISTER = (
    coordinationId: number
  ) =>
    `/api/client-settings/coordinations/${coordinationId}/conversion-attributes/`;
  export const MAP_CV_ATTRIBUTE_REGISTER = (
    coordinationId: number,
    conversionAttributeId: number
  ) =>
    `/api/client-settings/coordinations/${coordinationId}/conversion-attributes/${conversionAttributeId}/`;

  export const TD_WEB_IMPORT_CONFIG_DETAIL =
    "/api/client-settings/treasure-data/td-web-import-config/";
  export const TD_CONVERSION_LIST =
    "/api/client-settings/treasure-data/td-conversion/";
  export const TD_CONVERSION_DETAIL = (id: number) =>
    `/api/client-settings/treasure-data/td-conversion/${id}/`;
  export const TD_CONVERSION_ATTRIBUTE_LIST =
    "/api/client-settings/treasure-data/td-conversion-attr/";
  export const TD_CONVERSION_ATTRIBUTE_DETAIL = (id: number) =>
    `/api/client-settings/treasure-data/td-conversion-attr/${id}/`;

  export const MFA_CONFIGURE = "/api/mfa/configure/";
  export const MFA_ENABLE = "/api/mfa/enable/";
  export const MFA_VERIFY = "/api/mfa/verify/";

  export const SUGGESTION_PREPARE = "/api/input-form/prepare-suggestions/";
  export const SUGGESTION_GET_RESULT = "/api/input-form/suggestions/";
}
