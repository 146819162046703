<i18n src="@/i18n/components/home-menu/lag-cv.json"></i18n>
<template>
  <div class="menuPanelLagCvHeader">
    <div>
      <Icon :icon="Icons.Users" :color="Colors.Base900" :size="18" />
      {{ $t("lagCv") }}
    </div>
    <Tooltip
      class="menuPanelLagCvHeader__help"
      :placement="TooltipPlacement.BottomEnd"
    >
      <Icon :icon="Icons.HelpInvertWhite" :color="Colors.Base700" :size="12" />
      <template #content>
        <div class="menuPanelLagCvHeader__helpContent">
          {{ $t("lagCvNote1") }}
          <br />
          {{ $t("lagCvNote2") }}
          {{ $t("lagCvNote3") }}
          <br /><br />
          {{ $t("lagCvNote4") }}
          <ul class="menuPanelLagCvHeader__helpContentSub">
            <li v-t="'lagCvNote4-1'" />
            <li v-t="'lagCvNote4-2'" />
          </ul>
        </div>
      </template>
    </Tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { TooltipPlacement } from "@/const/tooltip";

@Component({
  components: {
    Tooltip,
    Icon
  }
})
export default class MenuPanelLagCvHeader extends Vue {
  Icons = Icons;
  Colors = Colors;
  TooltipPlacement = TooltipPlacement;
}
</script>

<style lang="scss" scoped>
.menuPanelLagCvHeader {
  display: inline-flex;
}

.menuPanelLagCvHeader__betaText {
  color: $colorBase600;
  font-size: 12px;
  font-weight: 500;
}

.menuPanelLagCvHeader__help {
  margin-left: 6px;
}

.menuPanelLagCvHeader__helpContent {
  width: 370px;
  white-space: normal;
  line-height: 1.6;
  font-weight: 500;
}

.menuPanelLagCvHeader__helpContentSub {
  padding-left: 20px;
  list-style: disc;
}
</style>
