import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";

/**
 * キャンペーン名による条件
 *
 * 広告での絞り込みに、キャンペーン名を条件として付与できる
 *
 */
export class FilterAdCampaignCondition {
  public readonly conditionType = FilterNodeConditionType.AdCampaign;

  constructor(public readonly campaignName: string) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.campaignName,
      i18n.t("models.search.campaignName") as string
    );
  }
}
