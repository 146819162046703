import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";

/**
 * 広告枠名による条件
 *
 * 広告での絞り込みに、広告枠名を条件として付与できる
 *
 */
export class FilterAdInventoryCondition {
  public readonly conditionType = FilterNodeConditionType.AdInverntory;

  constructor(public readonly inventoryName: string) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.inventoryName,
      i18n.t("models.search.inventoryName") as string
    );
  }
}
