<template>
  <div>
    <div
      :class="{
        'side-bar-item-expandable': true,
        'side-bar-item-expandable--disabled': isDisabled
      }"
      data-cy="expandable-item"
      @click="emits('toggle-subitems')"
    >
      <V3SideBarItem
        :show-minimized="showMinimized"
        :icon="icon"
        :text="text"
        :active="isActive"
        is-expandable
        :disabled="isDisabled"
      />
      <div
        v-show="!showMinimized"
        :class="{
          'side-bar-item-expandable__arrow': true,
          'side-bar-item-expandable__arrow--disabled': isDisabled,
          'side-bar-item-expandable__arrow--active': isActive
        }"
      >
        <V3Icon
          v-if="isExpanded"
          class="side-bar-item-expandable__arrow-up-icon"
          icon="side-bar-arrow-up"
          width="10"
          height="6.59"
        />
        <V3Icon
          v-else
          class="side-bar-item-expandable__arrow-down-icon"
          icon="side-bar-arrow-down"
          width="10"
          height="6.59"
        />
      </div>
    </div>
    <V3SlideDownTransition>
      <div v-if="isExpanded" data-cy="side-bar-sub-items">
        <div v-for="(item, index) in subitems" :key="index">
          <V3SideBarItem
            v-if="item.show"
            small
            :show-minimized="showMinimized"
            :icon="item.icon"
            :text="item.text"
            :data-cy="item.dataCy"
            :active="isItemActive(route, item)"
            @click.native="() => emits('click-subitem', item)"
            @mouseenter.native="
              event =>
                item.favoriteSearch &&
                showSearchBalloon(event, item.favoriteSearch)
            "
            @mouseleave.native="item.favoriteSearch && hideSearchBalloon()"
          />
        </div>
      </div>
    </V3SlideDownTransition>
  </div>
</template>

<script setup lang="ts">
import V3Icon from "@/components/v3-icons/V3Icon.vue";
import { computed } from "vue";
import V3SlideDownTransition from "@/components/v3-transitions/V3SlideDownTransition.vue";
import { Item } from "@/types/components/V3SideBar.type";
import V3SideBarItem from "@/components/v3-side-bar/V3SideBarItem.vue";
import { useRoute } from "vue-router/composables";
import { isItemActive } from "@/components/v3-side-bar/v3-side-bar-util";
import { useSearchBalloon } from "@/v3-composables/use-search-balloon";

const route = useRoute();
const { showSearchBalloon, hideSearchBalloon } = useSearchBalloon();

const props = defineProps<{
  icon: string;
  text: string;
  subitems: Item[];
  isExpanded: boolean;
  showMinimized?: boolean;
}>();

const emits = defineEmits<{
  (event: "click-subitem", subitem: Item): void;
  (event: "toggle-subitems", storageKey: string | undefined): void;
}>();

const isDisabled = computed(() => props.subitems.length === 0);

const isActive = computed(() => {
  return props.subitems.some(item => isItemActive(route, item));
});
</script>

<style lang="scss" scoped>
.side-bar-item-expandable {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:focus-visible {
    background-color: $colorBlue600;
  }

  &--disabled {
    pointer-events: none;
  }
}
.side-bar-item-expandable__arrow {
  display: flex;
  margin-right: 17px;
  align-items: center;

  :deep(path) {
    fill: $colorBase700;
  }

  &--active:deep(path) {
    fill: $colorBase900;
  }

  &--disabled:deep(path) {
    fill: $colorBase500;
  }
}
</style>
