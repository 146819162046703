import { GramOverviews } from "@/models/overview/Overviews";
import { UserPvStats } from "@/models/UserPvStats";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { SelectByConversionCondition } from "@/models/search/select-condition/SelectByConversionCondition";
import { SelectByBusinessEventCondition } from "@/models/search/select-condition/SelectByBusinessEventCondition";
import { SelectByContactCondition } from "@/models/search/select-condition/SelectByContactCondition";
import { SelectByAppCondition } from "@/models/search/select-condition/SelectByAppCondition";

/**
 * ユーザアイコンを表示する際に必要な情報をoverviewから集計する
 * @param overviews
 * @param condition
 */
export function aggregatePvStats(
  overviews: GramOverviews,
  condition: SelectCondition
): UserPvStats {
  const endMsec = endDate(overviews, condition).getTime();
  // 末日より30日前
  const startMsec = endMsec - 60 * 60 * 24 * 30 * 1000;

  let userPvStats = new UserPvStats(0, 0, 0, 0);

  for (const ov of overviews.visit) {
    const visitTime = ov.date.getTime();
    if (visitTime >= startMsec && visitTime <= endMsec) {
      userPvStats = userPvStats.merge(ov.pvStats);
    }
  }

  return userPvStats;
}

function endDate(overviews: GramOverviews, condition: SelectCondition): Date {
  const timeToCreateEnd = 60 * 60 * 24 * 1000;

  if (
    condition instanceof SelectByConversionCondition ||
    condition instanceof SelectByAppCondition
  ) {
    // 範囲内で一番最新のcvの日付け
    const start =
      condition.startDate !== null ? condition.startDate.getTime() : 0;
    const end =
      condition.endDate !== null
        ? condition.endDate.getTime() + timeToCreateEnd
        : 0;
    for (const visit of overviews.visit.slice().reverse()) {
      const visitTime = visit.date.getTime();
      if (visitTime >= start && visitTime < end && visit.hasConversion) {
        return new Date(visitTime);
      }
    }
  }
  if (condition instanceof SelectByBusinessEventCondition) {
    // 範囲内で一番最新のビジネスイベントの日付け
    const start =
      condition.startDate !== null ? condition.startDate.getTime() : 0;
    const end =
      condition.endDate !== null
        ? condition.endDate.getTime() + timeToCreateEnd
        : 0;
    for (const be of overviews.business.slice().reverse()) {
      if (be.date.getTime() >= start && be.date.getTime() < end) {
        return new Date(be.date.getTime());
      }
    }
  }
  if (condition instanceof SelectByContactCondition) {
    // 範囲内で一番最新の行動履歴の日付け
    const start =
      condition.startDate !== null ? condition.startDate.getTime() : 0;
    const end =
      condition.endDate !== null
        ? condition.endDate.getTime() + timeToCreateEnd
        : 0;
    for (const contact of overviews.contact.slice().reverse()) {
      if (contact.date.getTime() >= start && contact.date.getTime() < end) {
        return new Date(contact.date.getTime());
      }
    }
  }
  return new Date();
}
