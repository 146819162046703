<template>
  <Tooltip
    class="chartColoredPerioodTooltip"
    :value="show"
    :placement="tooltipPlacement"
    :manual="true"
    :style="{
      position: 'absolute',
      left: x + 'px',
      top: y + 'px'
    }"
  >
    <!-- insert div to make tooltip work normally -->
    <div class="chartColoredPerioodTooltip__empty" />

    <template #content>
      <div
        class="chartColoredPerioodTooltip__colorIndicator"
        :style="{
          'background-color': colorIndicator
        }"
      />
      <span class="chartColoredPerioodTooltip__label" v-text="label" />
      <span
        class="chartColoredPerioodTooltip__period"
        data-cy="gram-chart-tooltip-period"
        v-text="period"
      />
    </template>
  </Tooltip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Colors } from "@/const/Colors";
import { Tooltip } from "element-ui";

@Component({
  components: {
    Tooltip
  }
})
export default class ChartColoredPeriodTooltip extends Vue {
  @Prop({ type: Boolean, required: true })
  show!: boolean;

  @Prop({ type: Number, required: true })
  x!: boolean;

  @Prop({ type: Number, required: true })
  y!: boolean;

  @Prop({ type: String, required: true })
  colorIndicator!: Colors;

  @Prop({ type: String, required: true })
  label!: string;

  @Prop({ type: String, required: true })
  period!: string;

  @Prop({ type: Boolean, default: true })
  isUserDetail!: boolean;

  get tooltipPlacement(): string {
    return this.isUserDetail ? "bottom" : "top";
  }
}
</script>

<style scoped lang="scss">
.chartColoredPerioodTooltip__colorIndicator {
  display: inline-block;
  width: 10px;
  height: 10px;
}

.chartColoredPerioodTooltip__period {
  margin-left: 10px;
}
</style>
