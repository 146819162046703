<i18n src="@/i18n/views/almost-cv-user.json"></i18n>
<template>
  <div class="almostCvUser">
    <SearchResultContainer
      :view="searchResultView"
      :label="$t('almost_cv')"
      :icon="icon"
      :preferentially-display-position-date="displayPositionDate"
      :colored-periods="coloredPeriods"
      :almost-cv-user-list="almostCvUserList"
      :is-show-empty-description="isShowEmptyDescription"
      @view-change="onViewChange"
    >
      <template #conditions>
        <div class="almostCvUser__conversion">{{ conversionName }}</div>
      </template>
    </SearchResultContainer>
    <div
      v-if="!isLoading && !hasUserId"
      v-t="'noData'"
      class="almostCvUser__emptyDescription"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";
import { handleError, handleNoQueryCacheError } from "@/util/error-util";
import SearchResultContainer from "@/views/SearchResultContainer.vue";
import { Icons } from "@/const/Icons";
import { SearchResultViews } from "@/const/SearchResultViews";
import { ChartPeriod } from "@/const/chart-period";
import { getDeepCopiedMidnightDateRange } from "@/util/date-util";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { Colors } from "@/const/Colors";
import { AlmostCvPeriod } from "@/models/almost-cv/AlmostCvPeriod";
import { DateRange } from "@/components/date-picker/DateRange";
import { AlmostCvConversion } from "@/models/almost-cv/AlmostCvConversion";
import { AlmostCvUserList } from "@/models/almost-cv/AlmostCvUserList";
import {
  executeSearchByHistoryId,
  createQueryWithViewAndHid
} from "@/util/user-search-util";

@Component({
  components: {
    SearchResultContainer
  }
})
export default class AlmostCvUser extends Vue {
  icon = Icons.Conversion;
  searchResultView = SearchResultViews.Attribute;
  isLoading = true;

  created() {
    this.initSearchResultView();
    this.fetchAlmostCvInfoAndUsers();
  }

  @Watch("currentRoute")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeRoute(newRoute: string) {
    this.fetchAlmostCvInfoAndUsers();
  }

  get currentRoute(): string {
    return this.$route.path;
  }

  get conversionName(): string {
    const cvId = Number(this.$route.params.cvId);
    const conversions: AlmostCvConversion[] = this.$store.state.almostCv
      .almostCvInfo.conversions;

    const conversion = conversions.find(cv => cv.conversionId === cvId);

    return conversion?.conversionName || "";
  }

  get userIds(): string[] {
    return this.$store.state.almostCv.almostCvUserList.userIds;
  }

  get period(): AlmostCvPeriod {
    return this.$store.state.almostCv.almostCvInfo.period;
  }

  get displayPositionDate(): Date {
    return this.period.startDate;
  }

  get coloredPeriods(): ColoredPeriod[] {
    const dateRange: DateRange = {
      min: new Date(this.period.startDate),
      max: new Date(this.period.endDate)
    };
    const result = getDeepCopiedMidnightDateRange(dateRange);

    return [
      new ColoredPeriod(
        this.$t("overViewColorTooltipText") as string,
        result.min,
        result.max,
        Colors.Blue730,
        Colors.Blue800
      )
    ];
  }

  get almostCvUserList(): AlmostCvUserList {
    return this.$store.state.almostCv.almostCvUserList;
  }

  get isShowEmptyDescription(): boolean {
    return !this.isLoading && this.hasUserId;
  }

  get hasUserId(): boolean {
    return this.userIds.length > 0;
  }

  async initSearchResultView() {
    const view = this.$route.query.view;
    switch (view) {
      case SearchResultViews.Memo:
        this.searchResultView = SearchResultViews.Memo;
        break;
      case SearchResultViews.Attribute:
        this.searchResultView = SearchResultViews.Attribute;
        break;
      default:
        // default is overview of one month period
        this.searchResultView = SearchResultViews.Overview;
        this.$store.commit("preference/setChartPeriod", ChartPeriod.OneMonth);
    }
  }

  async fetchAlmostCvInfoAndUsers() {
    this.isLoading = true;

    // reset and show loading
    this.$store.dispatch("search/initializeBeforeSearch");

    /**
     * it is for direct access. almostCvInfo is neeeded to get coloredPeriods
     * URLを直接叩いた場合、概要に色をつけるためにalmostCvInfoをここで取得する
     */
    if (!this.$store.state.almostCv.isAlmostCvInfoFetched) {
      await this.$store.dispatch("almostCv/fetchAlmostCvInfo").catch(error => {
        handleError(error);
        throw new Error(error);
      });
    }

    // feat user ids
    await this.fetchAlmostCvUserList();

    if (!this.hasUserId) {
      this.$store.dispatch("search/clearForEmptyState");
    } else {
      // If historyId exists, recover filter condition
      if (this.$route.query.hid) {
        await executeSearchByHistoryId(Number(this.$route.query.hid));
      } else {
        // search by user ids
        await this.fetchUsersFromAlmostCvUserIds();
      }
    }

    this.isLoading = false;
  }

  async fetchAlmostCvUserList() {
    const conversionId = this.$route.params.cvId;
    await this.$store
      .dispatch("almostCv/fetchUserList", conversionId)
      .catch(error => {
        handleError(error);
        throw new Error(error);
      });
  }

  async fetchUsersFromAlmostCvUserIds() {
    const selectCondition = new SelectByUserIdCondition(this.userIds);
    await this.$store
      .dispatch("search/executeSelect", { selectCondition })
      .catch(error => {
        handleNoQueryCacheError(error);
        throw new Error(error);
      });
  }

  onViewChange(view: SearchResultViews) {
    this.searchResultView = view;
    const query = createQueryWithViewAndHid(view);
    this.$router.replace({ query });
  }
}
</script>
<style lang="scss" scoped>
.almostCvUser__emptyDescription {
  padding: 40px 20px 0 20px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.almostCvUser__conversion {
  margin-top: 8px;
  color: $colorBase700;
  font-size: 14px;
}
</style>
