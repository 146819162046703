<i18n src="@/i18n/components/funnel/funnel-search-history-item.json"></i18n>
<template>
  <div class="funnelSearchHistoryItem">
    <div @mouseenter="onMouseEnterToItem" @mouseleave="onMouseLeaveFromItem">
      <RouterLink
        class="funnelSearchHistoryItem__container"
        :class="{ 'funnelSearchHistoryItem__container--hovered': hovered }"
        :to="{
          name: 'funnel-analysis-detail',
          params: { id: analysisCondition.id }
        }"
      >
        <div
          class="funnelSearchHistoryItem__elapsed"
          v-text="elapsedTimeLabel"
        />
        <div class="funnelSearchHistoryItem__head">
          <div
            class="funnelSearchHistoryItem__title"
            v-text="analysisCondition.funnelTitle"
          />

          <div
            class="funnelSearchHistoryItem__periods"
            v-text="
              $t('analysisPeriod', { period: analysisCondition.displayPeriod })
            "
          />
        </div>

        <template v-if="hasFilterCondition">
          <FilterConditionWithResult
            class="funnelSearchHistoryItem__filterCondition"
            :attributes="userAttributeText"
            :activities="userActivityText"
            :num-filtered-users="analysisCondition.numFilteredUsers"
          />
        </template>

        <div class="funnelSearchHistoryItem__conditionContainer">
          <div
            v-for="(fd, i) in analysisCondition.funnelData"
            :key="i"
            class="funnelSearchHistoryItem__condition"
          >
            <div class="funnelSearchHistoryItem__wrapper">
              <FunnelItemOrder :order="i + 1" />
              <FunnelLabel
                v-if="fd.condition.notCondition"
                :type="notType"
                :label="$t('notCondition')"
                class="funnelSearchHistoryItem__notCondition"
              />
              <FunnelLabel
                :label="fd.condition.typeName"
                class="funnelSearchHistoryItem__type"
              />
              <div
                class="funnelSearchHistoryItem__displayValue"
                v-text="
                  getDisplayValue(
                    fd.condition.displayValueForHistory,
                    fd.condition.conditionType
                  )
                "
              />
              <div class="funnelSearchHistoryItem__numUsers">
                <template v-if="analysisCondition.isCreated">
                  <span
                    class="funnelSearchHistoryItem__numMatchedUsers"
                    v-text="fd.matchedUsers.total.toLocaleString()"
                  />
                  <span
                    v-if="i !== 0"
                    class="funnelSearchHistoryItem__numUsersBorder"
                    :style="{ right: borderRight }"
                  />
                  <span
                    v-if="i !== 0"
                    class="funnelSearchHistoryItem__numUnmatchedUsers"
                    :class="{
                      'funnelSearchHistoryItem__numUnmatchedUsers--max':
                        fd.unmatchedUsers.total === maxUnmatchedUser
                    }"
                    :style="{ width: maxWidth }"
                    v-text="fd.unmatchedUsers.total.toLocaleString()"
                  />
                </template>
                <template v-else>
                  <span v-t="'analyzing'" />
                </template>
              </div>
            </div>
            <div
              v-if="!isLastCondition(i) && isSameVisit(fd.condition.edge)"
              class="funnelSearchHistoryItem__sameVisit"
            >
              <Icon :icon="sameVisitIcon" :size="16" :color="sameVisitColor" />
              <span
                class="funnelSearchHistoryItem__sameVisitText"
                v-text="sameVisitText(fd.condition.edge)"
              />
            </div>
          </div>
        </div>
      </RouterLink>
    </div>

    <div @mouseenter="onMouseEnterToItem" @mouseleave="onMouseLeaveFromItem">
      <FunnelEditLink class="funnelSearchHistoryItem__edit" @edit="onEdit" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { FunnelAnalysisHistory } from "@/models/funnel/FunnelAnalysisCondition";
import { FunnelLablelType, FunnelType } from "@/const/funnel";
import {
  FunnelDataDefinitionType,
  getFunnelDataDefinitionLabel
} from "@/util/funnel-util";
import { getElapsedTxFromNow } from "@/util/date-util";
import FunnelItemOrder from "@/components/funnel/FunnelItemOrder.vue";
import FunnelLabel from "@/components/funnel/FunnelLabel.vue";
import FunnelEditLink from "@/components/funnel/FunnelEditLink.vue";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";
import {
  FunnelEdge,
  FunnelEdgeType,
  SameVisitTransitionType
} from "@/models/funnel/FunnelCondition";
import FilterConditionWithResult from "@/components/funnel/FilterConditionWithResult.vue";

@Component({
  components: {
    Icon,
    FunnelItemOrder,
    FunnelLabel,
    FunnelEditLink,
    FilterConditionWithResult
  }
})
export default class FunnelSearchHistoryItem extends Vue {
  sameVisitIcon = Icons.InflowBottom;
  sameVisitColor = Colors.Base500;

  @Prop({ type: Object, required: true })
  analysisCondition!: FunnelAnalysisHistory;

  @Prop({ type: Array, required: true })
  allDefinitions!: FunnelDataDefinitionType[];

  @Prop({ type: String, default: "" })
  userAttributeText!: string;

  @Prop({ type: String, default: "" })
  userActivityText!: string;

  @Emit("edit")
  onEdit() {}

  notType = FunnelLablelType.NOT;
  hovered = false;

  maxWidth = "auto";
  borderRight = "auto";

  async mounted() {
    await this.$nextTick();
    const maxWidthElement = this.$el.querySelector(
      ".funnelSearchHistoryItem__numUnmatchedUsers--max"
    );

    if (maxWidthElement) {
      const width = maxWidthElement.clientWidth;
      this.maxWidth = `${width}px`;
      this.borderRight = `${width + 30}px`;
    }
  }

  onMouseEnterToItem() {
    this.hovered = true;
  }

  onMouseLeaveFromItem() {
    this.hovered = false;
  }

  getDisplayValue(value: string | number, conditionType: FunnelType) {
    return getFunnelDataDefinitionLabel(
      this.allDefinitions,
      [value] as string[] | number[],
      conditionType
    );
  }

  get maxUnmatchedUser(): number {
    return Math.max(
      ...this.analysisCondition.funnelData.map(fd => fd.unmatchedUsers.total)
    );
  }

  get elapsedTimeLabel(): string {
    const createdAt: Date | undefined = this.analysisCondition.createdAt;
    if (!createdAt) {
      return "";
    }

    return getElapsedTxFromNow(createdAt.getTime());
  }

  get conditions() {
    return this.analysisCondition.funnelData.map(fd => fd.condition);
  }

  get hasFilterCondition(): boolean {
    return this.userAttributeText !== "" || this.userActivityText !== "";
  }

  isLastCondition(index: number): boolean {
    return index === this.analysisCondition.funnelData.length - 1;
  }

  isSameVisit(edge: FunnelEdge): boolean {
    return edge.type === FunnelEdgeType.sameVisit;
  }

  sameVisitText(edge: FunnelEdge): string {
    const baseText = this.$t("withinSameVisit") as string;

    if (edge.transitionType === SameVisitTransitionType.immediatelyAfter) {
      return baseText + ` (${this.$t("immediatelyAfter") as string})`;
    }
    return baseText;
  }
}
</script>

<style lang="scss" scoped>
.funnelSearchHistoryItem {
  position: relative;
}

.funnelSearchHistoryItem__container {
  display: block;
  padding: 20px;
  border-radius: $sizeRadius;
  background-color: $colorGray100;
  transition: background-color 0.5s ease;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
.funnelSearchHistoryItem__container--hovered {
  background: $colorHoverLightForWhite;
}

.funnelSearchHistoryItem__elapsed {
  color: $colorBase700;
  font-size: 12px;
  margin-bottom: 5px;
}

.funnelSearchHistoryItem__head {
  display: flex;
  align-items: flex-start;
  margin-right: 110px;
}

.funnelSearchHistoryItem__title {
  font-size: 14px;
  color: $colorBase900;
  font-weight: bold;
  line-height: 1.5;
}

.funnelSearchHistoryItem__periods {
  font-size: 14px;
  margin-left: 15px;
  color: $colorBase700;
  white-space: nowrap;
  line-height: 1.5;
}

.funnelSearchHistoryItem__userAttributes {
  display: flex;
  background-color: $colorGray200;
  color: $colorBase700;
  margin: 15px 0;
  padding: 12px;
  font-size: 14px;
  line-height: 1.5;
}
.funnelSearchHistoryItem__userAttributesHeader {
  display: inline-block;
  white-space: nowrap;
  margin-right: 5px;
}

.funnelSearchHistoryItem__conditionContainer {
  margin-top: 20px;
}

.funnelSearchHistoryItem__condition {
  display: block;
  align-items: start;

  &:not(:first-child) {
    margin-top: 10px;
  }
}

.funnelSearchHistoryItem__notCondition {
  margin-left: 10px;
}

.funnelSearchHistoryItem__type {
  margin-left: 10px;
}

.funnelSearchHistoryItem__displayValue {
  margin-top: 2px;
  margin-left: 10px;
  color: $colorBase900;
  word-break: break-all;
  font-size: 12px;
  line-height: 1.5;
  width: 1200px;
}

.funnelSearchHistoryItem__numUsers {
  margin-top: 2px;
  font-size: 12px;
  line-height: 1.5;
  color: $colorBase700;
  width: 300px;
  text-align: right;
  margin-left: auto;
}

.funnelSearchHistoryItem__numMatchedUsers {
  display: inline-block;
}

.funnelSearchHistoryItem__numUsersBorder {
  position: absolute;
  background: $colorBase500;
  width: 1px;
  height: 20px;
}

.funnelSearchHistoryItem__numUnmatchedUsers {
  display: inline-block;
  margin-left: 21px;
}

.funnelSearchHistoryItem__edit {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px;
}

.funnelSearchHistoryItem__wrapper {
  display: flex;
}
.funnelSearchHistoryItem__sameVisit {
  display: flex;
  align-items: center;
  margin: 10px 0 0 2px;
  color: $colorBase700;
  font-size: 12px;
}
.funnelSearchHistoryItem__sameVisitText {
  padding-left: 12px;
}

.funnelSearchHistoryItem__filterCondition {
  margin: 15px 0 0;
  background: $colorGray200;
}
</style>
