<template>
  <div
    :class="['intermediateArrow', { 'intermediateArrow--hovered': hovered }]"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class FunnelResultRowIntermediateArrow extends Vue {
  @Prop({ type: Boolean, default: false })
  hovered?: Boolean;
}
</script>

<style scoped lang="scss">
$arrow-spacing: 12px;
$arrow-color: $colorBase400;
.intermediateArrow {
  position: relative;
  display: inline-block;
  border: 2px solid $arrow-color;

  height: calc(100% - $arrow-spacing);
  border-radius: 0 $sizeRadius $sizeRadius 0;
  border-left: none;
  width: 17px;

  &::after {
    content: "";
    display: inline-block;
    border: 6px solid transparent;
    border-right-color: $arrow-color;
    border-left-width: 0px;
    position: absolute;
    bottom: -6px;
  }

  &--hovered {
    border-style: dashed;
    border-left: none;
    border-color: $colorHighlightedBar;

    &::after {
      border-right-color: $colorHighlightedBar;
    }
  }
}
</style>
