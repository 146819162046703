<i18n src="@/i18n/components/home-menu/lag-cv.json"></i18n>
<template>
  <div class="menuPanelLagCvFooter">
    {{ $t("lagCvPanelFooterText") }}
    <HelpSiteLink
      v-if="isJapanese"
      url="https://help.usergram.info/article/457-lag-cv"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import HelpSiteLink from "@/components/home-menu/HelpSiteLink.vue";

@Component({ components: { HelpSiteLink } })
export default class MenuPanelLagCvFooter extends Vue {
  get isJapanese(): boolean {
    return this.$i18n.locale === "ja";
  }
}
</script>

<style lang="scss" scoped>
.menuPanelLagCvFooter {
  margin-top: 20px;
  font-size: 12px;
  text-align: left;
  line-height: 1.5;
  color: $colorBase700;
}
</style>
