import Vue from "vue";
import VueI18n from "vue-i18n";
import m_conversionAttribute from "./models/conversion-attribute.json";
import m_businessEventAttribute from "./models/business-event-attribute.json";
import m_contactAttribute from "./models/contact-attribute.json";
import m_user from "./models/user.json";
import m_gram from "./models/gram.json";
import m_getServiceId from "./models/get-servide-id.json";
import m_device from "./models/device.json";
import m_search from "./models/search.json";
import m_segmentedTrendResult from "./models/segmented-trends/segmented-trend-result.json";
import m_system from "./models/system.json";
import m_selectByEngagementCondition from "./models/search/select-condition/select-by-engagement-condition.json";
import m_userTrendCondition from "./models/user-trend/user-trend-search-condition.json";
import m_clustering from "./models/search/clustering.json";
import m_visitSummaryRowData from "./models/visit-summary/visit-summary-row-data.json";
import m_funnelAnalysisCondition from "./models/funnel/funnel-analysis-condition.json";
import m_funnelCondition from "./models/funnel/funnel-condition.json";
import m_funnelConditionAttribute from "./models/funnel/funnel-condition-attribute.json";

import s_modules from "./store/modules.json";
import s_segmentedTrends from "./store/segmented-trends.json";
import s_tours from "./store/tours.json";
import s_userTrends from "./store/user-trends.json";

import u_dateUtil from "./util/date-util.json";
import u_errorUtil from "./util/error-util.json";
import u_dateRangePickerUtil from "./util/date-range-picker-util.json";
import u_funnelUtil from "./util/funnel-util.json";
import u_selectUtil from "./util/select-util.json";

import c_search from "./components/search/search.json";
import c_filter from "./components/search/filter.json";
import c_funnelUserActivityCondition from "./components/funnel/funnel-user-activity-condition.json";

import c_userTrendTable from "./components/user-trend/table/table-util.json";

import v_selectConditionLabelUtil from "./views/select-condition-label-util.json";

import c_pageTitle from "./page-title.json";

export enum AvailableLanguages {
  ja = "ja",
  "zh-hant" = "zh-hant",
  "zh-hans" = "zh-hans",
  en = "en"
}

Vue.use(VueI18n);

import Element from "element-ui";
import enLocale from "element-ui/lib/locale/lang/en";
import jaLocale from "element-ui/lib/locale/lang/ja";
import zhCnLocale from "element-ui/lib/locale/lang/zh-CN";
import zhTwLocale from "element-ui/lib/locale/lang/zh-TW";

const locale = getInitalLanguage();

const messages = {
  en: {
    components: {
      userTrend: {
        table: { ...c_userTrendTable.en }
      },
      search: {
        search: { ...c_search.en },
        filter: { ...c_filter.en }
      },
      funnel: {
        funnelUserActivityCondition: { ...c_funnelUserActivityCondition.en }
      }
    },
    models: {
      conversionAttribute: { ...m_conversionAttribute.en },
      businessEventAttribute: { ...m_businessEventAttribute.en },
      contactAttribute: { ...m_contactAttribute.en },
      user: { ...m_user.en },
      gram: { ...m_gram.en },
      getServiceId: { ...m_getServiceId.en },
      device: { ...m_device.en },
      search: { ...m_search.en },
      clustering: { ...m_clustering.en },
      selectByEngagementCondition: { ...m_selectByEngagementCondition.en },
      analysisTools: {
        segmentedTrends: {
          segmentedTrendResult: { ...m_segmentedTrendResult.en }
        }
      },
      userTrend: {
        searchCondition: { ...m_userTrendCondition.en }
      },
      system: { ...m_system.en },
      visitSummary: {
        visitSummaryRowData: { ...m_visitSummaryRowData.en }
      },
      funnel: {
        funnelAnalysisCondition: { ...m_funnelAnalysisCondition.en },
        funnelCondition: { ...m_funnelCondition.en },
        funnelConditionAttribute: { ...m_funnelConditionAttribute.en }
      }
    },
    store: {
      modules: { ...s_modules.en },
      segmentedTrends: { ...s_segmentedTrends.en },
      userTrends: { ...s_userTrends.en },
      tours: { ...s_tours.en }
    },
    util: {
      dateUtil: { ...u_dateUtil.en },
      errorUtil: { ...u_errorUtil.en },
      dateRangePickerUtil: { ...u_dateRangePickerUtil.en },
      funnelUtil: { ...u_funnelUtil.en },
      selectUtil: { ...u_selectUtil.en }
    },
    views: {
      selectConditionLabelUtil: { ...v_selectConditionLabelUtil.en }
    },
    pageTitle: { ...c_pageTitle.en },
    ...enLocale
  },
  ja: {
    components: {
      userTrend: {
        table: { ...c_userTrendTable.ja }
      },
      search: {
        search: { ...c_search.ja },
        filter: { ...c_filter.ja }
      },
      funnel: {
        funnelUserActivityCondition: { ...c_funnelUserActivityCondition.ja }
      }
    },
    models: {
      conversionAttribute: { ...m_conversionAttribute.ja },
      businessEventAttribute: { ...m_businessEventAttribute.ja },
      contactAttribute: { ...m_contactAttribute.ja },
      user: { ...m_user.ja },
      gram: { ...m_gram.ja },
      getServiceId: { ...m_getServiceId.ja },
      device: { ...m_device.ja },
      search: { ...m_search.ja },
      clustering: { ...m_clustering.ja },
      selectByEngagementCondition: { ...m_selectByEngagementCondition.ja },
      analysisTools: {
        segmentedTrends: {
          segmentedTrendResult: { ...m_segmentedTrendResult.ja }
        }
      },
      userTrend: {
        searchCondition: { ...m_userTrendCondition.ja }
      },
      system: { ...m_system.ja },
      visitSummary: {
        visitSummaryRowData: { ...m_visitSummaryRowData.ja }
      },
      funnel: {
        funnelAnalysisCondition: { ...m_funnelAnalysisCondition.ja },
        funnelCondition: { ...m_funnelCondition.ja },
        funnelConditionAttribute: { ...m_funnelConditionAttribute.ja }
      }
    },
    store: {
      modules: { ...s_modules.ja },
      segmentedTrends: { ...s_segmentedTrends.ja },
      userTrends: { ...s_userTrends.ja },
      tours: { ...s_tours.ja }
    },
    util: {
      dateUtil: { ...u_dateUtil.ja },
      errorUtil: { ...u_errorUtil.ja },
      dateRangePickerUtil: { ...u_dateRangePickerUtil.ja },
      funnelUtil: { ...u_funnelUtil.ja },
      selectUtil: { ...u_selectUtil.ja }
    },
    views: {
      selectConditionLabelUtil: { ...v_selectConditionLabelUtil.ja }
    },
    pageTitle: { ...c_pageTitle.ja },
    ...jaLocale
  },
  "zh-hant": {
    components: {
      userTrend: {
        table: { ...c_userTrendTable["zh-hant"] }
      },
      search: {
        search: { ...c_search["zh-hant"] },
        filter: { ...c_filter["zh-hant"] }
      },
      funnel: {
        funnelUserActivityCondition: {
          ...c_funnelUserActivityCondition["zh-hant"]
        }
      }
    },
    models: {
      conversionAttribute: { ...m_conversionAttribute["zh-hant"] },
      businessEventAttribute: { ...m_businessEventAttribute["zh-hant"] },
      contactAttribute: { ...m_contactAttribute["zh-hant"] },
      user: { ...m_user["zh-hant"] },
      gram: { ...m_gram["zh-hant"] },
      getServiceId: { ...m_getServiceId["zh-hant"] },
      device: { ...m_device["zh-hant"] },
      search: { ...m_search["zh-hant"] },
      clustering: { ...m_clustering["zh-hant"] },
      selectByEngagementCondition: {
        ...m_selectByEngagementCondition["zh-hant"]
      },
      analysisTools: {
        segmentedTrends: {
          segmentedTrendResult: { ...m_segmentedTrendResult["zh-hant"] }
        }
      },
      userTrend: {
        searchCondition: { ...m_userTrendCondition["zh-hant"] }
      },
      system: { ...m_system["zh-hant"] },
      visitSummary: {
        visitSummaryRowData: { ...m_visitSummaryRowData["zh-hant"] }
      },
      funnel: {
        funnelAnalysisCondition: { ...m_funnelAnalysisCondition["zh-hant"] },
        funnelCondition: { ...m_funnelCondition["zh-hant"] },
        funnelConditionAttribute: { ...m_funnelConditionAttribute["zh-hant"] }
      }
    },
    store: {
      modules: { ...s_modules["zh-hant"] },
      segmentedTrends: { ...s_segmentedTrends["zh-hant"] },
      userTrends: { ...s_userTrends["zh-hant"] },
      tours: { ...s_tours["zh-hant"] }
    },
    util: {
      dateUtil: { ...u_dateUtil["zh-hant"] },
      errorUtil: { ...u_errorUtil["zh-hant"] },
      dateRangePickerUtil: { ...u_dateRangePickerUtil["zh-hant"] },
      funnelUtil: { ...u_funnelUtil["zh-hant"] },
      selectUtil: { ...u_selectUtil["zh-hant"] }
    },
    views: {
      selectConditionLabelUtil: { ...v_selectConditionLabelUtil["zh-hant"] }
    },
    pageTitle: { ...c_pageTitle["zh-hant"] },
    ...zhTwLocale
  },
  "zh-hans": {
    components: {
      userTrend: {
        table: { ...c_userTrendTable["zh-hans"] }
      },
      search: {
        search: { ...c_search["zh-hans"] },
        filter: { ...c_filter["zh-hans"] }
      },
      funnel: {
        funnelUserActivityCondition: {
          ...c_funnelUserActivityCondition["zh-hans"]
        }
      }
    },
    models: {
      conversionAttribute: { ...m_conversionAttribute["zh-hans"] },
      businessEventAttribute: { ...m_businessEventAttribute["zh-hans"] },
      contactAttribute: { ...m_contactAttribute["zh-hans"] },
      user: { ...m_user["zh-hans"] },
      gram: { ...m_gram["zh-hans"] },
      getServiceId: { ...m_getServiceId["zh-hans"] },
      device: { ...m_device["zh-hans"] },
      search: { ...m_search["zh-hans"] },
      clustering: { ...m_clustering["zh-hans"] },
      selectByEngagementCondition: {
        ...m_selectByEngagementCondition["zh-hans"]
      },
      analysisTools: {
        segmentedTrends: {
          segmentedTrendResult: { ...m_segmentedTrendResult["zh-hans"] }
        }
      },
      userTrend: {
        searchCondition: { ...m_userTrendCondition["zh-hans"] }
      },
      system: { ...m_system["zh-hans"] },
      visitSummary: {
        visitSummaryRowData: { ...m_visitSummaryRowData["zh-hans"] }
      },
      funnel: {
        funnelAnalysisCondition: { ...m_funnelAnalysisCondition["zh-hans"] },
        funnelCondition: { ...m_funnelCondition["zh-hans"] },
        funnelConditionAttribute: { ...m_funnelConditionAttribute["zh-hans"] }
      }
    },
    store: {
      modules: { ...s_modules["zh-hans"] },
      segmentedTrends: { ...s_segmentedTrends["zh-hans"] },
      userTrends: { ...s_userTrends["zh-hans"] },
      tours: { ...s_tours["zh-hans"] }
    },
    util: {
      dateUtil: { ...u_dateUtil["zh-hans"] },
      errorUtil: { ...u_errorUtil["zh-hans"] },
      dateRangePickerUtil: { ...u_dateRangePickerUtil["zh-hans"] },
      funnelUtil: { ...u_funnelUtil["zh-hans"] },
      selectUtil: { ...u_selectUtil["zh-hans"] }
    },
    views: {
      selectConditionLabelUtil: { ...v_selectConditionLabelUtil["zh-hans"] }
    },
    pageTitle: { ...c_pageTitle["zh-hans"] },
    ...zhCnLocale
  }
};

const i18n = new VueI18n({
  locale,
  messages
});

Vue.use(Element, {
  i18n: (key: string, value?: any[] | { [key: string]: any }) =>
    i18n.t(key, value)
});

export function getInitalLanguage(): AvailableLanguages {
  try {
    return getBrowserLanguage();
  } catch (e) {
    return AvailableLanguages.ja;
  }
}

export function getBrowserLanguage(): AvailableLanguages {
  const navi = navigator as any;
  const lang: string =
    navi.userLanguage || navi.browserLanguage || navigator.language || "en";

  if (lang === "zh-CN") {
    return AvailableLanguages["zh-hans"];
  } else if (lang === "zh-TW") {
    return AvailableLanguages["zh-hant"];
  }

  const localeString = lang.substring(0, 2).toLowerCase();
  if (localeString === "ja") {
    return AvailableLanguages.ja;
  }

  return AvailableLanguages.en;
}

export { i18n };
