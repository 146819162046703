<template>
  <ElDialog
    class="dialog"
    :class="{ 'dialog--noHeader': noHeader }"
    :visible="visible"
    :title="title"
    :width="width"
    :top="top"
    :modal="true"
    :append-to-body="appendToBody"
    :close-on-click-modal="closeModel"
    :show-close="false"
    :center="center"
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @closed="onClosed"
    @mousedown.native="onMouseDown"
  >
    <template #title>
      <slot name="title"></slot>
    </template>

    <slot></slot>

    <template #footer>
      <slot name="footer"></slot>
    </template>

    <IconButton
      v-if="showCloseButton"
      class="dialog__closeButton"
      :icon="icons.Close"
      :size="36"
      :icon-scale="0.45"
      :background="true"
      :button-type="closeButtonType"
      @click="onClose"
    />
  </ElDialog>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop, Emit } from "vue-property-decorator";
import { Dialog as ElDialog } from "element-ui";
import { Icons } from "@/const/Icons";
import IconButton from "@/components/IconButton.vue";
import { IconButtonType, IconButtonColorType } from "@/const/IconButtons";

@Component({
  components: {
    ElDialog,
    IconButton
  }
})
export default class Dialog extends Vue {
  icons = Icons;
  closeModel: boolean = true;

  @Model("toggle", { type: Boolean, required: true })
  visible!: boolean;

  @Prop({ type: String, default: "" })
  title!: string;

  @Prop({ type: String, default: "50%" })
  width!: string;

  @Prop({ type: String, default: "10vh" })
  top!: string;

  //モーダルを入れ子にする場合はtrueにする
  @Prop({ type: Boolean, default: true })
  appendToBody!: boolean;

  @Prop({ type: Boolean, default: true })
  closeOnClickModal!: boolean;

  @Prop({ type: Boolean, default: true })
  showCloseButton!: boolean;

  @Prop({ type: Boolean, default: true })
  center!: boolean;

  @Prop({ type: Object, default: () => IconButtonType.Strong })
  closeButtonType!: IconButtonColorType;

  @Emit("open")
  onOpen() {}

  @Emit("opened")
  onOpened() {}

  @Emit("close")
  onClose() {}

  @Emit("closed")
  onClosed() {}

  //titleが無いときに余計なpaddingを消す
  get noHeader(): boolean {
    if (this.title.length === 0) {
      return true;
    }

    return false;
  }

  // To avoid close model when mouse press and release outside
  onMouseDown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const className = target.className;
    if (
      typeof className === "string" &&
      className.match("el-dialog__wrapper")
    ) {
      this.closeModel = true;
    } else {
      this.closeModel = false;
    }

    if (!this.closeOnClickModal) {
      this.closeModel = false;
    }
  }
}
</script>

<style scoped lang="scss">
.dialog__closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
