import Vue from "vue";
import * as FullStory from "@fullstory/browser";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import SentryFullStory from "@sentry/fullstory";

const isProduction = process.env.NODE_ENV === "production";

let sentryEnvironment: string = "local";

if (window.location.host.includes("staging-app.b")) {
  sentryEnvironment = "staging";
} else if (window.location.host.includes("dev.com")) {
  sentryEnvironment = "develop";
} else if (window.location.host.includes("usergram.info")) {
  sentryEnvironment = "production";
}

const isLocalhost = sentryEnvironment === "local";

if (isProduction && !isLocalhost) {
  FullStory.init({ orgId: process.env.VUE_APP_FULLSTORY_ORG_ID as string });

  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
      new Integrations.RewriteFrames(),
      new SentryFullStory(process.env.VUE_APP_SENTRY_ORG_SLUG as string)
    ],
    environment: sentryEnvironment,
    release: process.env.VUE_APP_SENTRY_RELEASE
  });
}
