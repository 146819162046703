import { SelectConditionInterface } from "@/models/search/select-condition/SelectCondition";
import {
  JourneyType,
  SelectByUserJourneyQuery,
  SelectQuery,
  UserListType
} from "@/api/apis/ApiSearch";
import {
  HOURLY_INTERVALS_DEFAULT_VALUE,
  msecToSec,
  Sec,
  SECONDS_IN_DAY
} from "@/util/date-util";
import { MAX_DISPLAY_SPAN_DAYS } from "@/const/app";

export class SelectByUserJourneyCondition implements SelectConditionInterface {
  constructor(
    public readonly userVisitInfoQueryId: string,
    public readonly pathId: number,
    public readonly stepIndex: number,
    public readonly journeyType: JourneyType,
    public readonly userListType: UserListType,
    public readonly endDate: Date | null = null,
    public readonly startDate: Date | null = null
  ) {}

  public readonly isAdditionalConditionAppendable: boolean = false;
}

const SELECT_METHOD_TYPE_USER_JOURNEY = 4;

export function convertSelectByUserJourneyConditionToJson(
  condition: SelectByUserJourneyCondition
): SelectByUserJourneyQuery {
  // end time は現在日時
  let endTimeSec: Sec = msecToSec(Date.now());
  // start time は現在から表示最大期間日前
  let startTimeSec: Sec = endTimeSec - MAX_DISPLAY_SPAN_DAYS * SECONDS_IN_DAY;

  if (condition.startDate !== null && condition.endDate !== null) {
    startTimeSec = msecToSec(condition.startDate.getTime());
    endTimeSec = msecToSec(condition.endDate.getTime());
  }

  return {
    select_method_type: SELECT_METHOD_TYPE_USER_JOURNEY,
    start_time_sec: startTimeSec,
    end_time_sec: endTimeSec,
    utc_time_period: HOURLY_INTERVALS_DEFAULT_VALUE,
    user_visit_info_query_id: condition.userVisitInfoQueryId,
    path_id: condition.pathId,
    step_index: condition.stepIndex,
    journey_type: condition.journeyType,
    user_list_type: condition.userListType
  };
}

export function convertJsonToSelectByUserJourneyCondition(
  query: SelectByUserJourneyQuery
): SelectByUserJourneyCondition {
  return new SelectByUserJourneyCondition(
    query.user_visit_info_query_id,
    query.path_id,
    query.step_index,
    query.journey_type,
    query.user_list_type
  );
}

export function isSelectByUserJourneyQuery(
  query: SelectQuery
): query is SelectByUserJourneyQuery {
  return "user_visit_info_query_id" in query;
}
