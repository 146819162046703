import { FunnelType, SearchField } from "@/const/funnel";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import {
  FunnelCondition,
  FunnelConditionDefault,
  FunnelConditionInflow,
  FunnelConditionInflowOrganic,
  FunnelConditionInflowParam,
  FunnelConditionPageTitleOrURL
} from "@/models/funnel/FunnelCondition";
import { MatchMethod } from "@/models/search/MatchMethod";

export enum FunnelInflowType {
  referrerOrLandingPage = 1,
  inflowParameter = 2,
  organicSearch = 3
}

export type DefinitionTypeForm = Pick<
  FunnelConditionDefault,
  "value" | "attributes"
> & {
  type:
    | FunnelType.CV
    | FunnelType.Event
    | FunnelType.BusinessEvent
    | FunnelType.Contact;
};

export type PageTitleOrURLTypeForm = Pick<
  FunnelConditionPageTitleOrURL,
  "value" | "wordMatchMethod" | "searchField"
> & { type: FunnelType.PV | FunnelType.Screen };

export type InflowTypeForm = Pick<FunnelConditionInflow, "matchConditions"> & {
  type: FunnelType.Inflow;
  inflowType: FunnelInflowType.referrerOrLandingPage;
};

export type InflowParamTypeForm = Pick<
  FunnelConditionInflowParam,
  "inflowParam"
> & {
  type: FunnelType.Inflow;
  inflowType: FunnelInflowType.inflowParameter;
};

export type InflowOrganicTypeForm = Pick<
  FunnelConditionInflowOrganic,
  "matchConditions"
> & {
  type: FunnelType.Inflow;
  inflowType: FunnelInflowType.organicSearch;
};

export type FunnelConditionSelectForm =
  | DefinitionTypeForm
  | PageTitleOrURLTypeForm
  | InflowTypeForm
  | InflowParamTypeForm
  | InflowOrganicTypeForm;

export const isDefinitionTypeForm = (arg: any): arg is DefinitionTypeForm => {
  return (
    arg.type === FunnelType.CV ||
    arg.type === FunnelType.Event ||
    arg.type === FunnelType.BusinessEvent ||
    arg.type === FunnelType.Contact
  );
};

export const isPageTitleOrURLTypeForm = (
  arg: any
): arg is PageTitleOrURLTypeForm => {
  return arg.type === FunnelType.PV || arg.type === FunnelType.Screen;
};

export const isInflowTypeForm = (arg: any): arg is InflowTypeForm => {
  return (
    arg.type === FunnelType.Inflow &&
    arg.inflowType === FunnelInflowType.referrerOrLandingPage
  );
};

export const isInflowParamTypeForm = (arg: any): arg is InflowParamTypeForm => {
  return (
    arg.type === FunnelType.Inflow &&
    arg.inflowType === FunnelInflowType.inflowParameter
  );
};

export const isInflowOrganicTypeForm = (
  arg: any
): arg is InflowOrganicTypeForm => {
  return (
    arg.type === FunnelType.Inflow &&
    arg.inflowType === FunnelInflowType.organicSearch
  );
};

export function defaultConditionSelectForm(
  activeCvDefs: ConversionDefinition[]
): FunnelConditionSelectForm {
  if (activeCvDefs.length !== 0) {
    return {
      type: FunnelType.CV,
      value: activeCvDefs[0].id,
      attributes: []
    };
  } else {
    return {
      type: FunnelType.PV,
      value: "",
      wordMatchMethod: MatchMethod.Partial,
      searchField: SearchField.URL
    };
  }
}

export function conditionSelectFormFromCondition(
  condition: FunnelCondition
): FunnelConditionSelectForm {
  if (condition instanceof FunnelConditionPageTitleOrURL) {
    return {
      type: condition.conditionType as PageTitleOrURLTypeForm["type"],
      value: condition.value,
      wordMatchMethod: condition.wordMatchMethod,
      searchField: condition.searchField
    };
  } else if (condition instanceof FunnelConditionDefault) {
    return {
      type: condition.conditionType as DefinitionTypeForm["type"],
      value: condition.value,
      attributes: condition.attributes
    };
  } else if (condition instanceof FunnelConditionInflow) {
    return {
      type: condition.conditionType as InflowTypeForm["type"],
      inflowType: FunnelInflowType.referrerOrLandingPage,
      matchConditions: condition.matchConditions
    };
  } else if (condition instanceof FunnelConditionInflowParam) {
    return {
      type: FunnelType.Inflow,
      inflowType: FunnelInflowType.inflowParameter,
      inflowParam: condition.inflowParam
    };
  } else if (condition instanceof FunnelConditionInflowOrganic) {
    return {
      type: FunnelType.Inflow,
      inflowType: FunnelInflowType.organicSearch,
      matchConditions: condition.matchConditions
    };
  }

  throw new Error("unsupported condition type");
}
