<i18n src="@/i18n/components/home-menu/tour.json"></i18n>
<template>
  <MenuPanel :label-icon="iconTour" :clickable="false" class="menuPanelTour">
    <template #header>
      <div class="menuPanelTour__header">
        {{ $t("tour") }}
        <span class="menuPanelTour__beta">BETA</span>
      </div>
    </template>

    <div class="menuPanelTour__content">
      <div v-t="`title`" class="menuPanelTour__title" />
      <div class="menuPanelTour__items">
        <img
          class="menuPanelTour__backgroundImage"
          :src="require('@/assets/img/tours/binocular.png')"
        />
        <TourPanelItem :title="$t('hint_1')" :icon="Icons.List" />
        <TourPanelItem :title="$t('hint_2')" :icon="Icons.SearchUser" />
        <TourPanelItem :title="$t('hint_3')" :icon="Icons.SpeechBalloon" />
      </div>
      <RouterLink :to="tourIndexRoute" class="menuPanelTour__button">
        {{ $t("button_text") }}
      </RouterLink>
    </div>
  </MenuPanel>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import MenuPanel from "@/components/home-menu/MenuPanel.vue";
import TourPanelItem from "@/components/home-menu/tours/TourPanelItem.vue";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";

@Component({
  components: {
    MenuPanel,
    TourPanelItem,
    Icon
  }
})
export default class MenuPanelTour extends Vue {
  Icons = Icons;
  iconTour = Icons.Binocular;

  get tourIndexRoute(): string {
    return "tours";
  }
}
</script>

<style lang="scss" scoped>
.menuPanelTour {
  padding-right: 20px;
  padding-left: 20px;
  background-color: $colorTourPanelBg;
}
.menuPanelTour__header {
  display: flex;
  align-items: center;
}

.menuPanelTour__beta {
  margin-left: 6px;
  color: $colorBase600;
  font-weight: 400;
  font-size: 12px;
}

.menuPanelTour__content {
  margin-top: 14px;
  height: 317px;
}

.menuPanelTour__title {
  margin-right: 5px;
  margin-left: 5px;
  color: $colorBase900;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.menuPanelTour__items {
  position: relative;
  margin-top: 15px;
  padding: 15px 15px 53px;
  background: $colorWhite;

  // Margin top will NOT be applied to the background image and the first TourPanelItem
  > :nth-child(n + 3) {
    margin-top: 12px;
  }
  height: 182px;
}

.menuPanelTour__backgroundImage {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 96px;
  opacity: 0.15;
}

.menuPanelTour__button {
  display: inline-block;
  margin: 25px auto;
  padding: 6px 13px;
  border: 1px solid $colorBase700;
  border-radius: 5px;
  background: $colorWhite;
  color: $colorBase900;
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background: $colorBlue600;
  }
}
</style>
