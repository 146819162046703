var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"funnelSearchHistoryItem"},[_c('div',{on:{"mouseenter":_vm.onMouseEnterToItem,"mouseleave":_vm.onMouseLeaveFromItem}},[_c('RouterLink',{staticClass:"funnelSearchHistoryItem__container",class:{ 'funnelSearchHistoryItem__container--hovered': _vm.hovered },attrs:{"to":{
        name: 'funnel-analysis-detail',
        params: { id: _vm.analysisCondition.id }
      }}},[_c('div',{staticClass:"funnelSearchHistoryItem__elapsed",domProps:{"textContent":_vm._s(_vm.elapsedTimeLabel)}}),_c('div',{staticClass:"funnelSearchHistoryItem__head"},[_c('div',{staticClass:"funnelSearchHistoryItem__title",domProps:{"textContent":_vm._s(_vm.analysisCondition.funnelTitle)}}),_c('div',{staticClass:"funnelSearchHistoryItem__periods",domProps:{"textContent":_vm._s(
            _vm.$t('analysisPeriod', { period: _vm.analysisCondition.displayPeriod })
          )}})]),(_vm.hasFilterCondition)?[_c('FilterConditionWithResult',{staticClass:"funnelSearchHistoryItem__filterCondition",attrs:{"attributes":_vm.userAttributeText,"activities":_vm.userActivityText,"num-filtered-users":_vm.analysisCondition.numFilteredUsers}})]:_vm._e(),_c('div',{staticClass:"funnelSearchHistoryItem__conditionContainer"},_vm._l((_vm.analysisCondition.funnelData),function(fd,i){return _c('div',{key:i,staticClass:"funnelSearchHistoryItem__condition"},[_c('div',{staticClass:"funnelSearchHistoryItem__wrapper"},[_c('FunnelItemOrder',{attrs:{"order":i + 1}}),(fd.condition.notCondition)?_c('FunnelLabel',{staticClass:"funnelSearchHistoryItem__notCondition",attrs:{"type":_vm.notType,"label":_vm.$t('notCondition')}}):_vm._e(),_c('FunnelLabel',{staticClass:"funnelSearchHistoryItem__type",attrs:{"label":fd.condition.typeName}}),_c('div',{staticClass:"funnelSearchHistoryItem__displayValue",domProps:{"textContent":_vm._s(
                _vm.getDisplayValue(
                  fd.condition.displayValueForHistory,
                  fd.condition.conditionType
                )
              )}}),_c('div',{staticClass:"funnelSearchHistoryItem__numUsers"},[(_vm.analysisCondition.isCreated)?[_c('span',{staticClass:"funnelSearchHistoryItem__numMatchedUsers",domProps:{"textContent":_vm._s(fd.matchedUsers.total.toLocaleString())}}),(i !== 0)?_c('span',{staticClass:"funnelSearchHistoryItem__numUsersBorder",style:({ right: _vm.borderRight })}):_vm._e(),(i !== 0)?_c('span',{staticClass:"funnelSearchHistoryItem__numUnmatchedUsers",class:{
                    'funnelSearchHistoryItem__numUnmatchedUsers--max':
                      fd.unmatchedUsers.total === _vm.maxUnmatchedUser
                  },style:({ width: _vm.maxWidth }),domProps:{"textContent":_vm._s(fd.unmatchedUsers.total.toLocaleString())}}):_vm._e()]:[_c('span',{directives:[{name:"t",rawName:"v-t",value:('analyzing'),expression:"'analyzing'"}]})]],2)],1),(!_vm.isLastCondition(i) && _vm.isSameVisit(fd.condition.edge))?_c('div',{staticClass:"funnelSearchHistoryItem__sameVisit"},[_c('Icon',{attrs:{"icon":_vm.sameVisitIcon,"size":16,"color":_vm.sameVisitColor}}),_c('span',{staticClass:"funnelSearchHistoryItem__sameVisitText",domProps:{"textContent":_vm._s(_vm.sameVisitText(fd.condition.edge))}})],1):_vm._e()])}),0)],2)],1),_c('div',{on:{"mouseenter":_vm.onMouseEnterToItem,"mouseleave":_vm.onMouseLeaveFromItem}},[_c('FunnelEditLink',{staticClass:"funnelSearchHistoryItem__edit",on:{"edit":_vm.onEdit}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }