import {
  SelectConditionInterface,
  MAX_PERIOD_DAYS,
  convertStartAndEndTimeSecFromCondition,
  MAX_HOLD_ADDITIONAL_CONDITION_SIZE
} from "@/models/search/select-condition/SelectCondition";
import {
  AdditionalSelectCondition,
  convertJsonToAdditionalCondition,
  convertAdditionalConditionToJson
} from "@/models/search/additional-condition/AdditionalSelectCondition";
import { SelectByConversionQuery } from "@/api/apis/ApiSearch";
import {
  secToMsec,
  HOURLY_INTERVALS_DEFAULT_VALUE,
  MINUTES_IN_HOUR,
  HOURS_IN_DAY,
  toLocalHour
} from "@/util/date-util";
import { ValidationResult } from "@/models/search/ValidationResult";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { Colors } from "@/const/Colors";
import { i18n } from "@/i18n";
import { GlobalConversionQueryType } from "@/store/modules/system";
import { GlobalConversionDefinition } from "@/models/client-settings/ConversionDefinition";

/**
 * アプリ用コンバージョンによるユーザ選定条件
 *
 * アプリ用コンバージョン定義と、期間を指定して
 * その期間内に指定したアプリ用コンバージョンが起こったユーザを選定する
 *
 * アプリ用コンバージョン定義は複数選択可能
 */
export class SelectByAppCondition implements SelectConditionInterface {
  constructor(
    // コンバージョン定義を指定
    public readonly conversionDefinitionIds: number[],
    // 選定対象期間の始め
    public readonly startDate: Date | null,
    // 選定対象期間の終わり
    public readonly endDate: Date | null,
    /**
     * 選定対象期間の時間
     * 例えば1が指定されていれば
     * 午前1時〜2時までを取得する（2時ちょうどは含めない）
     *
     * nullの場合は指定しない
     */
    public readonly hour: number | null,
    public readonly additionalConditions: AdditionalSelectCondition[]
  ) {}

  static emptyCondition(): SelectByAppCondition {
    return new SelectByAppCondition([], new Date(), new Date(), null, []);
  }

  get coloredPeriods(): ColoredPeriod[] {
    if (this.startDate !== null && this.endDate !== null) {
      return [
        new ColoredPeriod(
          i18n.t("models.search.designatedPeriod") as String,
          this.startDate,
          this.endDate,
          Colors.Blue730,
          Colors.Blue800
        )
      ];
    }
    return [];
  }

  // AdditionalSelectConditionは常に追加できない
  get isAdditionalConditionAppendable(): boolean {
    return (
      this.additionalConditions.length < MAX_HOLD_ADDITIONAL_CONDITION_SIZE
    );
  }

  get validate(): ValidationResult {
    const errorMessages: string[] = [];
    if (this.endDate === null && this.startDate === null) {
      errorMessages.push(i18n.t("models.search.enterTargetPeriod") as string);
    }
    /**
     * 期間のチェック（MAX_PERIOD_DAYS日以内か）
     * 日付の妥当性endがstartより後ろの日付か等はdate pickerがチェックする
     */
    if (this.endDate !== null && this.startDate !== null) {
      const diffMsec = this.endDate.getTime() - this.startDate.getTime();
      if (diffMsec / (1000 * 60 * 60 * 24) > MAX_PERIOD_DAYS) {
        errorMessages.push(i18n.t("models.search.tooLongPeriod") as string);
      }
    }

    // いずれかのコンバージョンが選択されているか
    if (this.conversionDefinitionIds.length <= 0) {
      errorMessages.push(i18n.t("models.search.selectCondition") as string);
    }

    if (errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessage: errorMessages.join("\n")
      };
    }

    return { isValid: true };
  }
}

/**
 * Returns true if conversionIds contains id of FirstOpen-globalConversionDefinition
 *
 * @param conversionIds list of conversionIds to check
 * @param globalConversionDefinitions lookup-list to get conversionId for FirstOpen
 */
export function containsAppFirstOpenConversion(
  conversionIds: number[],
  globalConversionDefinitions: GlobalConversionDefinition[]
): boolean {
  const conversionFirstOpen:
    | GlobalConversionDefinition
    | undefined = globalConversionDefinitions.find(
    condition =>
      condition.path.length > 0 &&
      condition.path[0] === GlobalConversionQueryType.FirstOpen
  );
  return conversionFirstOpen
    ? conversionIds.includes(conversionFirstOpen.id)
    : false;
}

/**
 * 検索条件 -> JSON
 */
export function convertSelectByAppConditionToJson(
  condition: SelectByAppCondition
): SelectByConversionQuery {
  // 時間帯が設定されていなければ、時間帯の初期値（任意の時間帯）をセットする
  // TODO 他でも使っているので関数に切り出したい
  const offsetHour = new Date().getTimezoneOffset() / MINUTES_IN_HOUR;
  const timePeriod =
    condition.hour === null
      ? HOURLY_INTERVALS_DEFAULT_VALUE
      : (condition.hour + offsetHour + HOURS_IN_DAY) % 24;
  const timeSec = convertStartAndEndTimeSecFromCondition(condition);

  return {
    conversion_ids: condition.conversionDefinitionIds,
    start_time_sec: timeSec.start_time_sec,
    end_time_sec: timeSec.end_time_sec,
    utc_time_period: timePeriod,
    sub_cnds: condition.additionalConditions.map(
      convertAdditionalConditionToJson
    )
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToSelectByAppCondition(
  query: SelectByConversionQuery,
  activeDefinitions: ActiveDefinitions
): SelectByAppCondition | null {
  // 利用可能なglobalコンバージョンのみ抽出
  const ids: number[] = query.conversion_ids.filter(id =>
    activeDefinitions.globalConversionDefinitions.some(def => def.id === id)
  );

  // 利用可能なコンバージョンがなければnull
  if (ids.length === 0) {
    return null;
  }

  const additionalConditions: AdditionalSelectCondition[] = [];
  query.sub_cnds.forEach(cnd => {
    const addCnd = convertJsonToAdditionalCondition(cnd, activeDefinitions);
    if (addCnd !== null) {
      additionalConditions.push(addCnd);
    }
  });

  return new SelectByAppCondition(
    ids,
    new Date(secToMsec(query.start_time_sec)),
    new Date(secToMsec(query.end_time_sec)),
    toLocalHour(query.utc_time_period),
    additionalConditions
  );
}

/**
 * 他のモデルはJSONがコンバージョン検索のJSONかどうかを判定する関数があるが、
 * サーバから送られて来るJSONはコンバージョンと同じなので、
 * isSelectByConversionQueryでコンバージョンと判断されたのち
 * コンバージョンIDがアプリ用コンバージョンのみかで、このモデルかどうか判断される
 */
