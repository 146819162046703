<i18n src="@/i18n/views/lag-cv-user.json"></i18n>
<template>
  <div class="lagCvUser">
    <SearchResultContainer
      :view="searchResultView"
      :label="$t('label') + ' (' + $t(periodType) + ')'"
      :icon="icon"
      :preferentially-display-position-date="displayPositionDate"
      :colored-periods="coloredPeriods"
      :is-show-empty-description="isShowEmptyDescription"
      @view-change="onViewChange"
    >
      <template #conditions>
        <div class="lagCvUser__conversion">{{ conversionName }}</div>
      </template>
    </SearchResultContainer>
    <div
      v-if="!isLoading && !hasUserId"
      v-t="'noData'"
      class="empty-description"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";
import SearchResultContainer from "@/views/SearchResultContainer.vue";
import { Icons } from "@/const/Icons";
import { SearchResultViews } from "@/const/SearchResultViews";
import { handleError, handleNoQueryCacheError } from "@/util/error-util";
import { getDeepCopiedMidnightDateRange } from "@/util/date-util";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { Colors } from "@/const/Colors";
import { DateRange } from "@/components/date-picker/DateRange";
import { ChartPeriod } from "@/const/chart-period";
import {
  executeSearchByHistoryId,
  createQueryWithViewAndHid
} from "@/util/user-search-util";
import LagCvUserCount, {
  LagCvConversion,
  LagCvPeriodType
} from "@/models/lag-cv/LagCvUserCount";
import { ParamsFetchUserIdsByConversionId } from "@/store/modules/lagCv";

@Component({
  components: {
    SearchResultContainer
  }
})
export default class LagCvUser extends Vue {
  icon = Icons.Users;
  searchResultView = SearchResultViews.Attribute;
  isLoading = true;

  async created() {
    if (!this.isValidPeriod) {
      this.$router.push("/");
      return;
    }

    if (!this.isUserCountsFetched) {
      await this.$store.dispatch("lagCv/fetchLagCvUserCounts");
    }

    this.initSearchResultView();
    this.fetchUsers();
  }

  get isValidPeriod(): boolean {
    return LagCvUserCount.isValidPeriod(this.periodType);
  }

  get periodType(): LagCvPeriodType {
    return this.$route.params.periodType as LagCvPeriodType;
  }

  @Watch("currentRoute")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChangeRoute(newRoute: string) {
    this.fetchUsers();
  }

  get currentRoute(): string {
    return this.$route.path;
  }

  get userIds(): string[] {
    return this.$store.state.lagCv.lagCvUserIds;
  }

  get coloredPeriods(): ColoredPeriod[] {
    if (!this.userCountOfSelectedPeriod) {
      return [];
    }

    const dateRange: DateRange = {
      min: this.userCountOfSelectedPeriod.startDate,
      max: this.userCountOfSelectedPeriod.endDate
    };
    const result = getDeepCopiedMidnightDateRange(dateRange);

    return [
      new ColoredPeriod(
        this.$t("overViewColorTooltipText") as string,
        result.min,
        result.max,
        Colors.Blue730,
        Colors.Blue800
      )
    ];
  }

  get conversionName(): string {
    if (!this.userCountOfSelectedPeriod) return "";

    const conversionId = Number(this.$route.params.conversionId);
    const conversions: LagCvConversion[] = this.userCountOfSelectedPeriod
      .conversions;

    const selectedConversion = conversions.find(cv => cv.id === conversionId);
    const name = selectedConversion ? selectedConversion.name : "";
    return name;
  }

  get displayPositionDate(): Date {
    if (this.userCountOfSelectedPeriod) {
      return this.userCountOfSelectedPeriod.startDate;
    }
    return new Date();
  }

  get userCountOfSelectedPeriod(): LagCvUserCount | undefined {
    return this.lagCvUserCounts.find(
      lagCvUserCount => lagCvUserCount.periodType === this.periodType
    );
  }

  get lagCvUserCounts(): LagCvUserCount[] {
    return this.$store.state.lagCv.lagCvUserCounts;
  }

  get isShowEmptyDescription(): boolean {
    return !this.isLoading && this.hasUserId;
  }

  get hasUserId(): boolean {
    return this.userIds.length > 0;
  }

  get isUserCountsFetched(): boolean {
    return this.$store.state.lagCv.isLagCvsFetched;
  }

  private initSearchResultView() {
    const view = this.$route.query.view;
    switch (view) {
      case SearchResultViews.Memo:
        this.searchResultView = SearchResultViews.Memo;
        break;
      case SearchResultViews.Attribute:
        this.searchResultView = SearchResultViews.Attribute;
        break;
      default:
        // default is overview of one month period
        this.searchResultView = SearchResultViews.Overview;
        this.$store.commit("preference/setChartPeriod", ChartPeriod.OneMonth);
    }
  }

  async fetchUsers() {
    this.isLoading = true;

    // reset and show loading
    this.$store.dispatch("search/initializeBeforeSearch");

    await this.fetchUserList(this.periodType, this.$route.params.conversionId);

    if (!this.hasUserId) {
      this.$store.dispatch("search/clearForEmptyState");
    } else {
      if (this.$route.query.hid) {
        await executeSearchByHistoryId(Number(this.$route.query.hid));
      } else {
        const condition = new SelectByUserIdCondition(this.userIds);
        this.$store
          .dispatch("search/executeSelect", {
            selectCondition: condition
          })
          .catch(handleNoQueryCacheError);
      }
    }

    this.isLoading = false;
  }

  private async fetchUserList(
    periodType: LagCvPeriodType,
    conversionId?: string
  ) {
    try {
      if (conversionId) {
        await this.fetchUserListByConversionId(periodType, conversionId);
      } else {
        await this.fetchUserListByPeriod(periodType);
      }
    } catch (error) {
      handleError(error);
    }
  }

  private fetchUserListByConversionId(
    periodType: LagCvPeriodType,
    conversionId: string
  ) {
    const params: ParamsFetchUserIdsByConversionId = {
      period: periodType,
      conversionId: Number(conversionId)
    };
    return this.$store.dispatch("lagCv/fetchUserListByConversionId", params);
  }

  private fetchUserListByPeriod(periodType: LagCvPeriodType) {
    return this.$store.dispatch("lagCv/fetchUserListByPeriod", periodType);
  }

  onViewChange(view: SearchResultViews) {
    this.searchResultView = view;
    const query = createQueryWithViewAndHid(view);
    this.$router.replace({ query });
  }
}
</script>
<style lang="scss" scoped>
.empty-description {
  padding: 40px 20px 0 20px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
.lagCvUser__conversion {
  margin-top: 8px;
  color: $colorBase700;
  font-size: $basicFontSize;
}
</style>
