import { LoginUser } from "@/models/auth/UgUser";

export async function sleep(msec: number) {
  return new Promise(resolve => window.setTimeout(resolve, msec));
}

/**
 * overflow-y を指定している先祖要素を取得
 */
export function getOverflowYStyleElement(target: HTMLElement): Element | null {
  let result: Element | null = null;
  let parent = target.parentNode;
  for (let i = 0; parent; i++) {
    if (parent instanceof Element) {
      const style = window.getComputedStyle(parent);
      const overflowY = style.getPropertyValue("overflow-y");

      if (overflowY === "scroll" || overflowY === "auto") {
        result = parent;
        break;
      }
    }
    parent = parent.parentNode;
  }

  return result;
}

/**
 * （ミリ秒単位での）一意な値を生成する
 */
export function generateUniqueId(): string {
  return Date.now().toString();
}

/**
 * To determine if given string is contain japanases text
 */
export function isEnglishText(str: string): boolean {
  return /^[A-Za-z0-9_-]*$/.test(str);
}

export function isGuestUser(user: LoginUser | null): boolean {
  if (!user) return false;
  return user.isGuest;
}

export function isNumber(arg: any): arg is number {
  return typeof arg === "number";
}

export function isString(arg: any): arg is string {
  return typeof arg === "string";
}
