<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterBaseInputText
    v-model="value"
    :prefix="$t('containsPrefix')"
    :suffix="$te('containsSuffix') ? $t('containsSuffix') : ''"
    class="filter-ad-media-form"
  />
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-property-decorator";
import { FilterAdMediaCondition } from "@/models/search/filter-node-condition/FilterAdMediaCondition";
import FilterBaseInputText from "@/components/filter/form/FilterBaseInputText.vue";

@Component({
  components: {
    FilterBaseInputText
  }
})
export default class FilterAdMediaField extends Vue {
  @Model("input", { type: FilterAdMediaCondition, required: true })
  condition!: FilterAdMediaCondition;

  onInput(condition: FilterAdMediaCondition) {
    this.$emit("input", condition);
  }

  get value(): string {
    return this.condition.mediaName;
  }
  set value(mediaName: string) {
    this.onInput(new FilterAdMediaCondition(mediaName));
  }
}
</script>
