<i18n src="@/i18n/views/home.json"></i18n>
<template>
  <div
    class="home"
    data-cy="home"
    :class="[isSideBarExpanded ? 'home--expanded' : 'home--condensed']"
  >
    <Draggable
      v-model="panelItemList"
      easing="cubic-bezier(0.785, 0.135, 0.15, 0.86)"
      animation="600"
      handle=".menu-panel__draggable-area"
      class="home__menu-panel-container"
      :force-fallback="true"
      ghost-class="home__menu-panel-drop-placeholder"
      fallback-class="home__menu-panel-chosen"
      :drag-class="'menu-panel--dragging'"
      @end="onDragEnd"
      @clone="isDragging = true"
      @unchoose="isDragging = false"
    >
      <span
        v-for="panelItem in panelItemList"
        :key="`${String(panelItem.type)}-${String(panelItem.favIndex)}`"
        :class="{ 'menu-panel--dragging': isDragging }"
      >
        <MenuPanelSearchHistory
          v-if="
            latestSearchHistory !== null &&
              clientPvStats !== null &&
              isAvailableUserList &&
              panelItem.type === panelType.PrevSearch
          "
          :label="$t('previousSearch')"
          :history="latestSearchHistory"
          :client-pv-stats="clientPvStats"
          :is-editable="false"
          :is-loading="isLoadingSearchHistoryUserInfo"
          @click="executeHistorySearch(latestSearchHistory)"
        >
          <template #condition>
            <SelectConditionLabel
              :select-condition="latestSearchHistory.selectCondition"
            />
            <template v-if="latestSearchHistory.filterCondition !== null">
              <Icon
                :icon="iconFilter"
                :size="12"
                :color="iconFilterColor"
                class="filter-icon"
              />
              <FilterConditionLabel
                :filter-condition="latestSearchHistory.filterCondition"
              />
            </template>
          </template>

          <template #conditionTooltip>
            <SelectFilterConditionLabel
              :select-condition="latestSearchHistory.selectCondition"
              :filter-condition="latestSearchHistory.filterCondition"
            />
          </template>
        </MenuPanelSearchHistory>

        <MenuPanel
          v-else-if="
            isAvailableUserList && panelItem.type === panelType.PrevSearch
          "
          :clickable="false"
        >
          <template #header><div v-t="'previousSearch'"/></template>
          <div v-t="'viewFromLatestSearch'" class="description" />
        </MenuPanel>

        <MenuPanelNoFavoriteSearch
          v-if="isShowEmptyFavoriteSearchPanel(panelItem)"
        />

        <template v-for="favoriteSearch in favoriteSearches">
          <MenuPanelSearchHistory
            v-if="
              panelItem.type === panelType.FavSearch &&
                panelItem.favIndex === favoriteSearch.id
            "
            :key="favoriteSearch.id"
            :label="favoriteSearch.label"
            :history="favoriteSearch.history"
            :client-pv-stats="clientPvStats"
            :is-edit-mode="
              labelEditingFavoriteSearch !== null &&
                labelEditingFavoriteSearch.id === favoriteSearch.id
            "
            :is-loading="isLoadingSearchHistoryUserInfo"
            :clickable="false"
            @open-edit-form="setLabelEditingFavoriteSearch(favoriteSearch)"
            @save-label="saveLabel"
          >
            <template #menu>
              <Tooltip
                :placement="tooltipPlacementDelete"
                :text="$t('clearSavedSearch')"
              >
                <IconButton
                  class="home__delete"
                  :icon="iconClose"
                  :size="32"
                  :icon-scale="0.5"
                  @click.capture.stop="showDeleteConfirmDialog(favoriteSearch)"
                />
              </Tooltip>
            </template>

            <template #condition>
              <SelectConditionLabel
                :select-condition="favoriteSearch.history.selectCondition"
              />
              <template v-if="favoriteSearch.history.filterCondition !== null">
                <Icon :icon="iconFilter" :size="12" :color="iconFilterColor" />
                <FilterConditionLabel
                  :filter-condition="favoriteSearch.history.filterCondition"
                />
              </template>
            </template>

            <template #conditionTooltip>
              <SelectFilterConditionLabel
                :select-condition="favoriteSearch.history.selectCondition"
                :filter-condition="favoriteSearch.history.filterCondition"
              />
              <div class="period-condition-label">
                <PeriodConditionLabel
                  :favorite-search="favoriteSearch"
                  :editing-label="isNowEditingFavoriteSearchLabel"
                />
              </div>
            </template>
          </MenuPanelSearchHistory>
        </template>

        <MenuPanelBookmark
          v-if="panelItem.type === panelType.Bookmark"
          :user-info="favoriteUserInfo"
          :client-pv-stats="clientPvStats"
          :is-loading="isLoadingUserInfo"
          @click="$router.push('/bookmark-users')"
        />

        <MenuPanelHistory
          v-if="panelItem.type === panelType.History"
          :user-info="historyUserInfo"
          :client-pv-stats="clientPvStats"
          :is-loading="isLoadingUserInfo"
          @click="$router.push('/watch-history')"
        />

        <MenuPanelLagCv
          v-if="isAvailableUserList && panelItem.type === panelType.LagCv"
          :id="panelId(panelItem.type)"
          :user-counts="lagCvUserCounts"
          :is-loading="isLoadingLagCvs || isRetryLagCvsLoading"
          :is-error="isErrorLagCvs"
          @click="onClickRetryLagCvs"
        />

        <MenuPanelAlmostCv
          v-if="isAvailableUserList && panelItem.type === panelType.AlmostCv"
          :id="panelId(panelItem.type)"
          :is-loading="isLoadingAlmostCvInfo || isRetryAlmostCvLoading"
          :almost-cv-info="almostCvInfo"
          :is-error="isAlmostCvFetchError"
          @click="onClickRetryAlmostCv"
        />

        <MenuPanelConversionTrend
          v-if="
            isAvailableUserList && panelItem.type === panelType.ConversionTrend
          "
          :conversion-trends="conversionTrends"
          :login-user-id="loginUser.id"
          :is-loading="isLoadingConversionTrend"
          :is-error="isConversionTrendFetchError"
          @click="onConversionTrendClick"
          @retry="executeConversionTrend"
        />

        <MenuPanelTour v-if="showTourPanel(panelItem.type)" />
      </span>
    </Draggable>

    <Dialog
      v-if="deleteTargetFavoriteSearch !== null"
      class="home__deleteDialog"
      :visible="showDialog"
      @close="showDialog = false"
    >
      {{
        $t("clearSavedSearchCondition", {
          condition: deleteTargetFavoriteSearch.label
        })
      }}

      <template #footer>
        <div class="home__deleteDialogFooter">
          <Button
            v-t="'ok'"
            class="home__deleteDialogButton"
            width="150px"
            @click="deleteFavoriteSearch"
          />
          <Button
            v-t="'cancel'"
            class="home__deleteDialogButton"
            width="150px"
            :type="cancelButtonType"
            @click="closeDeleteConfirmDialog"
          />
        </div>
      </template>
    </Dialog>

    <HomeOnboarding
      v-if="showOnboarding && isAvailableUserList"
      v-model="noMoreShowOnboarding"
      :locale="locale"
      @close="closeOnboarding"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import IconButton from "@/components/IconButton.vue";
import Icon from "@/components/Icon.vue";
import Button from "@/components/Button.vue";
import Dialog from "@/components/dialog/Dialog.vue";
import Tooltip from "@/components/Tooltip.vue";
import HomeOnboarding from "@/components/onboarding/HomeOnboarding.vue";
import SelectConditionLabel from "@/views/SelectConditionLabel.vue";
import FilterConditionLabel from "@/views/FilterConditionLabel.vue";
import SelectFilterConditionLabel from "@/views/SelectFilterConditionLabel.vue";
import PeriodConditionLabel from "@/views/PeriodConditionLabel.vue";
import MenuPanel from "@/components/home-menu/MenuPanel.vue";
import MenuPanelSearchHistory from "@/components/home-menu/MenuPanelSearchHistory.vue";
import MenuPanelBookmark from "@/components/home-menu/MenuPanelBookmark.vue";
import MenuPanelHistory from "@/components/home-menu/MenuPanelHistory.vue";
import MenuPanelLagCv from "@/components/home-menu/lag-cv/MenuPanelLagCv.vue";
import MenuPanelNoFavoriteSearch from "@/components/home-menu/MenuPanelNoFavoriteSearch.vue";
import MenuPanelAlmostCv from "@/components/home-menu/almost-cv/MenuPanelAlmostCv.vue";
import MenuPanelTour from "@/components/home-menu/tours/MenuPanelTour.vue";

import { addHidToUsersUrl } from "@/router";
import Balloon from "@/components/Balloon.vue";
import { Colors } from "@/const/Colors";
import { LoginUser } from "@/models/auth/UgUser";
import { UserInfo } from "@/models/UserInfo";
import { SearchHistory } from "@/models/search/SearchHistory";
import { ClientPvStats } from "@/models/system/ClientPvStats";
import { FavoriteSearch } from "@/models/search/FavoriteSearch";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import { AlmostCvInfo } from "@/models/almost-cv/AlmostCvInfo";
import { GlobalConversionDefinition } from "@/models/client-settings/ConversionDefinition";

import { handleError, handleNoQueryCacheError } from "@/util/error-util";
import { Icons } from "@/const/Icons";
import { BUTTON_TYPE } from "@/const/button";
import { TooltipPlacement } from "@/const/tooltip";
import { Favorite } from "@/models/userdata/Favorite";
import Draggable from "vuedraggable";
import { PanelType } from "@/const/home";
import { panelId } from "@/util/string-util";
import LagCvUserCount from "@/models/lag-cv/LagCvUserCount";
import { SearchResultViews } from "@/const/SearchResultViews";
import { SelectByConversionCondition } from "@/models/search/select-condition/SelectByConversionCondition";
import { SelectByAppCondition } from "@/models/search/select-condition/SelectByAppCondition";
import { ConversionTrendDailyCount } from "@/models/conversion-trend/ConversionTrendDailyCount";
import { ConversionTrendResult } from "@/models/conversion-trend/ConversionTrendResult";
import MenuPanelConversionTrend from "@/components/home-menu/MenuPanelConversionTrend.vue";
import { ConversionTrendStatus } from "@/api/apis/ApiConversionTrend";
import { Client } from "@/models/Client";
import { tourValidClients } from "@/util/tour-util";
import * as Sentry from "@sentry/browser";
import { sideBarStore } from "@/store/v3-side-bar";

const { isSideBarMinimized } = sideBarStore();

interface PanelItem {
  type: number;
  favIndex: number;
}

const TOOLTIP_PLACEMENT_DELETE: TooltipPlacement = TooltipPlacement.BottomEnd;

const defaultPanelList: PanelItem[] = [
  { type: PanelType.PrevSearch, favIndex: 0 },
  { type: PanelType.FavSearch, favIndex: 0 },
  { type: PanelType.Bookmark, favIndex: 0 },
  { type: PanelType.History, favIndex: 0 },
  { type: PanelType.Discussion, favIndex: 0 },
  { type: PanelType.LagCv, favIndex: 0 },
  { type: PanelType.AlmostCv, favIndex: 0 }
];

const conversionTrendPanel: PanelItem = {
  type: PanelType.ConversionTrend,
  favIndex: 0
};
const tourPanel: PanelItem = {
  type: PanelType.Tour,
  favIndex: 0
};

@Component({
  components: {
    MenuPanel,
    MenuPanelSearchHistory,
    MenuPanelBookmark,
    MenuPanelHistory,
    MenuPanelLagCv,
    MenuPanelAlmostCv,
    MenuPanelNoFavoriteSearch,
    MenuPanelConversionTrend,
    MenuPanelTour,
    IconButton,
    Icon,
    Button,
    Dialog,
    Tooltip,
    SelectConditionLabel,
    FilterConditionLabel,
    SelectFilterConditionLabel,
    PeriodConditionLabel,
    Balloon,
    HomeOnboarding,
    Draggable
  }
})
export default class Home extends Vue {
  iconClose: Icons = Icons.ButtonClose;
  iconFilter: Icons = Icons.Filter;
  iconFilterColor: Colors = Colors.Base500;
  labelEditingFavoriteSearch: FavoriteSearch | null = null;
  deleteTargetFavoriteSearch: FavoriteSearch | null = null;
  cancelButtonType: BUTTON_TYPE = BUTTON_TYPE.LIGHT;
  tooltipPlacementDelete = TOOLTIP_PLACEMENT_DELETE;
  isRetryLagCvsLoading: boolean = false;
  isRetryAlmostCvLoading: boolean = false;
  showDialog = false;

  isDragging: boolean = false;

  panelType = PanelType;

  panelItemList: PanelItem[] = [];

  panelId = panelId;

  mounted() {
    const historyFetched: boolean = this.$store.state.searchHistory
      .isUserInfoListFetched;
    const userInfoFetched: boolean = this.$store.state.userInfo.isFetched;
    const lagCvsFetched: boolean = this.$store.state.lagCv.isLagCvsFetched;
    const almostCvInfoFetched: boolean = this.$store.state.almostCv
      .isAlmostCvInfoFetched;

    if (!historyFetched || !userInfoFetched) {
      // initialize時にhistory系やお気に入りユーザなどは取得されている状態
      Promise.all([
        this.$store.dispatch("searchHistory/updateUserInfo"),
        this.$store.dispatch("userInfo/fetchUserInfo")
      ]).catch(errors => {
        throw new Error(errors);
      });
    }

    // Only call if user is not guest
    if (this.isAvailableUserList) {
      if (!lagCvsFetched) {
        this.$store.dispatch("lagCv/fetchLagCvUserCounts");
      }
      if (!almostCvInfoFetched) {
        this.$store.dispatch("almostCv/fetchAlmostCvInfo").catch(error => {
          throw new Error(error);
        });
      }
      if (this.conversionTrendStatus !== ConversionTrendStatus.SUCCEEDED) {
        this.executeConversionTrend();
      }
    }

    this.panelItemList = this.getPanelList();
    this.setToLocalStorage(this.panelItemList);
  }

  async executeConversionTrend() {
    await this.$store.dispatch("conversionTrend/create").catch(() => {
      this.$store.commit(
        "conversionTrend/setStatus",
        ConversionTrendStatus.FAILED
      );
    });
    this.$store.dispatch("conversionTrend/fetchResult").catch(() => {
      this.$store.commit(
        "conversionTrend/setStatus",
        ConversionTrendStatus.FAILED
      );
    });
  }

  getPanelListFromLocalStorage(): PanelItem[] {
    const LocalstorageData = localStorage.getItem(this.keyOfLocalStorage);
    if (LocalstorageData !== null) {
      return JSON.parse(LocalstorageData);
    }
    return [];
  }

  setToLocalStorage(list: PanelItem[]) {
    localStorage.setItem(this.keyOfLocalStorage, JSON.stringify(list));
  }

  getPanelList(): PanelItem[] {
    const savedPanelList = this.getPanelListFromLocalStorage();
    const panelList: PanelItem[] = this.mergePanelList(
      defaultPanelList,
      savedPanelList
    );

    const newAddedFavoriteSearches = this.favoriteSearches.filter(fav => {
      return !panelList.some(({ favIndex }) => favIndex == fav.id);
    });
    if (newAddedFavoriteSearches.length > 0) {
      newAddedFavoriteSearches.forEach(fav => {
        panelList.unshift({
          type: PanelType.FavSearch,
          favIndex: fav.id
        });
      });
    }

    if (!panelList.find(x => x.type === conversionTrendPanel.type)) {
      panelList.unshift(conversionTrendPanel);
    }

    if (!panelList.find(x => x.type === tourPanel.type)) {
      panelList.unshift(tourPanel);
    }

    return panelList;
  }

  mergePanelList(
    defaultList: PanelItem[],
    savedList: PanelItem[]
  ): PanelItem[] {
    const listInDefaultButNotSaved: PanelItem[] = [];

    defaultList.forEach((item: PanelItem) => {
      if (!savedList.some(savedItem => savedItem.type === item.type)) {
        listInDefaultButNotSaved.push(item);
      }
    });

    return [...savedList, ...listInDefaultButNotSaved];
  }

  onDeleteFavoriteSearchUpdatePanelItem(favoriteSearchId: number) {
    this.panelItemList.forEach((panelItem, index) => {
      if (panelItem.favIndex === favoriteSearchId) {
        this.panelItemList.splice(index, 1);
      }
    });
    this.setToLocalStorage(this.panelItemList);
  }

  onDragEnd() {
    this.setToLocalStorage(this.panelItemList);
    this.isDragging = false;

    UgTag.pushEvent(UgEventTag.SortHomePanels);
  }

  get globalConversioinIds(): number[] {
    return this.$store.getters["system/activeGlobalConversionDefinitions"].map(
      (c: GlobalConversionDefinition) => c.id
    );
  }

  get isNowEditingFavoriteSearchLabel(): Boolean {
    return this.labelEditingFavoriteSearch !== null;
  }

  setLabelEditingFavoriteSearch(favoriteSearch: FavoriteSearch) {
    this.labelEditingFavoriteSearch = favoriteSearch;
  }

  async saveLabel(label: string) {
    if (this.labelEditingFavoriteSearch) {
      await this.$store
        .dispatch("searchHistory/updateFavoriteSearch", {
          id: this.labelEditingFavoriteSearch.label,
          historyId: this.labelEditingFavoriteSearch.history.id,
          label
        })
        .catch(error => {
          handleError(error);
          throw new Error(error);
        });
    }

    this.labelEditingFavoriteSearch = null;
  }

  executeHistorySearch(history: SearchHistory) {
    if (this.labelEditingFavoriteSearch === null) {
      this.$store
        .dispatch("search/executeSelectFromHistory", {
          history,
          setHistoryIdToUrl: addHidToUsersUrl(this.$router)
        })
        .catch(error => {
          handleError(error);
          throw new Error(error);
        });

      let query: { [key: string]: string } = {
        view: this.$store.state.preference.searchResultView
      };

      this.$router.push({ name: "users", query });
    }
  }

  showDeleteConfirmDialog(favoriteSearch: FavoriteSearch) {
    if (this.labelEditingFavoriteSearch === null) {
      this.deleteTargetFavoriteSearch = favoriteSearch;
      this.showDialog = true;
    }
  }

  closeDeleteConfirmDialog() {
    this.deleteTargetFavoriteSearch = null;
    this.showDialog = false;
  }

  async deleteFavoriteSearch() {
    if (this.deleteTargetFavoriteSearch !== null) {
      this.showDialog = false;
      await this.$store
        .dispatch(
          "searchHistory/deleteFavoriteSearch",
          this.deleteTargetFavoriteSearch.id
        )
        .catch(error => {
          handleError(error);
          throw new Error(error);
        });
      this.onDeleteFavoriteSearchUpdatePanelItem(
        this.deleteTargetFavoriteSearch.id
      );
    }
    this.deleteTargetFavoriteSearch = null;
  }

  get conversionTrends(): ConversionTrendResult[] {
    return this.$store.getters["conversionTrend/resultsPerCv"];
  }

  get isLoadingConversionTrend(): boolean {
    return this.conversionTrendStatus === ConversionTrendStatus.RUNNING;
  }

  get isConversionTrendFetchError(): boolean {
    return this.conversionTrendStatus === ConversionTrendStatus.FAILED;
  }

  get isSideBarExpanded(): boolean {
    return !isSideBarMinimized.value;
  }

  get keyOfLocalStorage(): string {
    return this.loginUser.id + "Panels";
  }

  get userInfos(): UserInfo[] {
    return this.$store.state.userInfo.userInfos;
  }

  get favorites(): Favorite[] {
    return this.$store.state.favorite.favorites;
  }

  get favoriteUserInfo(): UserInfo[] {
    // Need to be refactored
    // ホーム画面での日付で UserInfo.createdAt が使用されていたので
    // Favorite.updatedDate を使用するように強引に変更している
    const favoriteUsers: Favorite[] = this.favorites.slice(0, 6);
    const updateDateMap: { [userId: string]: Date } = {};
    favoriteUsers.forEach(fu => {
      updateDateMap[fu.userId] = fu.updatedDate;
    });

    const userInfo: UserInfo[] = this.userInfos.filter((ui: UserInfo) =>
      favoriteUsers.some(fu => fu.userId === ui.userId)
    );

    userInfo.forEach(ui => {
      ui.createdAt = updateDateMap[ui.userId];
    });
    return userInfo;
  }

  get historyUserInfo(): UserInfo[] {
    const historyUserIds: string[] = this.$store.getters["history/userIds"];

    const userInfo: UserInfo[] = this.userInfos
      .filter((ui: UserInfo) => historyUserIds.some(id => id === ui.userId))
      .slice(0, 6);

    return userInfo;
  }

  get latestSearchHistory(): SearchHistory | null {
    const searchHistories = this.$store.getters[
      "searchHistory/searchHistories"
    ];
    return searchHistories.length > 0 ? searchHistories[0] : null;
  }

  get searchHistories(): SearchHistory[] {
    return this.$store.getters["searchHistory/searchHistories"];
  }

  get favoriteSearches(): FavoriteSearch[] {
    // v-forとv-ifの併用を避けるため
    if (!this.isAvailableUserList) {
      return [];
    }
    return this.$store.getters["searchHistory/favoriteSearches"];
  }

  isShowEmptyFavoriteSearchPanel(panelItem: PanelItem): boolean {
    return (
      this.favoriteSearches.length === 0 &&
      this.isAvailableUserList &&
      panelItem.type === this.panelType.FavSearch &&
      panelItem.favIndex === 0
    );
  }

  get clientPvStats(): ClientPvStats {
    return this.$store.state.system.clientPvStats;
  }

  get showOnboarding(): boolean {
    return this.$store.state.onboarding.showHomeOnboarding;
  }

  get locale(): string {
    return this.$i18n.locale;
  }

  get noMoreShowOnboarding(): boolean {
    return this.$store.state.preference.noMoreShowHomeOnboarding;
  }
  set noMoreShowOnboarding(isChecked: boolean) {
    this.$store.commit("preference/setNoMoreShowHomeOnboarding", isChecked);
  }

  get loginUser(): LoginUser {
    return this.$store.state.auth.user;
  }

  get isAvailableUserList(): boolean {
    return this.loginUser.permission.isAvailableUserList;
  }

  get isLoadingUserInfo(): boolean {
    return !this.$store.state.userInfo.isFetched;
  }

  get isLoadingSearchHistoryUserInfo(): boolean {
    return !this.$store.state.searchHistory.isUserInfoListFetched;
  }

  get lagCvUserCounts(): LagCvUserCount[] {
    return this.$store.state.lagCv.lagCvUserCounts;
  }

  get isLoadingLagCvs(): boolean {
    return !this.$store.state.lagCv.isLagCvsFetched;
  }

  get isErrorLagCvs() {
    return this.$store.state.lagCv.isLagCvsFetchError;
  }

  get isLoadingAlmostCvInfo(): boolean {
    return this.$store.state.almostCv.isLoadingAlmostCvInfo;
  }

  get almostCvInfo(): AlmostCvInfo {
    return this.$store.state.almostCv.almostCvInfo;
  }

  get isAlmostCvFetchError() {
    return this.$store.state.almostCv.isAlmostCvFetchError;
  }

  get conversionTrendStatus(): ConversionTrendStatus {
    return this.$store.state.conversionTrend.status;
  }

  get loginClient(): Client | null {
    return this.$store.state.client.client;
  }

  showTourPanel(panelType: number): boolean {
    if (this.loginClient === null) {
      return false;
    }
    return (
      this.isAvailableUserList &&
      panelType === PanelType.Tour &&
      tourValidClients.includes(this.loginClient.id)
    );
  }

  async onClickRetryAlmostCv() {
    this.isRetryAlmostCvLoading = true;
    try {
      await this.$store.dispatch("almostCv/fetchAlmostCvInfo");
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      this.isRetryAlmostCvLoading = false;
    }
  }

  async onClickRetryLagCvs() {
    this.isRetryLagCvsLoading = true;
    await this.$store.dispatch("lagCv/fetchLagCvUserCounts");
    this.isRetryLagCvsLoading = false;
  }

  closeOnboarding() {
    this.$store.commit("onboarding/setShowHomeOnboarding", false);
  }

  onConversionTrendClick(
    result: ConversionTrendResult,
    currentData: ConversionTrendDailyCount
  ) {
    const startDate = currentData.date;
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(startDate);
    endDate.setHours(23, 59, 59, 999);

    const condition = this.globalConversioinIds.includes(result.conversionId)
      ? new SelectByAppCondition(
          [result.conversionId],
          startDate,
          endDate,
          null,
          []
        )
      : new SelectByConversionCondition(
          [result.conversionId],
          startDate,
          endDate,
          null,
          []
        );

    this.$store.dispatch("searchForm/setCondition", condition);
    this.$store
      .dispatch("search/executeSelect", {
        selectCondition: condition,
        setHistoryIdToUrl: addHidToUsersUrl(this.$router)
      })
      .catch(handleNoQueryCacheError);

    UgTag.pushEvent(UgEventTag.ConversionTrend);

    let query: { [key: string]: string } = {
      view: SearchResultViews.Overview
    };
    this.$router.push({ name: "users", query });
  }
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
}

.home--condensed {
  max-width: $home4ColumnWidth;

  @media screen and (max-width: 1780px) {
    max-width: $home3ColumnWidth;
  }

  @media screen and (max-width: 1364px) {
    max-width: $home2ColumnWidth;
  }
}

.home--expanded {
  max-width: $home4ColumnWidth;

  @media screen and (max-width: 2020px) {
    max-width: $home3ColumnWidth;
  }

  @media screen and (max-width: 1604px) {
    max-width: $home2ColumnWidth;
  }
}

.home__delete {
  margin-bottom: -5px;
}
.home__tooltip-filter {
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px solid $colorBase500;
}
.home__tooltip-filter-icon {
  margin-right: 4px;
}
.description {
  margin-top: 18px;
  text-align: left;
  font-size: 18px;
  line-height: 1.4;
}
.home__menu-panel-container {
  display: flex;
  flex-wrap: wrap;
}
.home__menu-panel-drop-placeholder {
  .menu-panel {
    border: 1px dashed $colorBase800;
    background-color: $colorWhite;
    opacity: 0.3;
  }
  ::v-deep .menu-panel__draggable-area {
    opacity: 0;
  }
}
.home__menu-panel-chosen {
  .menu-panel {
    background-color: $colorBlue600;
  }
}
.home__deleteDialogFooter {
  margin-top: 30px;
}

.home__deleteDialogButton {
  margin: 0 10px;
}

.period-condition-label {
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px solid $colorBase500;
}

.home__highlightedPanel::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 5px;
  content: "";
  animation: frameAnimation 0.8s 8 ease-out alternate;
  pointer-events: none;
}

@keyframes frameAnimation {
  0% {
    border-color: transparent;
  }
  100% {
    border-color: $colorBadge;
  }
}
</style>
