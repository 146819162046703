<i18n src="@/i18n/views/blog-link-container.json"></i18n>
<template>
  <Transition name="blogLinkContainerSlide">
    <div v-if="showBlogLinkButton" class="blogLinkContainer">
      <BlogPageBalloon
        v-if="isOnboardingBalloonValid"
        class="blogLinkContainer__balloon"
        :blog-page-link="blogPageLink"
        :balloon-description="balloonDescription"
        :balloon-title="balloonTitle"
        @click="openBlogLink"
        @click-close="hideBlogPageBalloon"
      >
        <template #balloonImage>
          <component :is="svgComponent" :class="imageClass" />
        </template>
      </BlogPageBalloon>
      <a
        class="blogLinkContainer__button"
        target="_self"
        href="#"
        rel="noopener"
      >
        <IconTextButton
          :active-tooltip="!isOnboardingBalloonValid"
          :icon="icons.Hint"
          :tooltip-text="balloonTitle"
          @click="openBlogLink"
        />
      </a>
    </div>
  </Transition>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BlogPageBalloon from "@/components/BlogPageBalloon.vue";
import IconTextButton from "@/components/IconTextButton.vue";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import { BLOG_LINK_SWITCH_DURATION } from "@/const/BlogLink";
import { Icons } from "@/const/Icons";

import {
  BlogPage,
  BLOG_LINK_TO_SEARCH,
  BLOG_LINK_TO_FILTER,
  BLOG_LINK_TO_USERS_WITH_FEW_USERS,
  BLOG_LINK_TO_USERS_WITH_MANY_USERS,
  BLOG_LINK_TO_USER_DETAIL,
  BLOG_LINK_TO_USER_TREND,
  BLOG_LINK_PARAMS
} from "@/const/BlogLink";
import SearchImage from "@/assets/img/blog-page-balloon/search.svg";
import FilterImage from "@/assets/img/blog-page-balloon/filter.svg";
import UserIndexSmallImage from "@/assets/img/blog-page-balloon/user-index-small.svg";
import UserIndexLargeImage from "@/assets/img/blog-page-balloon/user-index-large.svg";
import UserDetailImage from "@/assets/img/blog-page-balloon/user-detail.svg";
import UserTrendImage from "@/assets/img/blog-page-balloon/user-trend.svg";

@Component({
  components: {
    BlogPageBalloon,
    IconTextButton
  }
})
export default class BlogLinkContainer extends Vue {
  icons = Icons;
  isSwitching = false;

  @Prop({ type: Boolean, required: true })
  searchFormIsDisplayed!: boolean;

  @Prop({ type: Boolean, required: true })
  filterFormIsDisplayed!: boolean;

  @Watch("searchFormIsDisplayed")
  changeSearchFormIsDisplayed(value: boolean) {
    this.handleIsSwitching(value);
  }

  @Watch("filterFormIsDisplayed")
  changeFilterFormIsDisplayed(value: boolean) {
    this.handleIsSwitching(value);
  }

  @Watch("isUsersWithFewUsers")
  changeIsUsersSmall(value: boolean) {
    this.handleIsSwitching(value);
  }

  @Watch("isUsersWithManyUsers")
  changIsUsersLarge(value: boolean) {
    this.handleIsSwitching(value);
  }

  @Watch("userDetailIsDisplayed")
  changeIsUserDetail(value: boolean) {
    this.handleIsSwitching(value);
  }

  @Watch("isUserTrend")
  changeIsUserTrend(value: boolean) {
    this.handleIsSwitching(value);
  }

  // ページが切り替わる場合は、一定時間isSwitchingをtrueにしておく
  handleIsSwitching(value: boolean) {
    if (!value) {
      this.isSwitching = true;
      window.setTimeout(() => {
        this.isSwitching = false;
      }, BLOG_LINK_SWITCH_DURATION); // transition 150ms + 待ち時間 100ms
    }
  }

  get blogPage(): BlogPage {
    if (this.searchFormIsDisplayed) {
      return BlogPage.Search;
    }
    if (this.filterFormIsDisplayed) {
      return BlogPage.Filter;
    }
    if (this.isUsersWithFewUsers) {
      return BlogPage.UserIndexSmall;
    }
    if (this.isUsersWithManyUsers) {
      return BlogPage.UserIndexLarge;
    }
    if (this.userDetailIsDisplayed) {
      return BlogPage.UserDetail;
    }
    if (this.isUserTrend) {
      return BlogPage.UserTrend;
    }

    return BlogPage.Empty;
  }

  get someModalIsDisplayed(): boolean {
    return (
      this.searchFormIsDisplayed ||
      this.filterFormIsDisplayed ||
      this.userDetailIsDisplayed
    );
  }

  get selectExactUserCount(): number {
    return this.$store.state.search.userCount;
  }

  get filteredExactUserCount(): number {
    return this.$store.state.filter.userCount;
  }

  get isFilterMode(): boolean {
    return this.$store.state.filter.isFilterMode;
  }

  get isCountFetched(): boolean {
    const searchCountFetched: boolean = this.$store.state.search.isCountFetched;
    const filterCountFetched: boolean = this.$store.state.filter.isCountFetched;
    return this.isFilterMode
      ? searchCountFetched && filterCountFetched
      : searchCountFetched;
  }

  get isUsers(): boolean {
    return this.$route.name === "users" && !this.someModalIsDisplayed;
  }

  get isUsersWithFewUsers(): boolean {
    return (
      this.isUsers &&
      (this.isFilterMode
        ? this.filteredExactUserCount < 5
        : this.selectExactUserCount < 5)
    );
  }

  get isUsersWithManyUsers(): boolean {
    return (
      this.isUsers &&
      (this.isFilterMode
        ? this.filteredExactUserCount >= 5
        : this.selectExactUserCount >= 5)
    );
  }

  get userDetailIsDisplayed(): boolean {
    return this.$store.state.user.showUserDetail;
  }

  get isUserTrend(): boolean {
    return (
      this.$route.path.includes("user-trend") && !this.someModalIsDisplayed
    );
  }

  get showBlogLinkButton(): boolean {
    return (
      !this.isSwitching &&
      (this.searchFormIsDisplayed ||
        this.filterFormIsDisplayed ||
        (this.isUsers && this.isCountFetched) ||
        this.userDetailIsDisplayed ||
        this.isUserTrend)
    );
  }

  get svgComponent(): Vue.Component {
    switch (this.blogPage) {
      case BlogPage.Search:
        return SearchImage;
      case BlogPage.Filter:
        return FilterImage;
      case BlogPage.UserIndexSmall:
        return UserIndexSmallImage;
      case BlogPage.UserIndexLarge:
        return UserIndexLargeImage;
      case BlogPage.UserDetail:
        return UserDetailImage;
      case BlogPage.UserTrend:
        return UserTrendImage;
      default:
        return UserDetailImage;
    }
  }

  get imageClass(): String {
    return "blogLinkContainer__balloonImage--" + this.blogPage;
  }

  openBlogLink() {
    UgTag.pushEvent(UgEventTag.BlogLinkButton);
  }

  hideBlogPageBalloon() {
    switch (this.blogPage) {
      case BlogPage.Search:
        this.$store.commit(
          "preference/setNoMoreShowSearchBlogPageBalloonOnboarding",
          true
        );
        break;
      case BlogPage.Filter:
        this.$store.commit(
          "preference/setNoMoreShowFilterBlogPageBalloonOnboarding",
          true
        );
        break;
      case BlogPage.UserIndexSmall:
        this.$store.commit(
          "preference/setNoMoreShowUserIndexSmallBlogPageBalloonOnboarding",
          true
        );
        break;
      case BlogPage.UserIndexLarge:
        this.$store.commit(
          "preference/setNoMoreShowUserIndexLargeBlogPageBalloonOnboarding",
          true
        );
        break;
      case BlogPage.UserDetail:
        this.$store.commit(
          "preference/setNoMoreShowUserDetailBlogPageBalloonOnboarding",
          true
        );
        break;
      case BlogPage.UserTrend:
        this.$store.commit(
          "preference/setNoMoreShowUserTrendBlogPageBalloonOnboarding",
          true
        );
        break;
      default:
    }
  }

  get isOnboardingBalloonValid(): boolean {
    switch (this.blogPage) {
      case BlogPage.Search:
        return !this.$store.state.preference
          .noMoreShowSearchBlogBalloonOnboarding;
      case BlogPage.Filter:
        return !this.$store.state.preference
          .noMoreShowFilterBlogBalloonOnboarding;
      case BlogPage.UserIndexSmall:
        return !this.$store.state.preference
          .noMoreShowUserIndexSmallBlogBalloonOnboarding;
      case BlogPage.UserIndexLarge:
        return !this.$store.state.preference
          .noMoreShowUserIndexLargeBlogBalloonOnboarding;
      case BlogPage.UserDetail:
        return !this.$store.state.preference
          .noMoreShowUserDetailBlogBalloonOnboarding;
      case BlogPage.UserTrend:
        return !this.$store.state.preference
          .noMoreShowUserTrendBlogBalloonOnboarding;
      default:
        return false;
    }
  }

  get blogPageLink(): string {
    switch (this.blogPage) {
      case BlogPage.Search:
        return BLOG_LINK_TO_SEARCH + BLOG_LINK_PARAMS;
      case BlogPage.Filter:
        return BLOG_LINK_TO_FILTER + BLOG_LINK_PARAMS;
      case BlogPage.UserIndexSmall:
        return BLOG_LINK_TO_USERS_WITH_FEW_USERS + BLOG_LINK_PARAMS;
      case BlogPage.UserIndexLarge:
        return BLOG_LINK_TO_USERS_WITH_MANY_USERS + BLOG_LINK_PARAMS;
      case BlogPage.UserDetail:
        return BLOG_LINK_TO_USER_DETAIL + BLOG_LINK_PARAMS;
      case BlogPage.UserTrend:
        return BLOG_LINK_TO_USER_TREND + BLOG_LINK_PARAMS;
      default:
        return "";
    }
  }

  get balloonTitle(): string {
    switch (this.blogPage) {
      case BlogPage.Search:
        return this.$i18n.t("searchTitle") as string;
      case BlogPage.Filter:
        return this.$i18n.t("filterTitle") as string;
      case BlogPage.UserIndexSmall:
        return this.$i18n.t("userIndexSmallTitle") as string;
      case BlogPage.UserIndexLarge:
        return this.$i18n.t("userIndexLargeTitle") as string;
      case BlogPage.UserDetail:
        return this.$i18n.t("userDetailTitle") as string;
      case BlogPage.UserTrend:
        return this.$i18n.t("userTrendTitle") as string;
      default:
        return "";
    }
  }

  get balloonDescription(): string {
    switch (this.blogPage) {
      case BlogPage.Search:
        return this.$i18n.t("searchDescription") as string;
      case BlogPage.Filter:
        return this.$i18n.t("filterDescription") as string;
      case BlogPage.UserIndexSmall:
        return this.$i18n.t("userIndexSmallDescription") as string;
      case BlogPage.UserIndexLarge:
        return this.$i18n.t("userIndexLargeDescription") as string;
      case BlogPage.UserDetail:
      case BlogPage.UserDetail:
        return this.$i18n.t("userDetailDescription") as string;
      case BlogPage.UserTrend:
        return this.$i18n.t("userTrendDescription") as string;
      default:
        return "";
    }
  }
}
</script>

<style lang="scss" scoped>
.blogLinkContainer {
  position: fixed;
  bottom: 30px;
  right: $appButtonRightPosition;
}

.blogLinkContainer__balloon {
  position: absolute;
  right: 0px;
  cursor: pointer;
  animation: balloonAnimation 0.8s ease-in-out infinite alternate;
}

.blogLinkContainer__button {
  display: block;
  outline: none;
}

.blogLinkContainerSlide-enter-active,
.blogLinkContainerSlide-leave-active {
  transition: right 0.15s ease, opacity 0.15s ease;
}

.blogLinkContainerSlide-enter,
.blogLinkContainerSlide-leave-to {
  opacity: 0;
  right: 0;
}

.blogLinkContainerSlide-enter-to,
.blogLinkContainerSlide-leave {
  opacity: 1;
  right: $appButtonRightPosition;
}

@keyframes balloonAnimation {
  0% {
    bottom: 65px;
  }
  100% {
    bottom: 70px;
  }
}
</style>
