import { MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { ApiRes } from "@/api/api-res";
import { UserInfo } from "@/models/UserInfo";

// ホームパネルに表示される顔アイコン数
const SEARCH_HISTORY_PANEL_LIMIT: number = 6;

export class UserInfoState {
  userInfos: UserInfo[] = [];
  isFetched: boolean = false;
}

const mutations: MutationTree<UserInfoState> = {
  setUserInfoList(state, userInfos: UserInfo[]) {
    state.userInfos = userInfos;
  },
  addUserInfo(state, userInfo: UserInfo) {
    const newUserInfos = state.userInfos.filter(
      i => i.userId !== userInfo.userId
    );
    newUserInfos.push(userInfo);
    state.userInfos = newUserInfos;
  },
  setIsFetched(state, isFetched: boolean) {
    state.isFetched = isFetched;
  }
};

const actions: ActionTree<UserInfoState, RootState> = {
  async fetchUserInfo({ commit, rootState }) {
    commit("setIsFetched", false);

    // 顔アイコンが表示されるのは、SEARCH_HISTORY_PANEL_LIMIT分のユーザだけなので、そのユーザ分のuserInfoをロードする
    const favoriteUserIds: string[] = rootState.favorite.favorites
      .slice(0, SEARCH_HISTORY_PANEL_LIMIT)
      .map(favorite => favorite.userId);

    const userHistoryUserids: string[] = rootState.history.userHistories
      .slice(0, SEARCH_HISTORY_PANEL_LIMIT)
      .map(userHistory => userHistory.userId);

    // お気に入りされたユーザ、閲覧履歴のユーザIDを取得
    // 閲覧履歴を実装していないので同じユーザに対しても処理は別チケット対応
    const userIds: string[] = favoriteUserIds.concat(userHistoryUserids);

    if (userIds.length > 0) {
      const response: ApiRes.UserInfo[] = await rootState.api.userdata.getUserInfoList(
        userIds
      );

      const userInfoList: UserInfo[] = response.map(UserInfo.fromJson);

      commit("setUserInfoList", userInfoList);
    }
    commit("setIsFetched", true);
  }
};

export const userInfo = {
  namespaced: true,
  state: new UserInfoState(),
  mutations: mutations,
  actions: actions
};
