<i18n src="@/i18n/components/funnel/funnel-user-activity-dialog.json"></i18n>
<template>
  <Dialog
    :visible="visible"
    :show-close-button="false"
    width="980px"
    top="15vh"
    @open="onOpen"
    @close="onClickCancel"
  >
    <template #title>
      <div class="funnelUserActivityDialog__title" v-text="title" />
    </template>
    <div class="funnelUserActivityDialog__condition">
      <FunnelConditionSelectForm
        :model-value="form"
        :active-conversion-definitions="activeConversionDefinitions"
        :business-event-definitions="businessEventDefinitions"
        :event-definitions="eventDefinitions"
        :contact-definitions="contactDefinitions"
        :conversion-attribute-definitions="conversionAttributeDefinitions"
        :search-engines="searchEngines"
        :can-use-webdata-features="canUseWebdataFeatures"
        :is-contract-app="isContractApp"
        @update:modelValue="form = $event"
      />

      <div class="funnelUserActivityDialog__numActivity">
        {{ $t("setNumActivity") }}
        <SelectBox
          v-model="times"
          class="funnelUserActivityDialog__selectBox"
          width="90px"
          :options="timesOptions"
        />
        <InputText
          v-if="showAnyTimeInput"
          v-model="anyTimes"
          placeholder="0~99"
          class="funnelUserActivityDialog__input"
        />
        <span v-if="showAnyTimeInput">{{ $t("time") }}</span>
        <SelectBox
          v-model="comparison"
          class="funnelUserActivityDialog__selectBox"
          width="120px"
          :options="comparisonOptions"
        />
      </div>

      <div class="funnelUserActivityDialog__period">
        {{ $t("setPrefilterPeriod") }}
        <DateRangePickerInput
          :value="prefilterDateValue"
          :enabled-period="enabledPeriod"
          :show-period-choices="false"
          class="funnelUserActivityDialog__datePicker"
          @input="onInputPrefilterPeriod"
        />

        <Tooltip
          class="funnelUserActivityDialog__help"
          :placement="TooltipPlacement.BottomStart"
          :wrap="true"
        >
          <Icon
            :icon="Icons.HelpInvertWhite"
            :color="Colors.Base700"
            :size="12"
          />
          <template #content>
            <div class="funnelUserActivityDialog__helpContent">
              <span v-html="$t('periodSettingHelpContent1')" />
              <ul>
                <li v-t="'periodSettingHelpContent2'" />
                <li v-t="'periodSettingHelpContent3'" />
                <li v-t="'periodSettingHelpContent4'" />
              </ul>
            </div>
          </template>
        </Tooltip>
      </div>
    </div>

    <div class="funnelUserActivityDialog__bottons">
      <Button
        class="funnelUserActivityDialog__submitButton"
        :disabled="shouldDisableSubmitButton"
        @click="onClickSubmit"
        >{{ submitButtonTitle }}
      </Button>
      <Button
        v-t="'cancel'"
        class="funnelUserActivityDialog__cancelButton"
        type="light"
        @click="onClickCancel"
      />
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue, Watch } from "vue-property-decorator";
import { DateRange } from "@/components/date-picker/DateRange";
import DateRangePickerInput from "@/components/date-picker/DateRangePickerInput.vue";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";
import FunnelConditionSelectForm from "@/components/funnel/FunnelConditionSelectForm.vue";
import Button from "@/components/Button.vue";
import Tooltip from "@/components/Tooltip.vue";
import { TooltipPlacement } from "@/const/tooltip";
import Icon from "@/components/Icon.vue";
import InputText from "@/components/form/InputText.vue";
import Dialog from "@/components/dialog/Dialog.vue";
import { FunnelType, InflowOrganicSearchField } from "@/const/funnel";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import {
  FunnelConditionDefault,
  FunnelConditionPageTitleOrURL,
  FunnelEdge,
  FunnelConditionInflow,
  FunnelConditionInflowParam,
  FunnelConditionInflowOrganic,
  InflowOrganicLocationCondition
} from "@/models/funnel/FunnelCondition";
import {
  FunnelConditionActivity,
  ComparisonType
} from "@/models/funnel/FunnelConditionActivity";
import {
  defaultConditionSelectForm,
  conditionSelectFormFromCondition,
  isPageTitleOrURLTypeForm,
  isInflowTypeForm,
  isInflowParamTypeForm,
  isInflowOrganicTypeForm,
  FunnelInflowType
} from "@/models/funnel/FunnelConditionSelectForm";
import { usergramEnabledPeriodRange } from "@/util/date-range-picker-util";
import { showAlert } from "@/util/modal-util";
import { SearchEngine } from "@/models/system/SearchEngine";

@Component({
  components: {
    SelectBox,
    Button,
    Tooltip,
    Icon,
    InputText,
    Dialog,
    DateRangePickerInput,
    FunnelConditionSelectForm
  }
})
export default class FunnelUserActivityDialog extends Vue {
  // 新規追加ではなく、編集の場合に対象となる検索条件
  @Prop({ type: Object, default: null })
  activityCondition!: FunnelConditionActivity | null;

  @Prop({ type: Boolean, default: false })
  visible!: boolean;

  @Prop({ type: Array, required: true })
  activeConversionDefinitions!: ConversionDefinition[];

  @Prop({ type: Array, required: true })
  eventDefinitions!: EventDefinition[];

  @Prop({ type: Array, required: true })
  businessEventDefinitions!: BusinessEventDefinition[];

  @Prop({ type: Array, required: true })
  contactDefinitions!: ContactDefinition[];

  @Prop({ type: Array, required: true })
  conversionAttributeDefinitions!: ConversionAttributeDefinition[];

  @Prop({ type: Array, required: true })
  searchEngines!: SearchEngine[];

  @Prop({ type: Boolean, required: true })
  canUseWebdataFeatures!: boolean;

  @Prop({ type: Boolean, required: true })
  isContractApp!: boolean;

  Icons = Icons;
  Colors = Colors;
  TooltipPlacement = TooltipPlacement;

  @Emit("cancel")
  onClickCancel() {}

  @Watch("activityCondition")
  onChangeActivityCondition(value: FunnelConditionActivity | null) {
    if (value === null) {
      this.isEditedOnce = false;
    }
  }

  onClickSubmit() {
    if (!this.prefilterDateValue) return;

    const condition = this.createCondition(this.form, this.prefilterDateValue);
    const validationResult = condition.validate(false, false);
    if (!validationResult.isValid) {
      showAlert(validationResult.errorMessage);
      return;
    }
    const anyTimesInNumber = Number(this.anyTimes);
    if (
      this.showAnyTimeInput &&
      (isNaN(anyTimesInNumber) || anyTimesInNumber < 0 || anyTimesInNumber > 99)
    ) {
      showAlert(this.$t("anyTimesInvalidMessage") as string);
      return;
    }

    const activityCondition = new FunnelConditionActivity(
      this.showAnyTimeInput ? anyTimesInNumber : this.times,
      this.comparison,
      condition
    );
    this.$emit("submit", activityCondition);
  }

  createCondition(form: typeof this.form, period: DateRange) {
    if (isPageTitleOrURLTypeForm(form)) {
      return new FunnelConditionPageTitleOrURL(
        form.type,
        period.min,
        period.max,
        form.value,
        false,
        form.wordMatchMethod,
        form.searchField,
        FunnelEdge.defaultCondition()
      );
    } else if (isInflowTypeForm(form)) {
      return new FunnelConditionInflow(
        FunnelType.Inflow,
        period.min,
        period.max,
        false,
        form.matchConditions,
        FunnelEdge.defaultCondition()
      );
    } else if (isInflowParamTypeForm(form)) {
      return new FunnelConditionInflowParam(
        FunnelType.InflowParam,
        period.min,
        period.max,
        false,
        form.inflowParam,
        FunnelEdge.defaultCondition()
      );
    } else if (isInflowOrganicTypeForm(form)) {
      return new FunnelConditionInflowOrganic(
        FunnelType.InflowOrganic,
        period.min,
        period.max,
        false,
        this.searchEngines,
        form.matchConditions,
        FunnelEdge.defaultCondition()
      );
    } else {
      return new FunnelConditionDefault(
        form.type,
        period.min,
        period.max,
        form.value,
        false,
        form.attributes,
        FunnelEdge.defaultCondition()
      );
    }
  }

  isEditedOnce = false;

  form = defaultConditionSelectForm([]);
  times = 1;
  anyTimes: string = "";

  comparison: ComparisonType = ComparisonType.greaterThanOrEqualTo;
  prefilterDateValue: DateRange | null = null;

  mounted() {
    this.setDefaultValues();
  }

  get enabledPeriod(): DateRange {
    return usergramEnabledPeriodRange();
  }

  get isEdit(): boolean {
    return this.activityCondition !== null;
  }

  get title(): string {
    return this.isEdit
      ? (this.$t("editUserActivity") as string)
      : (this.$t("addUserActivity") as string);
  }

  get submitButtonTitle(): string {
    return this.isEdit
      ? (this.$t("editCondition") as string)
      : (this.$t("addCondition") as string);
  }

  get shouldDisableSubmitButton(): boolean {
    if (!this.prefilterDateValue) return true;
    if (this.showAnyTimeInput && this.anyTimes.trim() === "") return true;
    if (this.form.type === FunnelType.PV && !this.form.value) return true;
    if (this.form.type === FunnelType.Screen && !this.form.value) return true;

    if (
      this.form.type === FunnelType.Inflow &&
      this.form.inflowType === FunnelInflowType.organicSearch
    ) {
      if (this.form.matchConditions.length === 0) return false;

      return this.form.matchConditions
        .filter(mc => mc.searchField !== InflowOrganicSearchField.SEARCH_ENGINE)
        .some(mc => (mc as InflowOrganicLocationCondition).value === "");
    }

    if (
      this.form.type === FunnelType.Inflow &&
      this.form.inflowType === FunnelInflowType.referrerOrLandingPage
    ) {
      return this.form.matchConditions.some(mc => mc.value === "");
    }

    if (
      this.form.type === FunnelType.Inflow &&
      this.form.inflowType === FunnelInflowType.inflowParameter
    ) {
      return !this.form.inflowParam;
    }

    return false;
  }

  get timesOptions(): SelectOption[] {
    const limitTimes = [0, 1, 2, 3, 4, 5].map(n => {
      return {
        value: n,
        label: this.$tc("timeCount", n).toString(),
        disabled: false
      };
    });
    const anyTime = {
      value: 6,
      label: this.$tc("any").toString(),
      disabled: false
    };
    return [...limitTimes, anyTime];
  }

  get showAnyTimeInput(): boolean {
    return this.times > 5;
  }

  get comparisonOptions(): SelectOption[] {
    return [
      {
        value: ComparisonType.greaterThanOrEqualTo,
        label: this.$t("greaterThanOrEqualTo").toString(),
        disabled: false
      },
      {
        value: ComparisonType.lessThanOrEqualTo,
        label: this.$t("lessThanOrEqualTo").toString(),
        disabled: false
      },
      {
        value: ComparisonType.equalTo,
        label: this.$t("equalTo").toString(),
        disabled: false
      }
    ];
  }

  setDefaultValues() {
    if (this.activityCondition) {
      const condition = this.activityCondition.condition;
      this.form = conditionSelectFormFromCondition(condition);
      this.times = this.activityCondition.times;
      if (this.activityCondition.times > 5) {
        // Any option
        this.times = 6;
        this.anyTimes = String(this.activityCondition.times);
      }
      this.comparison = this.activityCondition.comparison;
      this.prefilterDateValue = {
        min: condition.startDate,
        max: condition.endDate
      };
    } else if (!this.isEditedOnce) {
      this.form = defaultConditionSelectForm(this.activeConversionDefinitions);
      this.times = 1;
      this.anyTimes = "";
      this.comparison = ComparisonType.greaterThanOrEqualTo;
      this.prefilterDateValue = null;
    }

    this.isEditedOnce = true;
  }

  onInputPrefilterPeriod(value: DateRange | null) {
    if (value !== null) {
      this.prefilterDateValue = value;
    }
  }

  onOpen() {
    this.setDefaultValues();
  }
}
</script>

<style lang="scss" scoped>
.funnelUserActivityDialog__title {
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.funnelUserActivityDialog__condition {
  margin-bottom: 80px;
  min-height: 220px;
}
.funnelUserActivityDialog__numActivity {
  display: flex;
  align-items: center;
  margin-top: 15px;

  .funnelUserActivityDialog__selectBox {
    margin-left: 10px;
  }

  .funnelUserActivityDialog__input {
    width: 52px;
    margin: 0 10px;
  }
}
.funnelUserActivityDialog__period {
  display: flex;
  align-items: center;
  margin-top: 15px;

  .funnelUserActivityDialog__datePicker {
    margin-left: 10px;
  }
}

.funnelUserActivityDialog__bottons {
  display: flex;
}

.funnelUserActivityDialog__submitButton {
  flex: 1;
  margin-right: 10px;
}

.funnelUserActivityDialog__cancelButton {
  flex: 1;
  margin-left: 10px;
}

.funnelUserActivityDialog__help {
  margin-left: 10px;
}

.funnelUserActivityDialog__helpContent {
  width: 324px;
  word-break: normal;
  padding-bottom: 3px;
  padding-top: 3px;
}
.funnelUserActivityDialog__helpContent ul {
  padding-left: 14px;
  list-style: disc;
}
</style>
