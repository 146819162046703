<template>
  <div class="funnelInputTitle">
    <div v-if="icon !== null" class="funnelInputTitle__icon">
      <Icon :icon="icon" :color="COLORS.Base900" :size="15" />
    </div>
    <div class="funnelInputTitle__title">{{ title }}</div>
    <div
      class="funnelInputTitle__note"
      :class="{ 'funnelInputTitle__note--error': hasError }"
    >
      {{ note }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";

@Component({
  components: {
    Icon
  }
})
export default class FunnelInputTitle extends Vue {
  COLORS = Colors;

  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, default: "" })
  note!: string;

  @Prop({ type: Boolean, default: false })
  hasError!: boolean;

  @Prop({ type: String, default: null })
  icon!: string;
}
</script>

<style lang="scss" scoped>
.funnelInputTitle {
  display: flex;
  padding-bottom: 10px;
  color: $colorBase900;
  align-items: center;
}
.funnelInputTitle__icon {
  padding-right: 10px;
}
.funnelInputTitle__title {
  font-size: 16px;
  font-weight: bold;
}
.funnelInputTitle__note {
  font-size: 12px;
  padding-left: 10px;
}
.funnelInputTitle__note--error {
  color: $colorError;
}
</style>
