import {
  FilterNodeConditionType,
  filterInputCheck
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";

/**
 * 広告グループ名による条件
 *
 * 広告での絞り込みに、広告グループ名を条件として付与できる
 *
 */
export class FilterAdGroupCondition {
  public readonly conditionType = FilterNodeConditionType.AdGroup;

  constructor(public readonly groupName: string) {}

  get validate(): ValidationResult {
    return filterInputCheck(
      this.groupName,
      i18n.t("models.search.adGroupName") as string
    );
  }
}
