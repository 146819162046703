import { render, staticRenderFns } from "./FunnelResultRow.vue?vue&type=template&id=d888b2c6&scoped=true"
import script from "./FunnelResultRow.vue?vue&type=script&lang=ts"
export * from "./FunnelResultRow.vue?vue&type=script&lang=ts"
import style0 from "./FunnelResultRow.vue?vue&type=style&index=0&id=d888b2c6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d888b2c6",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/funnel/funnel-result-row.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Ffunnel%2FFunnelResultRow.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports