<template>
  <component :is="layout" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import DefaultLayoutV1 from "@/layouts/DefaultLayoutV1.vue";
import DefaultLayoutV3 from "@/layouts/DefaultLayoutV3.vue";

@Component({
  components: {
    DefaultLayoutV1,
    DefaultLayoutV3
  }
})
export default class DefaultLayout extends Vue {
  get canUseNewSideBar(): boolean {
    return this.$store.state.app.canUseNewSideBar;
  }

  get layout(): string {
    return this.canUseNewSideBar ? "DefaultLayoutV3" : "DefaultLayoutV1";
  }
}
</script>
