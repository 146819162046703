import { MutationTree, GetterTree, ActionTree } from "vuex";
import { i18n } from "@/i18n";
import { RootState } from "@/store/";
import { sleep, generateUniqueId } from "@/util/common-util";
import { TimeoutError } from "@/util/error-util";

import {
  AnalysisType,
  UserTrendColumnType,
  UserTrendDataType,
  DEFAULT_PER_PAGE
} from "@/const/user-trend";
import {
  UserTrendRowJsonData,
  UserTrendResultResponse,
  UserTrendScatterDataResponce,
  UserTrendStatus
} from "@/api/apis/ApiUserTrend";
import { UserTrendRowData } from "@/models/user-trend/UserTrendRowData";
import { UserTrendFilterCondition } from "@/models/user-trend/UserTrendFilterCondition";
import { UserTrendChartPoint } from "@/models/user-trend/UserTrendChartPoint";
import { DateFormat, formatDate } from "@/util/date-util";

// 再度APIを叩くまでの待機時間を2秒とする
const FETCH_INTERVAL_MSEC = 2000;
// 120秒(2秒 x 60回)で結果が返らない場合はタイムアウトとする
const TIMEOUT_COUNT = 60;
// デフォルトでは 1ページ目 を表示させる
const DEFAULT_PAGE = 1;
// 存在するデータのIDとは被らないデフォルトのID
const USER_TRENDS_UNSELECTED_VALUE = -1;
// scatterJsonはdefaultでは空のデータを格納
export const DEFAULT_SCATTER_JSON = { data: [] };

const analysisNameMap = {
  [AnalysisType.WebView]: "store.userTrends.webViewAnalysis",
  [AnalysisType.Landing]: "store.userTrends.landingPageAnalysis",
  [AnalysisType.Inflow]: "store.userTrends.inflowAnalysis",
  [AnalysisType.InflowDetail]: "store.userTrends.inflowAnalysis",
  [AnalysisType.AppView]: "store.userTrends.appViewAnalysis"
};

// 利用傾向分析のstateクラス
export class UserTrendBaseState {
  resultJson: UserTrendRowJsonData[] = [];
  isLoading: boolean = false;
  perPage: number = DEFAULT_PER_PAGE;
  currentPage: number = DEFAULT_PAGE;
  currentId: number = USER_TRENDS_UNSELECTED_VALUE;
  totalRows: number = 0;
  domain: string | null = null;
  csvDownloading: boolean = false;
  sortColumn: UserTrendColumnType;
  sortKey: UserTrendDataType = UserTrendDataType.base;
  order: "asc" | "desc" = "desc";
  filter: UserTrendFilterCondition = UserTrendFilterCondition.defaultFilter;

  scatterJson: UserTrendScatterDataResponce = DEFAULT_SCATTER_JSON;
  dataType: UserTrendDataType = UserTrendDataType.base;

  lastExecuteId: string | null = null;

  // インスタンス毎に異なるanalysisTypeを持つ
  constructor(readonly analysisType: AnalysisType) {
    this.sortColumn =
      analysisType === AnalysisType.WebView ||
      analysisType === AnalysisType.AppView
        ? UserTrendColumnType.count
        : UserTrendColumnType.visit;
  }
}

const mutations = <MutationTree<UserTrendBaseState>>{
  setResults(state: UserTrendBaseState, resultJson: UserTrendRowJsonData[]) {
    state.resultJson = resultJson;
  },
  setIsLoading(state: UserTrendBaseState, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  setPerPage(state: UserTrendBaseState, perPage: number) {
    state.perPage = perPage;
  },
  setCurrentPage(state: UserTrendBaseState, currentPage: number) {
    state.currentPage = currentPage;
  },
  setCurrentId(state: UserTrendBaseState, id: number) {
    state.currentId = id;
  },
  setDomain(state: UserTrendBaseState, domain: string | null) {
    state.domain = domain;
  },
  resetData(state: UserTrendBaseState) {
    state.resultJson = [];
    state.isLoading = false;
    state.currentPage = DEFAULT_PAGE;
    state.domain = null;
    state.totalRows = 0;
    state.sortColumn =
      state.analysisType === AnalysisType.WebView ||
      state.analysisType === AnalysisType.AppView
        ? UserTrendColumnType.count
        : UserTrendColumnType.visit;
    state.sortKey = UserTrendDataType.base;
    state.order = "desc";
    state.filter = UserTrendFilterCondition.defaultFilter;
    state.scatterJson = DEFAULT_SCATTER_JSON;
    state.dataType = UserTrendDataType.base;
    state.lastExecuteId = null;
  },
  setTotalRows(state: UserTrendBaseState, totalRows: number) {
    state.totalRows = totalRows;
  },
  setCsvDownloading(state: UserTrendBaseState, csvDownloading: boolean) {
    state.csvDownloading = csvDownloading;
  },
  setSortColumn(state: UserTrendBaseState, sortColumn: UserTrendColumnType) {
    state.sortColumn = sortColumn;
  },
  setSortKey(state: UserTrendBaseState, sortKey: UserTrendDataType) {
    state.sortKey = sortKey;
  },
  setSortOrder(state: UserTrendBaseState, sortOrder: "asc" | "desc") {
    state.order = sortOrder;
  },
  setFilter(state: UserTrendBaseState, filter: UserTrendFilterCondition) {
    state.filter = filter;
  },
  setScatterJson(
    state: UserTrendBaseState,
    json: UserTrendScatterDataResponce
  ) {
    state.scatterJson = json;
  },
  setDataType(state: UserTrendBaseState, dataType: UserTrendDataType) {
    state.dataType = dataType;
  },
  setLastExecuteId(state, executeId: string) {
    state.lastExecuteId = executeId;
  }
};

const getters = <GetterTree<UserTrendBaseState, RootState>>{
  results(state): UserTrendRowData[] {
    return state.resultJson.map(UserTrendRowData.fromJson);
  },
  chartPoints(state): UserTrendChartPoint[] {
    return state.scatterJson.data.map(UserTrendChartPoint.fromJson);
  },
  baseState(state): UserTrendBaseState {
    return state;
  }
};

const actions = <ActionTree<UserTrendBaseState, RootState>>{
  async executeTable(
    { dispatch, state, commit },
    setQueryIdToUrl?: (queryId: string) => void
  ) {
    // すでにデータがある場合は新しいデータを取得しない
    // ロード中は再度フェッチさせない
    if (state.resultJson.length > 0 || state.isLoading) {
      return;
    }

    const executeId = generateUniqueId();
    commit("setLastExecuteId", executeId);
    await dispatch("initialize");
    await dispatch("create");

    if (setQueryIdToUrl) {
      setQueryIdToUrl(String(state.currentId));
    }

    const isValid: boolean = await dispatch("checkStatus", executeId);
    if (isValid) {
      await dispatch("fetchResults");
    }
  },
  async executeScatter(
    { dispatch, state, commit },
    setQueryIdToUrl?: (queryId: string) => void
  ) {
    // すでにデータがある場合は新しいデータを取得しない
    // ロード中は再度フェッチさせない
    if (state.scatterJson.data.length > 0 || state.isLoading) {
      return;
    }

    const executeId = generateUniqueId();
    commit("setLastExecuteId", executeId);
    await dispatch("initializeScatter");
    await dispatch("create");

    if (setQueryIdToUrl) {
      setQueryIdToUrl(String(state.currentId));
    }

    const isValid: boolean = await dispatch("checkStatus", executeId);
    if (isValid) {
      await dispatch("fetchScatter");
    }
  },
  async initialize({ commit }) {
    commit("setResults", []);
    commit("setIsLoading", true);
  },
  async initializeScatter({ commit }) {
    commit("setScatterJson", DEFAULT_SCATTER_JSON);
    commit("setIsLoading", true);
  },
  async create({ state, commit, rootState }) {
    const response: {
      id: number;
    } = await rootState.api.userTrends.createNew(
      state.analysisType,
      rootState.userTrends.condition,
      state.domain
    );

    commit("setCurrentId", response.id);
  },
  async checkStatus({ state, rootState }, executeId: string) {
    const currentId = state.currentId;
    let count = 0;

    // 結果データが作成されるまで2秒おきに status を確認
    let { status } = await rootState.api.userTrends.getStatus(
      state.analysisType,
      currentId
    );

    while (status === UserTrendStatus.RUNNING) {
      // タイムアウト処理
      if (count > TIMEOUT_COUNT) {
        throw new TimeoutError(i18n.t("store.userTrends.apiTimeout") as string);
      }

      await sleep(FETCH_INTERVAL_MSEC);

      ({ status } = await rootState.api.userTrends.getStatus(
        state.analysisType,
        currentId
      ));
      count++;

      // 新しい解析が実行された場合はキャンセル
      if (executeId != state.lastExecuteId) {
        return false;
      }
    }

    if (executeId != state.lastExecuteId) {
      return false;
    }

    if (status == UserTrendStatus.FAILED) {
      throw new Error();
    }

    return status == UserTrendStatus.OK;
  },
  async fetchResults({ dispatch, commit, state, rootState }) {
    dispatch("initialize");

    const response: UserTrendResultResponse = await rootState.api.userTrends.getResults(
      state.analysisType,
      state.currentId,
      state.sortKey === UserTrendDataType.base
        ? state.sortColumn
        : state.sortColumn + "Comp",
      state.order,
      state.currentPage,
      state.perPage,
      state.filter
    );

    commit("setResults", response.data);
    if (state.totalRows === 0) {
      commit("setTotalRows", response.num_rows);
    }
    commit("setIsLoading", false);
  },
  async fetchScatter({ dispatch, commit, state, rootState }) {
    dispatch("initializeScatter");

    const response: UserTrendScatterDataResponce = await rootState.api.userTrends.getScatter(
      state.analysisType,
      state.dataType,
      state.currentId,
      state.filter
    );

    commit("setScatterJson", response);
    commit("setIsLoading", false);
  },
  async downloadCsv({ commit, state, rootState }) {
    commit("setCsvDownloading", true);

    const response: string = await rootState.api.userTrends.getCsv(
      state.analysisType,
      state.currentId,
      state.filter
    );

    let filename: string = i18n.t(
      "store.userTrends.csvFileNamePrefix"
    ) as string;
    filename += (i18n.t(analysisNameMap[state.analysisType]) as string) + "_";
    filename += formatDate(DateFormat.yyyyMMdd_HHmmss, new Date()) + ".csv";

    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(
      new Blob(["\ufeff", response], { type: "text/csv;" })
    );

    link.download = filename;

    document.body.appendChild(link); // for Firefox etc.
    link.click();
    document.body.removeChild(link); // for Firefox etc.

    commit("setCsvDownloading", false);
  }
};

export const webView = {
  namespaced: true,
  state: new UserTrendBaseState(AnalysisType.WebView),
  mutations,
  getters,
  actions
};

export const landing = {
  namespaced: true,
  state: new UserTrendBaseState(AnalysisType.Landing),
  mutations,
  getters,
  actions
};

export const inflow = {
  namespaced: true,
  state: new UserTrendBaseState(AnalysisType.Inflow),
  mutations,
  getters,
  actions
};

export const inflowDetail = {
  namespaced: true,
  state: new UserTrendBaseState(AnalysisType.InflowDetail),
  mutations,
  getters,
  actions
};

export const appView = {
  namespaced: true,
  state: new UserTrendBaseState(AnalysisType.AppView),
  mutations,
  getters,
  actions
};
