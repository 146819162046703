<template>
  <div class="tourPanelItem">
    <div class="tourPanelItem__iconContainer">
      <Icon :icon="icon" :size="iconSize" :color="iconColor" />
    </div>

    <div class="tourPanelItem__title" v-text="title" />
  </div>
</template>

<script lang="ts">
import { Colors } from "@/const/Colors";
import { Vue, Component, Prop } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";

@Component({
  components: {
    Icon
  }
})
export default class TourPanelItem extends Vue {
  iconColor = Colors.White;
  iconSize = 14;

  @Prop({ type: String, required: true })
  icon!: string;

  @Prop({ type: String, required: false, default: "" })
  title!: string;
}
</script>

<style lang="scss" scoped>
.tourPanelItem {
  display: flex;
  align-items: center;
  background: $colorWhite;
  height: 36px;
}

.tourPanelItem__iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: $colorTourBase;
}

.tourPanelItem__title {
  flex: 1;
  margin-left: 10px;
  color: $colorBase900;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
}
</style>
