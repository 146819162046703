<template>
  <div
    class="menu-panel"
    :class="{ 'menu-panel--clickable': clickable }"
    data-cy="menu-panel"
    v-on="$listeners"
  >
    <div
      class="menu-panel__draggable-area"
      @mouseenter="isMouseHover = true"
      @mouseleave="isMouseHover = false"
      @click.capture.stop
    >
      <IconButton :icon="icnos.Home" :size="14" />
    </div>
    <div class="menu-panel__header">
      <div v-if="labelIcon" class="menu-panel__header__label-icon">
        <Icon :icon="labelIcon" size="15" />
      </div>
      <slot name="header"></slot>
    </div>
    <div v-if="!isLoading" class="menu-panel__content">
      <slot></slot>
    </div>
    <div v-else class="menu-panel__loading">
      <img src="@/assets/img/loading_circle.gif" />
    </div>
    <div class="menu-panel__action-icon">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import IconButton from "@/components/IconButton.vue";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";

@Component({
  components: {
    Icon,
    IconButton
  }
})
export default class MenuPanel extends Vue {
  icnos = Icons;
  isMouseHover: boolean = false;

  @Prop({ type: String })
  labelIcon?: Icons;

  @Prop({ type: Boolean, default: true })
  clickable!: boolean;

  @Prop({ type: Boolean, default: false })
  isLoading!: boolean;
}
</script>

<style lang="scss" scoped>
.menu-panel {
  position: relative;
  margin: 0 8px 16px;
  padding: 40px 25px 45px;
  width: 400px;
  height: 400px;
  border-radius: $sizeRadius;
  background-color: $colorGray100;
  text-align: center;
  transition: background-color 0.5s ease;
  transition: 0.5s ease;

  &__draggable-area {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 8px;
    cursor: move;
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      background-color: $colorBlue600;
    }
  }

  &__header {
    position: relative;
    display: inline-flex;
    align-items: center;
    min-width: 10px;
    height: 25px;
    font-weight: bold;
    font-size: 18px;

    &__label-icon {
      position: absolute;
      top: 50%;
      right: calc(100% + 8px);
      transform: translateY(-50%);
    }
  }

  &__action-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    & img {
      width: 38px;
      height: 38px;
    }
  }
}

.menu-panel--dragging {
  .menu-panel {
    padding: 10px 10px;
    height: 50px;
    .menu-panel__content {
      display: none;
    }
    &__action-icon {
      display: none;
    }
    &__header {
      margin-top: 2px;
    }
    &:hover {
      background-color: inherit;
    }
  }
}
</style>
