<i18n src="@/i18n/components/funnel/funnel-user-activity-condition.json" />
<template>
  <FunnelAddableConditionWrapper
    class="funnelUserActivityCondition"
    :button-text="$t('addUserActivity').toString()"
    :button-note="$t('addUserActivityNote').toString()"
    :can-add="canAddUserActivity"
    :added-conditions="addedConditions"
    @add="addUserActivity"
    @edit="editUserActivity"
    @delete="deleteUserActivity"
  >
    <FunnelUserActivityDialog
      :activity-condition="editingUserActivity"
      :visible="showUserActivityDialog"
      :active-conversion-definitions="cvDefs"
      :event-definitions="eventDefs"
      :business-event-definitions="beDefs"
      :contact-definitions="contactDefs"
      :conversion-attribute-definitions="attrDefs"
      :search-engines="searchEngines"
      :can-use-webdata-features="canUseWebdataFeatures"
      :is-contract-app="isContractApp"
      @submit="onSubmitUserActivity"
      @cancel="onCancelUserActivity"
    />
  </FunnelAddableConditionWrapper>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "@/composables/useI18n";
import FunnelAddableConditionWrapper, {
  AddedCondition
} from "@/components/funnel/FunnelAddableConditionWrapper.vue";
import FunnelUserActivityDialog from "@/components/funnel/FunnelUserActivityDialog.vue";
import { FunnelType } from "@/const/funnel";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import {
  FunnelConditionDefault,
  FunnelConditionInflow,
  FunnelConditionInflowOrganic,
  FunnelConditionInflowParam,
  FunnelConditionPageTitleOrURL
} from "@/models/funnel/FunnelCondition";
import {
  ComparisonType,
  FunnelConditionActivity
} from "@/models/funnel/FunnelConditionActivity";
import { DateFormat, formatDate } from "@/util/date-util";
import {
  getFunnelDataAttributeDefinitionLabel,
  getFunnelDataDefinitionLabel
} from "@/util/funnel-util";
import { SearchEngine } from "@/models/system/SearchEngine";

const { t, tc } = useI18n();

const props = defineProps<{
  userActivities: FunnelConditionActivity[];
  cvDefs: ConversionDefinition[];
  eventDefs: EventDefinition[];
  beDefs: BusinessEventDefinition[];
  contactDefs: ContactDefinition[];
  attrDefs: ConversionAttributeDefinition[];
  searchEngines: SearchEngine[];
  canUseWebdataFeatures: boolean;
  isContractApp: boolean;
}>();

const emit = defineEmits<{
  (e: "change", userActivities: FunnelConditionActivity[]): void;
}>();

const showUserActivityDialog = ref(false);

const editingUserActivity = computed(
  (): FunnelConditionActivity | null => props.userActivities[0] || null
);

const canAddUserActivity = computed(() => {
  return props.userActivities.length === 0;
});

const addedConditions = computed(() => {
  return props.userActivities.map<AddedCondition>(ua => ({
    label: getLabel(ua),
    displayValue: getDisplayValue(ua)
  }));
});

function getDefinitionByType(type: FunnelType) {
  if (type === FunnelType.CV) return props.cvDefs;
  if (type === FunnelType.Event) return props.eventDefs;
  if (type === FunnelType.BusinessEvent) return props.beDefs;
  if (type === FunnelType.Contact) return props.contactDefs;
}

function getLabel(ua: FunnelConditionActivity) {
  const condition = ua.condition;
  const type = condition.conditionType;

  const preFilterType = t("userActivity").toString();
  const numOfTimes = tc(ComparisonType[ua.comparison], ua.times);

  if (condition instanceof FunnelConditionPageTitleOrURL) {
    const activityType = condition.prefix.replace(": ", "");
    const matchMethod = condition.wordMatchString;
    return `${preFilterType}: ${activityType} "${condition.value}" (${matchMethod}) (${numOfTimes})`;
  }

  if (
    condition instanceof FunnelConditionInflow ||
    condition instanceof FunnelConditionInflowParam
  ) {
    const displayValue = condition.displayValueForResult.join(", ");
    return `${preFilterType}: ${condition.title} "${displayValue}" (${numOfTimes})`;
  }

  if (condition instanceof FunnelConditionInflowOrganic) {
    const baseResult = `${preFilterType}: ${condition.title}`;
    const displayValue = condition.displayValueForResult.join(", ");

    return displayValue.length > 0
      ? `${baseResult} "${displayValue}" (${numOfTimes})`
      : `${baseResult} (${numOfTimes})`;
  }

  const activityType = t(FunnelType[type].toLocaleLowerCase()).toString();
  const defs = getDefinitionByType(type) || [];
  const defName = getFunnelDataDefinitionLabel(defs, [condition.value], type);
  return `${preFilterType}: ${activityType} "${defName}" (${numOfTimes})`;
}

function getDisplayValue(ua: FunnelConditionActivity) {
  const startDate = formatDate(DateFormat.yyyysMsd, ua.condition.startDate);
  const endDate = formatDate(DateFormat.yyyysMsd, ua.condition.endDate);

  if (!(ua.condition instanceof FunnelConditionDefault))
    return `${startDate} - ${endDate}`;

  const attr = getFunnelDataAttributeDefinitionLabel(
    props.attrDefs,
    ua.condition.attributes,
    ua.condition.conditionType
  );

  return attr
    ? `${attr}, ${startDate} - ${endDate}`
    : `${startDate} - ${endDate}`;
}

function addUserActivity() {
  showUserActivityDialog.value = true;
}

function editUserActivity() {
  showUserActivityDialog.value = true;
}

function deleteUserActivity() {
  showUserActivityDialog.value = false;
  emit("change", []);
}

function onSubmitUserActivity(activity: FunnelConditionActivity) {
  showUserActivityDialog.value = false;
  emit("change", [activity]);
}

function onCancelUserActivity() {
  showUserActivityDialog.value = false;
}
</script>

<style lang="scss" scoped>
.funnelUserActivityCondition {
  margin-top: 10px;
}
</style>
