<i18n src="@/i18n/components/home-menu/almost-cv.json"></i18n>
<template>
  <div class="menuPanelAlmostCvEmpty">
    <div class="menuPanelAlmostCvEmpty__title">{{
      $t("noConversionTitle")
    }}</div>
    <div class="menuPanelAlmostCvEmpty__content">{{
      $t("noConversionContent")
    }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MenuPanelAlmostCvEmpty extends Vue {}
</script>

<style lang="scss" scoped>
.menuPanelAlmostCvEmpty__title {
  margin-top: 12px;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  line-height: 1.5;
}

.menuPanelAlmostCvEmpty__content {
  font-size: 12px;
  text-align: left;
  line-height: 1.5;
  padding-bottom: 10px;
  color: $colorBase700;
}
</style>
