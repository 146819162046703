import { render, staticRenderFns } from "./FunnelAttributeCondition.vue?vue&type=template&id=6ef0c403"
import script from "./FunnelAttributeCondition.vue?vue&type=script&lang=ts&setup=true"
export * from "./FunnelAttributeCondition.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/funnel/funnel-attribute-condition.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Ffunnel%2FFunnelAttributeCondition.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports