<i18n src="@/i18n/views/watch-history.json"></i18n>
<template>
  <div class="watch-history">
    <SearchResultContainer
      :view="searchResultView"
      :label="$t('label')"
      :icon="icon"
      :is-show-empty-description="hasUserId"
      @view-change="onViewChange"
    />
    <div v-if="!hasUserId" class="empty-description">{{ $t("noData") }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";
import { handleNoQueryCacheError } from "@/util/error-util";
import SearchResultContainer from "@/views/SearchResultContainer.vue";
import { UserHistory } from "@/models/userdata/UserHistory";
import { Icons } from "@/const/Icons";
import { SearchResultViews } from "@/const/SearchResultViews";
import {
  executeSearchByHistoryId,
  createQueryWithViewAndHid
} from "@/util/user-search-util";

@Component({
  components: {
    SearchResultContainer
  }
})
export default class WatchHistory extends Vue {
  icon = Icons.History;
  searchResultView = SearchResultViews.Attribute;

  async created() {
    const view = this.$route.query.view;
    switch (view) {
      case SearchResultViews.Memo:
        this.searchResultView = SearchResultViews.Memo;
        break;
      case SearchResultViews.Overview:
        this.searchResultView = SearchResultViews.Overview;
        break;
      default:
        this.searchResultView = SearchResultViews.Attribute;
    }

    if (!this.hasUserId) {
      this.$store.dispatch("search/clearForEmptyState");
    } else {
      // If historyId exists, recover filter condition
      if (this.$route.query.hid) {
        await executeSearchByHistoryId(Number(this.$route.query.hid));
      } else {
        const condition = new SelectByUserIdCondition(this.userIds);
        this.$store
          .dispatch("search/executeSelect", { selectCondition: condition })
          .catch(error => {
            handleNoQueryCacheError(error);
            throw new Error(error);
          });
      }
    }
  }

  onViewChange(view: SearchResultViews) {
    this.searchResultView = view;
    const query = createQueryWithViewAndHid(view);
    this.$router.replace({ query });
  }

  get userIds(): string[] {
    return this.$store.state.history.userHistories.map(
      (uh: UserHistory) => uh.userId
    );
  }

  get hasUserId(): boolean {
    return this.userIds.length > 0;
  }
}
</script>
<style lang="scss" scoped>
.empty-description {
  padding: 40px 20px 0 20px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
