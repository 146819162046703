<template>
  <MenuPanel
    :class="{ 'menu-panel__no_editable': !isEditable }"
    :label-icon="isEditMode || !isEditable ? undefined : iconSave"
    :is-loading="isLoading"
    :clickable="clickable"
    @click="onClick"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <template #action>
      <slot name="menu"></slot>
    </template>
    <template #header>
      <div class="search-history-panel__header">
        <LabelForm v-if="isEditMode" :label="label" @save-label="onSaveLabel" />
        <div
          v-else
          class="search-history-panel__label"
          @click.capture.stop="onClickTitle"
        >
          {{ label }}
          <div class="search-history-panel__memo-icon">
            <Icon
              v-if="isEditable"
              :icon="iconMemo"
              :size="15"
              :color="iconMemoColor"
            />
          </div>
        </div>
      </div>
    </template>
    <div
      class="search-history-panel__list search-history-panel__label-container"
    >
      <Icon
        :icon="iconSearch"
        size="inherit"
        color="inherit"
        class="search-history-panel__label-icon"
      />
      <div class="search-history-panel__list-text search-history-panel__label">
        <slot name="condition"></slot>
      </div>
    </div>
    <Tooltip
      class="search-history-panel__tooltip-wrapper"
      :show="showTooltip"
      :color="tooltipColor"
      :placement="tooltipHeaderPlacement"
      :wrap="true"
    >
      <template #content>
        <div class="search-history-panel__tooltip">
          <slot name="conditionTooltip"></slot>
        </div>
      </template>
    </Tooltip>
    <div class="search-history-panel-container">
      <div
        v-for="u in slicedUsers"
        :key="u.id"
        class="search-history-panel-icon"
      >
        <UserIcon
          :is-pc="u.pv.isPc"
          :is-application="u.pv.isApplication"
          :gaze-level="u.pv.gazeLevelFromPv(clientPvStats)"
        />
        <div class="table-icon-p">
          <UserServiceIdText
            :service-id-text="u.serviceIdForDisplay"
            :is-fixed-service-id="u.isFixedServiceId"
            :ellipsis="true"
          />
        </div>
        <div class="search-panel-time">
          {{ formatDate(u.createdAt, u.createdAtTimezoneOffset) }}
          <br />
          {{ formatTime(u.createdAt, u.createdAtTimezoneOffset) }}
        </div>
      </div>
    </div>
  </MenuPanel>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { SearchHistory } from "@/models/search/SearchHistory";
import LabelForm from "@/components/form/LabelForm.vue";
import MenuPanel from "@/components/home-menu/MenuPanel.vue";
import UserServiceIdText from "@/components/users/UserServiceIdText.vue";
import { UserInfo } from "@/models/UserInfo";
import { ClientPvStats } from "@/models/system/ClientPvStats";
import UserIcon from "@/components/users/UserIcon.vue";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { DateFormat, formatDate } from "@/util/date-util";
import Tooltip from "@/components/Tooltip.vue";
import { TooltipPlacement, TooltipColor } from "@/const/tooltip";

// Can't show more than 5 icons per search history panel
const SEARCH_HISTORY_PANEL_LIMIT: number = 6;

/**
 * Panel which shows label and the overview results/count of the search
 */
@Component({
  components: {
    Icon,
    LabelForm,
    UserIcon,
    Tooltip,
    UserServiceIdText,
    MenuPanel
  }
})
export default class MenuPanelSearchHistory extends Vue {
  iconSave = Icons.Save;
  iconSearch = Icons.Search;
  iconMemo = Icons.Memo;
  iconMemoColor = Colors.Base600;
  tooltipHeaderPlacement = TooltipPlacement.Bottom;
  tooltipColor = TooltipColor.Light;

  showTooltip = false;

  @Prop({ type: String, required: true })
  label!: string;

  @Prop({ type: SearchHistory, required: true })
  history!: SearchHistory;

  @Prop({ type: ClientPvStats, required: true })
  clientPvStats!: ClientPvStats;

  @Prop({ type: Boolean, default: false })
  isEditMode!: boolean;

  @Prop({ type: Boolean, default: true })
  isEditable!: boolean;

  @Prop({ type: Boolean, default: false })
  isLoading!: boolean;

  @Prop({ type: Boolean, default: true })
  clickable!: boolean;

  onClick(e: MouseEvent) {
    this.$emit("click", e);
  }

  /**
   * Save changes on the label
   */
  onSaveLabel(label: string) {
    this.$emit("save-label", label);
  }

  /**
   * On edit click
   */
  @Emit("open-edit-form")
  onClickTitle() {}

  /**
   * Select item for deletion
   */
  @Emit("delete-item")
  onDeleteItemSelected() {}

  /**
   * Get all user info but at a certain limit
   */
  get slicedUsers(): UserInfo[] {
    // first user
    const start: number = 0;
    // last user to display
    const end: number = SEARCH_HISTORY_PANEL_LIMIT;

    const sorted: UserInfo[] = [...this.history.results].sort((a, b) =>
      a.pv.allPv < b.pv.allPv ? 1 : -1
    );

    // Get all user info but only at a certain limit
    return sorted.slice(start, end);
  }

  /**
   * Format profile created date
   */
  formatDate(createdDate: Date, timezoneOffset: number): string {
    return formatDate(DateFormat.yyyysMMsdd, createdDate, timezoneOffset);
  }
  formatTime(createdDate: Date, timezoneOffset: number): string {
    return formatDate(DateFormat.HHcmm, createdDate, timezoneOffset);
  }
}
</script>

<style lang="scss" scoped>
.search-history-panel__memo-icon {
  position: absolute;
  top: 50%;
  left: calc(100% + 8px);
  opacity: 0;
  transition: opacity 0.3s ease;
  transform: translateY(-50%);
}

.search-history-panel__header {
  cursor: text;

  &:hover .search-history-panel__memo-icon {
    opacity: 1;
  }
}
.search-history-panel__label-icon {
  position: absolute;
  top: 50%;
  right: calc(100% + 8px);
  transform: translateY(-50%);
}

.search-history-panel__label-container {
  margin-top: 15px;
  position: relative;
  display: table;
  margin-right: auto;
  margin-left: auto;
}

.menu-panel__no_editable .search-history-panel__header {
  cursor: pointer;
  pointer-events: none;
}

.search-history-panel__label {
  overflow: hidden;
  max-width: 270px;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.3;
}

.search-history-panel__label-tooltip {
  min-width: 200px;
}

.search-history-panel__tooltip-wrapper {
  top: -60px;
}
.search-history-panel__tooltip {
  width: calc(#{$searchHistoryPanelSize} - 40px);
  text-align: left;
}

.search-history-panel__list {
  margin-bottom: 10px;
  color: $colorBase600;
}

.search-history-panel__list-text {
  max-width: 220px;
}

.search-history-panel-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: -30px;
}

.search-history-panel-icon {
  padding-top: 35px;
  width: 33.3%;
}

.table-icon-p {
  overflow: hidden;
  margin: 10px auto 0;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}

.search-panel-time {
  margin-top: 8px;
  color: $colorBase700;
  font-size: 11px;
  line-height: 1.2;
}
</style>
