<i18n src="@/i18n/components/funnel/funnel-header.json"></i18n>
<template>
  <div class="funnelHeader">
    <SearchResultHeader
      class="funnelHeader__inner"
      :icon-title="ICONS.HorizontalBars"
      :title="title"
    />
    <div v-if="hasBackLink" class="funnelHeader__backLink">
      <RouterLink to="/funnel" class="funnelHeader__routerLink">
        <Icon :icon="ICONS.ArrowLeft" :color="COLORS.Base700" />
        <div class="funnelHeader__backText">{{ $t("back-button") }}</div>
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import SearchResultHeader from "@/components/search/SearchResultHeader.vue";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";

@Component({
  components: {
    SearchResultHeader,
    Icon
  }
})
export default class FunnelHeader extends Vue {
  ICONS = Icons;
  COLORS = Colors;

  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: Boolean, default: false })
  hasBackLink!: boolean;
}
</script>

<style lang="scss" scoped>
.funnelHeader {
  position: relative;
}
.funnelHeader__inner {
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.funnelHeader__backLink {
  position: absolute;
  bottom: 5px;
  left: 0;
  display: flex;
  font-size: 14px;
  cursor: pointer;
  span {
    margin-left: 1px;
  }
}
.funnelHeader__routerLink {
  display: flex;
  color: $colorBase700;
  text-decoration: none;
}

.funnelHeader__backText {
  margin-left: 2px;
}
</style>
