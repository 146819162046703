<i18n src="@/i18n/components/funnel/filter-condition-with-result.json"></i18n>
<template>
  <div
    class="filterConditionWithResult"
    :class="{ 'filterConditionWithResult--large': fontSize === 'large' }"
  >
    <div class="filterConditionWithResult__matchedUsers">
      <div
        v-t="'matchedUsers'"
        class="filterConditionWithResult__matchedUsersLabel"
      />
      <div class="filterConditionWithResult__numberOfMatchedUsers"
        >{{ numberOfUsers }}{{ $t("matchedUsersUnit") }}</div
      >
    </div>
    <div class="filterConditionWithResult__conditions">
      <div v-if="activities" class="filterConditionWithResult__activities">
        {{ $t("activities") }} : {{ activities }}
      </div>
      <div v-if="attributes" class="filterConditionWithResult__attributes">
        {{ $t("attributes") }} : {{ attributes }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";

const props = defineProps({
  fontSize: {
    type: String as PropType<"large" | "medium">,
    default: "medium"
  },
  activities: {
    type: String,
    default: null
  },
  attributes: {
    type: String,
    default: null
  },
  numFilteredUsers: {
    type: Number as PropType<number | null>,
    default: null
  }
});

const numberOfUsers = computed(() => {
  if (props.numFilteredUsers === null) return "- ";
  return props.numFilteredUsers.toLocaleString();
});
</script>

<style lang="scss" scoped>
.filterConditionWithResult {
  display: flex;
  background: $colorGray100;
  padding: 16px 12px 15px;
  font-size: 12px;
  color: $colorBase700;
}
.filterConditionWithResult--large {
  font-size: 14px;
}
.filterConditionWithResult__matchedUsers {
  padding-right: 14px;
  min-width: 94px;
  border-right: solid 1px $colorBase500;
}
.filterConditionWithResult__numberOfMatchedUsers {
  font-size: 12px;
}
.filterConditionWithResult__matchedUsersLabel {
  margin-bottom: 7px;
}
.filterConditionWithResult__conditions {
  padding-left: 14px;
}
.filterConditionWithResult__activities {
  margin-top: -0.15em;
  margin-bottom: 7px;
  word-break: break-word;
  line-height: 1.3;
}
.filterConditionWithResult__attributes {
  margin-top: -0.15em;
  line-height: 1.3;
}
</style>
