<i18n
  src="@/i18n/components/search/engagement-form/select-by-engagement-row.json"
></i18n>
<template>
  <div class="selectByEngagementRow">
    <div class="selectByEngagementRow__content">
      <div v-if="$slots.default" class="selectByEngagementRow__head">
        <div class="selectByEngagementRow__prefix">
          <slot />
        </div>

        <div
          v-t="'inTheLast'"
          class="selectByEngagementRow__text"
          :class="{ 'selectByEngagementRow__text--disabled': disabled }"
        />
      </div>

      <div
        v-else
        v-t="'recent'"
        class="selectByEngagementRow__text selectByEngagementRow__recent"
        :class="{ 'selectByEngagementRow__text--disabled': disabled }"
      />

      <div
        class="selectByEngagementRow__period"
        @mouseenter="onPeriodMouseenter"
        @mouseleave="onPeriodMouseleave"
      >
        <Tooltip :value="showPeriodTooltip" placement="top" :manual="true">
          <SelectBox
            class="selectByEngagementRow__periodSelectBox"
            :value="currentPeriodValue"
            :options="periodSelectOptions"
            :disabled="disabled"
            width="100px"
            @input="onPeriodInput"
          />

          <template #content>
            <div
              v-for="content in tooltipContents"
              :key="content.label"
              class="selectByEngagementRow__tooltip"
            >
              <div
                class="selectByEngagementRow__tooltipLabel"
                v-text="content.label"
              />

              <div class="selectByEngagementRow__tooltipPeriods">
                {{ content.pastPeriod }}<br />
                {{ content.targetPeriod }}
              </div>
            </div>
          </template>
        </Tooltip>
      </div>

      <div
        v-t="'and'"
        class="selectByEngagementRow__text"
        :class="{ 'selectByEngagementRow__text--disabled': disabled }"
      />

      <div class="selectByEngagementRow__fluctuation">
        <FluctuationForm
          :value="fluctuationValue"
          :disabled="disabled"
          @input="onFluctuationInput"
        />
      </div>

      <div
        v-t="'users'"
        class="selectByEngagementRow__text"
        :class="{ 'selectByEngagementRow__text--disabled': disabled }"
      />
    </div>

    <div
      v-if="showDirectAssignPeriodContainer && !disabled"
      class="selectByEngagementRow__directAssignPeriodContainer"
    >
      <div class="selectByEngagementRow__directAssignPeriod">
        <div
          v-t="'pastPeriod'"
          class="selectByEngagementRow__directAssignPeriodLabel"
        />
        <DateRangePickerInput
          :value="value.comparisonPeriod"
          data-cy="comparison-datepicker"
          :enabled-period="enabledPeriod"
          :show-period-choices="false"
          @input="onComparisonPeriodDateRangeInput"
        />
      </div>

      <div class="selectByEngagementRow__directAssignPeriod">
        <div
          v-t="'targetPeriod'"
          class="selectByEngagementRow__directAssignPeriodLabel"
        />
        <DateRangePickerInput
          :value="value.period"
          data-cy="period-datepicker"
          :enabled-period="enabledPeriod"
          :show-period-choices="false"
          @input="onPeriodDateRangeInput"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
/* components */
import { DateRange } from "@/components/date-picker/DateRange";
import { Tooltip } from "element-ui";
import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";
import FluctuationForm from "@/components/search/engagement-form/FluctuationForm.vue";
import DateRangePickerInput from "@/components/date-picker/DateRangePickerInput.vue";
/* models */
import {
  AnalysisType,
  FieldType,
  PeriodType,
  FluctuationValues,
  SelectByEngagementCondition
} from "@/models/search/select-condition/SelectByEngagementCondition";
/* utils */
import { usergramEnabledPeriodRange } from "@/util/date-range-picker-util";
import {
  getPeriodDateRanges,
  getDaysDiff
} from "@/util/select-by-engagement-util";
import { DateFormat, formatDate } from "@/util/date-util";

export const periodSelectOptions: SelectOption[] = [
  {
    label: "1week",
    value: PeriodType["1week"],
    disabled: false
  },
  {
    label: "2weeks",
    value: PeriodType["2weeks"],
    disabled: false
  },
  {
    label: "30days",
    value: PeriodType["30days"],
    disabled: false
  },
  {
    label: "90days",
    value: PeriodType["90days"],
    disabled: false
  },
  {
    label: "directly",
    value: PeriodType.directly,
    disabled: false
  }
];

export interface TooltipContent {
  label: string;
  pastPeriod: string;
  targetPeriod: string;
}

@Component({
  components: {
    DateRangePickerInput,
    SelectBox,
    FluctuationForm,
    Tooltip
  }
})
export default class SelectByEngagementRow extends Vue {
  @Prop({ type: SelectByEngagementCondition, required: true })
  value!: SelectByEngagementCondition;

  @Prop({ type: Boolean, required: true })
  disabled!: boolean;

  onInput(condition: SelectByEngagementCondition) {
    this.$emit("input", condition);
  }

  AnalysisType = AnalysisType;
  FieldType = FieldType;
  PeriodType = PeriodType;

  showPeriodTooltip = false;
  currentPeriodValue = this.periodValue;

  mounted() {
    if (this.periodValue !== PeriodType.directly) {
      const result = getPeriodDateRanges(this.periodValue);

      let condition = this.value.updatePeriod(result.targetPeriod);
      condition = condition.updateComparisonPeriod(result.pastPeriod);

      this.onInput(condition);
    }
  }

  // period
  get periodSelectOptions(): SelectOption[] {
    return periodSelectOptions.map((o: SelectOption) => {
      return {
        label: this.$t(o.label) as string,
        value: o.value,
        disabled: o.disabled
      } as SelectOption;
    });
  }

  get enabledPeriod(): DateRange {
    return usergramEnabledPeriodRange();
  }

  get periodValue(): number {
    const periodDiff = getDaysDiff(this.value.period);
    const comparisonPeriodDiff = getDaysDiff(this.value.comparisonPeriod);
    const periodTypeValues = Object.values(PeriodType);

    return !periodTypeValues.includes(periodDiff) ||
      !periodTypeValues.includes(comparisonPeriodDiff)
      ? PeriodType.directly
      : periodDiff;
  }

  get showDirectAssignPeriodContainer(): boolean {
    return PeriodType.directly === this.currentPeriodValue;
  }

  get tooltipContents(): TooltipContent[] {
    return this.periodSelectOptions
      .filter(o => o.value !== PeriodType.directly)
      .map(o => {
        const dataRages = getPeriodDateRanges(Number(o.value));
        const past = dataRages.pastPeriod;
        const pastMaxLabel = formatDate(DateFormat.yyyy年M月d日, past.max);
        const pastMinLabel = formatDate(DateFormat.yyyy年M月d日, past.min);
        const pastPrefix = this.$t("pastPeriod") as string;
        const pastLabel = `${pastPrefix}: ${pastMinLabel} - ${pastMaxLabel}`;
        const target = dataRages.targetPeriod;
        const targetMaxLabel = formatDate(DateFormat.yyyy年M月d日, target.max);
        const targetMinLabel = formatDate(DateFormat.yyyy年M月d日, target.min);
        const targetPrefix = this.$t("targetPeriod") as string;
        const targetLabel = `${targetPrefix}: ${targetMinLabel} - ${targetMaxLabel}`;

        return {
          label: o.label,
          pastPeriod: pastLabel,
          targetPeriod: targetLabel
        };
      });
  }

  onPeriodInput(value: number) {
    this.currentPeriodValue = value;

    if (value !== PeriodType.directly) {
      const result = getPeriodDateRanges(value);

      let condition = this.value.updatePeriod(result.targetPeriod);
      condition = condition.updateComparisonPeriod(result.pastPeriod);

      this.onInput(condition);
    }
  }

  onPeriodDateRangeInput(value: DateRange) {
    const condition = this.value.updatePeriod(value);

    this.onInput(condition);
  }

  onComparisonPeriodDateRangeInput(value: DateRange) {
    const condition = this.value.updateComparisonPeriod(value);

    this.onInput(condition);
  }

  onPeriodMouseenter() {
    this.showPeriodTooltip = true;
  }

  onPeriodMouseleave() {
    this.showPeriodTooltip = false;
  }

  // fluctuation
  get fluctuationValue(): FluctuationValues {
    const max = this.value.maxFluctuationValue;
    const min = this.value.minFluctuationValue;
    const fluctuationType = this.value.fluctuationType;

    return {
      min,
      max,
      type: fluctuationType
    } as FluctuationValues;
  }

  onFluctuationInput(value: FluctuationValues) {
    const condition = this.value.updateFluctuations(value);

    this.onInput(condition);
  }
}
</script>

<style scoped lang="scss">
.selectByEngagementRow__content {
  display: flex;
  align-items: center;
}

.selectByEngagementRow__head {
  display: flex;
  align-items: center;
}

.selectByEngagementRow__text {
  &:not(:first-child) {
    margin-left: 8px;
  }
}

.selectByEngagementRow__text--disabled {
  color: $colorBase500;
}

.selectByEngagementRow__tooltip {
  display: flex;
  line-height: 1.5;

  &:not(:first-child) {
    margin-top: 8px;
  }
}

.selectByEngagementRow__tooltipLabel {
  margin-right: 8px;
  width: 45px;
  text-align: right;
}

.selectByEngagementRow__period {
  margin-left: 8px;
}

.selectByEngagementRow__directAssignPeriodContainer {
  margin-top: 8px;
}

.selectByEngagementRow__directAssignPeriod {
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 8px;
  }
}

.selectByEngagementRow__directAssignPeriodLabel {
  margin-right: 8px;
}

.selectByEngagementRow__fluctuation {
  margin-left: 8px;
}

.selectByEngagementRow__website {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.selectByEngagementRow__websiteForm {
  margin-left: 8px;
}
</style>
