<i18n src="@/i18n/components/home-menu/lag-cv.json"></i18n>
<template>
  <div class="lagCvEmpty">
    <div class="lagCvEmpty__title">{{ $t("noConversionTitle") }}</div>
    <div class="lagCvEmpty__content">{{ $t("noConversionContent") }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class MenuPanelLagCvEmpty extends Vue {}
</script>

<style lang="scss" scoped>
.lagCvEmpty__title {
  margin-top: 30px;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  line-height: 1.5;
}

.lagCvEmpty__content {
  font-size: 12px;
  text-align: left;
  line-height: 1.5;
  padding-bottom: 10px;
  color: $colorBase700;
}
</style>
