import { SelectConditionInterface } from "@/models/search/select-condition/SelectCondition";
import { SelectByServiceIdQuery, SelectQuery } from "@/api/apis/ApiSearch";
import { msecToSec, Sec, SECONDS_IN_DAY } from "@/util/date-util";
import { MAX_DISPLAY_SPAN_DAYS } from "@/const/app";
import { ValidationResult } from "@/models/search/ValidationResult";
import { i18n } from "@/i18n";

// 選定条件にいれられるserviceIdの最大数
export const maxServiceIdNumber: number = 2000;
export const MAX_CHARACTERS_SERVICEID: number = 512;

/**
 * サービスIDによるユーザ選定条件
 */
export class SelectByServiceIdCondition implements SelectConditionInterface {
  constructor(
    // 取得したいユーザのサービスIDの配列
    public readonly serviceIds: string[],
    // コンバージョンしていないユーザを含むかどうか
    public readonly includesNoConversionUsers: boolean
  ) {}

  static emptyCondition(): SelectByServiceIdCondition {
    return new SelectByServiceIdCondition([], true);
  }

  get validate(): ValidationResult {
    if (this.serviceIds.length <= 0) {
      return {
        isValid: false,
        errorMessage: i18n.t("models.search.enterServiceId") as string
      };
    }
    if (this.serviceIds.length > maxServiceIdNumber) {
      return {
        isValid: false,
        errorMessage: i18n.t("models.search.specifyMaxServiceIds", {
          num: maxServiceIdNumber
        }) as string
      };
    }

    return { isValid: true };
  }

  // AdditionalSelectConditionを追加できるか
  public readonly isAdditionalConditionAppendable: boolean = false;
}

/**
 * 検索条件 -> JSON
 */
export function convertSelectByServiceIdConditionToJson(
  condition: SelectByServiceIdCondition
): SelectByServiceIdQuery {
  // end time は現在日時
  const endTimeSec: Sec = msecToSec(Date.now());
  // start time は現在から表示最大期間日前
  const startTimeSec: Sec = endTimeSec - MAX_DISPLAY_SPAN_DAYS * SECONDS_IN_DAY;

  return {
    start_time_sec: startTimeSec,
    end_time_sec: endTimeSec,
    include_no_cv_users: condition.includesNoConversionUsers,
    service_ids: condition.serviceIds
  };
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToSelectByServiceIdCondition(
  query: SelectByServiceIdQuery
): SelectByServiceIdCondition {
  return new SelectByServiceIdCondition(
    query.service_ids,
    query.include_no_cv_users
  );
}

/**
 * JSONがサービスID検索のものかどうかを判定する
 */
export function isSelectByServiceIdQuery(
  query: SelectQuery
): query is SelectByServiceIdQuery {
  return "service_ids" in query;
}
