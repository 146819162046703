import { render, staticRenderFns } from "./SegmentedTrendsWebViewTable.vue?vue&type=template&id=1c2a7f16&scoped=true"
import script from "./SegmentedTrendsWebViewTable.vue?vue&type=script&lang=ts"
export * from "./SegmentedTrendsWebViewTable.vue?vue&type=script&lang=ts"
import style0 from "./SegmentedTrendsWebViewTable.vue?vue&type=style&index=0&id=1c2a7f16&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c2a7f16",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/segmented-trends/segmented-trends-web-view-table.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fsegmented-trends%2FSegmentedTrendsWebViewTable.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports