<i18n src="@/i18n/views/favorite-button.json"></i18n>
<template>
  <div class="favorite-button">
    <Tooltip :text="$t('tooltip')">
      <IconButton
        class="favorite-button__icon"
        :icon="icon"
        :size="30"
        :icon-scale="0.53"
        :button-type="iconButtonType"
        :background="true"
        @click="openForm"
      />
    </Tooltip>
    <Overlay
      v-if="showForm"
      class="favorite-button__overlay"
      @click="closeForm"
    />
    <Balloon
      v-if="showForm"
      class="favorite-button__balloon"
      data-cy="favorite-balloon"
      :direction="direction"
      :class="placementClass"
    >
      <div class="favorite-button__form" data-cy="favorite-button__form">
        <div class="favorite-button__title">{{ $t("title") }}</div>

        <div v-if="!isFormAvailable" class="favorite-button__error">{{
          $t("notAddable", { num: maxFavoriteNum })
        }}</div>
        <div v-else>
          <div class="favorite-button__head">
            <div v-t="'label'" class="favorite-button__label" />
            <div
              class="favorite-button__descriptionLength"
              :class="{
                'favorite-button__descriptionLength--error': isTooLong
              }"
              >{{ descriptionRemainingCharacterCountMessage }}</div
            >
          </div>

          <TextArea
            ref="favorite-button__textarea"
            v-model="description"
            :has-error="isTooLong"
            class="favorite-button__textarea"
            data-cy="favorite-button__textarea"
            width="340px"
            height="50px"
          />
          <div class="favorite-button__note">{{ $t("note") }}</div>

          <div class="favorite-button__button-area">
            <img
              v-if="isUpdating"
              src="@/assets/img/loading_circle.gif"
              class="favorite-button__updating"
            />
            <div v-else>
              <Button
                class="favorite-button__button"
                data-cy="registration-button"
                width="200px"
                :disabled="isTooLong"
                @click="post"
                >{{ $t(isAdded ? "buttonEdit" : "buttonRegister") }}</Button
              >
              <div v-if="isAdded">
                <Button
                  :type="removeButtonType"
                  class="favorite-button__button__remove"
                  data-cy="favorite-button__button__remove"
                  width="100px"
                  @click="remove"
                  >{{ $t("buttonDelete") }}</Button
                >
              </div>
            </div>
          </div>
        </div>

        <Transition name="complete">
          <div v-if="showPostedMessage" class="favorite-button__comlate">
            {{ $t("complete") }}
            <br />
            {{ $t("remaining", { num: remainingNum }) }}
          </div>
        </Transition>
      </div>
    </Balloon>
    <ConfirmDialog
      v-model="showConfirmDialog"
      :message="$t('confirmMessage')"
      :button-label="$t('confirmDelete')"
      :cancel-button-label="$t('confirmCancel')"
      @click="confirm"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import Tooltip from "@/components/Tooltip.vue";
import IconButton from "@/components/IconButton.vue";
import Overlay from "@/components/Overlay.vue";
import Balloon from "@/components/Balloon.vue";
import TextArea from "@/components/form/TextArea.vue";
import Button from "@/components/Button.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";

import { BalloonDirection } from "@/const/balloon";
import { BUTTON_TYPE } from "@/const/button";
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import { Favorite } from "@/models/userdata/Favorite";
import { User } from "@/models/User";
import { showAlert } from "@/util/modal-util";
import { UgTag, UgEventTag } from "@/store/modules/ugTag";
import * as Sentry from "@sentry/browser";

@Component({
  components: {
    Tooltip,
    IconButton,
    Overlay,
    Balloon,
    TextArea,
    Button,
    ConfirmDialog
  }
})
export default class FavoriteButton extends Vue {
  showConfirmDialog = false;

  @Prop({ type: User, required: true })
  user!: User;

  @Prop({ type: String, default: BalloonDirection.TopStart })
  direction!: BalloonDirection;

  @Emit("show-form")
  onShowForm() {}

  @Emit("close-form")
  onCloseForm() {}

  get placementClass(): string {
    return "favorite-button__form__direction--" + this.direction;
  }

  removeButtonType = BUTTON_TYPE.LIGHT;

  maxFavoriteNum = 100;
  maxDescriptionLength = 30;

  description: string = "";
  completeTimerId: number = 0;
  showForm = false;
  showPostedMessage = false;
  isUpdating = false;
  isDestroyed = false;

  mounted() {
    const favorite = this.currentFavorite;
    if (favorite !== undefined) {
      this.description = favorite.description;
    } else {
      this.description = "";
    }
  }

  destroyed() {
    clearTimeout(this.completeTimerId);
  }

  get icon(): Icons {
    return this.isAdded ? Icons.Heart : Icons.HeartPlus;
  }
  get iconButtonType() {
    return this.isAdded ? IconButtonType.Favorite : IconButtonType.Strong;
  }

  get favorites(): Favorite[] {
    return this.$store.state.favorite.favorites;
  }

  get currentFavorite(): Favorite | undefined {
    return this.favorites.find(fav => fav.userId === this.user.id);
  }

  get isAdded(): boolean {
    return this.currentFavorite !== undefined;
  }

  get isFormAvailable(): boolean {
    return this.isAdded || this.favorites.length < this.maxFavoriteNum;
  }

  get remainingNum(): string {
    return this.maxFavoriteNum - this.favorites.length + "";
  }

  get descriptionRemainingCharacterCount(): number {
    return this.maxDescriptionLength - this.description.length;
  }

  get isTooLong(): boolean {
    return this.description.length > this.maxDescriptionLength;
  }

  get descriptionRemainingCharacterCountMessage(): string {
    if (this.isTooLong) {
      return this.$i18n.t("descriptionCharactersRemaining", {
        num: -this.descriptionRemainingCharacterCount
      }) as string;
    }

    if (this.description.length > 0) {
      return this.$i18n.t("exceededByDescriptionCharacters", {
        num: this.descriptionRemainingCharacterCount
      }) as string;
    }

    return this.$i18n.t("descriptionCharacterWithin", {
      num: this.descriptionRemainingCharacterCount
    }) as string;
  }

  get isFavoriteUsers(): boolean {
    return this.$route.name === "bookmark-users";
  }

  openForm() {
    this.showForm = true;
    this.onShowForm();
    if (this.isFormAvailable) {
      this.$nextTick(() =>
        (this.$refs["favorite-button__textarea"] as HTMLElement).focus()
      );
    }
  }

  closeForm() {
    clearTimeout(this.completeTimerId);
    this.showForm = false;
    this.showPostedMessage = false;
    this.onCloseForm();
  }

  async post() {
    const favorite = new Favorite(this.user.id, this.description, new Date());

    this.isUpdating = true;

    try {
      await this.$store.dispatch("favorite/addFavorite", favorite);
      if (!this.showForm) {
        this.isUpdating = false;
        return;
      }
      this.showPostedMessage = true;
      this.completeTimerId = window.setTimeout(() => {
        this.closeForm();
      }, 1000);
      UgTag.pushEvent(UgEventTag.AddFavoriteUser);
    } catch (e) {
      if (this.isAdded) {
        // TODO 翻訳
        showAlert("お気に入り追加に失敗しました");
      } else {
        // TODO 翻訳
        showAlert("お気に入り追加に失敗しました");
      }
      Sentry.captureException(e);
    }
    this.isUpdating = false;
  }

  remove() {
    this.showConfirmDialog = true;
  }

  async confirm() {
    this.showConfirmDialog = false;
    try {
      this.closeForm();
      await this.$store.dispatch("favorite/removeFavorites", [this.user.id]);
    } catch (e) {
      // TOOD 翻訳
      showAlert("お気に入りの削除に失敗しました");
      Sentry.captureException(e);
    }
    if (this.isFavoriteUsers) {
      this.$store.commit("search/removeUser", this.user.id);
    }
  }
}
</script>

<style scoped lang="scss">
.complete-enter-active {
  transition: opacity 0.3s;
}
.complete-enter {
  opacity: 0;
}

.favorite-button {
  position: relative;
}

.favorite-button__balloon {
  position: absolute;
  top: 40px;
  left: -10px;
}

.favorite-button__form {
  padding: 20px;
}

.favorite-button__title {
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

.favorite-button__head {
  display: flex;
  align-items: center;
  margin-top: 15px;
  line-height: 1;
}

.favorite-button__label {
  font-weight: bold;
}

.favorite-button__descriptionLength {
  margin-left: 10px;
  font-size: 12px;
}

.favorite-button__descriptionLength--error {
  color: $colorError;
}

.favorite-button__textarea {
  margin-top: 10px;
}

.favorite-button__note {
  margin-top: 8px;
  color: $colorBase900;
  text-align: left;
  white-space: normal;
  font-size: 12px;
  line-height: 1.5;
}

.favorite-button__button-area {
  margin-top: 20px;
  text-align: center;
}
.favorite-button__button__remove {
  margin-top: 16px;
}

.favorite-button__updating {
  width: 38px;
  height: 38px;
}

.favorite-button__comlate {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border-radius: $sizeRadius;
  background-color: $colorDark;
  color: $colorWhite;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

$topY: translateY(0);
$bottomY: translateY(calc(-100% - 46px));

$topBottomEndX: translateX(calc(-100% + 52px));
$topBottomCenterX: translateX(calc(-50% + 20px));

.favorite-button__form__direction--top {
  transform: $topBottomCenterX $topY;
}
.favorite-button__form__direction--top-start {
  transform: $topY;
}
.favorite-button__form__direction--top-end {
  transform: $topBottomEndX $topY;
}

.favorite-button__form__direction--bottom {
  transform: $topBottomCenterX $bottomY;
}
.favorite-button__form__direction--bottom-start {
  transform: $bottomY;
}
.favorite-button__form__direction--bottom-end {
  transform: $topBottomEndX $bottomY;
}

$leftX: translateX(46px);
$rightX: translateX(-100%);

$leftRightStartY: translateY(-52px);
$leftRightEndY: translateY(-100%);
$leftRightCenterY: translateY(calc(-50% - 30px));

.favorite-button__form__direction--right {
  transform: $rightX $leftRightCenterY;
}
.favorite-button__form__direction--right-start {
  transform: $rightX $leftRightStartY;
}
.favorite-button__form__direction--right-end {
  transform: $rightX $leftRightEndY;
}

.favorite-button__form__direction--left {
  transform: $leftX $leftRightCenterY;
}
.favorite-button__form__direction--left-start {
  transform: $leftX $leftRightStartY;
}
.favorite-button__form__direction--left-end {
  transform: $leftX $leftRightEndY;
}
.favorite-button__error {
  padding: 10px;
  width: 340px;
  text-align: left;
  white-space: pre-wrap;
  line-height: 1.5;
}
</style>
