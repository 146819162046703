<template>
  <input
    ref="textForm"
    class="label-form"
    :label="label"
    :value="label"
    type="text"
    @keyup.enter="saveLabel($event.target.value)"
    @blur="saveLabel($event.target.value)"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class LabelForm extends Vue {
  mounted() {
    const form = this.$refs.textForm as HTMLInputElement;
    form.focus();
  }

  @Prop({ type: String, required: true })
  label!: string;

  /**
   * Update label state
   */
  saveLabel(label: string) {
    this.$emit("save-label", label);
  }
}
</script>
<style scoped lang="scss">
.label-form {
  padding: 0 8px;
  height: $formPartsHeight;
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
  color: $colorText;
  font-size: $basicFontSize;
  /* IE11で中央にする */
  line-height: $formPartsHeight;
  resize: none;
  cursor: text;

  &:disabled {
    border: 1px solid $colorBase500;
    background-color: $colorBase300;
    color: $colorBase600;
    cursor: default;
  }
}
</style>
