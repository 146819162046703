<i18n
  src="@/i18n/components/user-trend/form/user-trend-search-form-input.json"
></i18n>
<template>
  <div class="userTrendSearchFormInput">
    <div class="userTrendSearchFormInput__row userTrendSearchFormInput__cv">
      <div class="userTrendSearchFormInput__title">
        <TitleTextWithIcon :title="selectCV" :icon="icons.Conversion" />
      </div>
      <div class="userTrendSearchFormInput__body">
        <div class="userTrendSearchFormInput__allCheck">
          <div
            v-t="'selectAll'"
            class="userTrendSearchFormInput__allCheckItem"
            @click="checkAllConversion"
          />
          <div
            v-t="'deselectAll'"
            class="userTrendSearchFormInput__allCheckItem"
            data-cy="userTrendDeselectAll"
            @click="removeAllConversion"
          />
        </div>

        <CheckBoxList
          data-cy="user-trend-search-form-input-check-box-list"
          :options="cvOptions"
          :column="0"
          :values="cvValues"
          @input="onInputCvs"
        />
      </div>
    </div>

    <div
      class="userTrendSearchFormInput__row userTrendSearchFormInput__selectPeriod"
      data-cy="user-trend-conditions-period"
    >
      <div class="userTrendSearchFormInput__title">
        <TitleTextWithIcon :title="selectPeriod" :icon="icons.History" />
      </div>
      <div class="userTrendSearchFormInput__body">
        <div class="userTrendSearchFormInput__dateContainer">
          <div class="userTrendSearchFormInput__date">
            <DateRangePickerInput
              :value="mainDates"
              width="100%"
              :enabled-period="enabledPeriod"
              :show-period-choices="false"
              data-cy="userTrendPeriodDatePicker"
              @input="onInputMainDate"
            />
          </div>

          <div
            class="userTrendSearchFormInput__checkbox"
            data-cy="user-trend-compared-check-box"
          >
            <CheckBox :checked="isCompared" @input="onInputCompare">{{
              $t("comp")
            }}</CheckBox>
          </div>

          <div class="userTrendSearchFormInput__date">
            <DateRangePickerInput
              v-show="isCompared"
              :value="compDates"
              width="100%"
              :enabled-period="enabledPeriod"
              :align="popupAlign.Right"
              :show-period-choices="false"
              data-cy="userTrendComparisonDatePicker"
              @input="onInputCompDate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { UserTrendSearchCondition } from "@/models/user-trend/UserTrendSearchCondition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { DateRange } from "@/components/date-picker/DateRange";
import { Icons } from "@/const/Icons";
import { usergramEnabledPeriodRange } from "@/util/date-range-picker-util";
import { PopupAlign } from "@/models/date-picker/DatePicker";
import CheckBox from "@/components/form/CheckBox.vue";
import CheckBoxList from "@/components/form/CheckBoxList.vue";
import DateRangePickerInput from "@/components/date-picker/DateRangePickerInput.vue";
import SelectOption from "@/components/form/SelectOption";
import TitleTextWithIcon from "@/components/TitleTextWithIcon.vue";

@Component({
  components: {
    CheckBox,
    CheckBoxList,
    DateRangePickerInput,
    TitleTextWithIcon
  }
})
export default class UserTrendSearchFormInput extends Vue {
  @Prop({ type: UserTrendSearchCondition, required: true })
  value!: UserTrendSearchCondition;

  @Prop({ type: Array, required: true })
  clientCvs!: ConversionDefinition[];

  onInput(value: UserTrendSearchCondition) {
    this.$emit("input", value);
  }

  mainDates: DateRange = { min: new Date(), max: new Date() };
  compDates: DateRange = { min: new Date(), max: new Date() };
  isCompared: boolean = false;
  cvOptions: SelectOption[] = [];
  cvValues: number[] = [];

  icons = Icons;
  popupAlign = PopupAlign;

  get selectCV(): string {
    return this.$i18n.t("selectCV") as string;
  }

  get selectPeriod(): string {
    return this.$i18n.t("selectPeriod") as string;
  }

  get enabledPeriod(): DateRange {
    return usergramEnabledPeriodRange();
  }

  created() {
    this.isCompared = this.value.hasComparison;
    this.mainDates.min = this.value.base.startDate;
    this.mainDates.max = this.value.base.endDate;

    this.compDates.min = this.value.comparison.startDate;
    this.compDates.max = this.value.comparison.endDate;

    this.cvValues = this.value.conversionIds;
    // CheckList を作成
    this.cvOptions = this.clientCvs.map(cv => {
      return { value: cv.id, label: cv.name, disabled: false };
    });
  }

  onInputCvs(cvIds: number[]) {
    this.cvValues = cvIds;

    this.onInput(
      new UserTrendSearchCondition(
        cvIds,
        this.value.cvAttr,
        this.value.hasComparison,
        this.value.base,
        this.value.comparison
      )
    );
  }

  onInputMainDate(date: DateRange) {
    this.mainDates.min = date.min;
    this.mainDates.max = date.max;

    this.onInput(
      new UserTrendSearchCondition(
        this.value.conversionIds,
        this.value.cvAttr,
        this.value.hasComparison,
        {
          startDate: date.min,
          endDate: date.max
        },
        this.value.comparison
      )
    );
  }

  onInputCompDate(date: DateRange) {
    this.compDates.min = date.min;
    this.compDates.max = date.max;

    this.onInput(
      new UserTrendSearchCondition(
        this.value.conversionIds,
        this.value.cvAttr,
        this.value.hasComparison,
        this.value.base,
        {
          startDate: date.min,
          endDate: date.max
        }
      )
    );
  }

  onInputCompare(isChecked: boolean) {
    this.isCompared = isChecked;

    this.onInput(
      new UserTrendSearchCondition(
        this.value.conversionIds,
        this.value.cvAttr,
        isChecked,
        this.value.base,
        this.value.comparison
      )
    );
  }

  checkAllConversion() {
    this.cvValues = this.cvOptions.map(opt => Number(opt.value));

    this.onInputCvs(this.cvValues);
  }

  removeAllConversion() {
    this.cvValues = [];

    this.onInputCvs(this.cvValues);
  }
}
</script>

<style scoped lang="scss">
.userTrendSearchFormInput__row {
  display: flex;
  min-height: $formPartsHeight;
  width: 100%;
}

.userTrendSearchFormInput__cv {
  align-items: flex-start;
}

.userTrendSearchFormInput__title {
  padding-right: 20px;
  width: $userTrendSearchFormTitleWidth;
}

.userTrendSearchFormInput__body {
  flex: 1;
}

.userTrendSearchFormInput__allCheck {
  display: flex;
  margin-bottom: 15px;
  padding-top: 2px;
}

.userTrendSearchFormInput__allCheckItem {
  margin-right: 15px;
  color: $colorBase700;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.userTrendSearchFormInput__selectPeriod {
  align-items: center;
  margin-top: 20px;
}

.userTrendSearchFormInput__dateContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.userTrendSearchFormInput__date {
  flex: 1;
  max-width: 350px;
}

.userTrendSearchFormInput__checkbox {
  margin: 0 5px 0 10px;
  min-width: 70px;
}
</style>
