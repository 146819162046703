<i18n src="@/i18n/components/home-menu/lag-cv.json"></i18n>
<template>
  <MenuPanel
    class="menuPanelLagCv"
    :label-icon="null"
    :is-loading="isLoading"
    :clickable="false"
  >
    <template #header>
      <MenuPanelLagCvHeader />
    </template>
    <MenuPanelError v-if="isError" @retry="onClickRetryLagCv" />
    <div v-else class="menuPanelLagCv__content">
      <MenuPanelLagCvContent v-if="hasConversions" :user-counts="userCounts" />
      <MenuPanelLagCvEmpty v-else />
      <MenuPanelLagCvFooter />
    </div>
  </MenuPanel>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import MenuPanel from "@/components/home-menu/MenuPanel.vue";
import MenuPanelLagCvFooter from "@/components/home-menu/lag-cv/MenuPanelLagCvFooter.vue";
import MenuPanelError from "@/components/home-menu/MenuPanelError.vue";
import MenuPanelLagCvEmpty from "@/components/home-menu/lag-cv/MenuPanelLagCvEmpty.vue";
import MenuPanelLagCvContent from "@/components/home-menu/lag-cv/MenuPanelLagCvContent.vue";
import MenuPanelLagCvHeader from "@/components/home-menu/lag-cv/MenuPanelLagCvHeader.vue";
import LagCvUserCount from "@/models/lag-cv/LagCvUserCount";

@Component({
  components: {
    MenuPanel,
    MenuPanelLagCvFooter,
    MenuPanelError,
    MenuPanelLagCvEmpty,
    MenuPanelLagCvContent,
    MenuPanelLagCvHeader
  }
})
export default class MenuPanelLagCv extends Vue {
  @Emit("click")
  onClickRetryLagCv() {}

  @Prop({ type: Array, required: true })
  userCounts!: LagCvUserCount[];

  @Prop({ type: Boolean, default: false })
  isLoading!: boolean;

  @Prop({ type: Boolean, default: false })
  isError!: boolean;

  get hasConversions(): boolean {
    return this.userCounts.some(uc => uc.conversions.length > 0);
  }
}
</script>

<style lang="scss" scoped>
.menuPanelLagCv__content {
  margin-top: 20px;
  height: 310px;
}
</style>
