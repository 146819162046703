<i18n src="@/i18n/views/totp-input"></i18n>

<template>
  <div class="totpInput" data-cy="totp-input">
    <h1 class="totpInput__title">{{ $t("title") }}</h1>
    <p class="totpInput__subtitle">{{ $t("subtitle") }}</p>
    <label class="totpInput__otpLabel">{{ $t("otpLabel") }}</label>
    <OtpForm
      v-model="otpCode"
      class="totpInput__otpForm"
      :spacing="submitButtonMarginTop"
      @submit="verifyOtpCode"
    />
    <p class="totpInput__remark">{{ $t("remark") }}</p>
    <span class="totpInput__link" @click="logout">{{ $t("loginLink") }}</span>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import InputText from "@/components/form/InputText.vue";
import Button from "@/components/Button.vue";
import OtpForm from "@/components/mfa/OtpForm.vue";
import { handleError, handleMfaErrorOnTotpInputPage } from "@/util/error-util";
import { ErrorType } from "@/store/modules/mfa";

@Component({
  components: {
    InputText,
    Button,
    OtpForm
  }
})
export default class TotpInput extends Vue {
  submitButtonWidth = "354px";
  submitButtonHeight = "35px";
  submitButtonMarginTop = "40px";

  otpCode: string = "";

  get isMfaVerified(): boolean {
    return this.$store.state.mfa.verified;
  }

  get errorType(): ErrorType | undefined {
    return this.$store.state.mfa.errorType;
  }

  async verifyOtpCode(otpCode: string) {
    this.otpCode = otpCode;
    await this.$store.dispatch("mfa/verify", this.otpCode);

    if (this.isMfaVerified) {
      await this.$store.dispatch("app/initializePostLogin");
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect as string);
      } else {
        this.$router.push({
          name: "home"
        });
      }
      return;
    }

    if (this.errorType === ErrorType.Unverified) {
      handleMfaErrorOnTotpInputPage();
      window.setTimeout(() => {
        location.assign("/");
      }, 3000);
    } else {
      handleError({});
      this.otpCode = "";
    }
  }

  async logout() {
    await this.$store.dispatch("auth/logout");
    location.assign("/");
  }
}
</script>

<style lang="scss" scoped>
.totpInput {
  padding-top: 60px;
  padding-bottom: 80px;
  width: 354px;
  margin: 0 auto;
}
.totpInput__title {
  color: $colorBase900;
  font-size: 32px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
}

.totpInput__subtitle {
  margin-top: 40px;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
}

.totpInput__otpLabel {
  display: inline-block;
  margin-top: 40px;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
}

.totpInput__otpForm {
  margin-top: 14px;
}

.totpInput__remark {
  margin-top: 30px;
  font-size: 14px;
  line-height: 1.4;
}

.totpInput__link {
  display: inline-block;
  margin-top: 30px;
  color: $colorBlue1000;
  cursor: pointer;
}
</style>
