<i18n src="@/i18n/components/funnel/funnel-attribute-condition.json" />
<template>
  <FunnelAddableConditionWrapper
    class="funnelAttributeCondition"
    :button-text="addAttributeText"
    :can-add="canAdd"
    :added-conditions="addedConditions"
    @add="onAdd"
    @edit="onEdit"
    @delete="onDelete"
  >
    <FunnelContactAttributeDialog
      v-if="conditionType === FunnelType.Contact"
      :edit-condition="editingAttribute"
      :contact-type="contactType"
      :visible="showAttributeDialog"
      @submit="onSubmit"
      @cancel="onCancel"
    />
    <FunnelConditionAttributeDialog
      v-else
      :edit-condition="editingAttribute"
      :conversion-attribute-definitions="attrDefs"
      :visible="showAttributeDialog"
      @submit="onSubmit"
      @cancel="onCancel"
    />
  </FunnelAddableConditionWrapper>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "@/composables/useI18n";
import FunnelConditionAttributeDialog from "@/components/funnel/FunnelConditionAttributeDialog.vue";
import FunnelContactAttributeDialog from "@/components/funnel/FunnelContactAttributeDialog.vue";
import FunnelAddableConditionWrapper, {
  AddedCondition
} from "@/components/funnel/FunnelAddableConditionWrapper.vue";
import { FunnelType } from "@/const/funnel";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { getFunnelDataAttributeDefinitionLabel } from "@/util/funnel-util";
import { FunnelConditionAttributeType } from "@/models/funnel/FunnelConditionAttribute";
import { ContactDefinitionType } from "@/models/client-settings/ContactDefinition";

const { t } = useI18n();

const props = defineProps<{
  conditionType: FunnelType;
  modelValue: FunnelConditionAttributeType[];
  contactType: ContactDefinitionType;
  attrDefs: ConversionAttributeDefinition[];
}>();

const emit = defineEmits<{
  (e: "update:modelValue", attributes: FunnelConditionAttributeType[]): void;
}>();

const showAttributeDialog = ref(false);

const attributes = computed(() => props.modelValue);

const editingAttribute = computed(
  (): FunnelConditionAttributeType | null => attributes.value[0] || null
);

const canAdd = computed(() => {
  return attributes.value.length === 0;
});

const addAttributeText = computed(() => {
  return props.conditionType === FunnelType.Contact
    ? t("addContactAttribute").toString()
    : t("addConversionAttribute").toString();
});

const addedConditions = computed(() => {
  return attributes.value.map<AddedCondition>(attr => ({
    label: getLabel(attr),
    displayValue: attr.displayValue
  }));
});

function getLabel(attr: FunnelConditionAttributeType) {
  return getFunnelDataAttributeDefinitionLabel(
    props.attrDefs,
    [attr],
    props.conditionType,
    false
  );
}

function onAdd() {
  showAttributeDialog.value = true;
}

function onEdit() {
  showAttributeDialog.value = true;
}

function onDelete() {
  showAttributeDialog.value = false;
  emit("update:modelValue", []);
}

function onSubmit(attribute: FunnelConditionAttributeType) {
  showAttributeDialog.value = false;
  emit("update:modelValue", [attribute]);
}

function onCancel() {
  showAttributeDialog.value = false;
}
</script>
