<i18n src="@/i18n/components/funnel/funnel-condition-select-form.json"></i18n>
<template>
  <div class="funnelConditionSelectForm">
    <div class="funnelConditionSelectForm__userBehavior">
      <div
        v-if="showUserActivityLabel"
        v-t="'userActivity'"
        class="funnelConditionSelectForm__label"
      />

      <SelectBox
        v-model="funnelTypeValue"
        class="funnelAnalysisSearchInput__typeSelectBox dragNotApplicable"
        width="180px"
        :options="funnelTypeOptions"
      />

      <div class="funnelConditionSelectForm__conditions">
        <SelectBox
          v-if="funnelTypeValue === FunnelType.CV"
          v-model="value"
          width="auto"
          class="funnelConditionSelectForm__selectBox dragNotApplicable"
          :options="conversionOptions"
        />
        <SelectBox
          v-else-if="funnelTypeValue === FunnelType.Event"
          v-model="value"
          width="auto"
          class="funnelConditionSelectForm__selectBox dragNotApplicable"
          :options="eventOptions"
        />
        <SelectBox
          v-else-if="funnelTypeValue === FunnelType.BusinessEvent"
          v-model="value"
          width="auto"
          class="funnelConditionSelectForm__selectBox dragNotApplicable"
          :options="businessEventOptions"
        />
        <SelectBox
          v-else-if="funnelTypeValue === FunnelType.Contact"
          :value="value"
          width="auto"
          class="funnelConditionSelectForm__selectBox dragNotApplicable"
          :options="contactOptions"
          @input="onUpdateContactSelectBox"
        />
        <SelectBox
          v-else-if="funnelTypeValue === FunnelType.Inflow"
          v-model="inflowTypeValue"
          width="auto"
          class="funnelConditionSelectForm__selectBox dragNotApplicable"
          :options="inflowRouteOptions"
        />
        <div
          v-else-if="
            funnelTypeValue === FunnelType.PV ||
              funnelTypeValue === FunnelType.Screen
          "
          class="funnelConditionSelectForm__pvOrScreenCondition"
        >
          <div
            class="funnelConditionSelectForm__radioContainer dragNotApplicable"
          >
            <RadioButton
              v-for="option in pvOrScreenOptions"
              :key="option.value"
              v-model="searchField"
              class="funnelConditionSelectForm__radioButton"
              :label="option.value"
            >
              {{ option.label }}
            </RadioButton>
          </div>
          <div
            class="funnelConditionSelectForm__pvOrScreenConditionInput dragNotApplicable"
          >
            <InputTextWithSuggestion
              v-model="value"
              class="funnelAnalysisSearchInput__input"
              :width="'320px'"
              :input-form-suggestion-type="inputFormSuggestionsType"
            />

            <SelectBox
              v-model="matchMethod"
              :options="matchMethodOptions"
              width="140px"
              class="funnelConditionSelectForm__matchMethod"
            />
          </div>
        </div>
      </div>
    </div>

    <FunnelAttributeCondition
      v-if="isDefinitionTypeForm(form) && showAttribute"
      class="funnelConditionSelectForm__attribute hideWhileDragging"
      :condition-type="funnelTypeValue"
      :model-value="attributes"
      :contact-type="contactType"
      :attr-defs="conversionAttributeDefinitions"
      @update:modelValue="onSubmitAttribute"
    />

    <FunnelInflowReferrerOrLandingCondition
      v-if="isInflowTypeForm(form)"
      :model-value="inflowConditions"
      @update:modelValue="onChangeInflowConditions"
    />
    <FilterBaseInputText
      v-if="isInflowParamTypeForm(form)"
      v-model="inflowParamValue"
      class="funnelConditionSelectForm__inflowParamInput dragNotApplicable"
      :prefix="$t('inflowParamPrefix')"
      :suffix="$te('containsSuffix') ? $t('containsSuffix') : ''"
      placeholder=" "
    />
    <FunnelInflowOrganicCondition
      v-if="isInflowOrganicTypeForm(form)"
      :model-value="inflowOrganicConditions"
      :search-engines="searchEngines"
      @update:modelValue="onChangeInflowOrganicConditions"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import RadioButton from "@/components/form/RadioButton.vue";
import SelectBox from "@/components/form/SelectBox.vue";
import SelectOption from "@/components/form/SelectOption";
import FunnelAttributeCondition from "@/components/funnel/FunnelAttributeCondition.vue";
import InputTextWithSuggestion from "@/components/input-form-suggestion/InputTextWithSuggestion.vue";
import { useI18n } from "@/composables/useI18n";
import { FunnelType, SearchField, InflowSearchField } from "@/const/funnel";
import { InputFormSuggestionType } from "@/const/input-form-suggestion";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import {
  ContactDefinition,
  ContactDefinitionType
} from "@/models/client-settings/ContactDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import {
  ConversionDefinition,
  GlobalConversionDefinition
} from "@/models/client-settings/ConversionDefinition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import { FunnelConditionAttributeType } from "@/models/funnel/FunnelConditionAttribute";
import {
  isDefinitionTypeForm,
  isPageTitleOrURLTypeForm,
  FunnelConditionSelectForm,
  isInflowTypeForm,
  FunnelInflowType,
  isInflowParamTypeForm,
  isInflowOrganicTypeForm
} from "@/models/funnel/FunnelConditionSelectForm";
import { MatchMethod } from "@/models/search/MatchMethod";
import { isString, isNumber } from "@/util/common-util";
import FunnelInflowReferrerOrLandingCondition from "@/components/funnel/FunnelInflowReferrerOrLandingCondition.vue";
import FunnelInflowOrganicCondition from "@/components/funnel/FunnelInflowOrganicCondition.vue";
import {
  InflowMatchCondition,
  InflowOrganicMatchCondition
} from "@/models/funnel/FunnelCondition";
import FilterBaseInputText from "@/components/filter/form/FilterBaseInputText.vue";
import { SearchEngine } from "@/models/system/SearchEngine";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    showUserActivityLabel?: boolean;
    modelValue: FunnelConditionSelectForm;
    activeConversionDefinitions: ConversionDefinition[];
    businessEventDefinitions: BusinessEventDefinition[];
    eventDefinitions: EventDefinition[];
    contactDefinitions: ContactDefinition[];
    conversionAttributeDefinitions: ConversionAttributeDefinition[];
    searchEngines: SearchEngine[];
    canUseWebdataFeatures: boolean;
    isContractApp: boolean;
  }>(),
  { showUserActivityLabel: false }
);

const emits = defineEmits<{
  (e: "update:modelValue", v: FunnelConditionSelectForm): void;
}>();

const form = computed(() => props.modelValue);

const funnelTypeValue = computed({
  get() {
    return form.value.type;
  },
  set(funnelType: FunnelType) {
    const type = funnelType;
    let value;
    const attributes: FunnelConditionAttributeType[] = [];

    switch (type) {
      case FunnelType.CV:
        value = conversionOptions.value[0].value;
        onInput({ type, value, attributes });
        break;
      case FunnelType.Event:
        value = eventOptions.value[0].value;
        onInput({ type, value, attributes });
        break;
      case FunnelType.BusinessEvent:
        value = businessEventOptions.value[0].value;
        onInput({ type, value, attributes });
        break;
      case FunnelType.Contact:
        value = contactOptions.value[0].value;
        onInput({ type, value, attributes });
        break;
      case FunnelType.PV:
      case FunnelType.Screen:
        onInput({
          type,
          value: "",
          wordMatchMethod: MatchMethod.Partial,
          searchField: SearchField.URL
        });
        break;
      case FunnelType.Inflow:
        onInput({
          type,
          inflowType: FunnelInflowType.referrerOrLandingPage,
          matchConditions: [
            {
              value: "",
              wordMatchMethod: MatchMethod.Partial,
              searchField: InflowSearchField.LOCATION_REFERRER
            }
          ]
        });
        return;
    }
  }
});

const value = computed({
  get() {
    if (
      isPageTitleOrURLTypeForm(form.value) ||
      isDefinitionTypeForm(form.value)
    ) {
      return form.value.value;
    }
    return "";
  },
  set(value: number | string) {
    if (isPageTitleOrURLTypeForm(form.value) && isString(value)) {
      onInput({ ...form.value, value });
    } else if (isDefinitionTypeForm(form.value) && isNumber(value)) {
      onInput({ ...form.value, value });
    }
  }
});

const searchField = computed({
  get() {
    return isPageTitleOrURLTypeForm(form.value)
      ? form.value.searchField
      : SearchField.URL;
  },
  set(searchField: SearchField) {
    if (isPageTitleOrURLTypeForm(form.value)) {
      onInput({ ...form.value, searchField });
    }
  }
});

const matchMethod = computed({
  get() {
    return isPageTitleOrURLTypeForm(form.value)
      ? form.value.wordMatchMethod
      : MatchMethod.Partial;
  },
  set(wordMatchMethod: MatchMethod) {
    if (isPageTitleOrURLTypeForm(form.value)) {
      onInput({ ...form.value, wordMatchMethod });
    }
  }
});

const attributes = computed({
  get() {
    return isDefinitionTypeForm(form.value) ? form.value.attributes : [];
  },
  set(attributes: FunnelConditionAttributeType[]) {
    if (isDefinitionTypeForm(form.value)) {
      onInput({ ...form.value, attributes });
    }
  }
});

const inflowTypeValue = computed({
  get() {
    if (isInflowTypeForm(form.value))
      return FunnelInflowType.referrerOrLandingPage;

    if (isInflowParamTypeForm(form.value))
      return FunnelInflowType.inflowParameter;

    return FunnelInflowType.organicSearch;
  },
  set(inflowType: FunnelInflowType) {
    if (inflowType === FunnelInflowType.referrerOrLandingPage) {
      onInput({
        type: FunnelType.Inflow,
        inflowType,
        matchConditions: [
          {
            value: "",
            wordMatchMethod: MatchMethod.Partial,
            searchField: InflowSearchField.LOCATION_REFERRER
          }
        ]
      });
    } else if (inflowType === FunnelInflowType.inflowParameter) {
      onInput({
        type: FunnelType.Inflow,
        inflowType,
        inflowParam: ""
      });
    } else if (inflowType === FunnelInflowType.organicSearch) {
      onInput({
        type: FunnelType.Inflow,
        inflowType,
        matchConditions: []
      });
    }
  }
});

const inflowParamValue = computed({
  get() {
    return isInflowParamTypeForm(form.value) ? form.value.inflowParam : "";
  },
  set(inflowParam: string) {
    if (isInflowParamTypeForm(form.value)) {
      onInput({ ...form.value, inflowParam });
    }
  }
});

const inflowConditions = computed({
  get() {
    return isInflowTypeForm(form.value) ? form.value.matchConditions : [];
  },
  set(matchConditions: InflowMatchCondition[]) {
    if (isInflowTypeForm(form.value)) {
      onInput({ ...form.value, matchConditions });
    }
  }
});

const inflowOrganicConditions = computed({
  get() {
    return isInflowOrganicTypeForm(form.value)
      ? form.value.matchConditions
      : [];
  },
  set(matchConditions: InflowOrganicMatchCondition[]) {
    if (isInflowOrganicTypeForm(form.value)) {
      onInput({ ...form.value, matchConditions });
    }
  }
});

const funnelTypeOptions = computed<SelectOption<FunnelType>[]>(() => {
  const availableTypes = [];

  if (props.activeConversionDefinitions.length > 0)
    availableTypes.push(FunnelType.CV);
  if (props.eventDefinitions.length > 0) availableTypes.push(FunnelType.Event);
  if (props.businessEventDefinitions.length > 0)
    availableTypes.push(FunnelType.BusinessEvent);
  if (contactOptions.value.length > 0) availableTypes.push(FunnelType.Contact);
  if (props.canUseWebdataFeatures) availableTypes.push(FunnelType.PV);
  if (props.isContractApp) availableTypes.push(FunnelType.Screen);
  if (props.canUseWebdataFeatures) availableTypes.push(FunnelType.Inflow);

  return availableTypes.map(type => ({
    value: type,
    label: funnelTypeLabel(type),
    disabled: false
  }));
});

const conversionOptions = computed<SelectOption<number>[]>(() =>
  getSelectOptions(props.activeConversionDefinitions)
);

const eventOptions = computed<SelectOption<number>[]>(() =>
  getSelectOptions(props.eventDefinitions)
);

const businessEventOptions = computed<SelectOption<number>[]>(() =>
  getSelectOptions(props.businessEventDefinitions)
);

const contactOptions = computed<SelectOption<number>[]>(() => {
  const availableContacts = [
    ContactDefinitionType.TEL,
    ContactDefinitionType.MAIL,
    ContactDefinitionType.SHOP
  ]
    .map(type => props.contactDefinitions.find(def => def.type === type))
    .filter((def): def is ContactDefinition => def !== undefined);

  return availableContacts.map(def => ({
    value: def.id,
    label: contactLabel(def.type),
    disabled: false
  }));
});

const pvOrScreenOptions = computed(() => {
  if (funnelTypeValue.value === FunnelType.PV) {
    return [
      {
        value: SearchField.URL,
        label: t("labelPageUrl").toString(),
        disabled: false
      },
      {
        value: SearchField.PAGE_TITLE,
        label: t("labelPageTitle").toString(),
        disabled: false
      }
    ];
  } else if (funnelTypeValue.value === FunnelType.Screen) {
    return [
      {
        value: SearchField.URL,
        label: t("screenURL").toString(),
        disabled: false
      },
      {
        value: SearchField.PAGE_TITLE,
        label: t("screenTitle").toString(),
        disabled: false
      }
    ];
  }
  return [];
});

const matchMethodOptions: SelectOption<MatchMethod>[] = [
  {
    value: MatchMethod.Partial,
    label: t("partialMatch").toString(),
    disabled: false
  },
  {
    value: MatchMethod.Exact,
    label: t("perfectMatch").toString(),
    disabled: false
  },
  {
    value: MatchMethod.Wildcard,
    label: t("wildcardMatch").toString(),
    disabled: false
  }
];

const inflowRouteOptions = computed(() => {
  return [
    {
      value: FunnelInflowType.referrerOrLandingPage,
      label: t("referrerOrLandingPage").toString(),
      disabled: false
    },
    {
      value: FunnelInflowType.inflowParameter,
      label: t("inflowParam").toString(),
      disabled: false
    },
    {
      value: FunnelInflowType.organicSearch,
      label: t("inflowFromOrganicSearch").toString(),
      disabled: false
    }
  ];
});

const inputFormSuggestionsType = computed(() => {
  if (funnelTypeValue.value === FunnelType.PV) {
    if (searchField.value === SearchField.PAGE_TITLE) {
      return InputFormSuggestionType.WEB_TITLE;
    } else if (searchField.value === SearchField.URL) {
      return InputFormSuggestionType.WEB_URL;
    }
  }
  if (funnelTypeValue.value === FunnelType.Screen) {
    if (searchField.value === SearchField.PAGE_TITLE) {
      return InputFormSuggestionType.APP_TITLE;
    } else if (searchField.value === SearchField.URL) {
      return InputFormSuggestionType.APP_URL;
    }
  }
  return InputFormSuggestionType.WEB_URL;
});

function funnelTypeLabel(type: FunnelType) {
  return t(FunnelType[type].toLowerCase()).toString();
}

function contactLabel(type: ContactDefinitionType) {
  return t(ContactDefinitionType[type].toLowerCase()).toString();
}

function onInput(form: FunnelConditionSelectForm) {
  emits("update:modelValue", form);
}

function onUpdateContactSelectBox(newValue: number) {
  if (isDefinitionTypeForm(form.value)) {
    onInput({ type: form.value.type, value: newValue, attributes: [] });
  }
}

const contactType = computed(() => {
  if (funnelTypeValue.value === FunnelType.Contact) {
    const contact = props.contactDefinitions.find(c => c.id === value.value);
    if (contact) {
      return contact.type;
    }
  }

  return ContactDefinitionType.TEL;
});

const showAttribute = computed(() => {
  if (funnelTypeValue.value === FunnelType.Contact) return true;
  if (props.conversionAttributeDefinitions.length === 0) return false;

  if (funnelTypeValue.value === FunnelType.CV) {
    const definition = props.activeConversionDefinitions.find(
      d => d.id === value.value
    );
    if (definition instanceof GlobalConversionDefinition) return false;
  }

  return [FunnelType.CV, FunnelType.Event].includes(funnelTypeValue.value);
});

function onSubmitAttribute(newAttributes: FunnelConditionAttributeType[]) {
  attributes.value = newAttributes;
}

const getSelectOptions = (defs: { id: number; name: string }[]) =>
  defs.map(d => ({
    value: d.id,
    label: d.name,
    disabled: false
  }));

function onChangeInflowConditions(newConditions: InflowMatchCondition[]) {
  inflowConditions.value = newConditions;
}

function onChangeInflowOrganicConditions(
  newConditions: InflowOrganicMatchCondition[]
) {
  inflowOrganicConditions.value = newConditions;
}
</script>

<style lang="scss" scoped>
.funnelConditionSelectForm__userBehavior {
  display: flex;
}

.funnelConditionSelectForm__label {
  flex-shrink: 0;
  padding-top: 10px;
  color: $colorBase900;
  font-weight: bold;
  font-size: 16px;
  margin-right: 20px;
}

.funnelConditionSelectForm__conditions {
  margin-left: 15px;

  .funnelConditionSelectForm__selectBox {
    min-width: 180px;
  }
}

.funnelConditionSelectForm__pvOrScreenCondition {
  padding-top: 5px;
}

.funnelConditionSelectForm__matchMethod {
  margin-left: 15px;
}

.funnelConditionSelectForm__screenCondition {
  padding-top: 5px;
}

.funnelConditionSelectForm__radioButton {
  margin-right: 5px;
}

.funnelConditionSelectForm__pvOrScreenConditionInput {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.funnelConditionSelectForm__screenConditionInput {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.funnelConditionSelectForm__radioContainer {
  display: inline-flex;
}

.funnelConditionSelectForm__attribute {
  margin-top: 15px;
}

.funnelConditionSelectForm__inflowParamInput {
  margin-top: 15px;
}
</style>
