<i18n src="@/i18n/components/onboarding/home-onboarding.json"></i18n>
<template>
  <div class="home-onboarding" data-cy="home-onboarding">
    <Transition name="fadeOverlay">
      <Overlay v-show="showOnboarding" @click="closeOnboarding" />
    </Transition>
    <Transition name="fadeContent" @after-leave="afterLeave">
      <div v-show="showOnboarding" class="home-onboarding__content">
        <OnBoardingHomeJa
          v-if="locale === AvailableLanguages.ja"
          class="home-onboarding__svg"
        />
        <OnBoardingHomeZhHant
          v-else-if="locale === AvailableLanguages['zh-hant']"
          class="home-onboarding__svg-zh"
        />
        <OnBoardingHomeZhHans
          v-else-if="locale === AvailableLanguages['zh-hans']"
          class="home-onboarding__svg-zh"
        />
        <OnBoardingHomeEn v-else class="home-onboarding__svg" />

        <div class="home-onboarding__actions">
          <LinkText
            class="home-onboarding__actions-link__wrapper"
            data-cy="home-onboarding__actions-link__wrapper"
            url="https://help.usergram.info/"
            :link-text="$t('helpSiteLink')"
          />

          <CheckBox
            :checked="noMoreShow"
            data-cy="home-onboarding-checkbox"
            @input="onUpdate"
            >{{ $t("hideNextTime") }}</CheckBox
          >
          <div class="home-onboarding__close-button" @click="closeOnboarding">
            <div
              class="home-onboarding__close-button__inner"
              data-cy="home-onboarding-close-button"
            >
              <Icon
                class="home-onboarding__close-button__icon"
                :icon="Icons.Close"
                :color="Colors.White"
                :size="10"
              />
              {{ $t("close") }}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Emit, Model, Vue } from "vue-property-decorator";
import Overlay from "@/components/Overlay.vue";

import Icon from "@/components/Icon.vue";
import Button from "@/components/Button.vue";
import CheckBox from "@/components/form/CheckBox.vue";
import LinkText from "@/components/LinkText.vue";

import { AvailableLanguages } from "@/i18n/index";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";

@Component({
  components: {
    Overlay,
    Icon,
    Button,
    CheckBox,
    LinkText,
    OnBoardingHomeEn: () => import("./HomeOnboardingEn.vue"),
    OnBoardingHomeJa: () => import("./HomeOnboardingJa.vue"),
    OnBoardingHomeZhHant: () => import("./HomeOnboardingZhHant.vue"),
    OnBoardingHomeZhHans: () => import("./HomeOnboardingZhHans.vue")
  }
})
export default class HomeOnboarding extends Vue {
  Icons = Icons;
  Colors = Colors;
  AvailableLanguages = AvailableLanguages;
  showOnboarding = false;

  @Model("input", { type: Boolean, required: true })
  noMoreShow!: boolean;

  @Prop({ type: String, required: true })
  locale!: AvailableLanguages;

  onUpdate(isChecked: boolean) {
    this.$emit("input", isChecked);
  }

  @Emit("close")
  onClose() {}

  mounted() {
    this.showOnboarding = true;
  }

  closeOnboarding() {
    this.showOnboarding = false;
  }

  afterLeave() {
    this.onClose();
  }
}
</script>

<style lang="scss" scoped>
.fadeContent-enter-active,
.fadeContent-leave-active {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}
.fadeContent-enter,
.fadeContent-leave-to {
  opacity: 0;
  transform: translateY(-40px);
}

.fadeOverlay-leave-active {
  transition: opacity 0.3s ease-out;
}

.fadeOverlay-leave-to {
  opacity: 0;
}

.home-onboarding {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.home-onboarding__content {
  position: relative;
  display: inline-flex;
}

.home-onboarding__svg {
  width: 1000px;
  height: 640px;
}

.home-onboarding__svg-zh {
  width: 1000px;
  height: 610px;
}

.home-onboarding__actions {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 39px 0 41px;
  width: 100%;
  height: 96px;
}

.home-onboarding__actions-link__wrapper {
  display: inline-flex;
  align-items: center;
  flex: 1;
}

.home-onboarding__close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  width: 100px;
  height: 26px;
  border-radius: $sizeRadius;
  background: $colorBase900;
  color: $colorWhite;
  cursor: pointer;

  &:hover {
    background: $colorBase1000;
  }
}
.home-onboarding__close-button__inner {
  display: flex;
  align-items: center;
  padding: 0 9px;
}

.home-onboarding__close-button__icon {
  margin-right: 8px;
}
</style>
