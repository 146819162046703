<i18n src="@/i18n/components/search/filter.json"></i18n>
<template>
  <FilterNode
    :index="index"
    :depth="condition.depth"
    :show-or-drop-area="condition.depth === 0 && isDragging"
    :title="$t('nodeTitleBrowseSite')"
    class="filter-node-browse-site"
    :show-add-condition="condition.isConditionAddable"
    :exclusion="exclusion"
    :has-all-additional-conditions="checkAdditionalConditions"
    :select-exclusion-type-value="exclutionTypeValue"
    @on-select-exclusion-type="onSelectExclusionType"
    @add-condition="onClickAddCondition"
    @add-or-node="$emit('add-or-node', $event)"
    @remove-node="$emit('remove-node')"
  >
    <FilterNodeItem
      v-model="conditionType"
      :is-deletable="false"
      :options="condition.selectOption(condition.condition, true, isFirstNode)"
    >
      <FilterSiteUrlField
        v-if="conditionType === siteUrl"
        v-model="mainCondition"
      />
      <FilterPageTitleField
        v-if="conditionType === pageTitle"
        v-model="mainCondition"
      />
    </FilterNodeItem>
    <FilterStayTimeField v-if="canUseStayTime" v-model="stayTimeCondition" />
    <FilterNodeItem
      v-for="(additionalCondition, index) in condition.additionalConditions"
      :key="index"
      :options="condition.selectOption(additionalCondition, false, isFirstNode)"
      :condition-type="additionalCondition.conditionType"
      :class="index == 0 ? 'filter-first-additional-node-item' : ''"
      @input="
        type => {
          onInputItem(type, index);
        }
      "
      @remove="onRemoveAdditionalCondition(index)"
    >
      <FilterSiteUrlField
        v-if="additionalCondition.conditionType === siteUrl"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterPageTitleField
        v-if="additionalCondition.conditionType === pageTitle"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterDateHourField
        v-if="additionalCondition.conditionType === dateHour"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterPeriodField
        v-if="additionalCondition.conditionType === period"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
    </FilterNodeItem>
  </FilterNode>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";

import {
  ExclusionType,
  ExclusionProps,
  FilterExclusion
} from "@/models/search/filter-node/FilterExclusion";
import { FilterNodeForBrowseSite } from "@/models/search/filter-node/FilterNodeForBrowseSite";
import { FilterSiteUrlCondition } from "@/models/search/filter-node-condition/FilterSiteUrlCondition";
import { FilterPageTitleCondition } from "@/models/search/filter-node-condition/FilterPageTitleCondition";
import { FilterAdditionalTimingCondition } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterStayTimeCondition } from "@/models/search/filter-node-condition/FilterStayTimeCondition";
import { MatchMethod } from "@/models/search/MatchMethod";

import FilterNode from "@/components/filter/FilterNode.vue";
import FilterNodeItem from "@/components/filter/FilterNodeItem.vue";
import FilterDateHourField from "@/components/filter/form/FilterDateHourField.vue";
import FilterPeriodField from "@/components/filter/form/FilterPeriodField.vue";
import FilterPageTitleField from "@/components/filter/form/FilterPageTitleField.vue";
import FilterSiteUrlField from "@/components/filter/form/FilterSiteUrlField.vue";
import FilterStayTimeField from "@/components/filter/form/FilterStayTimeField.vue";

@Component({
  name: "FilterNodeBrowseSite",
  components: {
    FilterNode,
    FilterNodeItem,
    FilterDateHourField,
    FilterPeriodField,
    FilterPageTitleField,
    FilterSiteUrlField,
    FilterStayTimeField
  }
})
export default class FilterNodeBrowseSite extends Vue {
  pageTitle = FilterNodeConditionType.PageTitle;
  siteUrl = FilterNodeConditionType.SiteUrl;
  dateHour = FilterNodeConditionType.DateHour;
  period = FilterNodeConditionType.Period;

  @Model("input", { type: FilterNodeForBrowseSite, required: true })
  condition!: FilterNodeForBrowseSite;

  @Prop({ type: Boolean, required: true })
  isDragging!: boolean;

  @Prop({ type: Boolean, required: true })
  isFirstNode!: boolean;

  @Prop({ type: Number, required: true })
  index!: number;

  @Prop({ type: Object, required: true })
  exclusion!: ExclusionProps;

  onInput(condition: FilterNodeForBrowseSite) {
    this.$emit("input", condition);
  }

  get conditionType():
    | FilterNodeConditionType.SiteUrl
    | FilterNodeConditionType.PageTitle {
    return this.condition.condition.conditionType;
  }
  set conditionType(
    conditionType:
      | FilterNodeConditionType.SiteUrl
      | FilterNodeConditionType.PageTitle
  ) {
    let condition!: FilterSiteUrlCondition | FilterPageTitleCondition;
    if (conditionType === FilterNodeConditionType.SiteUrl) {
      condition = new FilterSiteUrlCondition("", MatchMethod.Partial);
    } else if (conditionType === FilterNodeConditionType.PageTitle) {
      condition = new FilterPageTitleCondition("", MatchMethod.Partial);
    }
    this.onInput(this.createCondition(condition, null, null));
  }

  get mainCondition(): FilterSiteUrlCondition | FilterPageTitleCondition {
    return this.condition.condition;
  }
  set mainCondition(
    condition: FilterSiteUrlCondition | FilterPageTitleCondition
  ) {
    this.onInput(this.createCondition(condition, null, null));
  }

  get stayTimeCondition(): FilterStayTimeCondition {
    return this.condition.filterStayTime;
  }
  set stayTimeCondition(condition: FilterStayTimeCondition) {
    this.onInput(this.createCondition(null, null, condition));
  }

  // 条件を追加
  onClickAddCondition() {
    const additionalConditions = this.condition.additionalConditions;
    let addCondition!:
      | FilterPageTitleCondition
      | FilterSiteUrlCondition
      | FilterAdditionalTimingCondition;
    const hasFirstTime = additionalConditions.some(
      cnd => cnd instanceof FilterFirstTimeCondition
    );

    if (!this.condition.hasSiteUrlCondition) {
      addCondition = new FilterSiteUrlCondition("", MatchMethod.Partial);
    } else if (!this.condition.hasPageTitleCondition) {
      addCondition = new FilterPageTitleCondition("", MatchMethod.Partial);
    } else if (this.isFirstNode && !hasFirstTime) {
      addCondition = FilterFirstTimeCondition.buildDefaultData();
    } else {
      addCondition = FilterPeriodCondition.buildDefaultData();
    }

    additionalConditions.push(addCondition);
    this.onInput(this.createCondition(null, additionalConditions, null));
  }

  onRemoveAdditionalCondition(index: number) {
    const additionalConditions = this.condition.additionalConditions.filter(
      (cnd, idx) => idx !== index
    );
    this.onInput(this.createCondition(null, additionalConditions, null));
  }

  onInputItem(type: FilterNodeConditionType, index: number) {
    let additionalCondition!:
      | FilterPageTitleCondition
      | FilterSiteUrlCondition
      | FilterAdditionalTimingCondition;

    if (type === FilterNodeConditionType.SiteUrl) {
      additionalCondition = new FilterPageTitleCondition(
        "",
        MatchMethod.Partial
      );
    } else if (type === FilterNodeConditionType.PageTitle) {
      additionalCondition = new FilterSiteUrlCondition("", MatchMethod.Partial);
    } else if (type === FilterNodeConditionType.FirstTime) {
      additionalCondition = FilterFirstTimeCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.DateHour) {
      additionalCondition = FilterDateHourCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.Period) {
      additionalCondition = FilterPeriodCondition.buildDefaultData();
    }

    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? additionalCondition : cnd)
    );
    this.onInput(this.createCondition(null, additionalConditions, null));
  }

  onInputAdditionalCondition(
    condition:
      | FilterSiteUrlCondition
      | FilterPageTitleCondition
      | FilterAdditionalTimingCondition,
    index: number
  ) {
    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(this.createCondition(null, additionalConditions, null));
  }

  createCondition(
    condition: FilterSiteUrlCondition | FilterPageTitleCondition | null,
    conditions:
      | (
          | FilterSiteUrlCondition
          | FilterPageTitleCondition
          | FilterAdditionalTimingCondition
        )[]
      | null,
    filterStayTime: FilterStayTimeCondition | null
  ): FilterNodeForBrowseSite {
    const setCondition =
      condition === null ? this.condition.condition : condition;
    const setConditions =
      conditions === null ? this.condition.additionalConditions : conditions;
    const setFilterTime =
      filterStayTime === null ? this.condition.filterStayTime : filterStayTime;
    return new FilterNodeForBrowseSite(
      setCondition,
      setConditions,
      this.condition.childIndex,
      this.condition.depth,
      this.condition.edge,
      this.condition.filterExclusion,
      setFilterTime
    );
  }

  onSelectExclusionType(exclutionTypeValue: ExclusionType) {
    return this.onInput(
      new FilterNodeForBrowseSite(
        this.condition.condition,
        this.condition.additionalConditions,
        this.condition.childIndex,
        this.condition.depth,
        this.condition.edge,
        new FilterExclusion(exclutionTypeValue),
        this.condition.filterStayTime
      )
    );
  }
  get exclutionTypeValue() {
    return this.condition.filterExclusion.exclusionType;
  }

  get canUseStayTime(): boolean {
    return !this.exclusion.isExcluded && this.condition.depth === 0;
  }

  get checkAdditionalConditions(): boolean {
    const conditionsMax = 2;
    const conditions = this.$props.condition;

    return (
      conditions.additionalConditions &&
      conditions.additionalConditions.length === conditionsMax
    );
  }
}
</script>
<style scoped>
.filter-first-additional-node-item {
  margin-top: 15px;
}
</style>
