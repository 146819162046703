<i18n src="@/i18n/components/home-menu.json"></i18n>
<template>
  <MenuPanel :label-icon="icons.Heart" :is-loading="isLoading" @click="onClick">
    <template #header>
      <div v-t="'bookmarkedUsers'" />
    </template>
    <div v-if="userInfo.length > 0" class="bookmark-panel-container">
      <div v-for="u in userInfo" :key="u.userId" class="bookmark-panel-icon">
        <UserIcon
          :is-pc="u.pv.isPc"
          :is-application="u.pv.isApplication"
          :gaze-level="u.pv.gazeLevelFromPv(clientPvStats)"
        />
        <div class="table-icon-p">
          <UserServiceIdText
            :service-id-text="u.serviceIdForDisplay"
            :is-fixed-service-id="u.isFixedServiceId"
            :ellipsis="true"
          />
        </div>
        <div class="search-panel-time">
          {{ formatDate(u.createdAt, u.createdAtTimezoneOffset) }}
          <br />
          {{ formatTime(u.createdAt, u.createdAtTimezoneOffset) }}
        </div>
      </div>
    </div>
    <EmptyDataFallback
      v-else
      :description="$t('descriptionAboutBookmarkUser')"
      tips-image-path="img/home/tips-bookmark-user.png"
      :tips-body="$t('explanationForTipsAddBookmarkUser')"
      help-site-url="https://help.usergram.info/article/311-favorite"
    >
      {{ $t("titleForTipsAddBookmarkUser1") }}
      <Icon :icon="icons.HeartPlus" :size="12" :color="iconColor" />
      {{
        $te("titleForTipsAddBookmarkUser2")
          ? $t("titleForTipsAddBookmarkUser2")
          : ""
      }}
    </EmptyDataFallback>
  </MenuPanel>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import MenuPanel from "@/components/home-menu/MenuPanel.vue";
import EmptyDataFallback from "@/components/home-menu/EmptyDataFallback.vue";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { UserInfo } from "@/models/UserInfo";
import { ClientPvStats } from "@/models/system/ClientPvStats";
import { DateFormat, formatDate } from "@/util/date-util";
import UserIcon from "@/components/users/UserIcon.vue";
import UserServiceIdText from "@/components/users/UserServiceIdText.vue";
import { Colors } from "@/const/Colors";

@Component({
  components: {
    MenuPanel,
    UserIcon,
    UserServiceIdText,
    EmptyDataFallback,
    Icon
  }
})
export default class MenuPanelBookmark extends Vue {
  icons = Icons;
  iconColor = Colors.Base600;

  @Emit("click")
  onClick() {}

  @Prop({ type: Array, required: true })
  userInfo!: UserInfo[];

  @Prop({ type: ClientPvStats, required: true })
  clientPvStats!: ClientPvStats;

  @Prop({ type: Boolean, default: false })
  isLoading!: boolean;

  formatDate(createdDate: Date, timezoneOffset: number): string {
    return formatDate(DateFormat.yyyysMMsdd, createdDate, timezoneOffset);
  }
  formatTime(createdDate: Date, timezoneOffset: number): string {
    return formatDate(DateFormat.HHcmm, createdDate, timezoneOffset);
  }
}
</script>

<style lang="scss" scoped>
.bookmark-panel-container {
  display: flex;
  flex-wrap: wrap;
}
.bookmark-panel-icon {
  padding-top: 35px;
  width: 33.3%;
}
.table-icon-p {
  overflow: hidden;
  margin: 10px auto 0;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}
.search-panel-time {
  margin-top: 8px;
  color: $colorBase700;
  font-size: 11px;
  line-height: 1.2;
}
</style>
