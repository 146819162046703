<template>
  <div
    class="funnelItemOrder"
    :class="{
      'funnelItemOrder--selected': isSelected
    }"
    v-text="order"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class FunnelItemOrder extends Vue {
  @Prop({ type: Number, required: true })
  order!: number;

  @Prop({ type: Boolean, default: false })
  isSelected!: Boolean;
}
</script>

<style lang="scss" scoped>
.funnelItemOrder {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.1%;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $colorBase900;
  color: $colorWhite;
  font-weight: bold;
  font-size: 10px;
  line-height: 20px;
}

.funnelItemOrder--selected {
  background-color: $colorHighlightedBar;
}
</style>
