<template>
  <div class="child-filter-node-search-engine">
    <FilterNodeItem
      v-for="(additionalCondition, index) in condition.additionalConditions"
      :key="index"
      :options="condition.selectOption(additionalCondition, false, isFirstNode)"
      :condition-type="additionalCondition.conditionType"
      @input="
        type => {
          onInputItem(type, index);
        }
      "
      @remove="onRemoveAdditionalCondition(index)"
    >
      <FilterSearchEngineField
        v-if="additionalCondition.conditionType === searchEngine"
        :condition="additionalCondition"
        :search-engines="searchEngines"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterLandingPageUrlField
        v-if="additionalCondition.conditionType === landingPageUrl"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterLandingPageTitleField
        v-if="additionalCondition.conditionType === landingPageTitle"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterDateHourField
        v-if="additionalCondition.conditionType === dateHour"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterPeriodField
        v-if="additionalCondition.conditionType === period"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
    </FilterNodeItem>
    <FilterAddConditionButton
      v-if="condition.isConditionAddable"
      @click="onClickAddCondition"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import FilterNodeItem from "@/components/filter/FilterNodeItem.vue";
import FilterAddConditionButton from "@/components/filter/FilterAddConditionButton.vue";
import FilterSearchEngineField from "@/components/filter/form/FilterSearchEngineField.vue";
import FilterLandingPageUrlField from "@/components/filter/form/FilterLandingPageUrlField.vue";
import FilterLandingPageTitleField from "@/components/filter/form/FilterLandingPageTitleField.vue";
import FilterDateHourField from "@/components/filter/form/FilterDateHourField.vue";
import FilterPeriodField from "@/components/filter/form/FilterPeriodField.vue";

import { SearchEngine } from "@/models/system/SearchEngine";
import { ChildFilterNodeForSearchEngine } from "@/models/search/filter-node/ChildFilterNodeForSearchEngine";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterSearchEngineCondition } from "@/models/search/filter-node-condition/FilterSearchEngineCondition";
import { FilterLandingPageUrlCondition } from "@/models/search/filter-node-condition/FilterLandingPageUrlCondition";
import { FilterLandingPageTitleCondition } from "@/models/search/filter-node-condition/FilterLandingPageTitleCondition";
import { FilterAdditionalTimingCondition } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { MatchMethod } from "@/models/search/MatchMethod";

@Component({
  components: {
    FilterNodeItem,
    FilterSearchEngineField,
    FilterLandingPageUrlField,
    FilterLandingPageTitleField,
    FilterDateHourField,
    FilterPeriodField,
    FilterAddConditionButton
  }
})
export default class ChildFilterNodeSearchEngine extends Vue {
  searchEngine = FilterNodeConditionType.SearchEngine;
  landingPageUrl = FilterNodeConditionType.LandingPageUrl;
  landingPageTitle = FilterNodeConditionType.LandingPageTitle;

  dateHour = FilterNodeConditionType.DateHour;
  period = FilterNodeConditionType.Period;

  @Model("input", { type: ChildFilterNodeForSearchEngine, required: true })
  condition!: ChildFilterNodeForSearchEngine;

  @Prop({ type: Number, required: true })
  depth!: number;

  @Prop({ type: Boolean, required: true })
  isFirstNode!: boolean;

  @Prop({ type: Array, required: true })
  searchEngines!: SearchEngine[];

  onInput(condition: ChildFilterNodeForSearchEngine) {
    this.$emit("input", condition);
  }

  onRemoveAdditionalCondition(index: number) {
    const additionalConditions = this.condition.additionalConditions.filter(
      (cnd, idx) => idx !== index
    );
    this.onInput(
      new ChildFilterNodeForSearchEngine(additionalConditions, this.depth)
    );
  }

  onInputItem(type: FilterNodeConditionType, index: number) {
    let condition!:
      | FilterSearchEngineCondition
      | FilterLandingPageUrlCondition
      | FilterLandingPageTitleCondition
      | FilterAdditionalTimingCondition;

    if (type === FilterNodeConditionType.SearchEngine) {
      condition = new FilterSearchEngineCondition(this.searchEngines[0].id);
    } else if (type === FilterNodeConditionType.LandingPageUrl) {
      condition = new FilterLandingPageUrlCondition("", MatchMethod.Partial);
    } else if (type === FilterNodeConditionType.LandingPageTitle) {
      condition = new FilterLandingPageTitleCondition("", MatchMethod.Partial);
    } else if (type === FilterNodeConditionType.FirstTime) {
      condition = FilterFirstTimeCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.DateHour) {
      condition = FilterDateHourCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.Period) {
      condition = FilterPeriodCondition.buildDefaultData();
    }

    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(
      new ChildFilterNodeForSearchEngine(additionalConditions, this.depth)
    );
  }

  onInputAdditionalCondition(
    condition: FilterAdditionalTimingCondition,
    index: number
  ) {
    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(
      new ChildFilterNodeForSearchEngine(additionalConditions, this.depth)
    );
  }

  onClickAddCondition() {
    const additionalConditions = this.condition.additionalConditions;
    let condition!:
      | FilterSearchEngineCondition
      | FilterLandingPageUrlCondition
      | FilterLandingPageTitleCondition;

    if (!this.condition.hasSearchEngineCondition) {
      condition = new FilterSearchEngineCondition(this.searchEngines[0].id);
    } else if (!this.condition.hasLandingPageUrlCondition) {
      condition = new FilterLandingPageUrlCondition("", MatchMethod.Partial);
    } else if (!this.condition.hasLandingPageTitleCondition) {
      condition = new FilterLandingPageTitleCondition("", MatchMethod.Partial);
    }

    additionalConditions.push(condition);
    this.onInput(
      new ChildFilterNodeForSearchEngine(additionalConditions, this.depth)
    );
  }
}
</script>
