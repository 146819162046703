import { AdditionalSelectCondition } from "@/models/search/additional-condition/AdditionalSelectCondition";
import { ConversionAttributeTextCondition } from "@/models/search/additional-condition/ConversionAttributeTextCondition";
import { UserAttributeStringCondition } from "@/models/search/additional-condition/UserAttributeStringCondition";
import { UserAttributeNumberCondition } from "@/models/search/additional-condition/UserAttributeNumberCondition";
import { NpsCondition } from "@/models/search/additional-condition/NpsCondition";
import { EnqueteCondition } from "@/models/search/additional-condition/EnqueteCondition";
import { BusinessIndexCondition } from "@/models/search/additional-condition/BusinessIndexCondition";
import { BusinessEventAttributeCondition } from "@/models/search/additional-condition/BusinessEventAttributeCondition";
import { ContactAttributeCondition } from "@/models/search/additional-condition/ContactAttributeCondition";

import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { NpsDefinition } from "@/models/client-settings/NpsDefinition";
import { EnqueteDefinition } from "@/models/client-settings/EnqueteDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { BusinessIndexDefinition } from "@/models/client-settings/BusinessIndexDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import {
  ContactDefinition,
  ContactDefinitionType
} from "@/models/client-settings/ContactDefinition";
import {
  SelectByServiceIdCondition,
  convertSelectByServiceIdConditionToJson,
  convertJsonToSelectByServiceIdCondition,
  isSelectByServiceIdQuery
} from "@/models/search/select-condition/SelectByServiceIdCondition";
import {
  SelectByNpsCondition,
  isSelectByNpsQuery,
  convertJsonToSelectByNpsCondition,
  convertSelectByNpsConditionToJson
} from "@/models/search/select-condition/SelectByNpsCondition";
import {
  SelectByNpsChangeCondition,
  isSelectByNpsChangeQuery,
  convertJsonToSelectByNpsChangeCondition,
  convertSelectByNpsChangeConditionToJson
} from "@/models/search/select-condition/SelectByNpsChangeCondition";
import {
  SelectByContactCondition,
  isSelectByContactQuery,
  convertJsonToSelectByContactCondition,
  convertSelectByContactConditionToJson
} from "@/models/search/select-condition/SelectByContactCondition";
import {
  SelectByBusinessEventCondition,
  convertSelectByBusinessEventConditionToJson,
  isSelectByBusinessEventQuery,
  convertJsonToSelectByBusinessEventCondition
} from "@/models/search/select-condition/SelectByBusinessEventCondition";
import {
  SelectByConversionCondition,
  convertSelectByConversionConditionToJson,
  convertJsonToSelectByConversionCondition,
  isSelectByConversionQuery
} from "@/models/search/select-condition/SelectByConversionCondition";
import {
  SelectByUserIdCondition,
  convertSelectByUserIdConditionToJson,
  convertJsonToSelectByUserIdCondition,
  isSelectByUserIdQuery
} from "@/models/search/select-condition/SelectByUserIdCondition";
import { SelectUserApiRequestParams, SelectQuery } from "@/api/apis/ApiSearch";
import { ActiveDefinitions } from "@/store/modules/clientSettings";
import {
  SelectByAppCondition,
  convertSelectByAppConditionToJson,
  convertJsonToSelectByAppCondition
} from "@/models/search/select-condition/SelectByAppCondition";
import { SearchHistoryType } from "@/api/apis/ApiSearchHistory";
import {
  SelectByEngagementCondition,
  isSelectByEngagementQuery,
  convertJsonToSelectByEngagementCondition
} from "@/models/search/select-condition/SelectByEngagementCondition";
import { MeasurementTargetSite } from "@/models/client-settings/MeasurementTargetSite";
import {
  msecToSec,
  isTwoYearsAgoDate,
  getTwoYearsAgoStartDate,
  getTwoYearsAgoEndDate
} from "@/util/date-util";
import { i18n } from "@/i18n";
import { HistoryDateValidationResult } from "@/models/search/ValidationResult";
import {
  SelectByUserJourneyCondition,
  convertJsonToSelectByUserJourneyCondition,
  convertSelectByUserJourneyConditionToJson,
  isSelectByUserJourneyQuery
} from "@/models/search/select-condition/SelectByUserJourneyCondition";
/**
 * 追加条件を追加できる最大数
 */
export const MAX_HOLD_ADDITIONAL_CONDITION_SIZE = 5;

/**
 * 検索条件の期間としてセットできる最大期間日付
 */
export const MAX_PERIOD_DAYS: number = 92;
export const SIX_MONTHS_DAYS: number = 184;

export interface SelectConditionInterface {
  isAdditionalConditionAppendable: boolean;
}

export type SelectCondition =
  | SelectByServiceIdCondition
  | SelectByNpsCondition
  | SelectByNpsChangeCondition
  | SelectByContactCondition
  | SelectByBusinessEventCondition
  | SelectByConversionCondition
  | SelectByUserIdCondition
  | SelectByAppCondition
  | SelectByEngagementCondition
  | SelectByUserJourneyCondition;

/**
 * 選定条件のタイプ
 */
export enum SelectConditionType {
  SelectByServiceIdCondition,
  SelectByNpsCondition,
  SelectByNpsChangeCondition,
  SelectByContactCondition,
  SelectByBusinessEventCondition,
  SelectByConversionCondition,
  SelectByUserIdCondition
}

/**
 * additionalConditionsを持っているSelectConditionか
 */
const hasAdditionalConditions = (
  condition: SelectCondition
): condition is
  | SelectByNpsCondition
  | SelectByNpsChangeCondition
  | SelectByContactCondition
  | SelectByBusinessEventCondition
  | SelectByConversionCondition =>
  !(condition instanceof SelectByServiceIdCondition) &&
  !(condition instanceof SelectByUserIdCondition);

/**
 * コンバージョン・イベント属性のAdditionalSelectConditionは追加が可能か
 */
export function isConversionAttributeConditionAppendable(
  condition: SelectCondition,
  conversionAttributeDefinitions: ConversionAttributeDefinition[]
): boolean {
  // コンバージョンでの選定とアプリ検索とエンゲージメント検索以外の選定では追加不可
  if (
    !(condition instanceof SelectByConversionCondition) &&
    !(condition instanceof SelectByAppCondition) &&
    !(condition instanceof SelectByEngagementCondition)
  ) {
    return false;
  }

  return checkDefinitionsAndAreadyUsedAllCondition(
    condition,
    conversionAttributeDefinitions,
    (condition: AdditionalSelectCondition) => {
      return condition instanceof ConversionAttributeTextCondition;
    }
  );
}

/**
 * 顧客ロイヤルティ指標のAdditionalSelectConditionは追加が可能か
 */
export function isNpsConditionAppendable(
  condition: SelectCondition,
  npsDefinitions: NpsDefinition[]
): boolean {
  // npsの設定がなければ追加不可
  if (npsDefinitions.length <= 0) {
    return false;
  }

  // ロイヤルティでの選定では追加不可
  if (
    condition instanceof SelectByNpsCondition ||
    condition instanceof SelectByNpsChangeCondition
  ) {
    return false;
  }

  if (condition instanceof SelectByAppCondition) {
    return false;
  }

  // エンゲージメント検索では追加不可
  if (condition instanceof SelectByEngagementCondition) {
    return false;
  }

  // 既に顧客ロイヤルティ指標が追加されていたら追加させない
  if (hasAdditionalConditions(condition)) {
    const npsConditions = condition.additionalConditions.filter(
      c => c instanceof NpsCondition
    );
    if (0 < npsConditions.length) {
      return false;
    }
  }

  return true;
}

/**
 * 顧客ロイヤルティ指標(個別スコア)のAdditionalSelectConditionは追加が可能か
 */
export function isEnqueteConditionAppendable(
  condition: SelectCondition,
  enqueteDefinitions: EnqueteDefinition[]
): boolean {
  if (condition instanceof SelectByAppCondition) {
    return false;
  }

  // エンゲージメント検索では追加不可
  if (condition instanceof SelectByEngagementCondition) {
    return false;
  }

  return checkDefinitionsAndAreadyUsedAllCondition(
    condition,
    enqueteDefinitions,
    (condition: AdditionalSelectCondition) => {
      return condition instanceof EnqueteCondition;
    }
  );
}

/**
 * ビジネス指標のAdditionalSelectConditionは追加が可能か
 */
export function isBusinessIndexConditionAppendable(
  condition: SelectCondition,
  businessIndexDefinitions: BusinessIndexDefinition[]
): boolean {
  if (condition instanceof SelectByAppCondition) {
    return false;
  }

  // エンゲージメント検索では追加不可
  if (condition instanceof SelectByEngagementCondition) {
    return false;
  }

  return checkDefinitionsAndAreadyUsedAllCondition(
    condition,
    businessIndexDefinitions,
    (condition: AdditionalSelectCondition) => {
      return condition instanceof BusinessIndexCondition;
    }
  );
}

/**
 * ユーザ属性のAdditionalSelectConditionは追加が可能か
 */
export function isUserAttributeConditionAppendable(
  condition: SelectCondition,
  userAttributeDefinitions: UserAttributeDefinition[]
): boolean {
  if (condition instanceof SelectByAppCondition) {
    return false;
  }

  // エンゲージメント検索では追加不可
  if (condition instanceof SelectByEngagementCondition) {
    return false;
  }

  return checkDefinitionsAndAreadyUsedAllCondition(
    condition,
    userAttributeDefinitions,
    (condition: AdditionalSelectCondition) => {
      return (
        condition instanceof UserAttributeNumberCondition ||
        condition instanceof UserAttributeStringCondition
      );
    }
  );
}

/**
 * check if it is possible to add the business event as AdditionalSelectCondition
 */
export function isBusinessEventAttributeConditionAppendable(
  condition: SelectCondition,
  businessEventDefinitions: BusinessEventDefinition[]
): boolean {
  // Only add if it is business event selected
  if (!(condition instanceof SelectByBusinessEventCondition)) {
    return false;
  }

  return checkDefinitionsAndAreadyUsedAllCondition(
    condition,
    businessEventDefinitions,
    (condition: AdditionalSelectCondition) => {
      return condition instanceof BusinessEventAttributeCondition;
    }
  );
}

/**
 * check if it is possible to add the Contact Attribute as AdditionalSelectCondition
 */
export function isContactAttributeConditionAppendable(
  condition: SelectCondition,
  contactDefinition: ContactDefinition[]
): boolean {
  if (!(condition instanceof SelectByContactCondition)) {
    return false;
  }
  /**
   *  We only required TEL,MAIL & SHOP, since contactDefinition could have more item
   *  so, we need to filter to get only required three
   */
  const filterContactDefinition = contactDefinition.filter(
    contactDefinition =>
      contactDefinition.type === ContactDefinitionType.TEL ||
      contactDefinition.type === ContactDefinitionType.MAIL ||
      contactDefinition.type === ContactDefinitionType.SHOP
  );

  if (filterContactDefinition.length <= 0) {
    return false;
  }
  /**
   * Length of the definition
   */
  let definitionLength = filterContactDefinition.length;

  /**
   * If it is  shop we should add +1 to the length since shop have 2 type (purpose & store name)
   */
  if (
    contactDefinition.some(
      shopType => shopType.type === ContactDefinitionType.SHOP
    )
  ) {
    definitionLength++;
  }

  if (hasAdditionalConditions(condition)) {
    const additions = condition.additionalConditions.filter(
      c => c instanceof ContactAttributeCondition
    );
    /**
     * If additional condition already added and match definitionLength should return false
     * since there is no more contactDefinition to add
     */
    if (definitionLength <= additions.length) {
      return false;
    }
  }

  return true;
}

/**
 * conditionのstartDateとendDateからstart_time_secとend_time_secに変換する
 */
export function convertStartAndEndTimeSecFromCondition(
  condition:
    | SelectByAppCondition
    | SelectByBusinessEventCondition
    | SelectByContactCondition
    | SelectByConversionCondition
) {
  return {
    start_time_sec:
      condition.startDate !== null
        ? msecToSec(condition.startDate.getTime())
        : 0,
    end_time_sec:
      condition.endDate !== null ? msecToSec(condition.endDate.getTime()) : 0
  };
}

/**
 * 各要素のDefinitionsがあるか、及び全種類が既にadditionalConditionsに追加されているかの確認
 *
 * 例えばビジネス指標が全部で2種類しかなければ、additionalConditionsにBusinessIndexConditionが2個追加された時点で、
 * ビジネス指標の条件追加は表示しないようにする
 *
 * @param condition
 * @param definitions 各要素のDefinitionの配列
 * @param pred additionalConditionsの同定条件
 *
 * @returns boolean
 */
function checkDefinitionsAndAreadyUsedAllCondition<T>(
  condition: SelectCondition,
  definitions: T[],
  pred: (condition: AdditionalSelectCondition) => boolean
): boolean {
  // 設定が無い場合は、その条件は追加できない
  if (definitions.length <= 0) {
    return false;
  }
  if (hasAdditionalConditions(condition)) {
    const additions = condition.additionalConditions.filter(c => pred(c));
    // 1種類につき1度しか追加できないので、全種類のconditionを使い切ったらもう追加できない
    if (definitions.length <= additions.length) {
      return false;
    }
  }
  return true;
}

export function overwriteDates(
  condition: SelectCondition,
  favoriteSearchPeriod: number
): SelectCondition {
  const startDate: Date = new Date();
  const endDate: Date = new Date();

  // 引数により始点を更新.
  // favoriteSearchPeriod日間のデータをとるので、始点は(favoriteSearchPeriod - 1)日前
  startDate.setDate(startDate.getDate() - favoriteSearchPeriod + 1);
  startDate.setHours(0 /*hours*/, 0 /*min*/, 0 /*sec*/, 0 /*ms*/);

  // 昨日の場合は、終点も昨日に設定する
  if (favoriteSearchPeriod === 2) {
    endDate.setDate(endDate.getDate() - favoriteSearchPeriod + 1);
  }
  endDate.setHours(23 /*hours*/, 59 /*min*/, 59 /*sec*/, 999 /*ms*/);

  // conditionの種類によって場合わけ
  if (condition instanceof SelectByConversionCondition) {
    return new SelectByConversionCondition(
      condition.conversionDefinitionIds,
      startDate,
      endDate,
      condition.hour,
      condition.additionalConditions
    );
  }
  if (condition instanceof SelectByAppCondition) {
    return new SelectByAppCondition(
      condition.conversionDefinitionIds,
      startDate,
      endDate,
      condition.hour,
      condition.additionalConditions
    );
  }

  if (condition instanceof SelectByBusinessEventCondition) {
    return new SelectByBusinessEventCondition(
      condition.businessEventDefinitionIds,
      startDate,
      endDate,
      condition.hour,
      condition.additionalConditions
    );
  }
  if (condition instanceof SelectByContactCondition) {
    return new SelectByContactCondition(
      condition.contactDefinitionIds,
      startDate,
      endDate,
      condition.hour,
      condition.additionalConditions
    );
  }

  return condition;
}

/**
 * 検索条件 -> JSON
 */
export function convertSelectConditionToJson(
  condition: SelectCondition,
  npsDefinition: NpsDefinition | null
): SelectUserApiRequestParams | null {
  /**
   * SelectByEngagementConditionは、この関数経由では無く直接
   * convertSelectByEngagementConditionToJson が直接呼ばれるためここには含めない
   */

  if (condition instanceof SelectByServiceIdCondition) {
    return {
      select_method: "selectUsersByServiceIDs",
      selection: convertSelectByServiceIdConditionToJson(condition)
    };
  }

  if (condition instanceof SelectByUserIdCondition) {
    return {
      select_method: "selectUsersByUserIDs",
      selection: convertSelectByUserIdConditionToJson(condition)
    };
  }

  if (condition instanceof SelectByConversionCondition) {
    return {
      select_method: "selectUsersByDates",
      selection: convertSelectByConversionConditionToJson(condition)
    };
  }

  if (condition instanceof SelectByContactCondition) {
    return {
      select_method: "selectUsersByContacts",
      selection: convertSelectByContactConditionToJson(condition)
    };
  }

  if (condition instanceof SelectByBusinessEventCondition) {
    return {
      select_method: "selectUsersByBe",
      selection: convertSelectByBusinessEventConditionToJson(condition)
    };
  }

  if (condition instanceof SelectByAppCondition) {
    return {
      select_method: "selectUsersByDatesWithNativeApp",
      selection: convertSelectByAppConditionToJson(condition)
    };
  }

  if (condition instanceof SelectByNpsCondition) {
    return {
      select_method: "selectUsersByLoyalty",
      selection: convertSelectByNpsConditionToJson(condition)
    };
  }

  if (
    condition instanceof SelectByNpsChangeCondition &&
    npsDefinition !== null
  ) {
    return {
      select_method: "selectUsersByLoyalty",
      selection: convertSelectByNpsChangeConditionToJson(
        condition,
        npsDefinition
      )
    };
  }

  if (condition instanceof SelectByUserJourneyCondition) {
    return {
      select_method: "selectUsersByUserJourney",
      selection: convertSelectByUserJourneyConditionToJson(condition)
    };
  }

  return null;
}

/**
 * JSON -> 検索条件
 */
export function convertJsonToSelectCondition(
  type: SearchHistoryType,
  json: SelectQuery,
  activeDefinitions: ActiveDefinitions,
  activeMeasurementSites: MeasurementTargetSite[]
): SelectCondition | null {
  try {
    if (isSelectByConversionQuery(json)) {
      // typeがSearchHistoryType.AppならSelectByAppConditionを返す
      // queryの型がconversionと同じなのでAppはtypeで確認する
      if (type === SearchHistoryType.App) {
        return convertJsonToSelectByAppCondition(json, activeDefinitions);
      }
      return convertJsonToSelectByConversionCondition(json, activeDefinitions);
    }

    if (isSelectByServiceIdQuery(json)) {
      return convertJsonToSelectByServiceIdCondition(json);
    }

    if (isSelectByUserIdQuery(json)) {
      return convertJsonToSelectByUserIdCondition(json);
    }

    if (isSelectByContactQuery(json)) {
      return convertJsonToSelectByContactCondition(json, activeDefinitions);
    }

    if (isSelectByBusinessEventQuery(json)) {
      return convertJsonToSelectByBusinessEventCondition(
        json,
        activeDefinitions
      );
    }

    if (isSelectByNpsQuery(json)) {
      return convertJsonToSelectByNpsCondition(json, activeDefinitions);
    }

    if (isSelectByNpsChangeQuery(json)) {
      return convertJsonToSelectByNpsChangeCondition(json, activeDefinitions);
    }

    if (isSelectByEngagementQuery(json)) {
      /**
       * エンゲージメント検索の条件は、お気に入りの検索であっても常に入力された日付を使用します。
       * なのでisFavoriteSearchを渡す必要がないです。
       * Engagement search's condition always use inputted date even favorite search
       * So it does not need passing isFavoriteSearch
       */
      return convertJsonToSelectByEngagementCondition(
        json,
        activeDefinitions,
        activeMeasurementSites
      );
    }
    if (isSelectByUserJourneyQuery(json)) {
      return convertJsonToSelectByUserJourneyCondition(json);
    }
  } catch (e) {
    return null;
  }

  return null;
}

export function validationCheckForSelectionHistory(
  startDate: Date,
  endDate: Date
): HistoryDateValidationResult {
  const prevStartDate = startDate.toLocaleDateString().slice(0, 10);
  const prevEndDate = endDate.toLocaleDateString().slice(0, 10);
  let nowStartDate = "";
  let nowEndDate = "";

  if (isTwoYearsAgoDate(endDate) && isTwoYearsAgoDate(startDate)) {
    nowStartDate = getTwoYearsAgoStartDate()
      .toLocaleDateString()
      .slice(0, 10);
    nowEndDate = getTwoYearsAgoEndDate()
      .toLocaleDateString()
      .slice(0, 10);

    startDate = getTwoYearsAgoStartDate();
    endDate = getTwoYearsAgoEndDate();
  }

  if (isTwoYearsAgoDate(endDate) && !isTwoYearsAgoDate(startDate)) {
    nowStartDate = startDate.toLocaleDateString().slice(0, 10);
    nowEndDate = getTwoYearsAgoEndDate()
      .toLocaleDateString()
      .slice(0, 10);

    endDate = getTwoYearsAgoEndDate();
  }

  if (isTwoYearsAgoDate(startDate) && !isTwoYearsAgoDate(endDate)) {
    nowStartDate = getTwoYearsAgoStartDate()
      .toLocaleDateString()
      .slice(0, 10);
    nowEndDate = endDate.toLocaleDateString().slice(0, 10);

    startDate = getTwoYearsAgoStartDate();
  }

  if (nowEndDate !== "" || nowStartDate !== "") {
    const errorMessage = i18n.t(
      "models.search.invalidDateMessageForSelectionHistory",
      {
        prevStartDate,
        prevEndDate,
        nowStartDate,
        nowEndDate
      }
    ) as string;

    return {
      isValid: false,
      errorMessage: errorMessage,
      startDate: startDate,
      endDate: endDate
    };
  }

  return {
    isValid: true
  };
}

export function overwriteDatesIfOutdated(
  startDate: Date,
  endDate: Date,
  condition: SelectCondition
): SelectCondition {
  if (condition instanceof SelectByConversionCondition) {
    return new SelectByConversionCondition(
      condition.conversionDefinitionIds,
      startDate,
      endDate,
      condition.hour,
      condition.additionalConditions
    );
  }
  if (condition instanceof SelectByAppCondition) {
    return new SelectByAppCondition(
      condition.conversionDefinitionIds,
      startDate,
      endDate,
      condition.hour,
      condition.additionalConditions
    );
  }

  if (condition instanceof SelectByBusinessEventCondition) {
    return new SelectByBusinessEventCondition(
      condition.businessEventDefinitionIds,
      startDate,
      endDate,
      condition.hour,
      condition.additionalConditions
    );
  }
  if (condition instanceof SelectByContactCondition) {
    return new SelectByContactCondition(
      condition.contactDefinitionIds,
      startDate,
      endDate,
      condition.hour,
      condition.additionalConditions
    );
  }

  return condition;
}
