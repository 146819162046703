<i18n
  src="@/i18n/components/segmented-trends/segmented-trends-date-label.json"
></i18n>
<template>
  <div class="segmented-trends-date-label"
    >{{ $t("dataTimeSpan") }} : {{ dateLabel }}</div
  >
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateFormat, formatDate } from "@/util/date-util";
import { SelectCondition } from "@/models/search/select-condition/SelectCondition";
import { SelectByEngagementCondition } from "@/models/search/select-condition/SelectByEngagementCondition";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";

@Component({})
export default class SegmentedTrendsDateLabel extends Vue {
  @Prop({ type: Object, required: true })
  selectCondition!: SelectCondition;

  get dateLabel(): string {
    const condition: SelectCondition = this.selectCondition;
    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(startDate.getDate() - 92);
    endDate.setDate(endDate.getDate() - 2);

    if (condition instanceof SelectByUserIdCondition) {
      if (condition.startDate !== null && condition.endDate !== null) {
        startDate = condition.startDate;
        endDate = condition.endDate;
      }
    } else if ("startDate" in condition && "endDate" in condition) {
      if (condition.startDate !== null) {
        startDate = condition.startDate;
      }

      if (condition.endDate !== null) {
        endDate = condition.endDate;
      }

      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

      // 直近2日間は集計中なので除外
      if (endDate > twoDaysAgo) {
        endDate = twoDaysAgo;
      }
    } else if (condition instanceof SelectByEngagementCondition) {
      startDate = condition.period.min;
      endDate = condition.period.max;
    }
    const label =
      formatDate(DateFormat.yyyysMsd, startDate) +
      " - " +
      formatDate(DateFormat.yyyysMsd, endDate);
    return label;
  }
}
</script>

<style lang="scss" scoped>
.segmented-trends-date-label {
  font-size: 13px;
}
</style>
