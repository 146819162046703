<template>
  <div
    class="conversionTrendsPanel"
    :class="{ 'conversionTrendsPanel--dragging': isDragging }"
  >
    <div
      class="conversionTrendsPanel__label"
      :class="{ 'conversionTrendsPanel__label--dragging': isDragging }"
      v-text="conversionTrend.conversionName"
    />
    <Transition name="graph">
      <div v-if="!isDragging" class="conversionTrendsPanel__graph">
        <ConversionTrendsGraph
          :data="sortedDailyCounts"
          @click="onGraphClick"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
        />

        <Tooltip
          class="conversionTrendsPanel__tooltip"
          :value="showTooltip"
          placement="top"
          :manual="true"
          :style="{
            left: tooltipX + 'px',
            top: tooltipY + 'px'
          }"
        >
          <!-- insert div to make tooltip work normally -->
          <div class="conversionTrendsPanel__empty" />

          <template #content>
            <div
              class="conversionTrendsPanel__tooltipContent"
              v-text="tooltipContent"
            />
          </template>
        </Tooltip>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { ConversionTrendDailyCount } from "@/models/conversion-trend/ConversionTrendDailyCount";
import { ConversionTrendResult } from "@/models/conversion-trend/ConversionTrendResult";
import ConversionTrendsGraph, {
  BAR_WIDTH,
  XAxisData
} from "@/components/home-menu/conversion-trends/ConversionTrendsGraph.vue";
import { Tooltip } from "element-ui";

export const TOOLTIP_Y_ADJUST = 5;
export const TOOLTIP_Y_LOWER_LIMIT = 15;

@Component({
  components: { ConversionTrendsGraph, Tooltip }
})
export default class ConversionTrendsPanel extends Vue {
  @Prop({ type: ConversionTrendResult, required: true })
  conversionTrend!: ConversionTrendResult;

  @Prop({ type: Boolean, required: true })
  isDragging!: boolean;

  showTooltip = false;
  tooltipX = 0;
  tooltipY = 0;
  tooltipContent = 0;

  onClick(
    result: ConversionTrendResult,
    currentData: ConversionTrendDailyCount
  ) {
    this.$emit("click", result, currentData);
  }

  get sortedDailyCounts() {
    return [...this.conversionTrend.data].sort(
      (d0, d1) => d0.date.getTime() - d1.date.getTime()
    );
  }

  onGraphClick(data: ConversionTrendDailyCount) {
    this.onClick(this.conversionTrend, data);
  }

  onMouseEnter(data: XAxisData) {
    this.showTooltip = true;
    this.tooltipContent = data.dailyCount.count;
    this.tooltipX = data.x + BAR_WIDTH / 2 - 1;

    // When data.y is smaller than TOOLTIP_Y_LOWER_LIMIT, the direction of the Tooltip changes, so it is adjusted.
    const y = data.y > TOOLTIP_Y_LOWER_LIMIT ? data.y : TOOLTIP_Y_LOWER_LIMIT;
    this.tooltipY = y + TOOLTIP_Y_ADJUST;
  }

  onMouseLeave() {
    this.showTooltip = false;
  }
}
</script>

<style lang="scss" scoped>
.conversionTrendsPanel {
  display: flex;
  padding: 12px;
  width: 340px;
  height: 101px;
  background-color: $colorWhite;
  transition: height 0.3s ease;
}

.conversionTrendsPanel--dragging {
  height: 36px;
  line-height: 1;
}

.conversionTrendsPanel__label {
  width: 70px;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.3;

  @include default-break-word();
}

.conversionTrendsPanel__label--dragging {
  width: auto;
  line-height: 1;
}

.conversionTrendsPanel__graph {
  position: relative;
  flex: 1;
  height: 75px;
}

.conversionTrendsPanel__tooltip {
  position: absolute;
}

.graph-enter-active,
.graph-leave-active {
  height: 75px;
  opacity: 1;
  transition: height 0.3s ease, opacity 0.3s ease;
}

.graph-enter,
.graph-leave-to {
  height: 0;
  opacity: 0;
}
</style>
