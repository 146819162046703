<template>
  <FilterBaseSelectBox
    v-model="value"
    :options="option"
    class="filter-ad-type-form"
  />
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import {
  FilterAdTypeCondition,
  AdType,
  selectOption
} from "@/models/search/filter-node-condition/FilterAdTypeCondition";
import FilterBaseSelectBox from "@/components/filter/form/FilterBaseSelectBox.vue";
import SelectOption from "@/components/form/SelectOption";

@Component({
  components: {
    FilterBaseSelectBox
  }
})
export default class FilterAdTypeField extends Vue {
  @Model("input", { type: FilterAdTypeCondition, required: true })
  condition!: FilterAdTypeCondition;

  @Prop({ type: Boolean, required: true })
  isSelectedAdInverntory!: boolean;

  @Prop({ type: Boolean, required: true })
  isSelectedAdGroup!: boolean;

  onInput(condition: FilterAdTypeCondition) {
    this.$emit("input", condition);
  }

  get value(): AdType {
    return this.condition.adType;
  }
  set value(type: AdType) {
    this.onInput(new FilterAdTypeCondition(type));
  }

  get option(): SelectOption[] {
    return selectOption(this.isSelectedAdInverntory, this.isSelectedAdGroup);
  }
}
</script>
