import { render, staticRenderFns } from "./MenuPanelLagCvContent.vue?vue&type=template&id=535d3d02&scoped=true"
import script from "./MenuPanelLagCvContent.vue?vue&type=script&lang=ts"
export * from "./MenuPanelLagCvContent.vue?vue&type=script&lang=ts"
import style0 from "./MenuPanelLagCvContent.vue?vue&type=style&index=0&id=535d3d02&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "535d3d02",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/home-menu/lag-cv.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fhome-menu%2Flag-cv%2FMenuPanelLagCvContent.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports