import { ApiRes } from "@/api/api-res";
import {
  getDateFromYearMonthDayString,
  formatDate,
  DateFormat
} from "@/util/date-util";

/**
 * Model holding AlmostCvPeriod data
 */
export class AlmostCvPeriod {
  public constructor(
    public readonly startDate: Date,
    public readonly endDate: Date,
    public readonly hasPeriod: boolean
  ) {}

  get periodRange(): string {
    const fmtStartDate = this.formatDate(this.startDate);
    const fmtEndDate = this.formatDate(this.endDate);

    return `${fmtStartDate} - ${fmtEndDate}`;
  }

  private formatDate(date: Date): string {
    return formatDate(DateFormat.yyyysMMsdd, date);
  }

  /**
   * Convert data from API into an instance of AlmostCvInfo
   * @param json an element from API // ApiRes.AlmostCvInfo
   * @returns an instance of this class
   */
  public static fromJson(json: ApiRes.AlmostCvInfo): AlmostCvPeriod {
    const startDate =
      json.start_date === null
        ? new Date()
        : getDateFromYearMonthDayString(json.start_date);
    const endDate =
      json.end_date === null
        ? new Date()
        : getDateFromYearMonthDayString(json.end_date);

    const hasPeriod = json.start_date !== null && json.end_date !== null;

    return new AlmostCvPeriod(startDate, endDate, hasPeriod);
  }
}
