import {
  FilterAdTypeCondition,
  AdType
} from "@/models/search/filter-node-condition/FilterAdTypeCondition";
import { FilterAdCampaignCondition } from "@/models/search/filter-node-condition/FilterAdCampaignCondition";
import { FilterAdMediaCondition } from "@/models/search/filter-node-condition/FilterAdMediaCondition";
import { FilterAdInventoryCondition } from "@/models/search/filter-node-condition/FilterAdInventoryCondition";
import { FilterAdGroupCondition } from "@/models/search/filter-node-condition/FilterAdGroupCondition";
import { FilterLandingPageUrlCondition } from "@/models/search/filter-node-condition/FilterLandingPageUrlCondition";
import {
  FilterLandingPageTitleCondition,
  convertJsonToFilterLandingPageTitleCondition
} from "@/models/search/filter-node-condition/FilterLandingPageTitleCondition";
import {
  FilterAdditionalTimingCondition,
  FilterNodeConditionType,
  FilterAdditionalCondition,
  getAdditionalTimingConditions
} from "@/models/search/filter-node-condition/FilterNodeCondition";
import { ChildFilterNodeParamForAd } from "@/api/apis/ApiSearch";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { getHourlyInterval } from "@/util/date-util";
import SelectOptionGroup from "@/components/form/SelectOptionGroup";
import {
  firstTimePeriodSelectOption,
  ActivityEdgeType,
  validateConditions
} from "@/models/search/filter-node/FilterNode";
import { i18n } from "@/i18n";
import { ValidationResult } from "@/models/search/ValidationResult";

/**
 * 広告による絞り込み条件
 *
 * 広告から流入したユーザを絞り込む
 * FilterByInflowConditionに子になるので、edgeは持たない
 * （edgeはFilterByInflowConditionが保持する）
 *
 * 広告種別、媒体名、広告枠名、キャンペーン名、広告グループ名、入口ページURL、入口ページタイトル、初回、期間、日付と時間帯
 * を条件として追加できる
 */
export class ChildFilterNodeForAd {
  constructor(
    /**
     * 追加の検索条件
     *
     * 広告種別、媒体名、広告枠名、キャンペーン名、広告グループ名、
     * 入口ページURL、入口ページタイトル、初回、期間、日付と時間帯を 3つまで指定できる
     */
    public readonly additionalConditions: ChildFilterNodeForAdCondition[],
    public readonly parentDepth: number | null = 0
  ) {}

  get validate(): ValidationResult {
    return validateConditions(this.additionalConditions);
  }

  get isConditionAddable(): boolean {
    return this.additionalConditions.length < 3;
  }

  get hasAdTypeCondition(): boolean {
    return this.additionalConditions.some(
      cnd => cnd instanceof FilterAdTypeCondition
    );
  }

  get hasAdCampaignCondition(): boolean {
    return this.additionalConditions.some(
      cnd => cnd instanceof FilterAdCampaignCondition
    );
  }

  get hasAdMediaCondition(): boolean {
    return this.additionalConditions.some(
      cnd => cnd instanceof FilterAdMediaCondition
    );
  }

  get hasAdInventoryCondition(): boolean {
    return this.additionalConditions.some(
      cnd => cnd instanceof FilterAdInventoryCondition
    );
  }

  get hasAdGroupCondition(): boolean {
    return this.additionalConditions.some(
      cnd => cnd instanceof FilterAdGroupCondition
    );
  }
  get hasLandingPageUrlCondition(): boolean {
    return this.additionalConditions.some(
      cnd => cnd instanceof FilterLandingPageUrlCondition
    );
  }

  get hasLandingPageTitleCondition(): boolean {
    return this.additionalConditions.some(
      cnd => cnd instanceof FilterLandingPageTitleCondition
    );
  }

  isInverntoryDisabled(
    currentCondition: FilterAdditionalCondition | null
  ): boolean {
    // Always selectable when only has one additionalCondition
    if (this.additionalConditions.length < 2) return false;

    if (this.hasAdInventoryCondition) {
      // When additionalCondition is 2 or more and already has Inventry,
      // should not be disabled if currentCondition is Inventry
      // should be disabled if currentCondition is others
      return !(currentCondition instanceof FilterAdInventoryCondition);
    }

    const currentConditionIndex = this.additionalConditions.findIndex(
      cnd => cnd === currentCondition
    );
    const adTypeConditionIndex = this.additionalConditions.findIndex(
      cnd =>
        cnd instanceof FilterAdTypeCondition &&
        [AdType.Listing, AdType.Affiliate].includes(cnd.adType)
    );
    const adGroupConditionIndex = this.additionalConditions.findIndex(
      cnd => cnd instanceof FilterAdGroupCondition
    );

    // Inverntory should not be selectable if Type(Listing|Affiliate) or Group is already selected
    // but it should be selectable in following cases
    // 1. current select box is Group, and Type(Listing|Affiliate) is not selected in other select box
    // 2. current select box is Type(Listing|Affiliate), and Group is not selected in other select box
    if (currentConditionIndex === adGroupConditionIndex) {
      return adTypeConditionIndex > -1;
    } else if (currentConditionIndex === adTypeConditionIndex) {
      return adGroupConditionIndex > -1;
    } else {
      return adTypeConditionIndex > -1 || adGroupConditionIndex > -1;
    }
  }

  isGroupDisabled(currentCondition: FilterAdditionalCondition): boolean {
    // Always selectable when only has one additionalCondition
    if (this.additionalConditions.length < 2) return false;

    if (this.hasAdGroupCondition) {
      // When additionalCondition is 2 or more and already has Group,
      // should not be disabled if currentCondition is Group
      // should be disabled if currentCondition is others
      return !(currentCondition instanceof FilterAdGroupCondition);
    }

    const currentConditionIndex = this.additionalConditions.findIndex(
      cnd => cnd === currentCondition
    );
    const adTypeConditionIndex = this.additionalConditions.findIndex(
      cnd =>
        cnd instanceof FilterAdTypeCondition &&
        [AdType.Banner, AdType.Text, AdType.Mail, AdType.Affiliate].includes(
          cnd.adType
        )
    );
    const adInventoryConditionIndex = this.additionalConditions.findIndex(
      cnd => cnd instanceof FilterAdInventoryCondition
    );

    // Group should not be selectable if Type(other than Listing) or Inventory is already selected
    // but it should be selectable in following cases
    // 1. current select box is Inventory, and Type(other than Listing) is not selected in other select box
    // 2. current select box is Type(other than Listing), and Inventory is not selected in other select box
    if (currentConditionIndex === adInventoryConditionIndex) {
      return adTypeConditionIndex > -1;
    } else if (currentConditionIndex === adTypeConditionIndex) {
      return adInventoryConditionIndex > -1;
    } else {
      return adTypeConditionIndex > -1 || adInventoryConditionIndex > -1;
    }
  }

  selectOption(
    currentCondition: FilterAdditionalCondition,
    isFirstNode: boolean
  ): SelectOptionGroup[] {
    const options: SelectOptionGroup[] = [
      {
        label: i18n.t("models.search.activityOverview") as string,
        options: [
          {
            value: FilterNodeConditionType.AdType,
            label: i18n.t("models.search.advertisementType") as string,
            disabled:
              this.hasAdTypeCondition &&
              !(currentCondition instanceof FilterAdTypeCondition)
          },
          {
            value: FilterNodeConditionType.AdMedia,
            label: i18n.t("models.search.mediaName") as string,
            disabled:
              this.hasAdMediaCondition &&
              !(currentCondition instanceof FilterAdMediaCondition)
          },
          {
            value: FilterNodeConditionType.AdInverntory,
            label: i18n.t("models.search.inventoryName") as string,
            disabled: this.isInverntoryDisabled(currentCondition)
          },
          {
            value: FilterNodeConditionType.AdCampaign,
            label: i18n.t("models.search.campaignName") as string,
            disabled:
              this.hasAdCampaignCondition &&
              !(currentCondition instanceof FilterAdCampaignCondition)
          },
          {
            value: FilterNodeConditionType.AdGroup,
            label: i18n.t("models.search.adGroupName") as string,
            disabled: this.isGroupDisabled(currentCondition)
          },
          {
            value: FilterNodeConditionType.LandingPageUrl,
            label: i18n.t("models.search.entryPageUrl") as string,
            disabled:
              this.hasLandingPageUrlCondition &&
              !(currentCondition instanceof FilterLandingPageUrlCondition)
          },
          {
            value: FilterNodeConditionType.LandingPageTitle,
            label: i18n.t("models.search.entryPageTitle") as string,
            disabled:
              this.hasLandingPageTitleCondition &&
              !(currentCondition instanceof FilterLandingPageTitleCondition)
          }
        ]
      }
    ];

    if (this.parentDepth === 0) {
      options.push(
        firstTimePeriodSelectOption(
          currentCondition,
          isFirstNode,
          this.additionalConditions
        )
      );
    }

    return options;
  }
}

export type ChildFilterNodeForAdCondition =
  | FilterAdTypeCondition
  | FilterAdMediaCondition
  | FilterAdInventoryCondition
  | FilterAdCampaignCondition
  | FilterAdGroupCondition
  | FilterLandingPageUrlCondition
  | FilterLandingPageTitleCondition
  | FilterAdditionalTimingCondition;

export function convertChildFilterNodeForAdToJson(
  node: ChildFilterNodeForAd
): ChildFilterNodeParamForAd {
  // edgeは別の場所でつける
  const result: ChildFilterNodeParamForAd = {
    activity_edge: null,
    activity_type: ActivityEdgeType.Ad
  };

  const conditions: ChildFilterNodeForAdCondition[] = node.additionalConditions;

  for (const condition of conditions) {
    if (condition instanceof FilterAdTypeCondition) {
      result.ad_type = condition.adType;
    } else if (condition instanceof FilterAdMediaCondition) {
      result.media = condition.mediaName;
    } else if (condition instanceof FilterAdInventoryCondition) {
      result.menu = condition.inventoryName;
    } else if (condition instanceof FilterAdCampaignCondition) {
      result.advertise = condition.campaignName;
    } else if (condition instanceof FilterAdGroupCondition) {
      result.advertise_group = condition.groupName;
    } else if (condition instanceof FilterLandingPageUrlCondition) {
      result.entrance_url = {
        url: condition.pageUrl,
        word_match_method: condition.matchMethod
      };
    } else if (condition instanceof FilterLandingPageTitleCondition) {
      result.entrance_title = {
        title: condition.pageTitle,
        word_match_method: condition.matchMethod
      };
    } else if (condition instanceof FilterFirstTimeCondition) {
      result.is_in_first_visit = true;
    } else if (condition instanceof FilterPeriodCondition) {
      result.dates = FilterPeriodCondition.buildSecTimes(condition);
    } else if (condition instanceof FilterDateHourCondition) {
      const interval = getHourlyInterval(condition.date, condition.hour);
      result.dates = {
        start_time_sec: interval.start,
        end_time_sec: interval.end
      };
    }
  }

  return result;
}

export function convertJsonToFilterNodeForAd(json: ChildFilterNodeParamForAd) {
  const additionalConditions: ChildFilterNodeForAdCondition[] = getAdditionalTimingConditions(
    json
  );
  if (json.ad_type) {
    additionalConditions.push(new FilterAdTypeCondition(json.ad_type));
  }
  if (json.media) {
    additionalConditions.push(new FilterAdMediaCondition(json.media));
  }
  if (json.menu) {
    additionalConditions.push(new FilterAdInventoryCondition(json.menu));
  }
  if (json.advertise) {
    additionalConditions.push(new FilterAdCampaignCondition(json.advertise));
  }
  if (json.advertise_group) {
    additionalConditions.push(new FilterAdGroupCondition(json.advertise_group));
  }
  if (json.entrance_url) {
    additionalConditions.push(
      new FilterLandingPageUrlCondition(
        json.entrance_url.url,
        json.entrance_url.word_match_method
      )
    );
  }
  if (json.entrance_title) {
    additionalConditions.push(
      convertJsonToFilterLandingPageTitleCondition(json.entrance_title)
    );
  }
  return new ChildFilterNodeForAd(additionalConditions);
}
