<i18n src="@/i18n/components/home-menu/almost-cv.json"></i18n>
<template>
  <MenuPanel
    class="menuPanelAlmostCv"
    :is-loading="isLoading"
    :clickable="false"
  >
    <template #header>
      <MenuPanelAlmostCvHeader />
    </template>
    <MenuPanelError v-if="isError" @retry="onRetry" />
    <div v-else class="menuPanelAlmostCv__content">
      <MenuPanelAlmostCvContent
        v-if="hasConversion"
        :almost-cv-info="almostCvInfo"
      />
      <MenuPanelAlmostCvEmpty v-else />
      <MenuPanelAlmostCvFooter />
    </div>
  </MenuPanel>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import MenuPanel from "@/components/home-menu/MenuPanel.vue";
import MenuPanelAlmostCvHeader from "@/components/home-menu/almost-cv/MenuPanelAlmostCvHeader.vue";
import MenuPanelAlmostCvEmpty from "@/components/home-menu/almost-cv/MenuPanelAlmostCvEmpty.vue";
import MenuPanelAlmostCvContent from "@/components/home-menu/almost-cv/MenuPanelAlmostCvContent.vue";
import MenuPanelAlmostCvFooter from "@/components/home-menu/almost-cv/MenuPanelAlmostCvFooter.vue";
import MenuPanelError from "@/components/home-menu/MenuPanelError.vue";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";
import { AlmostCvInfo } from "@/models/almost-cv/AlmostCvInfo";

@Component({
  components: {
    MenuPanel,
    MenuPanelAlmostCvHeader,
    MenuPanelAlmostCvEmpty,
    MenuPanelAlmostCvContent,
    MenuPanelAlmostCvFooter,
    MenuPanelError,
    Tooltip,
    Icon
  }
})
export default class MenuPanelAlmostCv extends Vue {
  @Emit("click")
  onRetry() {}

  @Prop({ type: Boolean, default: false })
  isLoading!: boolean;

  @Prop({ type: AlmostCvInfo, required: true })
  almostCvInfo!: AlmostCvInfo;

  @Prop({ type: Boolean, default: false })
  isError!: boolean;

  today = new Date();

  get hasConversion(): boolean {
    return this.almostCvInfo.conversions.length > 0;
  }
}
</script>

<style lang="scss" scoped>
.menuPanelAlmostCv__content {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
}
</style>
