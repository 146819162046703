<i18n src="@/i18n/components/home-menu/lag-cv.json"></i18n>
<template>
  <div class="menuPanelLagCvContent">
    <div class="menuPanelLagCvContent__periodContainer">
      <div
        v-for="userCount in userCounts"
        :key="userCount.periodType"
        class="menuPanelLagCvContent__tab"
        @click="onChangePeriod(userCount.periodType)"
      >
        <Tooltip>
          <RadioButton
            class="menuPanelLagCvContent__radioButton"
            :value="selectedPeriod"
            :size="radioButtonSize.Small"
            :label="userCount.periodType"
            >{{ $t(userCount.periodType) }}</RadioButton
          >
          <template #content>
            <span v-text="userCount.periodRange" />
          </template>
        </Tooltip>
      </div>
    </div>
    <div v-if="userCountOfSelectedPeriod">
      <MenuPanelLagCvList :user-count="userCountOfSelectedPeriod" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Tooltip from "@/components/Tooltip.vue";
import RadioButton from "@/components/form/RadioButton.vue";
import MenuPanelLagCvList from "@/components/home-menu/lag-cv/MenuPanelLagCvList.vue";
import { RadioButtonSize } from "@/const/RadioButtonSize";
import LagCvUserCount, {
  LagCvPeriodType
} from "@/models/lag-cv/LagCvUserCount";

@Component({
  components: {
    Tooltip,
    RadioButton,
    MenuPanelLagCvList
  }
})
export default class MenuPanelLagCvContent extends Vue {
  radioButtonSize = RadioButtonSize;

  @Prop({ type: Array, required: true })
  userCounts!: LagCvUserCount[];

  selectedPeriod: LagCvPeriodType = LagCvPeriodType.FourWeeks;

  onChangePeriod(period: LagCvPeriodType) {
    if (this.selectedPeriod === period) return;
    this.selectedPeriod = period;
  }

  get userCountOfSelectedPeriod(): LagCvUserCount | undefined {
    return this.userCounts.find(item => item.periodType == this.selectedPeriod);
  }
}
</script>

<style lang="scss" scoped>
.menuPanelLagCvContent__radioButton {
  padding-right: 0;
}

.menuPanelLagCvContent__tab {
  margin-right: 35px;
}

.menuPanelLagCvContent__periodContainer {
  margin-bottom: 20px;
  display: flex;
}
</style>
