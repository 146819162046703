<i18n src="@/i18n/components/home-menu/no-favorite-search.json"></i18n>
<template>
  <MenuPanel :label-icon="iconSave" :clickable="false">
    <template #header>
      <div v-t="'savedSearch'" />
    </template>
    <EmptyDataFallback
      :description="$t('descriptionAboutSavedSearch')"
      :tips-image-path="tipsImagePathByLocale"
      :tips-body="$t('explanationForTipsAddSavedSearch')"
      help-site-url="https://help.usergram.info/article/308-userlist"
      >{{ $t("titleForTipsAddSavedSearch") }}</EmptyDataFallback
    >
  </MenuPanel>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import MenuPanel from "@/components/home-menu/MenuPanel.vue";
import EmptyDataFallback from "@/components/home-menu/EmptyDataFallback.vue";
import { Icons } from "@/const/Icons";

@Component({
  components: {
    MenuPanel,
    EmptyDataFallback
  }
})
export default class MenuPanelNoFavoriteSearch extends Vue {
  iconSave: Icons = Icons.Save;

  get tipsImagePathByLocale(): string {
    return `img/home/tips-favorite-search-${this.$i18n.locale}.png`;
  }
}
</script>
