import { ActionTree, MutationTree } from "vuex";
import { RootState } from "@/store/";
import { SelectByServiceIdCondition } from "@/models/search/select-condition/SelectByServiceIdCondition";
import { SelectByConversionCondition } from "@/models/search/select-condition/SelectByConversionCondition";
import {
  convertJsonToSelectCondition,
  SelectCondition
} from "@/models/search/select-condition/SelectCondition";
import { SelectByBusinessEventCondition } from "@/models/search/select-condition/SelectByBusinessEventCondition";
import { SelectByContactCondition } from "@/models/search/select-condition/SelectByContactCondition";
import { SelectByNpsCondition } from "@/models/search/select-condition/SelectByNpsCondition";
import {
  NpsLabel,
  SelectByNpsChangeCondition
} from "@/models/search/select-condition/SelectByNpsChangeCondition";
import {
  FieldType,
  SelectByEngagementCondition
} from "@/models/search/select-condition/SelectByEngagementCondition";
import { ApiRes } from "@/api/api-res";
import { SearchHistoryType } from "@/api/apis/ApiSearchHistory";
import { AdditionalSelectCondition } from "@/models/search/additional-condition/AdditionalSelectCondition";
import { ContactDefinitionType } from "@/models/client-settings/ContactDefinition";
import { SelectByAppCondition } from "@/models/search/select-condition/SelectByAppCondition";
import { GlobalConversionQueryType } from "@/store/modules/system";
import { GlobalConversionDefinition } from "@/models/client-settings/ConversionDefinition";

export enum TabType {
  Conditions,
  ServiceId,
  Repeat,
  FeaturedUsers,
  History
}

export enum FormType {
  Conversion,
  App,
  BusinessEvent,
  Contact,
  Nps
}

export enum NpsFormType {
  Score,
  Change
}

export class SearchFormState {
  currentTabType = TabType.Conditions;
  currentFormType = FormType.Conversion;
  currentNpsFormType = NpsFormType.Score;
  serviceIdCondition = SelectByServiceIdCondition.emptyCondition();
  conversionCondition = SelectByConversionCondition.emptyCondition();
  businessEventCondition = SelectByBusinessEventCondition.emptyCondition();
  contactCondition = SelectByContactCondition.emptyCondition();
  npsCondition = SelectByNpsCondition.emptyCondition();
  npsChangeCondition = SelectByNpsChangeCondition.emptyCondition();
  appCondition = SelectByAppCondition.emptyCondition();
  engagementCondition = SelectByEngagementCondition.defaultCondition();
}

const mutations: MutationTree<SearchFormState> = {
  setCurrentTabType(state: SearchFormState, tabType: TabType) {
    state.currentTabType = tabType;
  },
  setCurrentFormType(state: SearchFormState, formType: FormType) {
    state.currentFormType = formType;
  },
  setCurrentNpsFormType(state: SearchFormState, npsFormType: NpsFormType) {
    state.currentNpsFormType = npsFormType;
  },
  setSelectByServiceIdCondition(
    state: SearchFormState,
    condition: SelectByServiceIdCondition
  ) {
    state.serviceIdCondition = condition;
  },
  setSelectByConversionCondition(
    state: SearchFormState,
    condition: SelectByConversionCondition
  ) {
    state.conversionCondition = condition;
  },
  setSelectByBusinessEventCondition(
    state: SearchFormState,
    condition: SelectByBusinessEventCondition
  ) {
    state.businessEventCondition = condition;
  },
  setSelectByContactCondition(
    state: SearchFormState,
    condition: SelectByContactCondition
  ) {
    state.contactCondition = condition;
  },
  setSelectByNpsCondition(
    state: SearchFormState,
    condition: SelectByNpsCondition
  ) {
    state.npsCondition = condition;
  },
  setSelectByNpsChangeCondition(
    state: SearchFormState,
    condition: SelectByNpsChangeCondition
  ) {
    state.npsChangeCondition = condition;
  },
  setSelectByAppCondition(
    state: SearchFormState,
    condition: SelectByAppCondition
  ) {
    state.appCondition = condition;
  },
  setSelectByEngagementCondition(
    state: SearchFormState,
    condition: SelectByEngagementCondition
  ) {
    state.engagementCondition = condition;
  },
  initialize(state: SearchFormState) {
    state.currentFormType = FormType.Conversion;
    state.currentNpsFormType = NpsFormType.Score;
    state.serviceIdCondition = SelectByServiceIdCondition.emptyCondition();
    state.conversionCondition = SelectByConversionCondition.emptyCondition();
    state.businessEventCondition = SelectByBusinessEventCondition.emptyCondition();
    state.contactCondition = SelectByContactCondition.emptyCondition();
    state.npsCondition = SelectByNpsCondition.emptyCondition();
    state.npsChangeCondition = SelectByNpsChangeCondition.emptyCondition();
    state.appCondition = SelectByAppCondition.emptyCondition();
    state.engagementCondition = SelectByEngagementCondition.defaultCondition();
  }
};

const actions: ActionTree<SearchFormState, RootState> = {
  setCondition({ commit }, condition: SelectCondition) {
    if (condition instanceof SelectByServiceIdCondition) {
      commit("setSelectByServiceIdCondition", condition);
    } else if (condition instanceof SelectByConversionCondition) {
      commit("setSelectByConversionCondition", condition);
    } else if (condition instanceof SelectByBusinessEventCondition) {
      commit("setSelectByBusinessEventCondition", condition);
    } else if (condition instanceof SelectByContactCondition) {
      commit("setSelectByContactCondition", condition);
    } else if (condition instanceof SelectByNpsCondition) {
      commit("setSelectByNpsCondition", condition);
    } else if (condition instanceof SelectByNpsChangeCondition) {
      commit("setSelectByNpsChangeCondition", condition);
    } else if (condition instanceof SelectByAppCondition) {
      commit("setSelectByAppCondition", condition);
    } else if (condition instanceof SelectByEngagementCondition) {
      commit("setSelectByEngagementCondition", condition);
    }
  },
  // 渡されたconditionのデータを表示できるフォームが選択されているようにする
  setDisplayFormFromCondition({ commit }, condition: SelectCondition) {
    if (condition instanceof SelectByServiceIdCondition) {
      commit("setCurrentTabType", TabType.ServiceId);
    } else if (condition instanceof SelectByConversionCondition) {
      commit("setCurrentTabType", TabType.Conditions);
      commit("setCurrentFormType", FormType.Conversion);
    } else if (condition instanceof SelectByBusinessEventCondition) {
      commit("setCurrentTabType", TabType.Conditions);
      commit("setCurrentFormType", FormType.BusinessEvent);
    } else if (condition instanceof SelectByContactCondition) {
      commit("setCurrentTabType", TabType.Conditions);
      commit("setCurrentFormType", FormType.Contact);
    } else if (condition instanceof SelectByNpsCondition) {
      commit("setCurrentTabType", TabType.Conditions);
      commit("setCurrentFormType", FormType.Nps);
      commit("setCurrentNpsFormType", NpsFormType.Score);
    } else if (condition instanceof SelectByNpsChangeCondition) {
      commit("setCurrentTabType", TabType.Conditions);
      commit("setCurrentFormType", FormType.Nps);
      commit("setCurrentNpsFormType", NpsFormType.Change);
    } else if (condition instanceof SelectByAppCondition) {
      commit("setCurrentTabType", TabType.Conditions);
      commit("setCurrentFormType", FormType.App);
    } else if (condition instanceof SelectByEngagementCondition) {
      commit("setCurrentTabType", TabType.Repeat);
    }
  },
  async setDefaultToConversionCondition({ commit, rootState, rootGetters }) {
    // 初期値
    const startDate = null;
    const endDate = null;

    let conversionDefinitionIds: number[] = [];
    let hour: number | null = null;
    let additionalConditions: AdditionalSelectCondition[] = [];

    // サーバサイドから取得した履歴から条件を復活できたか
    let isSetHistoryCondition = false;

    // サーバサイドに履歴が保存されていれば、その条件を復活させる
    const searchHistories: ApiRes.SelectionHistory[] = await rootState.api.searchHistory.getSelectionHistory(
      SearchHistoryType.Conversion
    );
    if (searchHistories.length > 0) {
      const history = convertJsonToSelectCondition(
        searchHistories[0].type,
        searchHistories[0].query,
        rootGetters["clientSettings/activeDefinitions"],
        rootGetters["clientSettings/activeMeasurementSites"]
      );
      if (history !== null && history instanceof SelectByConversionCondition) {
        isSetHistoryCondition = true;
        conversionDefinitionIds = history.conversionDefinitionIds;
        hour = history.hour;
        additionalConditions = history.additionalConditions;
      }
    }
    // 履歴から復活できない場合はデフォルトをセットする（すべてのコンバージョン）
    if (!isSetHistoryCondition) {
      conversionDefinitionIds = rootState.clientSettings.activeConversionDefinitions.map(
        def => def.id
      );
    }

    commit(
      "setSelectByConversionCondition",
      new SelectByConversionCondition(
        conversionDefinitionIds,
        startDate,
        endDate,
        hour,
        additionalConditions
      )
    );
  },
  async setDefaultToServiceIdCondition({ commit, rootState, rootGetters }) {
    // サーバサイドに履歴が保存されていれば、その条件を復活させる
    const searchHistories: ApiRes.SelectionHistory[] = await rootState.api.searchHistory.getSelectionHistory(
      SearchHistoryType.ServiceId
    );
    if (searchHistories.length > 0) {
      const history = convertJsonToSelectCondition(
        searchHistories[0].type,
        searchHistories[0].query,
        rootGetters["clientSettings/activeDefinitions"],
        rootGetters["clientSettings/activeMeasurementSites"]
      );
      if (history !== null && history instanceof SelectByServiceIdCondition) {
        commit("setSelectByServiceIdCondition", history);
        return;
      }
    }

    // 履歴から復活できない場合はデフォルトをセットする
    commit(
      "setSelectByServiceIdCondition",
      new SelectByServiceIdCondition([], true)
    );
  },
  async setDefaultToBusinessEventCondition({ commit, rootState, rootGetters }) {
    // 初期値
    const startDate = null;
    const endDate = null;

    let businessEventDefinitionIds: number[] = [];
    let hour: number | null = null;
    let additionalConditions: AdditionalSelectCondition[] = [];

    // サーバサイドから取得した履歴から条件を復活できたか
    let isSetHistoryCondition = false;

    // サーバサイドに履歴が保存されていれば、その条件を復活させる
    const searchHistories: ApiRes.SelectionHistory[] = await rootState.api.searchHistory.getSelectionHistory(
      SearchHistoryType.BusinessEvent
    );
    if (searchHistories.length > 0) {
      const history = convertJsonToSelectCondition(
        searchHistories[0].type,
        searchHistories[0].query,
        rootGetters["clientSettings/activeDefinitions"],
        rootGetters["clientSettings/activeMeasurementSites"]
      );
      if (
        history !== null &&
        history instanceof SelectByBusinessEventCondition
      ) {
        isSetHistoryCondition = true;
        businessEventDefinitionIds = history.businessEventDefinitionIds;
        hour = history.hour;
        additionalConditions = history.additionalConditions;
      }
    }
    // 履歴から復活できない場合はデフォルトをセットする
    if (!isSetHistoryCondition) {
      businessEventDefinitionIds = rootState.clientSettings.activeBusinessEventDefinitions.map(
        def => def.id
      );
    }

    commit(
      "setSelectByBusinessEventCondition",
      new SelectByBusinessEventCondition(
        businessEventDefinitionIds,
        startDate,
        endDate,
        hour,
        additionalConditions
      )
    );
  },
  async setDefaultToContactCondition({ commit, rootState, rootGetters }) {
    // 初期値
    const startDate = null;
    const endDate = null;

    let contactDefinitionIds: number[] = [];
    let hour: number | null = null;
    let additionalConditions: AdditionalSelectCondition[] = [];

    // サーバサイドから取得した履歴から条件を復活できたか
    let isSetHistoryCondition = false;

    // サーバサイドに履歴が保存されていれば、その条件を復活させる
    const searchHistories: ApiRes.SelectionHistory[] = await rootState.api.searchHistory.getSelectionHistory(
      SearchHistoryType.Contact
    );
    if (searchHistories.length > 0) {
      const history = convertJsonToSelectCondition(
        searchHistories[0].type,
        searchHistories[0].query,
        rootGetters["clientSettings/activeDefinitions"],
        rootGetters["clientSettings/activeMeasurementSites"]
      );
      if (history !== null && history instanceof SelectByContactCondition) {
        isSetHistoryCondition = true;
        contactDefinitionIds = history.contactDefinitionIds;
        hour = history.hour;
        additionalConditions = history.additionalConditions;
      }
    }
    // 履歴から復活できない場合はデフォルトをセットする
    if (!isSetHistoryCondition) {
      contactDefinitionIds = rootState.clientSettings.activeContactDefinitions
        .filter(def => {
          return (
            def.type === ContactDefinitionType.TEL ||
            def.type === ContactDefinitionType.MAIL ||
            def.type === ContactDefinitionType.SHOP
          );
        })
        .map(def => def.id);
    }

    commit(
      "setSelectByContactCondition",
      new SelectByContactCondition(
        contactDefinitionIds,
        startDate,
        endDate,
        hour,
        additionalConditions
      )
    );
  },
  /**
   * NPSは、SelectByNpsConditionかSelectByNpsChangeConditionどちらかのみ
   * サーバから取得したデータが使われる
   */
  async setDefaultToNpsCondition({ commit, rootState, rootGetters }) {
    let npsCondition: SelectByNpsCondition | null = null;
    let npsChangeCondition: SelectByNpsChangeCondition | null = null;

    /**
     * 復活した条件はSelectByNpsChangeConditionか
     * npsのtypeのselectでどちらを選択しておくかで使用する
     */
    let isChange = false;

    // サーバサイドに履歴が保存されていれば、その条件を復活させる
    const searchHistories: ApiRes.SelectionHistory[] = await rootState.api.searchHistory.getSelectionHistory(
      SearchHistoryType.Loyalty
    );
    if (searchHistories.length > 0) {
      const history = convertJsonToSelectCondition(
        searchHistories[0].type,
        searchHistories[0].query,
        rootGetters["clientSettings/activeDefinitions"],
        rootGetters["clientSettings/activeMeasurementSites"]
      );
      // 日付を最新にするために条件を作り直す
      if (history !== null && history instanceof SelectByNpsCondition) {
        npsCondition = new SelectByNpsCondition(
          history.from,
          history.to,
          new Date(),
          history.additionalConditions
        );
      }
      if (history instanceof SelectByNpsChangeCondition) {
        isChange = true;
        npsChangeCondition = history;
      }
    }

    if (npsCondition === null) {
      let range = { from: 0, to: 0 };
      const npsDefinitions = rootState.clientSettings.npsDefinitions;
      if (npsDefinitions.length) {
        const npsDefinition = npsDefinitions[0];
        range = npsDefinition.mediumRange;
      }
      npsCondition = new SelectByNpsCondition(
        range.from,
        range.to,
        new Date(),
        []
      );
    }
    if (npsChangeCondition === null) {
      npsChangeCondition = new SelectByNpsChangeCondition(
        [NpsLabel.Detractor],
        NpsLabel.Passive,
        []
      );
    }

    commit("setSelectByNpsCondition", npsCondition);
    commit("setSelectByNpsChangeCondition", npsChangeCondition);
    commit(
      "setCurrentNpsFormType",
      isChange ? NpsFormType.Change : NpsFormType.Score
    );
  },
  async setDefaultToAppCondition({ commit, rootState, rootGetters }) {
    // 初期値
    const startDate = null;
    const endDate = null;

    let conversionDefinitionIds: number[] = [];
    let hour: number | null = null;
    let additionalConditions: AdditionalSelectCondition[] = [];

    // サーバサイドから取得した履歴から条件を復活できたか
    let isSetHistoryCondition = false;

    // サーバサイドに履歴が保存されていれば、その条件を復活させる
    const searchHistories: ApiRes.SelectionHistory[] = await rootState.api.searchHistory.getSelectionHistory(
      SearchHistoryType.App
    );
    if (searchHistories.length > 0) {
      const history = convertJsonToSelectCondition(
        searchHistories[0].type,
        searchHistories[0].query,
        rootGetters["clientSettings/activeDefinitions"],
        rootGetters["clientSettings/activeMeasurementSites"]
      );
      if (history !== null && history instanceof SelectByAppCondition) {
        isSetHistoryCondition = true;
        conversionDefinitionIds = history.conversionDefinitionIds;
        hour = history.hour;
        additionalConditions = history.additionalConditions;
      }
    }
    // Set default if unable to recover from history (App first open from global conversions)
    if (!isSetHistoryCondition) {
      const globalConversions: GlobalConversionDefinition[] =
        rootGetters["system/activeGlobalConversionDefinitions"];
      conversionDefinitionIds = globalConversions
        .filter(
          def =>
            def.path.length > 0 &&
            def.path[0] === GlobalConversionQueryType.FirstOpen
        )
        .map(def => def.id);
    }

    commit(
      "setSelectByAppCondition",
      new SelectByAppCondition(
        conversionDefinitionIds,
        startDate,
        endDate,
        hour,
        additionalConditions
      )
    );
  },
  async setDefaultToEngagementCondition({ commit, rootState, rootGetters }) {
    let condition!: SelectByEngagementCondition;

    // サーバサイドから取得した履歴から条件を復活できたか
    let isSetHistoryCondition = false;

    // サーバサイドに履歴が保存されていれば、その条件を復活させる
    const searchHistories: ApiRes.SelectionHistory[] = await rootState.api.searchHistory.getSelectionHistory(
      SearchHistoryType.Engagement
    );
    if (searchHistories.length > 0) {
      const history = convertJsonToSelectCondition(
        searchHistories[0].type,
        searchHistories[0].query,
        rootGetters["clientSettings/activeDefinitions"],
        rootGetters["clientSettings/activeMeasurementSites"]
      );
      if (history !== null && history instanceof SelectByEngagementCondition) {
        isSetHistoryCondition = true;
        condition = history;
      }
    }

    // 履歴から復活できない場合はデフォルトをセットする
    if (!isSetHistoryCondition) {
      const definitions = rootGetters["clientSettings/activeDefinitions"];
      condition = SelectByEngagementCondition.defaultCondition();

      // default で condition.fieldType は FieldType.AppOnly になっているため、
      // Webのみ契約場合は更新する
      if (rootState.app.canUseWebdataFeatures) {
        condition = condition.updateFieldType(FieldType.WebOnly);
      }

      if (definitions.conversionDefinitions.length > 0) {
        condition = condition.updateConversionDefinitionId(
          definitions.conversionDefinitions[0].id
        );
      }

      if (definitions.eventDefinitions.length > 0) {
        condition = condition.updateEventDefinitionId(
          definitions.eventDefinitions[0].id
        );
      }
    }

    commit("setSelectByEngagementCondition", condition);
  }
};

export const searchForm = {
  namespaced: true,
  state: new SearchFormState(),
  mutations: mutations,
  actions: actions
};
