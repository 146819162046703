<i18n src="@/i18n/views/funnel-create.json"></i18n>
<template>
  <div class="funnelCreate">
    <FunnelHeader :has-back-link="true" :title="$t('funnelAnalysisCreate')" />
    <FunnelPrimaryContent
      class="funnelCreate__content"
      :class="{ 'funnelCreate__content--dragging': isDragging }"
    >
      <div class="funnelCreate__basicInformation">
        <div class="funnelCreate__funnelName">
          <FunnelInputTitle
            :title="$t('funnelName')"
            :icon="ICONS.Tag"
            :note="funnelTitleNote"
            :has-error="!isFunnelTitleValid"
          />
          <InputText
            v-model="funnelTitleValue"
            horizontal-padding="10px"
            :has-error="!isFunnelTitleValid"
            @focus="focusOnTitle"
          />
        </div>
        <div class="funnelCreate__period">
          <FunnelInputTitle :title="$t('period')" :icon="ICONS.History" />
          <DateRangePickerInput
            v-model="dateValue"
            :enabled-period="enabledPeriod"
            :show-period-choices="false"
            data-cy="funnel-periods"
          />
        </div>
      </div>

      <div class="funnelCreate__preFilterConditions">
        <FunnelInputTitle
          :title="$t('preFilterConditions')"
          :icon="ICONS.Users"
        />

        <FunnelUserActivityCondition
          :user-activities="userActivities"
          :cv-defs="allActiveConversionDefinitions"
          :event-defs="eventDefinitions"
          :be-defs="businessEventDefinitions"
          :contact-defs="contactDefinitions"
          :attr-defs="conversionAttributeDefinitions"
          :search-engines="searchEngines"
          :can-use-webdata-features="canUseWebdataFeatures"
          :is-contract-app="isContractApp"
          @change="onChangeUserActivities"
        />

        <FunnelUserAttributeCondition
          v-if="userAttributeDefinitions.length > 0"
          :user-attributes="userAttributes"
          :user-attribute-definitions="userAttributeDefinitions"
          :top-margin="topMarginForUserAttributeCondtion"
          @change="onChangeUserAttributes"
        />
      </div>

      <div class="funnelCreate__steps">
        <FunnelInputTitle
          :title="$t('conditionSetting')"
          :icon="ICONS.Toggle"
          :note="$t('setSteps', { steps: stepProgress })"
        />

        <Draggable
          v-model="conditions"
          easing="cubic-bezier(0.785, 0.135, 0.15, 0.86)"
          animation="600"
          ghost-class="funnelCreate__dragArea--ghost"
          fallback-class="funnelCreate__dragArea--fallback"
          chosen-class="funnelCreate__dragArea--chosen"
          filter=".dragNotApplicable"
          :prevent-on-filter="false"
          :force-fallback="true"
          @start="isDragging = true"
          @end="isDragging = false"
        >
          <div
            v-for="(condition, index) in conditions"
            :key="`funnelCreate__input--${index + renderingCount}`"
            class="funnelCreate__input"
          >
            <FunnelAnalysisSearchInput
              :key="`funnel-analysis-search-input-${index + renderingCount}`"
              class="funnelCreate__searchInput"
              :condition="condition"
              :order="index + 1"
              :all-active-conversion-definitions="
                allActiveConversionDefinitions
              "
              :event-definitions="eventDefinitions"
              :business-event-definitions="businessEventDefinitions"
              :contact-definitions="contactDefinitions"
              :conversion-attribute-definitions="conversionAttributeDefinitions"
              :search-engines="searchEngines"
              :target-period="dateValue"
              :can-use-webdata-features="canUseWebdataFeatures"
              :is-contract-app="isContractApp"
              :is-same-visit-selected="isSameVisitSelected"
              @input="onInputFunnelAnalysisSearchInput($event, index)"
              @delete="onDeleteFunnelAnalysisSearchInput(index)"
            />
            <div
              v-if="index < conditions.length - 1"
              :key="`step-glue-${index}`"
              class="funnelCreate__stepGlue dragNotApplicable hideWhileDragging"
            >
              <Icon
                class="funnelCreate__addStepsIcon"
                :icon="ICONS.InflowBottom"
                :size="20"
                :color="COLORS.Base500"
              />
              <SelectBox
                :value="condition.edge.type"
                class="funnelCreate_edge"
                data-cy="edge-condition"
                :options="edgeTypeOptions"
                @input="onEdgeConditionInput($event, conditions, index)"
              />
              <SelectBox
                v-if="condition.edge.type === edgeType.sameVisit"
                :value="condition.edge.transitionType"
                class="funnelCreate_edge_transition"
                :options="edgeTransitionTypeOptions"
                @input="
                  onEdgeTransitionConditionInput($event, conditions, index)
                "
              />
            </div>
          </div>
        </Draggable>
      </div>

      <div
        v-if="canAddCondition"
        class="funnelCreate__addSteps hideWhileDragging"
        :class="{
          'funnelCreate__addSteps--notfirst': conditions.length > 0
        }"
      >
        <Icon
          class="funnelCreate__addStepsIcon"
          :icon="ICONS.InflowBottom"
          :size="20"
          :color="COLORS.Base500"
        />
        <Button
          v-t="'addStep'"
          class="funnelCreate__addStepsButton"
          :type="BUTTON_TYPE.LIGHT"
          :width="'200px'"
          :disabled="!isStepButtonAvailable"
          @click="addConditionInput"
        />
        <span class="funnelCreate__stepProgress">{{
          $t("setSteps", { steps: stepProgress })
        }}</span>
      </div>
    </FunnelPrimaryContent>

    <div class="funnelCreate__submitButtonWrapper">
      <Button
        v-t="'analyze'"
        class="funnelCreate__submitButton"
        :type="BUTTON_TYPE.DARK"
        :disabled="isSubmitting"
        :width="'350px'"
        @click="onClickSubmitButton"
      />
      <Button
        v-t="'cancel'"
        class="funnelCreate__submitButton"
        :type="BUTTON_TYPE.LIGHT"
        :width="'350px'"
        @click="onClickCancelButton"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Draggable from "vuedraggable";
import { DateRange } from "@/components/date-picker/DateRange";
import DateRangePickerInput from "@/components/date-picker/DateRangePickerInput.vue";
import InputText from "@/components/form/InputText.vue";
import SelectBox from "@/components/form/SelectBox.vue";
import SelectOption from "@/components/form/SelectOption";
import FunnelHeader from "@/components/funnel/FunnelHeader.vue";
import FunnelPrimaryContent from "@/components/funnel/FunnelPrimaryContent.vue";
import FunnelInputTitle from "@/components/funnel/FunnelInputTitle.vue";
import FunnelAnalysisSearchInput from "@/components/funnel/FunnelAnalysisSearchInput.vue";
import FunnelUserActivityCondition from "@/components/funnel/FunnelUserActivityCondition.vue";
import FunnelUserAttributeCondition from "@/components/funnel/FunnelUserAttributeCondition.vue";
import Button from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import { BUTTON_TYPE } from "@/const/button";
import { FunnelStatus } from "@/const/funnel";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import {
  MAX_TITLE_CHAR,
  MAX_CONDITION_NUM,
  FunnelAnalysisCondition
} from "@/models/funnel/FunnelAnalysisCondition";
import {
  FunnelCondition,
  FunnelEdge,
  FunnelEdgeType,
  SameVisitTransitionType
} from "@/models/funnel/FunnelCondition";
import { FunnelConditionActivity } from "@/models/funnel/FunnelConditionActivity";
import { FunnelConditionAttributeType } from "@/models/funnel/FunnelConditionAttribute";
import { Client } from "@/models/Client";
import { usergramEnabledPeriodRange } from "@/util/date-range-picker-util";
import { handleError } from "@/util/error-util";
import { showAlert } from "@/util/modal-util";
import { SearchEngine } from "@/models/system/SearchEngine";

@Component({
  components: {
    FunnelHeader,
    FunnelPrimaryContent,
    FunnelInputTitle,
    FunnelAnalysisSearchInput,
    FunnelUserActivityCondition,
    FunnelUserAttributeCondition,
    InputText,
    DateRangePickerInput,
    Icon,
    Button,
    Draggable,
    SelectBox
  }
})
export default class FunnelCreate extends Vue {
  ICONS = Icons;
  COLORS = Colors;
  edgeType = FunnelEdgeType;
  BUTTON_TYPE = BUTTON_TYPE;

  isTitleFocused = false;
  renderingCount = 0;
  isSubmitting = false;
  isDragging = false;

  mounted() {
    this.$store.commit("funnel/setStatus", FunnelStatus.NOT_STARTED);
  }

  get canUseLongTerm(): boolean {
    return this.$store.state.funnel.canUseLongTerm;
  }

  get searchEngines(): SearchEngine[] {
    return this.$store.state.system.searchEngines;
  }

  get funnelAnalysisCondition(): FunnelAnalysisCondition {
    return this.$store.state.funnel.condition;
  }

  get userActivities(): FunnelConditionAttributeType[] {
    return this.$store.state.funnel.userActivities;
  }

  get userAttributes(): FunnelConditionAttributeType[] {
    return this.$store.state.funnel.userAttributes;
  }

  get conditions(): FunnelCondition[] {
    return this.funnelAnalysisCondition.conditions;
  }
  set conditions(value: FunnelCondition[]) {
    this.renderingCount += MAX_CONDITION_NUM;

    // Maintain edge condition after sorting.
    const newValue = value.map((cur, index) => {
      return this.updateEdgeOfCondition(
        cur,
        this.conditions[index].edge.type,
        this.conditions[index].edge.transitionType
      );
    });

    this.$store.dispatch("funnel/sortFunnelCondition", newValue);
  }

  get enabledPeriod(): DateRange {
    return usergramEnabledPeriodRange();
  }

  get conversionAttributeDefinitions(): ConversionAttributeDefinition[] {
    return this.$store.state.clientSettings
      .activeConversionAttributeDefinitions;
  }
  get contactDefinitions(): ContactDefinition[] {
    return this.$store.state.clientSettings.activeContactDefinitions;
  }

  get eventDefinitions(): EventDefinition[] {
    return this.$store.state.clientSettings.activeEventDefinitions;
  }

  get businessEventDefinitions(): BusinessEventDefinition[] {
    return this.$store.state.clientSettings.activeBusinessEventDefinitions;
  }

  get userAttributeDefinitions(): UserAttributeDefinition[] {
    return this.$store.state.clientSettings.activeUserAttributeDefinitions;
  }

  get allActiveConversionDefinitions(): ConversionDefinition[] {
    return this.$store.getters["clientSettings/allActiveConversionDefinitions"];
  }

  get isStepButtonAvailable(): boolean {
    return (
      this.allActiveConversionDefinitions.length > 0 ||
      this.canUseWebdataFeatures
    );
  }

  get client(): Client {
    return this.$store.state.client.client;
  }

  get isContractApp(): boolean {
    return this.client.isContractApp;
  }

  get canUseWebdataFeatures(): boolean {
    return this.$store.state.app.canUseWebdataFeatures;
  }

  get startDate(): Date {
    return this.funnelAnalysisCondition.startDate;
  }

  get endDate(): Date {
    return this.funnelAnalysisCondition.endDate;
  }

  get dateValue(): DateRange {
    return {
      min: this.startDate,
      max: this.endDate
    };
  }

  get isSameVisitSelected(): boolean {
    return this.conditions
      .slice(0, -1) // Since the edge in last condition is invisible, ignore it
      .map(c => c.edge.type)
      .includes(FunnelEdgeType.sameVisit);
  }

  set dateValue(dateRange: DateRange) {
    this.$store.dispatch("funnel/updateFunnelPeriod", dateRange);
  }

  get funnelTitleValue(): string {
    return this.funnelAnalysisCondition.funnelTitle;
  }

  set funnelTitleValue(title: string) {
    this.$store.dispatch("funnel/updateFunnelTitle", title);
  }

  get canAddCondition(): boolean {
    return this.conditions.length < MAX_CONDITION_NUM;
  }

  get stepProgress(): string {
    return `${this.conditions.length} / ${MAX_CONDITION_NUM}`;
  }

  get funnelTitleLength(): number {
    return this.funnelAnalysisCondition.funnelTitle.length;
  }

  get isFunnelTitleValid(): boolean {
    return (
      0 <= this.funnelTitleLength && this.funnelTitleLength <= MAX_TITLE_CHAR
    );
  }

  get funnelTitleNote(): string {
    if (!this.isTitleFocused) {
      return this.$t("charactersWithin", { num: MAX_TITLE_CHAR }) as string;
    }

    if (!this.isFunnelTitleValid) {
      return this.$t("charactersOver", {
        num: this.funnelTitleLength - MAX_TITLE_CHAR
      }) as string;
    }

    return this.$t("charactersRemaining", {
      num: MAX_TITLE_CHAR - this.funnelTitleLength
    }) as string;
  }

  get topMarginForUserAttributeCondtion() {
    if (this.userAttributes.length === 0) return "15px";
    return this.userActivities.length > 0 ? "10px" : "25px";
  }

  addConditionInput() {
    if (this.isStepButtonAvailable) {
      this.$store.dispatch("funnel/addFunnelCondition");
    }
  }

  onInputFunnelAnalysisSearchInput(
    funnelCondition: FunnelCondition,
    order: number
  ) {
    this.$store.dispatch("funnel/updateFunnelCondition", {
      funnelCondition,
      order
    });
  }

  onDeleteFunnelAnalysisSearchInput(index: number) {
    this.$store
      .dispatch("funnel/deleteFunnelCondition", index)
      // 削除しても:keyにより以前の描画が残るため、:keyを更新し再描画させる
      .then(() => (this.renderingCount += MAX_CONDITION_NUM));
  }

  async onClickSubmitButton() {
    // Prevent submit button from multiple click
    if (this.isSubmitting) {
      return;
    }
    const validationResult = this.funnelAnalysisCondition.validate(
      this.canUseLongTerm
    );
    if (!validationResult.isValid) {
      showAlert(validationResult.errorMessage);
      return;
    }
    this.isSubmitting = true;

    await this.$store
      .dispatch("funnel/create")
      .then(id => {
        this.$router.push({ path: `/funnel/${id}` });
      })
      .catch(handleError)
      .finally(() => (this.isSubmitting = false));
  }

  onClickCancelButton() {
    this.$router.push({
      name: "funnel-analysis"
    });
  }

  focusOnTitle() {
    this.isTitleFocused = true;
  }

  onChangeUserActivities(userActivities: FunnelConditionActivity[]) {
    this.$store.commit("funnel/setUserActivities", userActivities);
  }

  onChangeUserAttributes(userAttributes: FunnelConditionAttributeType[]) {
    this.$store.commit("funnel/setUserAttributes", userAttributes);
  }

  // Options of edge selectbox
  get edgeTypeOptions(): SelectOption[] {
    return [
      {
        value: FunnelEdgeType.allVisit,
        label: this.$t("allVisit") as string,
        disabled: false
      },
      {
        value: FunnelEdgeType.sameVisit,
        label: this.$t("sameVisit") as string,
        disabled: false
      }
    ];
  }

  get edgeTransitionTypeOptions(): SelectOption[] {
    return [
      {
        value: SameVisitTransitionType.allAfter,
        label: this.$t("allAfter") as string,
        disabled: false
      },
      {
        value: SameVisitTransitionType.immediatelyAfter,
        label: this.$t("immediatelyAfter") as string,
        disabled: false
      }
    ];
  }

  onEdgeConditionInput(
    edge: FunnelEdgeType,
    conditions: FunnelCondition[],
    order: number
  ) {
    // Uncheck all noCondition when sameVisit is selected
    if (edge == FunnelEdgeType.sameVisit) {
      conditions.forEach((condition, index) => {
        if (condition.notCondition !== false) {
          const funnelCondition = condition.update({ notCondition: false });
          this.$store.dispatch("funnel/updateFunnelCondition", {
            funnelCondition: funnelCondition,
            order: index
          });
        }
      });
    }

    const funnelCondition = this.updateEdgeOfCondition(conditions[order], edge);
    this.$store.dispatch("funnel/updateFunnelCondition", {
      funnelCondition: funnelCondition,
      order: order
    });
  }

  onEdgeTransitionConditionInput(
    transitionType: SameVisitTransitionType,
    conditions: FunnelCondition[],
    order: number
  ) {
    const funnelCondition = this.updateEdgeOfCondition(
      conditions[order],
      FunnelEdgeType.sameVisit,
      transitionType
    );
    this.$store.dispatch("funnel/updateFunnelCondition", {
      funnelCondition,
      order
    });
  }

  updateEdgeOfCondition(
    funnelCondition: FunnelCondition,
    edge: FunnelEdgeType,
    transitionType?: SameVisitTransitionType
  ): FunnelCondition {
    return funnelCondition.update({
      edge: new FunnelEdge(edge, transitionType)
    });
  }
}
</script>

<style lang="scss" scoped>
.funnelCreate {
  padding: 0 $appContentPadding;
}
.funnelCreate__content {
  margin-top: 35px;
  padding: 25px 20px;
}
.funnelCreate__basicInformation {
  display: flex;
  margin-bottom: 25px;
}
.funnelCreate__funnelName {
  padding-right: 30px;
  width: 610px;
}
.funnelCreate__preFilterConditions {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid $colorBase400;
}
.funnelCreate__stepGlue {
  padding: 20px 0 20px 21px;
  display: flex;
  align-items: center;
}
.funnelCreate__addSteps {
  display: flex;
  align-items: center;
  padding: 10px 0 0 21px;
}
.funnelCreate__addSteps--notfirst {
  margin-top: 10px;
}
.funnelCreate__addStepsIcon {
  margin-right: 15px;
}
.funnelCreate_edge_transition {
  margin-left: 7px;
}
.funnelCreate__stepProgress {
  padding-left: 20px;
  color: $colorBase900;
  font-size: 12px;
}
.funnelCreate__submitButtonWrapper {
  display: flex;
  justify-content: center;
  padding: 30px 0;
}
.funnelCreate__submitButton {
  margin: 0 15px;
}

/**
 * Styles related to drag & drop.
 *
 * .funnelCreate__dragArea
 *   --chosen: A class for grabbed item (when mouse down but dragging is not started).
 *   --ghost: A class for source item. VueDraggable clones grabbed item and moves it when drag starts.
 *   --fallback: A class for an item that being dragged.
 * .hideWhileDragging: A class for child elements that should be hidden while dragging.
 */
.funnelCreate__dragArea--chosen {
  cursor: move;
}
.funnelCreate__dragArea--ghost {
  opacity: 0;
}
.funnelCreate__dragArea--fallback {
  .funnelCreate__searchInput {
    background-color: $colorBlue600;
  }
}
.funnelCreate__content--dragging {
  :deep(.hideWhileDragging) {
    display: none;
  }

  .funnelCreate__searchInput {
    cursor: move;
  }

  .funnelCreate__stepGlue {
    display: block !important;
    opacity: 0;
  }
}
</style>
