var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"funnelResultWrapper"},[_c('div',{staticClass:"funnelResultLeft"},[_c('div',{staticClass:"funnelResultRow"},[_c('div',{staticClass:"funnelResultRow__conditionContainer"},[_c('FunnelItemOrder',{staticClass:"funnelResultRow__order",attrs:{"order":_vm.order,"is-selected":_vm.isMatchedSelected || _vm.isUnmatchedSelected}}),_c('div',{staticClass:"funnelResultRow__condition"},[(_vm.isNotCondition)?_c('FunnelLabel',{staticClass:"funnelResultRow__notCondition",attrs:{"label":_vm.$t('notCondition'),"type":_vm.funnelLablelType}}):_vm._e(),_c('div',{staticClass:"funnelResultRow__title",attrs:{"title":_vm.title},domProps:{"textContent":_vm._s(_vm.title)}})],1),_vm._l((_vm.descriptions),function(text,index){return [_c('div',{key:index,staticClass:"funnelResultRow__description",attrs:{"title":text},domProps:{"textContent":_vm._s(_vm.descriptionForDisplay(text))}})]}),_c('div',{staticClass:"funnelResultRow__attribute",attrs:{"title":_vm.attributeDescription},domProps:{"textContent":_vm._s(_vm.attributeDescriptionForDisplay)}}),(_vm.funnelData.periodsForDisplay !== _vm.periodsForDisplay)?_c('div',{staticClass:"funnelResultRow__preiod"},[_vm._v(" "+_vm._s(_vm.$t("period"))+": "+_vm._s(_vm.funnelData.periodsForDisplay)+" ")]):_vm._e()],2),_c('div',{staticClass:"funnelResultRow__graphContainer"},[_c('div',{staticClass:"funnelResultRow__barContainer",style:({ width: _vm.totalUsersRatio }),on:{"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[_c('div',{staticClass:"funnelResultRow__bar funnelResultRow__matchedBar",class:[
              {
                'funnelResultRow__bar--selected': _vm.isMatchedSelected,
                'funnelResultRow__bar--before-selected': _vm.isBeforeSelected
              }
            ],style:({ width: _vm.matchedUsersRatioInConditionWidth }),on:{"click":function($event){return _vm.onClickMacthed(_vm.matchedUsers.ids)}}}),_c('div',{staticClass:"funnelResultRow__bar funnelResultRow__unmatchedBar",class:[
              { 'funnelResultRow__bar--selected': _vm.isUnmatchedSelected }
            ],style:({
              width: _vm.unmatchedUsersRatioInConditionWidth,
              left: _vm.matchedUsersRatioInConditionWidth
            }),on:{"click":function($event){return _vm.onClickUnmacthed(_vm.unmatchedUsers.ids)}}}),_c('Balloon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBlloon),expression:"showBlloon"}],staticClass:"funnelResultRow__tooltip",attrs:{"direction":_vm.balloonDirection}},[_c('div',{staticClass:"funnelResultRow__tooltipContent"},[_c('div',{staticClass:"funnelResultRow__tooltipColumn"},[_c('span',{staticClass:"funnelResultRow__tooltipDescription"},[_vm._v(_vm._s(_vm.$t("breakdown"))+" ")]),_c('span',{staticClass:"funnelResultRow__tooltipMatchedUser"},[_vm._v(" "+_vm._s(_vm.getFixedPercent(_vm.matchedUsersRatioInCondition))+" ")])]),_c('div',{staticClass:"funnelResultRow__tooltipColumn"},[_c('span',{staticClass:"funnelResultRow__tooltipUnmatchedUser"},[_vm._v(" "+_vm._s(_vm.getFixedPercent(_vm.unmatchedUsersRatioInCondition))+" ")]),_c('span',[_vm._v(" ("+_vm._s(_vm.$t("ofXUsers", { num: _vm.totalUsersInCondition }))+")")])])])])],1),_c('div',{staticClass:"funnelResultRow__userContainer",style:({ width: _vm.totalUsersRatio })},[_c('div',{staticClass:"funnelResultRow__users funnelResultRow__matchedUsers",class:[
              {
                'funnelResultRow__users--selected': _vm.isMatchedSelected,
                'funnelResultRow__users--disabled': _vm.matchedUsers.total === 0
              }
            ],on:{"click":function($event){return _vm.onClickMacthed(_vm.matchedUsers.ids)}}},[(_vm.isMatchedSelected)?_c('Icon',{attrs:{"icon":_vm.selectedIcon,"color":_vm.selectedIconColor,"size":16}}):_c('UserIconMatched',{staticClass:"funnelResultRow__userIcon"}),_c('div',{staticClass:"funnelResultRow__userRatio funnelResultRow__matchedUserRatio",class:[
                {
                  'funnelResultRow__userRatio--disabled':
                    _vm.isMatchedSelected || _vm.matchedUsers.total === 0
                }
              ]},[_vm._v(" "+_vm._s(_vm.matchedUsersRatio)+" "),_c('span',{staticClass:"funnelResultRow__userCount"},[_vm._v(" ("+_vm._s(_vm.$t("numUsers", { num: _vm.matchedUsers.total.toLocaleString() }))+") ")])])],1),(!_vm.isFirstCondition)?_c('div',{staticClass:"funnelResultRow__users funnelResultRow__unmatchedUsers",class:[
              {
                'funnelResultRow__users--selected': _vm.isUnmatchedSelected,
                'funnelResultRow__users--disabled': _vm.unmatchedUsers.total === 0
              }
            ],on:{"click":function($event){return _vm.onClickUnmacthed(_vm.unmatchedUsers.ids)}}},[(_vm.isUnmatchedSelected)?_c('Icon',{attrs:{"icon":_vm.selectedIcon,"color":_vm.selectedIconColor,"size":16}}):_c('UserIconUnmatched',{staticClass:"funnelResultRow__userIcon"}),_c('div',{staticClass:"funnelResultRow__userRatio funnelResultRow__unmatchedUserRatio",class:[
                {
                  'funnelResultRow__userRatio--disabled':
                    _vm.isUnmatchedSelected || _vm.unmatchedUsers.total === 0
                }
              ]},[_vm._v(" "+_vm._s(_vm.unmatchedUsersRatio)+" "),_c('span',{staticClass:"funnelResultRow__userCount"},[_vm._v(" ("+_vm._s(_vm.$t("numUsers", { num: _vm.unmatchedUsers.total.toLocaleString() }))+") ")])])],1):_vm._e()])])]),(!_vm.isLastStep)?_c('div',{staticClass:"funnelResultRow__sameVisit",class:[{ 'funnelResultRow__sameVisit--margin': _vm.isSameVisit }]},[(_vm.isSameVisit)?_c('div',{staticClass:"funnelResultRow__sameVisitWrapper"},[_c('Icon',{attrs:{"icon":_vm.sameVisitIcon,"size":16,"color":_vm.sameVisitColor}}),_c('span',{staticClass:"funnelResultRow__sameVisitText",domProps:{"textContent":_vm._s(_vm.sameVisitText)}})],1):_vm._e()]):_vm._e()]),(_vm.canUseIntermediateJourney)?_c('div',{staticClass:"funnelResultRight"},[_c('FunnelResultRowIntermediateLinks',{attrs:{"funnel-id":_vm.funnelId,"start-end-link":_vm.getIntermediateJourneyLink(
          _vm.funnelId,
          _vm.order,
          'START_END',
          _vm.nextMatchedUsersCount
        ),"start-exit-link":_vm.getIntermediateJourneyLink(
          _vm.funnelId,
          _vm.order,
          'START_EXIT',
          _vm.nextUnmatchedUsersCount
        ),"is-first-step":_vm.order === 1,"is-last-step":_vm.isLastStep},on:{"mouse-enter-intermediate-link":_vm.mouseEnterIntermediateLink,"mouse-leave-intermediate-link":_vm.mouseLeaveIntermediateLink}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }