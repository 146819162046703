<i18n src="@/i18n/views/setting-nav.json"></i18n>
<template>
  <div class="setting-nav">
    <Balloon
      v-show="true"
      class="setting-nav_wrapper"
      data-cy="setting-nav_wrapper"
      :direction="balloonDirection"
    >
      <div class="setting-nav_user">
        <div class="setting-nav_user-company">{{ loginUser.client }}</div>
        <div class="setting-nav_user-name">{{ loginUser.name }}</div>
        <div class="setting-nav_user-loginName">{{ loginUser.loginName }}</div>
      </div>

      <div class="setting-nav_links" data-cy="setting-nav_links">
        <a
          class="setting-nav_item"
          href="/individual-settings/login-id/"
          data-cy="setting-nav-individual-settings"
        >
          <NavigationItem :icon="iconUser">
            <span v-t="'personalAccountSetting'" class="setting-nav_text" />
          </NavigationItem>
        </a>
        <RouterLink
          v-if="isAvailableClientSettings"
          :to="{ name: 'client-settings-top' }"
          class="setting-nav_item"
          href="/client-settings/"
          data-cy="setting-nav-client-settings"
        >
          <NavigationItem :icon="iconSetting">
            <span v-t="'systemSetting'" class="setting-nav_text" />
          </NavigationItem>
        </RouterLink>
        <div data-cy="setting-nav-logout" @click="onClickLogout">
          <NavigationItem :icon="iconLogout">
            <span v-t="'logout'" class="setting-nav_text" />
          </NavigationItem>
        </div>
      </div>
    </Balloon>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { BalloonDirection } from "@/const/balloon";
import Balloon from "@/components/Balloon.vue";
import Icon from "@/components/Icon.vue";
import NavigationItem from "@/components/NavigationItem.vue";
import { LoginUser } from "@/models/auth/UgUser";

const ICON_COLOR: string = "inherit";
const ICON_SIZE: number = 11;

@Component({
  components: {
    Balloon,
    Icon,
    NavigationItem
  }
})
export default class SettingNav extends Vue {
  iconUser = Icons.User;
  iconSetting = Icons.Setting;
  iconHelp = Icons.Help;
  iconLogout = Icons.Logout;
  iconExternalLink = Icons.ExternalLink;
  iconColor = ICON_COLOR;
  iconSize = ICON_SIZE;

  balloonDirection = BalloonDirection.TopEnd;

  get loginUser(): LoginUser {
    return this.$store.state.auth.user;
  }

  get isAvailableClientSettings(): boolean {
    return this.loginUser.permission.isAvailableClientSettings;
  }

  async onClickLogout() {
    await this.$store.dispatch("auth/logout");

    // Purpose to call location.assign.
    // 1. Force browser reload to reset all Vuex state
    // 2. Set next location after login to `/`
    location.assign("/");
  }
}
</script>

<style lang="scss" scoped>
.setting-nav_wrapper {
  width: 320px;
}

.setting-nav_user {
  padding: 15px;
  border-bottom: 1px solid $colorBase500;
}

.setting-nav_user-company {
  margin-bottom: 7px;
  font-size: 12px;
}

.setting-nav_user-name {
  font-weight: bold;
  font-size: 16px;
}

.setting-nav_user-loginName {
  margin-top: 5px;
  font-style: 12px;
}

.setting-nav_links {
  padding: 10px 0;
}

.setting-nav_item {
  display: block;

  &:hover {
    text-decoration: none;
  }
}

.setting-nav_text {
  color: $colorBase900;
}

.setting-nav_icon {
  margin-left: 10px;
}
</style>
