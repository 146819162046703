<i18n src="@/i18n/components/funnel/funnel-analysis-search-input.json"></i18n>
<template>
  <div class="funnelAnalysisSearchInput">
    <FunnelItemOrder class="funnelAnalysisSearchInput__order" :order="order" />

    <div class="funnelAnalysisSearchInput__container">
      <FunnelConditionSelectForm
        show-user-activity-label
        :model-value="form"
        :active-conversion-definitions="allActiveConversionDefinitions"
        :business-event-definitions="businessEventDefinitions"
        :event-definitions="eventDefinitions"
        :contact-definitions="contactDefinitions"
        :conversion-attribute-definitions="conversionAttributeDefinitions"
        :search-engines="searchEngines"
        :can-use-webdata-features="canUseWebdataFeatures"
        :is-contract-app="isContractApp"
        @update:modelValue="onUpdateForm"
      />

      <div class="funnelAnalysisSearchInput__additional hideWhileDragging">
        <div class="funnelAnalysisSearchInput__stepPeriod dragNotApplicable">
          <CheckBox v-model="stepPeriodValue" @input="onStepPeriodInput">{{
            $t("setStepPeriod")
          }}</CheckBox>

          <DateRangePickerInput
            :value="stepPeriodDateValue"
            :enabled-period="enabledPeriod"
            :disabled="!stepPeriodValue"
            :show-period-choices="false"
            class="funnelAnalysisSearchInput__period"
            @input="onStepPeriodDateRangeInput"
          />
        </div>

        <div
          v-if="order > 1"
          class="funnelAnalysisSearchInput__notCondition dragNotApplicable"
        >
          <CheckBox v-model="notConditionValue" :disabled="isSameVisitSelected">
            {{ $t("setExcludeCondition") }}
          </CheckBox>
          <span
            v-if="isSameVisitSelected"
            class="funnelAnalysisSearchInput__notConditionReason"
          >
            ({{ $t("excludeConditionDisableReason") }})
          </span>
        </div>
      </div>
    </div>

    <IconButton
      class="funnelAnalysisSearchInput__deleteButton dragNotApplicable hideWhileDragging"
      :icon="icons.Close"
      :size="36"
      :icon-scale="0.45"
      :background="true"
      @click="onDeleteClick"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ConversionDefinition } from "@/models/client-settings/ConversionDefinition";
import { EventDefinition } from "@/models/client-settings/EventDefinition";
import { BusinessEventDefinition } from "@/models/client-settings/BusinessEventDefinition";
import { ContactDefinition } from "@/models/client-settings/ContactDefinition";
import { ConversionAttributeDefinition } from "@/models/client-settings/ConversionAttributeDefinition";
import {
  FunnelCondition,
  FunnelConditionDefault,
  FunnelConditionInflow,
  FunnelConditionPageTitleOrURL,
  FunnelConditionInflowParam,
  FunnelConditionInflowOrganic
} from "@/models/funnel/FunnelCondition";
import {
  conditionSelectFormFromCondition,
  defaultConditionSelectForm,
  FunnelConditionSelectForm as ConditionSelectForm,
  isPageTitleOrURLTypeForm,
  isInflowTypeForm,
  isInflowParamTypeForm,
  isInflowOrganicTypeForm
} from "@/models/funnel/FunnelConditionSelectForm";
import { Icons } from "@/const/Icons";
import { FunnelType } from "@/const/funnel";
import { DateRange, changeDateRange } from "@/components/date-picker/DateRange";
import DateRangePickerInput from "@/components/date-picker/DateRangePickerInput.vue";
import IconButton from "@/components/IconButton.vue";
import CheckBox from "@/components/form/CheckBox.vue";
import FunnelItemOrder from "@/components/funnel/FunnelItemOrder.vue";
import FunnelConditionSelectForm from "@/components/funnel/FunnelConditionSelectForm.vue";
import { SearchEngine } from "@/models/system/SearchEngine";

@Component({
  components: {
    DateRangePickerInput,
    IconButton,
    CheckBox,
    FunnelItemOrder,
    FunnelConditionSelectForm
  }
})
export default class FunnelAnalysisSearchInput extends Vue {
  @Prop({ type: Object, required: true })
  condition!: FunnelCondition;

  @Prop({ type: Number, required: true })
  order!: number;

  @Prop({ type: Array, required: true })
  allActiveConversionDefinitions!: ConversionDefinition[];

  @Prop({ type: Array, required: true })
  eventDefinitions!: EventDefinition[];

  @Prop({ type: Array, required: true })
  businessEventDefinitions!: BusinessEventDefinition[];

  @Prop({ type: Array, required: true })
  contactDefinitions!: ContactDefinition[];

  @Prop({ type: Array, required: true })
  conversionAttributeDefinitions!: ConversionAttributeDefinition[];

  @Prop({ type: Array, required: true })
  searchEngines!: SearchEngine[];

  @Prop({ type: Object, required: true })
  targetPeriod!: DateRange;

  @Prop({ type: Boolean, required: true })
  canUseWebdataFeatures!: boolean;

  @Prop({ type: Boolean, required: true })
  isContractApp!: boolean;

  @Prop({ type: Boolean, required: true })
  isSameVisitSelected!: boolean;

  onInput(condition: FunnelCondition) {
    this.$emit("input", condition);
  }

  onDelete(condition: FunnelCondition) {
    this.$emit("delete", condition);
  }

  @Watch("targetPeriod")
  updateTargetPeriod(period: DateRange) {
    if (this.stepPeriodValue) {
      const { min, max } = this.stepPeriodDateValue;
      let value: DateRange = {
        min: min > period.min ? min : period.min,
        max: max > period.max ? period.max : max
      };

      if (min > period.max && max > period.max) {
        value = changeDateRange([period.max, period.max]);
      } else if (period.min > min && period.min > max) {
        value = changeDateRange([period.min, period.min]);
      }

      this.stepPeriodDateValue = value;
    } else {
      this.stepPeriodDateValue = period;
    }

    this.onStepPeriodDateRangeInput(this.stepPeriodDateValue);
  }

  icons = Icons;
  funnelType = FunnelType;

  form = defaultConditionSelectForm([]);

  stepPeriodValue = false;
  stepPeriodDateValue: DateRange = this.targetPeriod;

  get enabledPeriod(): DateRange {
    return this.targetPeriod;
  }

  get notConditionValue() {
    return this.condition.notCondition;
  }
  set notConditionValue(notCondition: boolean) {
    const result = this.condition.update({ notCondition });
    this.onInput(result);
  }

  get period(): DateRange {
    const min = this.stepPeriodValue
      ? this.stepPeriodDateValue.min
      : this.targetPeriod.min;

    const max = this.stepPeriodValue
      ? this.stepPeriodDateValue.max
      : this.targetPeriod.max;

    return {
      min,
      max
    };
  }

  createCondition(form: ConditionSelectForm) {
    if (isPageTitleOrURLTypeForm(form)) {
      return new FunnelConditionPageTitleOrURL(
        form.type,
        this.condition.startDate,
        this.condition.endDate,
        form.value,
        this.condition.notCondition,
        form.wordMatchMethod,
        form.searchField,
        this.condition.edge
      );
    } else if (isInflowTypeForm(form)) {
      return new FunnelConditionInflow(
        FunnelType.Inflow,
        this.condition.startDate,
        this.condition.endDate,
        this.condition.notCondition,
        form.matchConditions,
        this.condition.edge
      );
    } else if (isInflowParamTypeForm(form)) {
      return new FunnelConditionInflowParam(
        FunnelType.InflowParam,
        this.condition.startDate,
        this.condition.endDate,
        this.condition.notCondition,
        form.inflowParam,
        this.condition.edge
      );
    } else if (isInflowOrganicTypeForm(form)) {
      return new FunnelConditionInflowOrganic(
        FunnelType.InflowOrganic,
        this.condition.startDate,
        this.condition.endDate,
        this.condition.notCondition,
        this.searchEngines,
        form.matchConditions,
        this.condition.edge
      );
    } else {
      return new FunnelConditionDefault(
        form.type,
        this.condition.startDate,
        this.condition.endDate,
        form.value,
        this.condition.notCondition,
        form.attributes,
        this.condition.edge
      );
    }
  }

  onUpdateForm(form: ConditionSelectForm) {
    this.form = form;
    this.onInput(this.createCondition(this.form));
  }

  onStepPeriodInput(value: boolean) {
    if (!value) {
      this.stepPeriodDateValue = this.targetPeriod;
    }

    const result = this.condition.update({
      startDate: this.period.min,
      endDate: this.period.max
    });
    this.onInput(result);
  }

  onStepPeriodDateRangeInput(value: DateRange | null) {
    if (value !== null) {
      this.stepPeriodDateValue = value;

      const { min, max } = value;

      const result = this.condition.update({
        startDate: min,
        endDate: max
      });
      this.onInput(result);
    }
  }

  created() {
    if (
      this.condition.startDate.getTime() !== this.targetPeriod.min.getTime() ||
      this.condition.endDate.getTime() !== this.targetPeriod.max.getTime()
    ) {
      this.stepPeriodValue = true;
      this.stepPeriodDateValue = {
        min: this.condition.startDate,
        max: this.condition.endDate
      };
    } else {
      this.stepPeriodValue = false;
      this.stepPeriodDateValue = this.targetPeriod;
    }

    this.form = conditionSelectFormFromCondition(this.condition);
  }

  onDeleteClick() {
    this.onDelete(this.condition);
  }
}
</script>

<style lang="scss" scoped>
.funnelAnalysisSearchInput {
  position: relative;
  padding: 20px 20px 20px 60px;
  border: 1px solid $colorBase700;
  border-radius: $sizeRadius;
  background-color: $colorWhite;
}

.funnelAnalysisSearchInput__order {
  position: absolute;
  top: 20px;
  left: 20px;
}

.funnelAnalysisSearchInput__additional {
  margin-top: 20px;
}

.funnelAnalysisSearchInput__stepPeriod {
  display: inline-flex;
  align-items: center;
}

.funnelAnalysisSearchInput__notCondition {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.funnelAnalysisSearchInput__notConditionReason {
  display: inline-block;
  margin-top: 3px;
  color: $colorBase900;
  font-size: 12px;
}

.funnelAnalysisSearchInput__deleteButton {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
