<i18n src="@/i18n/views/user-search-result.json"></i18n>
<template>
  <UserBaseBalloon
    class="userOtherBalloon"
    :show="show"
    :x="x"
    :y="y"
    :direction="direction"
    :adjust="adjust"
  >
    <div class="userOtherBalloon__container">
      <div class="userOtherBalloon__column userOtherBalloon__attribute">
        <div class="userOtherBalloon__inner">
          <PropertyList>
            <tr>
              <th v-t="user.isFixedServiceId ? 'serviceId' : 'userId'" />
              <td>
                <UserServiceIdText
                  :service-id-text="user.serviceIdForDisplay"
                  :is-fixed-service-id="user.isFixedServiceId"
                />
              </td>
            </tr>
            <tr>
              <th v-t="'terminalUsed'" />
              <td>{{ user.device.toString }}</td>
            </tr>
            <tr>
              <th v-t="'conversionDate'" />
              <td>{{ formatDate(user.conversion.date) }}</td>
            </tr>
            <tr>
              <th v-t="'conversionName'" />
              <td>{{ user.conversion.name }}</td>
            </tr>
            <tr v-for="column in columns" :key="column.id">
              <th>{{ column.name }}</th>
              <td>{{ getColumnValue(column.id, user) }}</td>
            </tr>
          </PropertyList>
        </div>
      </div>
      <div class="userOtherBalloon__column">
        <div v-if="hasConversionSummary || hasEventSummary">
          <div class="userOtherBalloon__userSummary">
            <div
              v-if="hasConversionSummary"
              class="userOtherBalloon__conversionSummary"
            >
              <UserSummary
                :title="$t('cvName')"
                :row-data="summaryConversions"
              />
            </div>
            <div v-if="hasEventSummary" class="userOtherBalloon__eventSummary">
              <UserSummary :title="$t('eventName')" :row-data="summaryEvents" />
            </div>
          </div>
          <div class="userOtherBalloon__userSummaryPeriod"
            >{{ $t("targetPeriod") }}: {{ periodRange }}</div
          >
        </div>
        <div v-if="$i18n.locale === 'ja'" class="userOtherBalloon__wordCloud">
          <slot name="wordcloud"></slot>
        </div>
      </div>
    </div>
  </UserBaseBalloon>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { User } from "@/models/User";
import {
  formatDate,
  DateFormat,
  getTwoYearsAgoStartDate
} from "@/util/date-util";
import { TableColumn } from "@/components/users/AttributeTableColumnForm";
import { getColumnValue } from "@/components/users/getUserAttributeTableColumn";
import PropertyList from "@/components/list/PropertyList.vue";
import UserBaseBalloon, {
  UserBalloonDirection
} from "@/components/users/UserBaseBalloon.vue";
import UserServiceIdText from "@/components/users/UserServiceIdText.vue";
import UserSummary from "@/components/table/UserSummary.vue";
import { UserSummaryItem } from "@/models/user-summary/UserSummaryItem";
import { isLastItem } from "@/util/list-util";

const DEFAULT_USER_SUMMARY_ITEM_SIZE = 4;

@Component({
  components: {
    PropertyList,
    UserBaseBalloon,
    UserServiceIdText,
    UserSummary
  }
})
export default class UserOtherBalloon extends Vue {
  @Prop({ type: User, required: true })
  user!: User;

  @Prop({ type: Array, required: true })
  columns!: TableColumn[];

  @Prop({ type: Boolean, default: false })
  show!: boolean;

  @Prop({ type: Number, required: true })
  direction!: UserBalloonDirection;

  @Prop({ type: String, default: "auto" })
  y!: string;

  @Prop({ type: String, default: "auto" })
  x!: string;

  @Prop({ type: Number, default: 0 })
  adjust!: number;

  get summaryConversions(): UserSummaryItem[] {
    if (!this.user.summary) return [];
    const conversions = this.user.summary.conversions;
    if (!conversions) return [];
    if (conversions.length < DEFAULT_USER_SUMMARY_ITEM_SIZE) return conversions;
    return this.convertLastItemNameToOthers(conversions);
  }

  get summaryEvents(): UserSummaryItem[] {
    if (!this.user.summary) return [];
    const events = this.user.summary.events;
    if (!events) return [];
    if (events.length < DEFAULT_USER_SUMMARY_ITEM_SIZE) return events;
    return this.convertLastItemNameToOthers(events);
  }

  get hasConversionSummary(): boolean {
    return this.summaryConversions.length > 0;
  }

  get hasEventSummary(): boolean {
    return this.summaryEvents.length > 0;
  }

  get periodRange(): string {
    const today = new Date();
    const startDate = getTwoYearsAgoStartDate();
    return `${this.formatPeriodDate(startDate)} - ${this.formatPeriodDate(
      today
    )}`;
  }

  formatDate(date: Date): string {
    return formatDate(DateFormat.yyyy年M月d日E_HH時mm分_TZONEDIFF, date);
  }

  getColumnValue(columnId: string, user: User) {
    return getColumnValue(columnId, user);
  }

  private convertLastItemNameToOthers(
    originalItems: UserSummaryItem[]
  ): UserSummaryItem[] {
    return originalItems.map((item, index) => {
      if (isLastItem(originalItems, index)) {
        return new UserSummaryItem(
          this.$i18n.t("others") as string,
          item.count,
          item.rate
        );
      }
      return item;
    });
  }

  private formatPeriodDate(date: Date): string {
    return formatDate(DateFormat.yyyysMMsdd, date);
  }
}
</script>

<style scoped lang="scss">
.userOtherBalloon__container {
  display: flex;
  max-width: $browserMinWidth;
}

.userOtherBalloon__column {
  &:first-child {
    padding-right: 10px;
  }

  &:last-child {
    padding-left: 10px;
  }
}

.userOtherBalloon__attribute {
  width: 400px;
}

.userOtherBalloon__inner {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: $colorClear;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $colorScrollbar;
  }
}

$user-summary-table-width: 228px;
$user-summary-table-gap: 18px;
.userOtherBalloon__userSummary {
  display: flex;
  width: calc(2 * #{$user-summary-table-width} + #{$user-summary-table-gap});
}

.userOtherBalloon__conversionSummary,
.userOtherBalloon__eventSummary {
  flex: 1;
  width: 0;
  &:not(:last-child) {
    margin-right: $user-summary-table-gap;
  }
}

.userOtherBalloon__userSummaryPeriod {
  font-size: 10px;
  color: $colorBase700;
  text-align: right;
  margin-top: 6px;
}

.userOtherBalloon__wordCloud {
  text-align: center;
  margin-top: 30px;
  max-width: $wordCloudWidth + 10px;
}
</style>
