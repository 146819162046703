<i18n
  src="@/i18n/components/funnel/funnel-result-row-intermediate-links.json"
></i18n>
<template>
  <div class="funnelResultRow_intermediate">
    <div v-if="isFirstStep" class="funnelResultRow_intermediate_title">
      {{ $t("intermediateJourney") }}
      <br />
      {{ $t("beta") }}
    </div>
    <div v-if="!isLastStep" class="funnelResultRow_intermediateLinks">
      <a
        :href="startEndLink"
        class="funnelResultRow_intermediateLinks_link"
        :class="{ disable_link: !startEndLink }"
        target="_blank"
        rel="noopener"
        @mouseenter="onMouseEnter('START_END')"
        @mouseleave="onMouseLeave"
      >
        <span>{{ $t("intermediateStartEnd") }}</span>
        <Icon
          :icon="iconExternalLink"
          :color="startEndLink ? undefined : Colors.Base600"
          :size="12"
        />
      </a>
      <a
        :href="startExitLink"
        class="funnelResultRow_intermediateLinks_link"
        :class="{ disable_link: !startExitLink }"
        target="_blank"
        rel="noopener"
        @mouseenter="onMouseEnter('START_EXIT')"
        @mouseleave="onMouseLeave"
      >
        <span>{{ $t("intermediateStartExit") }}</span>
        <Icon
          :icon="iconExternalLink"
          :color="startExitLink ? undefined : Colors.Base600"
          :size="12"
        />
      </a>
    </div>
    <FunnelResultRowIntermediateArrow
      v-if="!isLastStep"
      class="funnelResultRow_intermediateArrow"
      :hovered="intermediateArrowHovered"
    />
  </div>
</template>

<script lang="ts">
import FunnelResultRowIntermediateArrow from "@/components/funnel/FunnelResultRowIntermediateArrow.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";

@Component({
  components: {
    FunnelResultRowIntermediateArrow,
    Icon
  }
})
export default class FunnelResultRowIntermediateLinks extends Vue {
  @Prop({ type: String, required: true })
  funnelId!: string;

  @Prop({ type: String, required: true })
  startEndLink!: string;

  @Prop({ type: String, required: true })
  startExitLink!: string;

  @Prop({ type: Boolean, required: false })
  isFirstStep!: number;

  @Prop({ type: Boolean, required: false })
  isLastStep!: number;

  Colors = Colors;
  iconExternalLink = Icons.ExternalLink;

  intermediateArrowHovered = false;

  onMouseEnter(journeySubType: string) {
    this.intermediateArrowHovered = true;
    this.$emit("mouse-enter-intermediate-link", journeySubType);
  }

  onMouseLeave() {
    this.intermediateArrowHovered = false;
    this.$emit("mouse-leave-intermediate-link");
  }
}
</script>

<style scoped lang="scss">
.funnelResultRow_intermediate {
  height: 100%;
  position: relative;
  width: 131px;
  margin-left: 18px;
  display: flex;
  flex-direction: column;
}

.funnelResultRow_intermediate_title {
  position: absolute;
  top: 6px;
  width: 100%;
  font-size: 12px;
  color: $colorBase700;
  text-align: center;
  line-height: 15.6px;
  padding-left: 35px;
}

$links-margin-top: 38px;
.funnelResultRow_intermediateLinks {
  position: absolute;
  height: 100%;
  top: $links-margin-top;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.funnelResultRow_intermediateLinks_link {
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  padding: 6px 9px;
  margin: 5px 0;
  color: $colorText;
  font-size: 12px;

  &:hover {
    text-decoration: none;
    background-color: $colorBlue600;
  }

  & > span {
    margin-right: 6px;
  }
}

.funnelResultRow_intermediateArrow {
  top: $links-margin-top + 5px;
}

.disable_link {
  pointer-events: none;
  border-color: $bgColorDisabled;
  color: $colorDisabled;
}
</style>
