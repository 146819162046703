<i18n src="@/i18n/components/funnel/funnel-result-row.json"></i18n>
<template>
  <div class="funnelResultWrapper">
    <div class="funnelResultLeft">
      <div class="funnelResultRow">
        <div class="funnelResultRow__conditionContainer">
          <FunnelItemOrder
            class="funnelResultRow__order"
            :order="order"
            :is-selected="isMatchedSelected || isUnmatchedSelected"
          />
          <div class="funnelResultRow__condition">
            <FunnelLabel
              v-if="isNotCondition"
              :label="$t('notCondition')"
              :type="funnelLablelType"
              class="funnelResultRow__notCondition"
            />

            <div class="funnelResultRow__title" :title="title" v-text="title" />
          </div>
          <template v-for="(text, index) in descriptions">
            <div
              :key="index"
              class="funnelResultRow__description"
              :title="text"
              v-text="descriptionForDisplay(text)"
            />
          </template>
          <div
            class="funnelResultRow__attribute"
            :title="attributeDescription"
            v-text="attributeDescriptionForDisplay"
          />
          <div
            v-if="funnelData.periodsForDisplay !== periodsForDisplay"
            class="funnelResultRow__preiod"
          >
            {{ $t("period") }}: {{ funnelData.periodsForDisplay }}
          </div>
        </div>
        <div class="funnelResultRow__graphContainer">
          <div
            class="funnelResultRow__barContainer"
            :style="{ width: totalUsersRatio }"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
          >
            <div
              class="funnelResultRow__bar funnelResultRow__matchedBar"
              :class="[
                {
                  'funnelResultRow__bar--selected': isMatchedSelected,
                  'funnelResultRow__bar--before-selected': isBeforeSelected
                }
              ]"
              :style="{ width: matchedUsersRatioInConditionWidth }"
              @click="onClickMacthed(matchedUsers.ids)"
            />
            <div
              class="funnelResultRow__bar funnelResultRow__unmatchedBar"
              :class="[
                { 'funnelResultRow__bar--selected': isUnmatchedSelected }
              ]"
              :style="{
                width: unmatchedUsersRatioInConditionWidth,
                left: matchedUsersRatioInConditionWidth
              }"
              @click="onClickUnmacthed(unmatchedUsers.ids)"
            />
            <Balloon
              v-show="showBlloon"
              class="funnelResultRow__tooltip"
              :direction="balloonDirection"
            >
              <div class="funnelResultRow__tooltipContent">
                <div class="funnelResultRow__tooltipColumn">
                  <span class="funnelResultRow__tooltipDescription"
                    >{{ $t("breakdown") }}
                  </span>
                  <span class="funnelResultRow__tooltipMatchedUser">
                    {{ getFixedPercent(matchedUsersRatioInCondition) }}
                  </span>
                </div>
                <div class="funnelResultRow__tooltipColumn">
                  <span class="funnelResultRow__tooltipUnmatchedUser">
                    {{ getFixedPercent(unmatchedUsersRatioInCondition) }}
                  </span>
                  <span>
                    ({{ $t("ofXUsers", { num: totalUsersInCondition }) }})</span
                  >
                </div>
              </div>
            </Balloon>
          </div>
          <div
            class="funnelResultRow__userContainer"
            :style="{ width: totalUsersRatio }"
          >
            <div
              class="funnelResultRow__users funnelResultRow__matchedUsers"
              :class="[
                {
                  'funnelResultRow__users--selected': isMatchedSelected,
                  'funnelResultRow__users--disabled': matchedUsers.total === 0
                }
              ]"
              @click="onClickMacthed(matchedUsers.ids)"
            >
              <Icon
                v-if="isMatchedSelected"
                :icon="selectedIcon"
                :color="selectedIconColor"
                :size="16"
              />
              <UserIconMatched v-else class="funnelResultRow__userIcon" />
              <div
                class="funnelResultRow__userRatio funnelResultRow__matchedUserRatio"
                :class="[
                  {
                    'funnelResultRow__userRatio--disabled':
                      isMatchedSelected || matchedUsers.total === 0
                  }
                ]"
              >
                {{ matchedUsersRatio }}
                <span class="funnelResultRow__userCount">
                  ({{
                    $t("numUsers", {
                      num: matchedUsers.total.toLocaleString()
                    })
                  }})
                </span>
              </div>
            </div>
            <div
              v-if="!isFirstCondition"
              class="funnelResultRow__users funnelResultRow__unmatchedUsers"
              :class="[
                {
                  'funnelResultRow__users--selected': isUnmatchedSelected,
                  'funnelResultRow__users--disabled': unmatchedUsers.total === 0
                }
              ]"
              @click="onClickUnmacthed(unmatchedUsers.ids)"
            >
              <Icon
                v-if="isUnmatchedSelected"
                :icon="selectedIcon"
                :color="selectedIconColor"
                :size="16"
              />
              <UserIconUnmatched v-else class="funnelResultRow__userIcon" />
              <div
                class="funnelResultRow__userRatio funnelResultRow__unmatchedUserRatio"
                :class="[
                  {
                    'funnelResultRow__userRatio--disabled':
                      isUnmatchedSelected || unmatchedUsers.total === 0
                  }
                ]"
              >
                {{ unmatchedUsersRatio }}
                <span class="funnelResultRow__userCount">
                  ({{
                    $t("numUsers", {
                      num: unmatchedUsers.total.toLocaleString()
                    })
                  }})
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!isLastStep"
        class="funnelResultRow__sameVisit"
        :class="[{ 'funnelResultRow__sameVisit--margin': isSameVisit }]"
      >
        <div v-if="isSameVisit" class="funnelResultRow__sameVisitWrapper">
          <Icon :icon="sameVisitIcon" :size="16" :color="sameVisitColor" />
          <span class="funnelResultRow__sameVisitText" v-text="sameVisitText" />
        </div>
      </div>
    </div>
    <div v-if="canUseIntermediateJourney" class="funnelResultRight">
      <FunnelResultRowIntermediateLinks
        :funnel-id="funnelId"
        :start-end-link="
          getIntermediateJourneyLink(
            funnelId,
            order,
            'START_END',
            nextMatchedUsersCount
          )
        "
        :start-exit-link="
          getIntermediateJourneyLink(
            funnelId,
            order,
            'START_EXIT',
            nextUnmatchedUsersCount
          )
        "
        :is-first-step="order === 1"
        :is-last-step="isLastStep"
        @mouse-enter-intermediate-link="mouseEnterIntermediateLink"
        @mouse-leave-intermediate-link="mouseLeaveIntermediateLink"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import UserIconMatched from "@/assets/svg/funnel/match.svg";
import UserIconUnmatched from "@/assets/svg/funnel/unmatch.svg";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import Icon from "@/components/Icon.vue";
import Balloon from "@/components/Balloon.vue";
import { BalloonDirection } from "@/const/balloon";
import { FunnelUserList } from "@/api/apis/ApiFunnel";
import { FunnelData } from "@/models/funnel/FunnelData";
import FunnelItemOrder from "@/components/funnel/FunnelItemOrder.vue";
import { FunnelLablelType } from "@/const/funnel";
import FunnelLabel from "@/components/funnel/FunnelLabel.vue";
import FunnelResultRowIntermediateLinks from "@/components/funnel/FunnelResultRowIntermediateLinks.vue";
import { UgTag, UgEventTag, UgAttributeTag } from "@/store/modules/ugTag";
import {
  FunnelEdge,
  FunnelEdgeType,
  SameVisitTransitionType
} from "@/models/funnel/FunnelCondition";

@Component({
  components: {
    Icon,
    Balloon,
    FunnelItemOrder,
    FunnelLabel,
    UserIconMatched,
    UserIconUnmatched,
    FunnelResultRowIntermediateLinks
  }
})
export default class FunnelResultRow extends Vue {
  selectedIcon = Icons.Search;
  selectedIconColor = Colors.HighlightedBar;

  sameVisitIcon = Icons.InflowBottom;
  sameVisitColor = Colors.Base500;

  balloonDirection = BalloonDirection.Bottom;
  showBlloon = false;

  isMatchedClicked = false;
  isUnmatchedClicked = false;

  funnelLablelType = FunnelLablelType.NOT;
  graphContainerWidth = 0;

  @Prop({ type: String, required: true })
  funnelId!: string;

  @Prop({ type: Array, required: true })
  descriptions!: string[];

  @Prop({ type: String, default: "" })
  attributeDescription!: string;

  @Prop({ type: Number, required: true })
  order!: number;

  @Prop({ type: Number, required: true })
  selectedOrder!: number;

  @Prop({ type: Number, required: true })
  totalUsers!: number;

  @Prop({ type: Object, required: true })
  funnelData!: FunnelData;

  @Prop({ type: Object, default: undefined })
  nextFunnelData!: FunnelData | undefined;

  @Prop({ type: String, required: true })
  periodsForDisplay!: string;

  @Prop({ type: Boolean, required: true })
  isLastStep!: number;

  @Prop({ type: Number, default: -1 })
  matchParamInUrl!: number;

  @Prop({ type: Array, required: true })
  notConditionOrders!: number[];

  onClick(ids: string[], order: number, isMatchedSelected: boolean) {
    this.$emit("click", ids, order, isMatchedSelected);
  }

  mounted() {
    if (this.matchParamInUrl === 1) {
      this.isMatchedClicked = true;
    } else if (this.matchParamInUrl === 0) {
      this.isUnmatchedClicked = true;
    }
    const graphContainer = document.getElementsByClassName(
      "funnelResultRow__graphContainer"
    )[0];
    if (graphContainer !== undefined) {
      this.graphContainerWidth = graphContainer.clientWidth;
    }
  }

  get isFirstCondition(): boolean {
    return this.order === 1;
  }

  get title(): string {
    return this.funnelData.condition.title;
  }

  get matchedUsers(): FunnelUserList {
    return this.funnelData.matchedUsers;
  }
  get unmatchedUsers(): FunnelUserList {
    return this.funnelData.unmatchedUsers;
  }

  get nextMatchedUsersCount(): number {
    return this.nextFunnelData?.matchedUsers.total || 0;
  }
  get nextUnmatchedUsersCount(): number {
    return this.nextFunnelData?.unmatchedUsers.total || 0;
  }

  get totalUsersInCondition(): number {
    return this.matchedUsers.total + this.unmatchedUsers.total;
  }

  get matchedUsersRatioInCondition(): number {
    if (this.totalUsersInCondition === 0) {
      return 0;
    }
    return (this.matchedUsers.total * 100) / this.totalUsersInCondition;
  }

  get matchedUsersRatioInConditionWidth(): string {
    return 0 < this.matchedUsersRatioInCondition &&
      this.matchedUsersRatioInCondition * this.graphContainerWidth < 1
      ? "1px"
      : this.getFixedPercent(this.matchedUsersRatioInCondition);
  }

  get unmatchedUsersRatioInCondition(): number {
    if (this.totalUsersInCondition === 0) {
      return 0;
    }

    return (this.unmatchedUsers.total * 100) / this.totalUsersInCondition;
  }

  get unmatchedUsersRatioInConditionWidth(): string {
    return 0 < this.unmatchedUsersRatioInCondition &&
      this.unmatchedUsersRatioInCondition * this.graphContainerWidth < 1
      ? "1px"
      : this.getFixedPercent(this.unmatchedUsersRatioInCondition);
  }

  get matchedUsersRatio(): string {
    if (this.totalUsers === 0) {
      return "0.0%";
    }

    return this.getFixedPercent(
      (this.matchedUsers.total * 100) / this.totalUsers
    );
  }

  get unmatchedUsersRatio(): string {
    if (this.totalUsers === 0) {
      return "0.0%";
    }

    return this.getFixedPercent(
      (this.unmatchedUsers.total * 100) / this.totalUsers
    );
  }

  get totalUsersRatio() {
    return `calc(${this.matchedUsersRatio} + ${this.unmatchedUsersRatio})`;
  }

  get isNotCondition(): boolean {
    return this.funnelData.condition.notCondition;
  }

  descriptionForDisplay(text: string) {
    if (text.length < 36) {
      return text;
    } else {
      return text.substring(0, 35) + "...";
    }
  }
  get attributeDescriptionForDisplay(): string {
    if (this.attributeDescription.length < 36) {
      return this.attributeDescription;
    } else {
      return this.attributeDescription.substring(0, 35) + "...";
    }
  }

  get isConditionSelected(): boolean {
    return this.order === this.selectedOrder;
  }
  get isMatchedSelected(): boolean {
    return this.isConditionSelected ? this.isMatchedClicked : false;
  }
  get isUnmatchedSelected(): boolean {
    return this.isConditionSelected ? this.isUnmatchedClicked : false;
  }
  get isBeforeSelected(): boolean {
    return this.order < this.selectedOrder;
  }

  get funnelEdge(): FunnelEdge {
    return this.funnelData.condition.edge;
  }

  get isSameVisit(): boolean {
    return this.funnelEdge.type === FunnelEdgeType.sameVisit;
  }

  get sameVisitText(): string {
    const baseText = this.$t("withinSameVisit") as string;

    if (
      this.funnelEdge.transitionType ===
      SameVisitTransitionType.immediatelyAfter
    ) {
      return baseText + ` (${this.$t("immediatelyAfter") as string})`;
    }
    return baseText;
  }

  getIntermediateJourneyLink(
    funnelId: string,
    order: number,
    journeySubType: string,
    usersCount: number
  ): string {
    // Not create IntermediateJourneyLink for cases:
    // 1. with 0 matchedUsers/unmatchedUsers
    // 2. start condition or end condition is NOT condition
    if (
      usersCount === 0 ||
      this.notConditionOrders.includes(order) ||
      this.notConditionOrders.includes(order + 1)
    ) {
      return "";
    }
    return `/intermediate-journey?funnelId=${funnelId}&funnelConditionIndex=${order}&type=${journeySubType}`;
  }

  getFixedPercent(value: number): string {
    return `${value.toFixed(1)}%`;
  }

  getUserCount(count: number) {
    return this.$i18n.t("numUsers", { num: count }) as string;
  }

  onClickMacthed(ids: string[] = []) {
    if (!this.isMatchedSelected && ids.length > 0) {
      this.isUnmatchedClicked = false;
      this.isMatchedClicked = true;

      UgTag.pushEvent(UgEventTag.FunnelToUserList, {
        [UgAttributeTag.UserListType]: "funnel_matched_users",
        [UgAttributeTag.FunnelOrder]: this.order
      });

      this.onClick(ids, this.order, true);
    }
  }

  onClickUnmacthed(ids: string[] = []) {
    if (!this.isUnmatchedSelected && ids.length > 0) {
      this.isMatchedClicked = false;
      this.isUnmatchedClicked = true;

      UgTag.pushEvent(UgEventTag.FunnelToUserList, {
        [UgAttributeTag.UserListType]: "funnel_unmatched_users",
        [UgAttributeTag.FunnelOrder]: this.order
      });

      this.onClick(ids, this.order, false);
    }
  }

  onMouseEnter() {
    if (!this.isFirstCondition) {
      this.showBlloon = true;
    }
  }

  onMouseLeave() {
    this.showBlloon = false;
  }

  mouseEnterIntermediateLink(journeySubType: string) {
    const matchedBar = this.$el.querySelector(".funnelResultRow__matchedBar");
    matchedBar && matchedBar.classList.add("funnelResultRow__bar--highlighted");

    const nextRow = this.$el.nextElementSibling;
    const nextHighlightBar =
      nextRow &&
      nextRow.querySelector(
        journeySubType == "START_END"
          ? ".funnelResultRow__matchedBar"
          : ".funnelResultRow__unmatchedBar"
      );
    nextHighlightBar &&
      nextHighlightBar.classList.add("funnelResultRow__bar--highlighted");
  }

  mouseLeaveIntermediateLink() {
    const parentElement = this.$el.parentElement;

    parentElement &&
      parentElement
        .querySelectorAll(".funnelResultRow__bar--highlighted")
        .forEach(bar =>
          bar.classList.remove("funnelResultRow__bar--highlighted")
        );
  }

  get canUseIntermediateJourney(): boolean {
    return this.$store.state.app.canUseIntermediateJourney;
  }
}
</script>

<style scoped lang="scss">
.funnelResultWrapper {
  display: flex;
}

.funnelResultRow {
  display: flex;
  flex: 1;
  padding: 20px 0;
}

.funnelResultRow__order {
  position: absolute;
  top: 0;
  left: 0;
}

.funnelResultRow__conditionContainer {
  position: relative;
  padding: 0 30px;
  width: $funnelResultRowConditionWidth + 2px;
}

.funnelResultRow__condition {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.funnelResultRow__notCondition {
  margin-right: 10px;
}

.funnelResultRow__title {
  overflow: hidden;
  height: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align-last: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.funnelResultRow__description {
  color: $colorBase900;
  font-size: 12px;
  line-height: 1.75;
  overflow-wrap: break-word;
}
.funnelResultRow__attribute {
  padding-top: 2px;
  color: $colorBase700;
  word-break: break-all;
  font-size: 12px;
  line-height: 1.5;
}

.funnelResultRow__preiod {
  color: $colorBase700;
  word-break: break-all;
  font-size: 12px;
  line-height: 1.5;
}

.funnelResultRow__graphContainer {
  flex: 1;
}
.funnelResultRow__barContainer {
  position: relative;
  height: $funnelBarHeight;
}
.funnelResultRow__matchedBar {
  left: 0;
  background-color: $colorCv;
}
.funnelResultRow__unmatchedBar {
  background-color: $colorGray200;
}
.funnelResultRow__bar {
  position: absolute;
  height: $funnelBarHeight;
  cursor: pointer;

  &--selected {
    background-color: $colorHighlightedBar;
    cursor: none;
    pointer-events: none;
  }
  &--before-selected {
    background-color: $colorSemiHighlightedBar;
  }

  &--highlighted {
    outline: 2px dashed $colorHighlightedBar;
    z-index: 1;
  }
}

.funnelResultRow__userContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.funnelResultRow__matchedUsers {
  margin-right: 15px;
  padding-left: 10px;
  color: $colorBlue900;
}

.funnelResultRow__unmatchedUsers {
  color: $colorBase700;
}

.funnelResultRow__users {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  &--selected {
    color: $colorHighlightedBar;
    cursor: none;
    pointer-events: none;
  }

  &--disabled {
    cursor: none;
    pointer-events: none;
  }
}

.funnelResultRow__userIcon {
  width: 18px;
  height: 18px;
}

.funnelResultRow__userRatio {
  margin-left: 8px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &--disabled {
    text-decoration: none;
  }
}

.funnelResultRow__userCount {
  white-space: nowrap;
  font-weight: normal;
  font-size: 12px;
}

.funnelResultRow__tooltip {
  position: absolute;
  top: $funnelBarHeight * -1 + 12px;
  left: 50%;
  transform: translateX(-50%);
}

.funnelResultRow__tooltipContent {
  display: flex;
  align-items: center;
  padding: 10px;
  color: $colorBase900;
  white-space: nowrap;
  font-size: 12px;
  line-height: 1;
}
.funnelResultRow__tooltipColumn {
  display: flex;
  align-items: center;

  &:first-child {
    padding-right: 8px;
  }

  &:last-child {
    padding-left: 8px;
    border-left: 1px solid $colorBase900;
  }
}
.funnelResultRow__tooltipDescription {
  font-weight: bold;

  &::after {
    margin-right: 3px;
    content: ":";
  }
}
.funnelResultRow__tooltipMatchedUser {
  color: $colorBlue900;
  font-weight: bold;
}
.funnelResultRow__tooltipUnmatchedUser {
  margin-right: 8px;
  color: $colorBase700;
  font-weight: bold;
}
.funnelResultRow__sameVisit {
  padding-left: 2px;
  color: $colorBase700;
  font-size: 12px;
}
.funnelResultRow__sameVisit--margin {
  height: auto;
}
.funnelResultRow__sameVisitWrapper {
  display: flex;
  align-items: center;
}
.funnelResultRow__sameVisitText {
  padding-left: 12px;
}

.funnelResultLeft {
  flex: 1;
}
</style>
