<i18n src="@/i18n/components/home-menu/almost-cv.json"></i18n>
<template>
  <div class="menuPanelAlmostCvHeader">
    <div>
      <Icon :icon="Icons.Users" :color="Colors.Base900" :size="18" />
      {{ $t("almost_cv") }}
      <span class="menuPanelAlmostCvHeader__beta">BETA</span>
    </div>
    <div
      class="menuPanelAlmostCvHeader__tooltipContainer"
      @mouseenter="showTooltip = true"
      @mouseleave="showTooltip = false"
    >
      <Tooltip :placement="headerTooltipPlacement" :show="showTooltip">
        <Icon
          :icon="Icons.HelpInvertWhite"
          :color="Colors.Base700"
          :size="12"
        />
        <template #content>
          <div class="menuPanelAlmostCvHeader__tooltipContent">
            <span v-t="`tooltip_1`" />
            <br />
            <br />
            <span v-t="`tooltip_2`" />
            <br />
            <ul class="menuPanelAlmostCvHeader__tooltipContent--sub">
              <li v-t="`tooltip_3`" />
              <li v-t="`tooltip_4`" />
            </ul>
            <br />
            <span v-t="`tooltip_5`" />
            <ul class="menuPanelAlmostCvHeader__tooltipContent--sub">
              <li v-t="'tooltip_6'" />
              <li v-t="'tooltip_7'" />
              <li v-t="'tooltip_8'" />
            </ul>
          </div>
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Tooltip from "@/components/Tooltip.vue";
import Icon from "@/components/Icon.vue";
import { Icons } from "@/const/Icons";
import { Colors } from "@/const/Colors";
import { TooltipPlacement } from "@/const/tooltip";

@Component({
  components: {
    Tooltip,
    Icon
  }
})
export default class MenuPanelAlmostCvHeader extends Vue {
  Icons = Icons;
  Colors = Colors;
  headerTooltipPlacement = TooltipPlacement.BottomEnd;
  showTooltip = false;
}
</script>

<style lang="scss" scoped>
.menuPanelAlmostCvHeader {
  display: inline-flex;
}

.menuPanelAlmostCvHeader__beta {
  color: $colorBase600;
  font-size: 12px;
  font-weight: 500;
}

.menuPanelAlmostCvHeader__tooltipContainer {
  margin-left: 6px;
}

.menuPanelAlmostCvHeader__tooltipContent {
  width: 370px;
  white-space: normal;
  line-height: 1.6;
  font-weight: 500;

  &--sub {
    padding-left: 20px;
    list-style: disc;
  }
}
</style>
