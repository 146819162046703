<i18n src="@/i18n/views/funnel-result.json"></i18n>
<template>
  <div class="funnelResult">
    <div class="funnelResult__condition">
      <FunnelHeader :has-back-link="true" :title="funnelTitle" />

      <div
        class="funnelResult__head"
        :class="{ 'funnelResult__head--visibility': isLoading }"
      >
        <div class="funnelResult__analysisPeriod">{{
          $t("analysisPeriod", { period: condition.displayPeriod })
        }}</div>

        <FunnelEditLink
          class="funnelResult__edit"
          :color="editLinkColor"
          :size="editLinkSize"
          :enable-text-underline="false"
        />
      </div>

      <template v-if="!isLoading && hasFilterCondition">
        <FilterConditionWithResult
          class="funnelResult__filterCondition"
          :attributes="userAttributeText"
          :activities="userActivityText"
          :num-filtered-users="numFilteredUsers"
          :font-size="'large'"
        />
      </template>

      <FunnelPrimaryContent class="funnelResult__result">
        <div v-if="isLoading" class="funnelResult__progress">
          <Progress :progress="funnelProgress" />
        </div>

        <div
          v-else
          class="funnelResult__rowContainer"
          :class="{ funnelResult__padding: !canUseIntermediateJourney }"
        >
          <FunnelResultRow
            v-for="(data, index) in funnelData"
            :key="'funnelResult__row' + index"
            class="funnelResult__row"
            :funnel-id="funnelId"
            :descriptions="funnelDataDefinitionLabels[index]"
            :attribute-description="funnelDataAttributeDefinitionLabels[index]"
            :total-users="totalUsers"
            :funnel-data="data"
            :order="index + 1"
            :selected-order="selectedOrder"
            :periods-for-display="condition.displayPeriod"
            :is-last-step="index == funnelData.length - 1"
            :match-param-in-url="matchParamInUrl"
            :next-funnel-data="funnelData.at(index + 1)"
            :not-condition-orders="notConditionOrders"
            @click="onClick"
          />
        </div>
      </FunnelPrimaryContent>

      <div class="funnelResult__induction" />
    </div>

    <div id="funnelResultContent" class="funnelResult__content">
      <SearchResultContainer
        v-if="showTable"
        :view="searchResultView"
        :label="$t('userSearchResult')"
        :icon="Icons.Search"
        :colored-periods="coloredPeriods"
        class="funnelResult__searchResultContainer"
        @view-change="onViewChange"
      >
        <template #conditions>
          <div class="funnelResult__conditionLabel">{{
            searchResultConditionLabel
          }}</div>
        </template>
      </SearchResultContainer>

      <div
        v-else
        v-t="'noSelectedDescription'"
        class="funnelResult__noSelected"
      />
    </div>

    <Transition name="slide">
      <Button
        v-if="showReturnButton"
        class="funnelResult__returnTopButton"
        :style="buttonStyle"
        @click="onClickReturnTop"
      >
        <Icon
          class="funnelResult__returnTopIcon"
          :icon="Icons.ArrowTopBar"
          :color="Colors.White"
          :size="10"
        />
        {{ $t("backToFunnel") }}
      </Button>
    </Transition>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { FunnelAnalysisCondition } from "@/models/funnel/FunnelAnalysisCondition";
import { FunnelData } from "@/models/funnel/FunnelData";
import { FunnelConditionAttributeType } from "@/models/funnel/FunnelConditionAttribute";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { UserAttributeDefinition } from "@/models/client-settings/UserAttributeDefinition";
import { SearchResultViews } from "@/const/SearchResultViews";
import { Colors } from "@/const/Colors";
import { Icons } from "@/const/Icons";
import {
  FunnelStatus,
  FunnelEditLinkType,
  FunnelEditLinkSize,
  FunnelFailedReason
} from "@/const/funnel";

import {
  handleNoQueryCacheError,
  handleNotFoundError
} from "@/util/error-util";

import { showAlert } from "@/util/modal-util";

import Loading from "@/components/Loading.vue";
import Icon from "@/components/Icon.vue";
import Button from "@/components/Button.vue";
import FunnelHeader from "@/components/funnel/FunnelHeader.vue";
import FunnelPrimaryContent from "@/components/funnel/FunnelPrimaryContent.vue";
import SearchResultContainer from "@/views/SearchResultContainer.vue";
import FunnelResultRow from "@/components/funnel/FunnelResultRow.vue";
import FunnelEditLink from "@/components/funnel/FunnelEditLink.vue";
import Progress from "@/components/Progress.vue";
import {
  isValidQuery,
  getActivityString,
  FunnelDataDefinitionType
} from "@/util/funnel-util";
import { FilterCondition } from "@/models/search/filter-condition/FilterCondition";
import { UgTag, UgEventTag, UgAttributeTag } from "@/store/modules/ugTag";
import FilterConditionWithResult from "@/components/funnel/FilterConditionWithResult.vue";
import { sideBarStore } from "@/store/v3-side-bar";

const { isSideBarMinimized } = sideBarStore();

const RESULT_SHOW_DELAY = 500;

@Component({
  components: {
    Loading,
    Icon,
    Button,
    FunnelHeader,
    FunnelPrimaryContent,
    SearchResultContainer,
    FunnelResultRow,
    FunnelEditLink,
    Progress,
    FilterConditionWithResult
  }
})
export default class FunnelResult extends Vue {
  Colors = Colors;
  Icons = Icons;

  showTable = false;
  searchResultConditionLabel = "";
  searchResultView = SearchResultViews.Overview;

  editLinkColor = FunnelEditLinkType.EMPHASIS;
  editLinkSize = FunnelEditLinkSize.SMALL;

  buttonLeft = 0;
  scrollY = 0;
  scrollOption = {
    duration: 500,
    easing: "ease",
    offset: -70
  };

  matchParamInUrl: number = -1;
  restoreFromURL = false;

  @Watch("globalNavWidth")
  onChangeGlobalNav() {
    this.updateButtonLeft();
  }

  async created() {
    const id = this.$route.params.id;
    if (id) {
      this.$store.commit("funnel/setCurrentId", id);
      this.fetchFunnelData(id);
    } else {
      this.$router.push({ name: "home" });
    }
  }

  mounted() {
    window.addEventListener("scroll", this.updateInductionPositionY);
    this.updateInductionPositionY();

    window.addEventListener("resize", this.updateButtonLeft);
    this.updateButtonLeft();

    window.addEventListener("popstate", this.onBackButtonPressed);
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.updateInductionPositionY);
    window.removeEventListener("resize", this.updateButtonLeft);
    window.removeEventListener("popstate", this.onBackButtonPressed);
  }

  beforeRouteEnter(
    to: Route,
    from: Route,
    next: (to?: string | false | ((vm: Vue) => any) | void) => void
  ) {
    // Send UgEvent only when reload or access from shared URL
    if (from.name === null || from.name === "login") {
      UgTag.pushEvent(UgEventTag.FunnelUrlShare, {
        [UgAttributeTag.FunnelUrlParams]: Object.keys(to.query).length
      });
    }
    next();
  }

  updateButtonLeft() {
    this.buttonLeft =
      (window.innerWidth - this.globalNavWidth) / 2 + this.globalNavWidth - 74;
  }

  updateInductionPositionY() {
    this.scrollY = window.scrollY || window.pageYOffset;
  }

  onBackButtonPressed() {
    if (this.showReturnButton) {
      this.scrollToTop();
      history.pushState(null, "", null);
    } else {
      history.back();
    }
  }

  onClickReturnTop() {
    this.scrollToTop();
  }

  scrollToTop() {
    this.$scrollTo(".funnelResult", this.scrollOption);
  }

  get buttonStyle() {
    return {
      position: "fixed",
      width: "148px",
      height: "41px",
      left: this.buttonLeft + "px",
      border: "2px solid #FFF",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: 0
    };
  }

  get showReturnButton(): Boolean {
    return this.scrollY > 0 && this.showTable;
  }

  get globalNavWidth(): number {
    return isSideBarMinimized.value ? 60 : 300;
  }

  get funnelStatus(): FunnelStatus {
    return this.$store.state.funnel.status;
  }

  get funnelId(): string {
    return this.$store.state.funnel.currentId;
  }

  get funnelProgress(): number {
    return this.$store.getters["funnel/funnelProgress"];
  }

  get funnelFailedReason(): FunnelFailedReason | null {
    return this.$store.state.funnel.failedReason;
  }

  get numFilteredUsers(): number | null {
    return this.$store.state.funnel.numFilteredUsers;
  }

  get isLoading(): boolean {
    return this.funnelStatus !== FunnelStatus.CREATED;
  }

  get condition(): FunnelAnalysisCondition {
    return this.$store.state.funnel.condition;
  }

  get coloredPeriods(): ColoredPeriod[] {
    return [
      new ColoredPeriod(
        this.$t("targetPeriod") as string,
        this.condition.startDate,
        this.condition.endDate,
        Colors.Blue730,
        Colors.Blue800
      )
    ];
  }

  get funnelData(): FunnelData[] {
    return this.$store.getters["funnel/funnelData"];
  }

  get funnelDataDefinitions(): FunnelDataDefinitionType[] {
    return this.$store.getters["funnel/funnelDataDefinitions"];
  }

  get funnelDataDefinitionLabels(): string[] {
    return this.$store.getters["funnel/funnelDataDefinitionLabels"];
  }

  get funnelDataAttributeDefinitionLabels(): string[] {
    return this.$store.getters["funnel/funnelDataAttributeDefinitionLabels"];
  }

  get totalUsers(): number {
    return this.funnelData[0].matchedUsers.total;
  }

  get funnelTitle(): string {
    return this.isLoading
      ? (this.$t("analyzing") as string)
      : this.condition.funnelTitle;
  }

  get funnelUserAttributes(): FunnelConditionAttributeType[] {
    return this.$store.getters["funnel/funnelUserAttributes"];
  }

  get userAttributesNames(): { [key: number]: string } {
    const activeUserAttributes: UserAttributeDefinition[] = this.$store.state
      .clientSettings.activeUserAttributeDefinitions;

    const userAttributesNames: { [key: number]: string } = {};
    activeUserAttributes.forEach(ua => {
      userAttributesNames[ua.id] = ua.name;
    });
    return userAttributesNames;
  }

  get userAttributeText(): string {
    const attributes = this.funnelUserAttributes.map(
      a => `${this.userAttributesNames[a.attributeId]}: ${a.displayValue}`
    );
    return attributes.join(" / ");
  }

  get selectedOrder(): number {
    return this.$store.state.funnel.selectedOrder;
  }

  get filterCondition(): FilterCondition {
    return this.$store.state.filter.filterCondition;
  }

  get hasFilterCondition(): boolean {
    return this.userAttributeText !== "" || this.userActivityText !== "";
  }

  get conversionAttributeDefinitions() {
    return this.$store.state.clientSettings.conversionAttributeDefinitions;
  }

  get notConditionOrders(): number[] {
    return this.funnelData.reduce(
      (acc: number[], data: FunnelData, index: number) => {
        if (data.condition.notCondition) acc.push(index + 1);
        return acc;
      },
      []
    );
  }

  async fetchFunnelData(id: string) {
    await this.$store
      .dispatch("funnel/fetchFunnelData", id)
      .then((data: FunnelStatus) => {
        window.setTimeout(() => {
          this.$store.commit("funnel/setStatus", data);
        }, RESULT_SHOW_DELAY);
      })
      .catch(e =>
        handleNotFoundError(
          e,
          "funnel-analysis",
          this.$t("404error").toString()
        )
      );

    if (this.funnelFailedReason === FunnelFailedReason.TABLE_NOT_READY) {
      showAlert(this.$t("failedFunnelAnalysisBecauseTableNotReady") as string);
    }

    const query = this.$route.query;
    if (isValidQuery(query, this.funnelData.length)) {
      const order = Number(query.s);
      const isMatched = Number(query.m);
      this.matchParamInUrl = isMatched;
      this.restoreFromURL = true;

      const data = this.funnelData[order - 1];
      const userIds = isMatched
        ? data.matchedUsers.ids
        : data.unmatchedUsers.ids;

      let isValidFid = true;

      if (query.fid) {
        await this.$store
          .dispatch("filter/fetchFilterConditionById", Number(query.fid))
          .catch(() => {
            isValidFid = false;
          });
      }

      if (userIds && userIds.length > 0 && isValidFid) {
        window.setTimeout(() => {
          if (this.restoreFromURL) {
            this.onClick(
              userIds,
              order,
              Boolean(isMatched),
              query.fid as string,
              Number(query.hid)
            );
          }
        }, 1000);
        return;
      }
    }
    if (Object.keys(this.$route.query).length !== 0) {
      this.$router.replace({ query: {} });
    }
  }

  getSearchResultConditionLabel(
    order: number,
    isMatchedSelected: boolean
  ): string {
    const index = order - 1;
    const data = this.funnelData[index];
    const definition = this.funnelDataDefinitionLabels[index];
    const attributeDefinition = this.funnelDataAttributeDefinitionLabels[index];
    const definitionLabel =
      attributeDefinition.length > 0
        ? `${definition}, ${attributeDefinition}`
        : definition;

    const value = isMatchedSelected
      ? (this.$t("selectedMatchedUsers", {
          type: definitionLabel
        }) as string)
      : (this.$t("selectedUnmatchedUsers", {
          type: definitionLabel
        }) as string);

    return `${data.condition.title}: ${value}`;
  }

  onClick(
    ids: string[],
    order: number,
    isMatchedSelected: boolean,
    filterId?: string,
    historyId?: number
  ) {
    this.showTable = false;
    this.$store.commit("funnel/setSelectedOrder", order);
    this.$store.commit("funnel/setIsMatchedSelected", isMatchedSelected);

    const query: { [key: string]: string } = {
      s: String(order),
      m: isMatchedSelected ? "1" : "0"
    };
    if (this.restoreFromURL && filterId) {
      query.fid = filterId;
    }
    this.$router.replace({ query });
    this.restoreFromURL = false;

    const selectCondition = new SelectByUserIdCondition(
      ids,
      this.condition.endDate,
      this.condition.startDate
    );

    if (filterId) {
      this.$store.commit("search/setSelectCondition", selectCondition);
      this.$store
        .dispatch("search/executeSelectAndFilter", {
          filterCondition: this.filterCondition,
          historyId
        })
        .catch(handleNoQueryCacheError);
    } else {
      this.$store
        .dispatch("search/executeSelect", { selectCondition, historyId })
        .catch(handleNoQueryCacheError);
    }

    this.showTable = true;
    this.searchResultConditionLabel = this.getSearchResultConditionLabel(
      order,
      isMatchedSelected
    );

    this.$nextTick(() => {
      this.$scrollTo("#funnelResultContent", this.scrollOption);
      history.pushState(null, "", null);
    });
  }

  onViewChange(view: SearchResultViews) {
    this.searchResultView = view;
  }
  get canUseIntermediateJourney(): boolean {
    return this.$store.state.app.canUseIntermediateJourney;
  }

  get userActivityText(): string {
    return getActivityString(
      this.condition,
      this.funnelDataDefinitions,
      this.conversionAttributeDefinitions
    );
  }
}
</script>

<style lang="scss" scoped>
.funnelResult__condition {
  padding: 0 $appContentPadding;
}

.funnelResult__head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.funnelResult__head--visibility {
  visibility: hidden;
}

.funnelResult__analysisPeriod {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  color: $colorBase700;
  font-size: 14px;
  line-height: 1;

  &::after {
    display: block;
    margin-left: 20px;
    width: 1px;
    height: 26px;
    background-color: $colorBase500;
    content: "";
  }
}

.funnelResult__edit {
  padding: 7px 9px 5px;
  height: 26px;
  border: 1px solid $colorBase600;
  border-radius: $sizeRadius;
  background-color: $colorWhite;

  &:hover {
    background-color: $colorBlue600;
  }
}

.funnelHeader__editText {
  margin-left: 6px;
  color: $colorBase700;
  font-size: 14px;
}

.funnelResult__userAttributes {
  display: flex;
  background-color: $colorGray100;
  color: $colorBase700;
  margin: 15px 0 25px;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 1.5;
}
.funnelResult__userAttributesHeader {
  display: inline-block;
  white-space: nowrap;
  margin-right: 5px;
}

.funnelResult__result {
  margin-top: 25px;
}

.funnelResult__progress {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.funnelResult__rowContainer {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: $funnelResultRowConditionWidth;
    width: 2px;
    height: 100%;
    background-color: $colorCv;
    content: "";
  }
}

.funnelResult__padding {
  padding-right: 15px;
}

.funnelResult__induction {
  margin-top: 30px;
  text-align: center;

  &::before {
    display: inline-block;
    border-top: 20px solid $colorBase600;
    border-right: 20px solid $colorClear;
    border-bottom: 0 solid $colorClear;
    border-left: 20px solid $colorClear;
    content: "";
  }
}

.funnelResult__content {
  margin-top: 10px;
}

.funnelResult__searchResultContainer {
  min-height: 90vh;
}

.funnelResult__noSelected {
  margin: 20px $appContentPadding 0;
  padding: 40px;
  border-radius: $sizeRadius;
  background-color: $colorGray100;
  color: $colorBase900;
  text-align: center;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 1.5;
}

.funnelResult__conditionLabel {
  margin-top: 10px;
  color: $colorBase700;
  font-size: 14px;
  word-break: break-all;
  line-height: 1.5;
}

.funnelResult__returnTopButton {
  bottom: 0;
  font-size: 13px;
}

.funnelResult__returnTopIcon {
  margin-right: 6px;
}

.funnelResult__filterCondition {
  margin: 15px 0 0;
}

.slide-enter-active,
.slide-leave-active {
  bottom: 0;
  transition: bottom 0.15s ease;
}

.slide-enter,
.slide-leave-to {
  bottom: -41px;
}
</style>
