<template>
  <div class="funnelLabel" :class="[`funnelLabel--${type}`]" v-text="label" />
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { FunnelLablelType } from "@/const/funnel";

@Component
export default class FunnelLabel extends Vue {
  @Prop({ type: String, required: true })
  label!: string;

  @Prop({ type: String, default: FunnelLablelType.DEFAULT })
  type!: FunnelLablelType;
}
</script>

<style lang="scss" scoped>
.funnelLabel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  height: 20px;
  border: 1px solid;
  text-align: center;
  font-weight: bold;
  font-size: 11px;
}

.funnelLabel--default {
  border-color: $colorBase500;
  background-color: $colorWhite;
  color: $colorBase700;
}

.funnelLabel--not {
  border-color: $colorElmentUIGray300;
  background-color: $colorElmentUIGray300;
  color: $colorWhite;
}
</style>
