import { render, staticRenderFns } from "./FunnelInflowOrganicCondition.vue?vue&type=template&id=46c4cf1e&scoped=true"
import script from "./FunnelInflowOrganicCondition.vue?vue&type=script&lang=ts&setup=true"
export * from "./FunnelInflowOrganicCondition.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./FunnelInflowOrganicCondition.vue?vue&type=style&index=0&id=46c4cf1e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46c4cf1e",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/funnel/funnel-inflow-organic-condition.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Ffunnel%2FFunnelInflowOrganicCondition.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports