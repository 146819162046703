<template>
  <div class="emptyDataFallback">
    <div class="emptyDataFallback__description">{{ description }}</div>

    <div class="emptyDataFallback__tips">
      <div class="emptyDataFallback__tipsTitle">
        <div class="emptyDataFallback__tipsTitleIcon"><TipsTitleIcon /></div>
        <div class="emptyDataFallback__tipsTitleText">
          <slot></slot>
        </div>
      </div>
      <div class="emptyDataFallback__tipsBody">
        <img
          class="emptyDataFallback__tipsBodyImage"
          :src="require(`@/assets/${tipsImagePath}`)"
        />
        <div class="emptyDataFallback__tipsBodyText">{{ tipsBody }}</div>
      </div>
    </div>

    <div v-if="isJapanese" class="emptyDataFallback__helpSiteLink">
      <HelpSiteLink :url="helpSiteUrl" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import HelpSiteLink from "@/components/home-menu/HelpSiteLink.vue";
import TipsTitleIcon from "@/assets/svg/home/tips.svg";

@Component({ components: { HelpSiteLink, TipsTitleIcon } })
export default class EmptyDataFallback extends Vue {
  @Prop({ type: String, required: true })
  description!: string;

  @Prop({ type: String, required: true })
  tipsImagePath!: string;

  @Prop({ type: String, required: true })
  tipsBody!: string;

  @Prop({ type: String, required: true })
  helpSiteUrl!: string;

  get isJapanese(): boolean {
    return this.$i18n.locale === "ja";
  }
}
</script>

<style lang="scss" scoped>
.emptyDataFallback__description {
  margin-top: 18px;
  text-align: left;
  font-size: 16px;
  line-height: 1.5;
}

.emptyDataFallback__tips {
  margin-top: 30px;
  padding: 15px;
  background: $colorWhite;
}

.emptyDataFallback__tipsTitle {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}

.emptyDataFallback__tipsTitleIcon {
  margin: 0 5px 0 0;
}

.emptyDataFallback__tipsTitleText {
  font-weight: bold;
  font-size: 12px;
}

.emptyDataFallback__tipsBody {
  display: flex;
}

.emptyDataFallback__tipsBodyImage {
  margin: 0 15px 0 0;
  width: 105px;
  height: 70px;
  border: 1px solid $colorBase400;
}

.emptyDataFallback__tipsBodyText {
  text-align: left;
  font-size: 12px;
  line-height: 1.4;
}

.emptyDataFallback__helpSiteLink {
  margin: 15px 0 0 0;
  color: $colorBase700;
  text-align: left;
  font-size: 12px;
}
</style>
