import { MutationTree, ActionTree } from "vuex";
import { RootState } from "@/store/";
import { UgUser } from "@/models/auth/UgUser";
import { Client } from "@/models/Client";

export class ClientState {
  client: Client | null = null;
  users: UgUser[] = [];
}

const mutations: MutationTree<ClientState> = {
  setClient(state, client: Client) {
    state.client = client;
  },
  setUsers(state, users: UgUser[]) {
    state.users = users;
  },
  initialize(state: ClientState) {
    state.users = [];
    state.client = null;
  }
};

const actions: ActionTree<ClientState, RootState> = {
  async fetchClient({ commit, rootState }) {
    const body = await rootState.api.clients.getClient();
    const client: Client = Client.fromJson(body.client);
    commit("setClient", client);
  },
  async fetchUsers({ commit, rootState }) {
    const body = await rootState.api.clients.getClientUgUserList();
    const users: UgUser[] = body.users.map(UgUser.fromJson);
    commit("setUsers", users);
  }
};

export const client = {
  namespaced: true,
  state: new ClientState(),
  mutations: mutations,
  actions: actions
};
