<i18n src="@/i18n/components/home-menu/lag-cv.json"></i18n>
<template>
  <div class="menuPanelError">
    <div class="menuPanelError__noResult">
      <NoResultImage />
      <div class="menuPanelError__noResultText">{{ $t("errorText") }}</div>
      <Button
        v-t="'errorTextButton'"
        type="ButtonType.LIGHT"
        class="menuPanelError__noResultButton"
        height="24px"
        width="80px"
        @click="onRetry"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-property-decorator";
import NoResultImage from "@/assets/svg/lag-cv/lag_cv_error_face.svg";
import Button from "@/components/Button.vue";
import { BUTTON_TYPE } from "@/const/button";

@Component({
  components: {
    NoResultImage,
    Button
  }
})
export default class MenuPanelError extends Vue {
  ButtonType = BUTTON_TYPE;

  @Emit("retry")
  onRetry() {}
}
</script>

<style lang="scss" scoped>
.menuPanelError {
  padding-top: 33px;
}

.menuPanelError__noResult {
  text-align: center;
}

.menuPanelError__noResultText {
  color: $colorBase700;
  font-size: 14px;
  line-height: 1.5;
  padding: 30px 0px;
  white-space: pre-wrap;
}

.menuPanelError__noResultButton {
  border: 1px solid $colorBase500;
  color: $colorBase700;
  background-color: $colorWhite;
  font-size: 14px;
  font-weight: normal;
  &:hover {
    background-color: $colorBlue600;
  }
}
</style>
