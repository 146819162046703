<i18n src="@/i18n/views/favorite-user.json"></i18n>
<template>
  <div class="favorite-user">
    <SearchResultContainer
      :view="searchResultView"
      :label="$t('label')"
      :icon="Icons.Heart"
      :is-show-empty-description="hasUserId"
      @view-change="onViewChange"
    />
    <div
      v-if="!hasUserId"
      class="favorite-user__onboarding"
      data-cy="favorite-user__onboarding"
    >
      <OnBoardingJa
        v-if="locale === AvailableLanguages.ja"
        class="favorite-user__onboarding-svg"
      />
      <OnBoardingZhHant
        v-else-if="locale === AvailableLanguages['zh-hant']"
        class="favorite-user__onboarding-svg"
      />
      <OnBoardingZhHans
        v-else-if="locale === AvailableLanguages['zh-hans']"
        class="favorite-user__onboarding-svg"
      />
      <OnBoardingEn v-else class="favorite-user__onboarding-svg" />

      <LinkText
        class="favorite-user__onboarding-link-wrapper"
        url="https://help.usergram.info/article/311-favorite"
        :link-text="$t('howToUseFavorite')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { SelectByUserIdCondition } from "@/models/search/select-condition/SelectByUserIdCondition";
import { handleNoQueryCacheError } from "@/util/error-util";
import SearchResultContainer from "@/views/SearchResultContainer.vue";
import { Icons } from "@/const/Icons";
import { SearchResultViews } from "@/const/SearchResultViews";
import { AvailableLanguages } from "@/i18n/index";
import Icon from "@/components/Icon.vue";
import LinkText from "@/components/LinkText.vue";

import OnBoardingJa from "@/assets/svg/onboarding/no-favorite-ja.svg";
import OnBoardingEn from "@/assets/svg/onboarding/no-favorite-en.svg";
import OnBoardingZhHant from "@/assets/svg/onboarding/no-favorite-zh-hant.svg";
import OnBoardingZhHans from "@/assets/svg/onboarding/no-favorite-zh-hans.svg";
import {
  executeSearchByHistoryId,
  createQueryWithViewAndHid,
  executeSearchByUserIds
} from "@/util/user-search-util";

@Component({
  components: {
    SearchResultContainer,
    Icon,
    LinkText,
    OnBoardingJa,
    OnBoardingEn,
    OnBoardingZhHant,
    OnBoardingZhHans
  }
})
export default class FavoriteUser extends Vue {
  @Watch("userIds")
  onChangeUserIds(ids: string[], oldIds: string[]) {
    // This comparison is necessary
    // not to call following when initial load
    if (ids.length === oldIds.length) return;
    this.isUserIdsUpdated = true;
  }

  @Watch("isUserIdsUpdated")
  @Watch("showUserDetail")
  async reRenderUserResult() {
    await this.$nextTick();

    if (this.showUserDetail || !this.isUserIdsUpdated) return;

    this.isUserIdsUpdated = false;
    executeSearchByUserIds(this.userIds);
  }

  Icons = Icons;
  AvailableLanguages = AvailableLanguages;
  searchResultView = SearchResultViews.Memo;
  isUserIdsUpdated = false;

  async created() {
    const view = this.$route.query.view;

    switch (view) {
      case SearchResultViews.Attribute:
        this.searchResultView = SearchResultViews.Attribute;
        break;
      case SearchResultViews.Overview:
        this.searchResultView = SearchResultViews.Overview;
        break;
      default:
        this.searchResultView = SearchResultViews.Memo;
    }

    // お気に入りのuserIdが1件もない場合は検索しない
    if (!this.hasUserId) {
      this.$store.dispatch("search/clearForEmptyState");
    } else {
      // If historyId exists, recover filter condition
      if (this.$route.query.hid) {
        await executeSearchByHistoryId(Number(this.$route.query.hid));
      } else {
        const selectCondition = new SelectByUserIdCondition(this.userIds);
        this.$store
          .dispatch("search/executeSelect", { selectCondition })
          .catch(error => {
            handleNoQueryCacheError(error);
            throw new Error(error);
          });
      }
    }
  }

  onViewChange(view: SearchResultViews) {
    this.searchResultView = view;
    const query = createQueryWithViewAndHid(view);
    this.$router.replace({ query });
  }

  get locale(): string {
    return this.$i18n.locale;
  }

  get userIds(): string[] {
    return this.$store.getters["favorite/userIds"];
  }

  get hasUserId(): boolean {
    return this.userIds.length > 0;
  }

  get showUserDetail(): boolean {
    return this.$store.state.user.showUserDetail;
  }
}
</script>

<style lang="scss" scoped>
.favorite-user__onboarding {
  position: relative;
  padding: 40px 20px 0 20px;
  text-align: center;
}
.favorite-user__onboarding-svg {
  width: 960px;
  height: 361px;
}
.favorite-user__onboarding-svg-en {
  width: 960px;
  height: 358px;
}
.favorite-user__onboarding-link-wrapper {
  position: absolute;
  bottom: 46px;
  left: calc(50% - 40px);
  transform: translate(-100%, 0);
}
</style>
