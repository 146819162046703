<template>
  <div class="gramChart" data-cy="gram-chart">
    <ChartBase
      :end-date="endDate"
      :chart-period="chartPeriod"
      :is-omo="isOmo"
      :omo-min-action-unixtime="omoMinActionUnixtime"
      :omo-max-action-unixtime="omoMaxActionUnixtime"
      :visit-overviews="visitOverviews"
      :nps-overviews="npsOverviews"
      :enquete-overviews="enqueteOverviews"
      :colored-periods="coloredPeriods"
      :baseline-length="baselineLength"
      :first-scale-date="firstScaleDate"
      :last-scale-date="lastScaleDate"
      :last-scale-x="lastScaleX"
      :highlight-msec="highlightMsec"
      :is-user-detail="isUserDetail"
      :filter-matched-visit-ids="filterMatchedVisitIds"
      :almost-matched-visit-ids="almostMatchedVisitIds"
      :funnel-matched-gram-ids="funnelMatchedTopGramIds"
      :funnel-matched-selected-gram-ids="funnelMatchedSelectedTopGramIds"
      :selected-conversion-ids="selectedConversionIds"
      :is-toggle-time-of-day-enabled="isToggleTimeOfDayEnabled"
      @click="onClick"
      @mouseenter-overview="onMouseEnterOverview"
      @mouseleave-overview="onMouseLeaveOverview"
      @mouseenter-colored-period="onMouseEnterColoredPeriod"
      @mouseleave-colored-period="onMouseLeaveColoredPeriod"
    >
      <template #default="{ props }">
        <ChartContactIcons
          v-if="props.isVisible"
          class="gramChart__chartContactIcons"
          :last-scale-date="lastScaleDate"
          :last-scale-x="lastScaleX"
          :first-scale-date="firstScaleDate"
          :baseline-length="baselineLength"
          :contact-overviews="contactOverviews"
          :filter-matched-ids="filterMatchedIds"
          :scroll-x="props.scrollX"
          :scroll-width="props.scrollWidth"
          :is-chart-scrollable="props.isScrollable"
          :is-in-user-detail="isUserDetail"
          :funnel-matched-gram-ids="funnelMatchedGramIds"
          :funnel-matched-selected-gram-ids="funnelMatchedSelectedGramIds"
          @click="onClick"
          @mouse-over="onMouseEnterOverview"
          @mouse-leave="onMouseLeaveOverview"
        />

        <template v-if="props.isVisible">
          <ChartBusinessEvent
            v-for="businessEvent in sortedBusinessEventOverviews"
            :key="businessEvent.gramId"
            :business-event-overview="businessEvent"
            :last-scale-date="lastScaleDate"
            :last-scale-x="lastScaleX"
            :first-scale-date="firstScaleDate"
            :baseline-length="baselineLength"
            :filter-matched-ids="filterMatchedIds"
            :scroll-x="props.scrollX"
            :scroll-width="props.scrollWidth"
            :is-chart-scrollable="props.isScrollable"
            :is-in-user-detail="isUserDetail"
            :funnel-matched-gram-ids="funnelMatchedGramIds"
            :funnel-matched-selected-gram-ids="funnelMatchedSelectedGramIds"
            @click="onClick"
            @mouse-over="onMouseEnterOverview"
            @mouse-leave="onMouseLeaveOverview"
          />
        </template>

        <ChartBalloon
          v-if="balloonContentOverview !== null"
          :is-user-detail="isUserDetail"
          :x="balloonX"
          :visit-bar-y="visitBarBalloonY"
          :show-balloon="showBalloon"
          :baseline-y="baselineY"
          :balloon-content-overview="balloonContentOverview"
        />
      </template>
    </ChartBase>

    <ChartColoredPeriodTooltip
      :show="showColoredPeriodTooltip"
      :x="coloredPeriodTooltipX"
      :y="coloredPeriodTooltipY"
      :color-indicator="coloredPeriodTooltipColorIndicator"
      :label="coloredPeriodTooltipLabel"
      :period="coloredPeriodTooltipPeriod"
      :is-user-detail="isUserDetail"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { ColoredPeriod } from "@/models/overview/ColoredPeriod";
import { VisitOverview } from "@/models/overview/VisitOverview";
import { NpsOverview } from "@/models/overview/NpsOverview";
import { ContactOverview } from "@/models/overview/ContactOverview";
import { BusinessEventOverview } from "@/models/overview/BusinessEventOverview";
import { EnqueteOverview } from "@/models/overview/EnqueteOverview";

import { ChartPeriod } from "@/const/chart-period";
import { Colors } from "@/const/Colors";

import { Msec } from "@/util/date-util";

import {
  START_LINE_X,
  BASELINE_EDGE_LENGTH,
  SIX_MONTH_BASELINE_EDGE_LENGTH,
  ALL_PLOT_PERIOD,
  DAYS_IN_SHOWN_BASELINE_LENHTH,
  BASELINE_Y_DEFAULT,
  BASELINE_Y_OMO,
  getBarHeight,
  getDiffDays,
  getNextMonthFirst,
  getGramIdsInVisitOverviews,
  getMaxUnixtimeInOverviews,
  getMinUnixtimeInOverviews,
  getOverviewsMatchedBehind,
  convertTimeToX,
  formatPeriodOfColoredPeriod,
  getOverviewsFunnelMatchedBehind
} from "@/components/chart/chart-util";
import ChartBase from "@/components/chart/ChartBase.vue";
import ChartContactIcons from "@/components/chart/ChartContactIcons.vue";
import ChartBusinessEvent from "@/components/chart/ChartBusinessEvent.vue";
import ChartBalloon from "@/components/chart/ChartBalloon.vue";
import ChartColoredPeriodTooltip from "@/components/chart/ChartColoredPeriodTooltip.vue";

const PADDING_ARROW_ICON: number = 5;
const SIZE_ARROW_ICON: number = 25;
const BALLOON_SHOW_DELAY: number = 50;
const SHOW_TOOLTIP_DELAY: number = 50;

enum OverViewWidth {
  OneMonth = 1000,
  SixMonth = 900,
  OneYear = 960,
  TwoYears = 1080
}
@Component({
  components: {
    ChartBase,
    ChartContactIcons,
    ChartBusinessEvent,
    ChartBalloon,
    ChartColoredPeriodTooltip
  }
})
export default class GramChart extends Vue {
  @Prop({ type: Date, required: true })
  endDate!: Date;

  @Prop({ type: Number, required: true })
  chartPeriod!: ChartPeriod;

  @Prop({ type: Array, required: true })
  coloredPeriods!: ColoredPeriod[];

  @Prop({ type: Array, required: true })
  visitOverviews!: VisitOverview[];

  @Prop({ type: Array, required: true })
  npsOverviews!: NpsOverview[];

  @Prop({ type: Array, required: true })
  enqueteOverviews!: EnqueteOverview[];

  @Prop({ type: Array, required: true })
  businessEventOverviews!: BusinessEventOverview[];

  @Prop({ type: Array, required: true })
  contactOverviews!: ContactOverview[];

  @Prop({ type: Number, default: null })
  highlightMsec!: Msec | null;

  @Prop({ type: Boolean, required: true })
  isOmo!: boolean;

  @Prop({ type: Boolean, default: true })
  isUserDetail!: boolean;

  @Prop({ type: Array, required: true })
  filterMatchedIds!: string[];

  @Prop({ type: Array, required: true })
  almostCvGramIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedGramIds!: string[];

  @Prop({ type: Array, default: () => [] })
  funnelMatchedSelectedGramIds!: string[];

  @Prop({ type: Array, required: true })
  selectedConversionIds!: number[];

  @Prop({ type: Boolean, required: true })
  isToggleTimeOfDayEnabled!: boolean;

  onClick(
    overview:
      | VisitOverview
      | NpsOverview
      | BusinessEventOverview
      | EnqueteOverview
      | ContactOverview
  ) {
    this.$emit("click", overview);
  }

  // initial dummy balloonContentOverview for balloon
  balloonContentOverview:
    | VisitOverview
    | NpsOverview
    | BusinessEventOverview
    | EnqueteOverview
    | ContactOverview
    | null = null;

  showBalloon: boolean = false;
  // Used for ChartColoredPeriodTooltip
  showColoredPeriodTooltip: boolean = false;
  coloredPeriodTooltipX: number = 0;
  coloredPeriodTooltipLabel: String = "";
  coloredPeriodTooltipPeriod: String = "";
  coloredPeriodTooltipColorIndicator: Colors = Colors.Blue900; //tentative

  get firstScaleDate(): Date {
    const date = new Date(this.lastScaleDate);
    date.setMonth(date.getMonth() - ALL_PLOT_PERIOD);

    if (this.isShowOneMonth) {
      date.setDate(date.getDate() - 7);
    }
    return date;
  }

  get lastScaleDate(): Date {
    if (this.isShowOneMonth) {
      // todayの7日後とする。
      const date: Date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + 7);
      return date;
    }

    const now: Date = new Date();
    return getNextMonthFirst(now);
  }

  get daysInPeriod(): number {
    return getDiffDays(this.firstScaleDate, this.lastScaleDate);
  }

  get overviewWidth(): number {
    const sideSpace = (PADDING_ARROW_ICON + SIZE_ARROW_ICON) * 2;

    if (this.chartPeriod === ChartPeriod.OneMonth) {
      return OverViewWidth.OneMonth - sideSpace;
    }
    if (this.chartPeriod === ChartPeriod.OneYear) {
      return OverViewWidth.OneYear - sideSpace;
    }
    if (this.chartPeriod === ChartPeriod.TwoYears) {
      return OverViewWidth.TwoYears - sideSpace;
    }

    return OverViewWidth.SixMonth - sideSpace;
  }

  get showBaselineLength(): number {
    if (this.isShowSixMonth) {
      return (
        this.overviewWidth - START_LINE_X - SIX_MONTH_BASELINE_EDGE_LENGTH * 2
      );
    }
    return this.overviewWidth - START_LINE_X - BASELINE_EDGE_LENGTH * 2;
  }

  get isShowOneMonth(): boolean {
    return this.chartPeriod == ChartPeriod.OneMonth;
  }

  get isShowSixMonth(): boolean {
    return this.chartPeriod == ChartPeriod.SixMonths;
  }

  get baselineLength(): number {
    if (this.isShowOneMonth) {
      return (
        (this.showBaselineLength / DAYS_IN_SHOWN_BASELINE_LENHTH) *
        this.daysInPeriod
      );
    }

    return (this.showBaselineLength / this.chartPeriod) * ALL_PLOT_PERIOD;
  }

  get lastScaleX(): number {
    if (this.isShowSixMonth) {
      return (
        START_LINE_X + SIX_MONTH_BASELINE_EDGE_LENGTH + this.baselineLength
      );
    }
    return START_LINE_X + BASELINE_EDGE_LENGTH + this.baselineLength;
  }

  get omoMaxActionUnixtime(): Msec {
    const contactMax: Msec = getMaxUnixtimeInOverviews(this.contactOverviews);
    const NpsMax: Msec = getMaxUnixtimeInOverviews(this.npsOverviews);
    const enqueteMax: Msec = getMaxUnixtimeInOverviews(this.enqueteOverviews);
    const businessEventMax: Msec = getMaxUnixtimeInOverviews(
      this.businessEventOverviews
    );
    // these args of Math.max() contain more than 1 number except -Infinity.
    return Math.max(contactMax, NpsMax, enqueteMax, businessEventMax);
  }

  get omoMinActionUnixtime(): Msec {
    const contactMin: Msec = getMinUnixtimeInOverviews(this.contactOverviews);
    const NpsMin: Msec = getMinUnixtimeInOverviews(this.npsOverviews);
    const enqueteMin: Msec = getMinUnixtimeInOverviews(this.enqueteOverviews);
    const businessEventMin: Msec = getMinUnixtimeInOverviews(
      this.businessEventOverviews
    );
    // these args of Math.min() contain more than 1 number except Infinity.
    return Math.min(contactMin, NpsMin, enqueteMin, businessEventMin);
  }

  get sortedDescVisitOverviews(): VisitOverview[] {
    return Object.assign([], this.visitOverviews).sort(
      (a: any, b: any) =>
        Number(b.gramId.split(":")[0]) - Number(a.gramId.split(":")[0])
    );
  }

  get filterMatchedVisitIds(): string[] {
    /**
     * gramIdに含まれる時間を使ってmatchしているかをチェックするので、
     * contactとbusinessEventのデータは省いておく
     */
    const filteredFilterMatchedIds = this.filterMatchedIds.filter(
      id =>
        !this.contactOverviews.some(o => o.gramId === id) &&
        !this.businessEventOverviews.some(o => o.gramId === id)
    );

    return getGramIdsInVisitOverviews(
      filteredFilterMatchedIds,
      this.sortedDescVisitOverviews
    );
  }

  get almostMatchedVisitIds(): string[] {
    return getGramIdsInVisitOverviews(
      this.almostCvGramIds,
      this.sortedDescVisitOverviews
    );
  }

  get otherThanVisitOverviewGramIds(): string[] {
    const businessEventOverviewGramIds = this.businessEventOverviews.map(
      o => o.gramId
    );
    const contactOverviewGramIds = this.contactOverviews.map(o => o.gramId);

    return businessEventOverviewGramIds.concat(contactOverviewGramIds);
  }

  get funnelMatchedTopGramIds(): string[] {
    const visitGramIds: string[] = [];
    const notVisitGramIds: string[] = [];

    for (const funnelMatchedGramId of this.funnelMatchedGramIds) {
      if (this.otherThanVisitOverviewGramIds.includes(funnelMatchedGramId)) {
        notVisitGramIds.push(funnelMatchedGramId);
      } else {
        visitGramIds.push(funnelMatchedGramId);
      }
    }

    const gramIdsInVisitOverviews = getGramIdsInVisitOverviews(
      visitGramIds,
      this.sortedDescVisitOverviews
    );

    return notVisitGramIds.concat(gramIdsInVisitOverviews);
  }

  get funnelMatchedSelectedTopGramIds(): string[] {
    return this.funnelMatchedSelectedGramIds.map(
      funnelMatchedSelectedGramId => {
        return !this.otherThanVisitOverviewGramIds.includes(
          funnelMatchedSelectedGramId
        )
          ? getGramIdsInVisitOverviews(
              [funnelMatchedSelectedGramId],
              this.sortedDescVisitOverviews
            )[0]
          : "";
      }
    );
  }

  // Used for ChartBusinessEvent components
  get sortedBusinessEventOverviews(): BusinessEventOverview[] {
    let nextOverviews: BusinessEventOverview[] = [];
    if (
      this.funnelMatchedSelectedGramIds ||
      (this.funnelMatchedGramIds && this.funnelMatchedGramIds.length > 0)
    ) {
      nextOverviews = getOverviewsFunnelMatchedBehind(
        this.businessEventOverviews,
        this.funnelMatchedSelectedGramIds,
        this.funnelMatchedGramIds
      );
    }
    nextOverviews =
      nextOverviews.length > 0 ? nextOverviews : this.businessEventOverviews;

    if (this.filterMatchedIds && this.filterMatchedIds.length > 0) {
      return getOverviewsMatchedBehind(nextOverviews, this.filterMatchedIds);
    } else {
      return nextOverviews;
    }
  }

  // Used for ChartBalloon components
  get baselineY(): number {
    return this.isOmo ? BASELINE_Y_OMO : BASELINE_Y_DEFAULT;
  }

  // Used for ChartBalloon components
  get balloonX(): number {
    if (this.balloonContentOverview !== null) {
      return convertTimeToX(
        this.firstScaleDate,
        this.lastScaleDate,
        this.lastScaleX,
        this.baselineLength,
        this.balloonContentOverview.date.getTime()
      );
    }
    return 0;
  }

  // Used for ChartBalloon components
  get visitBarBalloonY() {
    if (this.balloonContentOverview instanceof VisitOverview) {
      return (
        this.baselineY - getBarHeight(this.balloonContentOverview.pvStats.allPv)
      );
    }
    return 0;
  }

  // Used for ChartColoredPeriodTooltip
  get coloredPeriodTooltipY(): number {
    return this.isUserDetail ? this.baselineY : 0;
  }

  /**
   * change the tooltip conentet for coloredPeriod
   * @param coloredPeriod ColoredPeriod class object
   */
  updateColoredPeriodTooltipContent(coloredPeriod: ColoredPeriod) {
    this.coloredPeriodTooltipLabel = coloredPeriod.periodName;
    this.coloredPeriodTooltipPeriod = formatPeriodOfColoredPeriod(
      coloredPeriod
    );
    this.coloredPeriodTooltipColorIndicator = coloredPeriod.color;
  }

  onMouseEnterOverview(
    overview: ContactOverview | BusinessEventOverview | VisitOverview
  ) {
    this.balloonContentOverview = overview;
    //IE11 & Edgeでは連続で別アイコンをhoverするとballoonが同じ場所に表示するため、
    //初期化し表示を遅らせる
    this.showBalloon = false;
    window.setTimeout(() => {
      this.showBalloon = true;
    }, BALLOON_SHOW_DELAY);
  }

  onMouseLeaveOverview() {
    //setTimeoutなしの場合先にonMouseLeave実行され残る場合があるので同じ時間遅らせる
    window.setTimeout(() => {
      this.showBalloon = false;
    }, BALLOON_SHOW_DELAY);
  }

  onMouseEnterColoredPeriod(mouseX: number, coloredPeriod: ColoredPeriod) {
    this.coloredPeriodTooltipX = mouseX;
    this.updateColoredPeriodTooltipContent(coloredPeriod);

    //初期化し表示を遅らせる
    this.showColoredPeriodTooltip = false;
    window.setTimeout(() => {
      this.showColoredPeriodTooltip = true;
    }, SHOW_TOOLTIP_DELAY);
  }

  onMouseLeaveColoredPeriod() {
    //setTimeoutなしの場合先にonMouseLeave実行され残る場合があるので同じ時間遅らせる
    window.setTimeout(() => {
      this.showColoredPeriodTooltip = false;
    }, SHOW_TOOLTIP_DELAY);
  }
}
</script>

<style scoped lang="scss">
.gramChart {
  position: relative;
  width: 100%;
}

.gramChart__chartContactIcons {
  position: relative;
}
</style>
