<i18n
  src="@/i18n/components/funnel/funnel-contact-attribute-dialog.json"
></i18n>
<template>
  <Dialog
    :visible="visible"
    :show-close-button="false"
    width="600px"
    top="15vh"
    @open="onOpen"
    @close="onClickCancel"
  >
    <template #title>
      <div class="funnelConditionAttributeDialog__title">
        {{ title }}
      </div>
    </template>
    <div class="funnelConditionAttributeDialog">
      <!-- 各属性、指標の選択 -->
      <div class="funnelConditionAttributeDialog__select">
        <SelectBox
          v-model="selectedDefinitionId"
          :options="definitionOptions"
          width="100%"
        />

        <div v-t="'is'" class="is" />
      </div>

      <!-- 文字列入力フォーム -->
      <div v-if="showStringForm" class="funnelConditionAttributeDialog__string">
        <InputText
          v-model="stringValue"
          :placeholder="inputTextPlaceholder"
          class="funnelConditionAttributeDialog__stringInput"
          horizontal-padding="10px"
        />

        <SelectBox
          v-model="stringMatchMethod"
          :options="matchMethodOptions"
          width="160px"
          class="funnelConditionAttributeDialog__stringMatchMethod"
        />
      </div>

      <!-- 数値入力フォーム -->
      <div v-else class="funnelConditionAttributeDialog__number">
        <div class="funnelConditionAttributeDialog__numberInput">
          <RadioButton v-model="numberMethod" :label="valueInputMethod.Value">{{
            $t("valueSpecification")
          }}</RadioButton>

          <InputText
            v-model="numberValue"
            class="input"
            @focus="onFocusNumberValue"
          />
          <div class="funnelConditionAttributeDialog__numberMatchMethod">
            <SelectBox
              v-model="numberMatchMethod"
              :options="numberMatchMethodOptions"
              width="160px"
              @focus="onFocusNumberMatchMethod"
            />
          </div>
        </div>
        <div class="funnelConditionAttributeDialog__numberInput">
          <RadioButton v-model="numberMethod" :label="valueInputMethod.Range">{{
            $t("rangeSpecification")
          }}</RadioButton>

          <div class="funnelConditionAttributeDialog__numberRange">
            <InputText
              v-model="numberRangeMinValue"
              class="input"
              @focus="onFocusNumberRange"
            />
            <div class="funnelConditionAttributeDialog__numberRangeHyphen"
              >〜</div
            >
            <InputText
              v-model="numberRangeMaxValue"
              class="input"
              @focus="onFocusNumberRange"
            />
          </div>
        </div>
      </div>

      <div class="funnelConditionAttributeDialog__bottons">
        <Button
          class="funnelConditionAttributeDialog__addButton"
          :disabled="shouldDisableSubmitButton"
          @click="onClickSubmit"
          >{{ submitButtonTitle }}</Button
        >
        <Button
          v-t="'cancel'"
          class="funnelConditionAttributeDialog__cancelButton"
          type="light"
          @click="onClickCancel"
        />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { ValueInputMethod } from "@/models/search/additional-condition/AdditionalSelectCondition";
import { ContactDefinitionType } from "@/models/client-settings/ContactDefinition";

import {
  FunnelConditionAttributeType,
  FunnelConditionNumberAttribute,
  FunnelConditionTextAttribute
} from "@/models/funnel/FunnelConditionAttribute";

import SelectOption from "@/components/form/SelectOption";
import SelectBox from "@/components/form/SelectBox.vue";
import TextArea from "@/components/form/TextArea.vue";
import RadioButton from "@/components/form/RadioButton.vue";
import InputText from "@/components/form/InputText.vue";
import Button from "@/components/Button.vue";

import { isNumberText } from "@/util/string-util";
import { MatchMethod } from "@/models/search/MatchMethod";
import { showAlert } from "@/util/modal-util";
import Dialog from "@/components/dialog/Dialog.vue";
import { NumberMatchMethod } from "@/api/apis/ApiSearch";
import { MAX_LENGTH } from "@/const/additional-condition";
import { ContactFieldType } from "@/models/search/additional-condition/ContactAttributeCondition";

@Component({
  components: {
    TextArea,
    SelectBox,
    Button,
    RadioButton,
    InputText,
    Dialog
  }
})
export default class FunnelContactAttributeDialog extends Vue {
  // 新規追加ではなく、編集の場合に対象となる検索条件
  @Prop({ type: Object, default: null })
  editCondition!: FunnelConditionAttributeType | null;

  @Prop({ type: Number, required: true })
  contactType!: ContactDefinitionType;

  @Prop({ type: Boolean, default: false })
  visible!: boolean;

  onSubmit(attributeCondition: FunnelConditionAttributeType) {
    this.$emit("submit", attributeCondition);
  }

  @Emit("cancel")
  onClickCancel() {}

  // 選択中の各属性、指標のId
  selectedDefinitionId: number = 0;

  // 文字列入力の値
  stringValue: string = "";

  // 文字列入力の場合のマッチ条
  stringMatchMethod: number = MatchMethod.Partial;

  // 数値入力の場合の入力方法(１つの値 or レンジ)
  numberMethod: ValueInputMethod = ValueInputMethod.Value;

  // 数値入力の値（１つの値の場合）
  numberValue: string = "";

  // レンジ入力の最小値
  numberRangeMinValue: string = "";

  // レンジ入力の最大値
  numberRangeMaxValue: string = "";

  // 数値入力のマッチ条件
  numberMatchMethod: NumberMatchMethod =
    NumberMatchMethod.LESS_THAN_OR_EQUAL_TO;

  valueInputMethod = ValueInputMethod;

  mounted() {
    this.setDefaultValues();
  }

  // 編集状態か（編集状態じゃない場合は新規追加）
  get isEdit(): boolean {
    return this.editCondition !== null;
  }

  // フォームのタイトル
  get title(): string {
    return this.isEdit
      ? (this.$t("editContactAttribute") as string)
      : (this.$t("addContactAttribute") as string);
  }

  // submitボタンのタイトル
  get submitButtonTitle(): string {
    return this.isEdit
      ? (this.$t("editCondition") as string)
      : (this.$t("addCondition") as string);
  }

  // 各属性、指標の選択肢
  get definitionOptions(): SelectOption[] {
    switch (this.contactType) {
      case ContactDefinitionType.TEL:
        return [
          {
            value: ContactFieldType.Content,
            label: this.$t("content") as string,
            disabled: false
          }
        ];
      case ContactDefinitionType.MAIL:
        return [
          {
            value: ContactFieldType.Title,
            label: this.$t("title") as string,
            disabled: false
          }
        ];
      case ContactDefinitionType.SHOP:
        return [
          {
            value: ContactFieldType.StoreName,
            label: this.$t("nameOfStore") as string,
            disabled: false
          },
          {
            value: ContactFieldType.Purpose,
            label: this.$t("storeVisitPurpose") as string,
            disabled: false
          }
        ];
      default:
        return [];
    }
  }

  get inputTextPlaceholder(): string {
    return this.$t("enterContactAttribute") as string;
  }

  // 文字列入力のマッチ条件選択肢
  get matchMethodOptions(): SelectOption[] {
    return [
      {
        value: MatchMethod.Partial,
        label: this.$t("partialMatch") as string,
        disabled: false
      },
      {
        value: MatchMethod.Exact,
        label: this.$t("perfectMatch") as string,
        disabled: false
      }
    ];
  }

  get numberMatchMethodOptions(): SelectOption[] {
    return [
      {
        value: NumberMatchMethod.LESS_THAN_OR_EQUAL_TO,
        label: this.$t("lessThanOrEqualMatch") as string,
        disabled: false
      },
      {
        value: NumberMatchMethod.GREATER_THAN_OR_EQUAL_TO,
        label: this.$t("greaterThanOrEqualMatch") as string,
        disabled: false
      }
    ];
  }

  // 文字列入力フォームを表示するか
  get showStringForm(): boolean {
    return true;
  }

  get shouldDisableSubmitButton(): boolean {
    if (!this.showStringForm) {
      if (this.numberMethod === ValueInputMethod.Value) {
        return this.numberValue === "";
      } else {
        return this.isEmptyNumberRange;
      }
    }

    return this.stringValue === "";
  }

  get isEmptyNumberRange(): boolean {
    return this.numberRangeMinValue === "" || this.numberRangeMaxValue === "";
  }

  // 追加、編集ボタンを押した
  onClickSubmit() {
    const condition = this.createConditionAttribute();
    if (condition !== null) {
      this.onSubmit(condition);
    }
  }

  createConditionAttribute(): FunnelConditionAttributeType | null {
    if (this.showStringForm) {
      if (!this.validateStringValue()) {
        return null;
      }
      return new FunnelConditionTextAttribute(
        this.selectedDefinitionId,
        this.stringValue,
        this.stringMatchMethod
      );
    } else {
      if (!this.validateNumberValue()) {
        return null;
      }

      return new FunnelConditionNumberAttribute(
        this.selectedDefinitionId,
        this.getFromToNumberValue().min,
        this.getFromToNumberValue().max
      );
    }
  }

  // 入力された数値から検索条件のfromとtoにセットする数値を返す
  getFromToNumberValue(): { min: number | null; max: number | null } {
    if (this.numberMethod === ValueInputMethod.Value) {
      if (this.numberMatchMethod === NumberMatchMethod.LESS_THAN_OR_EQUAL_TO) {
        return { min: null, max: Number(this.numberValue) };
      }
      return { min: Number(this.numberValue), max: null };
    } else {
      return {
        min: Number(this.numberRangeMinValue),
        max: Number(this.numberRangeMaxValue)
      };
    }
  }

  // １つの数値入力のinputにフォーカスがあたったら、入力方法は１つの数値にする
  onFocusNumberValue() {
    this.numberMethod = ValueInputMethod.Value;
  }

  // レンジ入力のinputにフォーカスがあたったら、入力方法はレンジにする
  onFocusNumberRange() {
    this.numberMethod = ValueInputMethod.Range;
  }

  onFocusNumberMatchMethod() {
    this.numberMethod = ValueInputMethod.Value;
  }

  // デフォルト値としてセットする
  setDefaultValues() {
    // キャンセルしても値が残るため一旦data属性をリセット
    this.selectedDefinitionId =
      this.definitionOptions.length > 0
        ? Number(this.definitionOptions[0].value)
        : 0;
    this.stringValue = "";
    this.stringMatchMethod = MatchMethod.Partial;
    this.numberMethod = ValueInputMethod.Value;
    this.numberValue = "";
    this.numberRangeMinValue = "";
    this.numberRangeMaxValue = "";

    if (this.editCondition instanceof FunnelConditionTextAttribute) {
      this.selectedDefinitionId = this.editCondition.attributeId;
      this.stringValue = this.editCondition.value;
      this.stringMatchMethod = this.editCondition.wordMatchMethod;
      return;
    }

    if (this.editCondition instanceof FunnelConditionNumberAttribute) {
      this.selectedDefinitionId = this.editCondition.attributeId;

      if (
        this.editCondition.minValue !== null &&
        this.editCondition.maxValue !== null
      ) {
        this.numberRangeMinValue = String(this.editCondition.minValue);
        this.numberRangeMaxValue = String(this.editCondition.maxValue);
        this.numberMethod = ValueInputMethod.Range;

        return;
      }

      this.numberMethod = ValueInputMethod.Value;
      if (this.editCondition.minValue !== null) {
        this.numberValue = String(this.editCondition.minValue);
        this.numberMatchMethod = NumberMatchMethod.GREATER_THAN_OR_EQUAL_TO;
      }

      if (this.editCondition.maxValue !== null) {
        this.numberValue = String(this.editCondition.maxValue);
        this.numberMatchMethod = NumberMatchMethod.LESS_THAN_OR_EQUAL_TO;
      }
    }
  }

  // 数値入力のバリデーション
  validateNumberValue(
    method: ValueInputMethod = this.numberMethod,
    value: string = this.numberValue,
    min: string = this.numberRangeMinValue,
    max: string = this.numberRangeMaxValue
  ): boolean {
    if (method === ValueInputMethod.Value) {
      if (value === "" || !isNumberText(value)) {
        showAlert(this.$t("enterCorrectValue") as string);
        return false;
      }
    }

    if (method === ValueInputMethod.Range) {
      if (
        min === "" ||
        max === "" ||
        !isNumberText(min) ||
        !isNumberText(max) ||
        Number(min) > Number(max)
      ) {
        showAlert(this.$t("enterCorrectValue") as string);
        return false;
      }
    }

    return true;
  }

  // 文字列入力のバリデーション
  validateStringValue(): boolean {
    const attrName = this.$t("contactAttribute") as string;

    let errorMessages = [];
    if (this.stringValue.length <= 0) {
      errorMessages.push(this.$t("enterAttrName", { attrName }) as string);
    }
    if (this.stringValue.length > MAX_LENGTH) {
      errorMessages.push(
        this.$t("maxCharacters", {
          maxLength: String(MAX_LENGTH)
        }) as string
      );
    }

    if (errorMessages.length > 0) {
      showAlert(errorMessages.join("\n"));
      return false;
    }

    return true;
  }

  onOpen() {
    this.setDefaultValues();
  }
}
</script>
<style lang="scss" scoped>
.funnelConditionAttributeDialog__title {
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.funnelConditionAttributeDialog__select {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .is {
    margin: 0 20px;
  }
}

.funnelConditionAttributeDialog__stringInput {
  margin-bottom: 10px;
}

.funnelConditionAttributeDialog__stringMatchMethod {
  margin-bottom: 30px;
}

.funnelConditionAttributeDialog__number {
  margin-bottom: 40px;
}

.funnelConditionAttributeDialog__numberInput {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .radio-button {
    width: 140px;
  }

  .input {
    width: 90px;
  }
}
.funnelConditionAttributeDialog__numberMatchMethod {
  padding-left: 10px;
}

.funnelConditionAttributeDialog__numberRangeHyphen {
  margin: 0 16px;
}

.funnelConditionAttributeDialog__numberRange {
  display: flex;
  align-items: center;
}

.funnelConditionAttributeDialog__bottons {
  display: flex;
}

.funnelConditionAttributeDialog__addButton {
  flex: 1;
  margin-right: 10px;
}

.funnelConditionAttributeDialog__cancelButton {
  flex: 1;
  margin-left: 10px;
}
</style>
