<i18n src="@/i18n/views/notification.json"></i18n>
<template>
  <div class="notificationNav">
    <Balloon class="notificationNav__wrapper" :direction="balloonDirection">
      <div class="notificationNav__inner">
        <div class="notificationNav__items">
          <div
            v-for="(item, index) in notificationItems"
            :key="`item.title-${index}`"
            class="notificationNav__item"
          >
            <div class="notificationNav__header">
              <span class="notificationNav__date">{{ item.startDate }}</span>
              <TagNew v-if="!item.isRead" />
            </div>
            <div v-t="item.id" class="notificationNav__text"></div>
            <div class="notificationNav__links">
              <LinkText
                v-if="item.helpSiteUrl"
                class="notificationNav__linksHelp"
                :size="linkTextSize.Small"
                :url="item.helpSiteUrl"
                :link-text="$t('externalLink')"
                :show-left-icon="false"
                @click="markAsRead(item.id)"
              />

              <button
                v-if="item.panelType"
                v-t="'button'"
                class="notificationNav__button"
                @click="highlightPanel(item.panelType, item.id)"
              />

              <!-- TODO:user_trendの告知期間が終了後に削除する -->
              <button
                v-if="item.pathName && isAvailableUserTrend"
                v-t="'button'"
                class="notificationNav__button"
                @click="toFeaturePage(item.pathName, item.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </Balloon>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { Icons } from "@/const/Icons";
import { BalloonDirection } from "@/const/balloon";
import Balloon from "@/components/Balloon.vue";
import Icon from "@/components/Icon.vue";
import TagNew from "@/components/TagNew.vue";
import LinkText from "@/components/LinkText.vue";
import { LinkTextSize } from "@/const/LinkText";
import { PanelType } from "@/const/home";
import { panelId } from "@/util/string-util";

const ICON_COLOR: string = "inherit";
const ICON_SIZE: number = 11;

interface HightlightTimer {
  id: number;
  selector: string;
}

@Component({
  components: {
    Balloon,
    Icon,
    TagNew,
    LinkText
  }
})
export default class NotificationNav extends Vue {
  iconHelp = Icons.Help;
  iconExternalLink = Icons.ExternalLink;
  iconMortarboard = Icons.Mortarboard;
  iconColor = ICON_COLOR;
  iconSize = ICON_SIZE;

  balloonDirection = BalloonDirection.TopEnd;

  SCROLL_DURATION = 500;
  SCROLL_OFFSET_ADJUSTMENT = -80;
  PANEL_HIGHLIGHT_BGCOLOR = "#e6f3ff";
  PANEL_HIGHLIGHT_DURATION = 7000;

  highlightTimers: HightlightTimer[] = [];

  linkTextSize = LinkTextSize;

  @Prop({ type: Boolean, required: true })
  isAvailableUserTrend!: boolean;

  mounted() {
    this.$store.dispatch("notification/setMarkAsRead").catch(error => {
      throw new Error(error);
    });
  }

  get notificationItems() {
    return this.$store.getters["notification/items"];
  }

  @Emit("button-clicked")
  toFeaturePage(name: string, notificationId: string) {
    this.markAsRead(notificationId);
    this.$router.push({ name: name });
  }

  @Emit("button-clicked")
  async highlightPanel(
    panelName: keyof typeof PanelType,
    notificationId: string
  ) {
    const targetPanelSelector = `#${panelId(PanelType[panelName])}`;

    // Cancel the timer for the same selector if already exist
    this.highlightTimers.forEach(obj => {
      if (obj.selector !== targetPanelSelector) return;
      this.clearTimer(obj.id);
    });

    this.markAsRead(notificationId);

    if (this.$route.name !== "home") {
      this.$router.push({
        name: "home"
      });
    }

    await this.$nextTick();

    const targetPanelElement: HTMLElement | null = document.querySelector(
      targetPanelSelector
    );

    if (!targetPanelElement) return;

    if (targetPanelElement.classList.contains("home__highlightedPanel")) {
      targetPanelElement.classList.remove("home__highlightedPanel");
    }

    this.$scrollTo(targetPanelSelector, this.SCROLL_DURATION, {
      offset: this.SCROLL_OFFSET_ADJUSTMENT
    });

    targetPanelElement.classList.add("home__highlightedPanel");

    const timerId = window.setTimeout(() => {
      targetPanelElement.classList.remove("home__highlightedPanel");
      this.clearTimer(timerId);
    }, this.PANEL_HIGHLIGHT_DURATION);

    this.highlightTimers.push({
      id: timerId,
      selector: targetPanelSelector
    });
  }

  clearTimer(timerId: number) {
    const highlightTimers = this.highlightTimers.filter(
      obj => obj.id !== timerId
    );

    clearTimeout(timerId);
    this.highlightTimers = highlightTimers;
  }

  markAsRead(notificationId: string) {
    this.$store
      .dispatch("notification/addMarkAsRead", notificationId)
      .catch(error => {
        throw new Error(error);
      });
  }
}
</script>

<style lang="scss" scoped>
.notificationNav__wrapper {
  width: 320px;
}

.notificationNav__inner {
  padding: 5px 0;
}

.notificationNav__items {
  display: block;

  &:hover {
    text-decoration: none;
  }
}

.notificationNav__item {
  padding: 15px;
  border-bottom: solid 1px $colorBase500;
}

.notificationNav__item:last-child {
  border-bottom: none;
}

.notificationNav__header {
  display: flex;
  align-items: center;
  margin-bottom: calc(10px - 0.25em);
}

.notificationNav__text {
  color: $colorBase900;
  margin-bottom: 10px;
  font-size: $basicFontSize;
  line-height: 1.5;
}

.notificationNav__date {
  color: $colorBase700;
  font-size: 12px;
  margin-right: 10px;
}
.notificationNav__new {
  color: $colorBadge;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

.notificationNav__icon {
  margin-left: 10px;
}

.notificationNav__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificationNav__button {
  color: $colorWhite;
  background: $colorBase900;
  font-size: 12px;
  width: 80px;
  height: 20px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
