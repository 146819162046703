<i18n src="@/i18n/components/progress.json"></i18n>
<template>
  <div class="progress">
    <div class="progress__text"> {{ $t("text1") }}<br />{{ $t("text2") }} </div>

    <div class="progress__iconContainer">
      <Face class="progress__icon" />

      <FaceSub class="progress__subicon" />
    </div>

    <div class="progress__linearContainer">
      <div class="progress__linear" :style="progressStyle" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Face from "@/assets/svg/user/face-2-1.svg";
import FaceSub from "@/assets/svg/user/face-2-2.svg";

@Component({
  components: { Face, FaceSub }
})
export default class Progress extends Vue {
  @Prop({
    type: Number,
    required: true,
    validator: (progress: number) => {
      return progress >= 0.0 && progress <= 100.0;
    }
  })
  progress!: number;

  get progressStyle() {
    return {
      width: String(this.progress) + "%"
    };
  }
}
</script>

<style scoped lang="scss">
.progress {
  display: inline-flex;
  flex-direction: column;
}

.progress__text {
  text-align: center;
  line-height: 1.5;
}

.progress__iconContainer {
  position: relative;
  margin: 30px auto 0;
  width: 48px;
}

.progress__icon {
  width: 48px;
}

.progress__subicon {
  position: absolute;
  top: -12px;
  right: -20px;
  width: 24px;
}

.progress__linearContainer {
  position: relative;
  margin: 15px auto 0;
  width: 80px;
  height: 8px;
  background-color: $colorBase300;
}

.progress__linear {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: $colorBlue800;
}
</style>
