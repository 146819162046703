<template>
  <div class="funnelPrimaryContent">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FunnelPrimaryContent extends Vue {}
</script>

<style lang="scss" scoped>
.funnelPrimaryContent {
  border: solid 1px $colorBase500;
  border-radius: calc(#{$sizeRadius} - 1px);
  padding: 20px;
}
</style>
