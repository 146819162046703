import { render, staticRenderFns } from "./DatePickerInput.vue?vue&type=template&id=01ab364a&scoped=true"
import script from "./DatePickerInput.vue?vue&type=script&lang=ts"
export * from "./DatePickerInput.vue?vue&type=script&lang=ts"
import style0 from "./DatePickerInput.vue?vue&type=style&index=0&id=01ab364a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01ab364a",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/components/date-picker/date-picker-input.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusergram-front%2Fsrc%2Fcomponents%2Fdate-picker%2FDatePickerInput.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports