<i18n src="@/i18n/components/funnel/funnel-added-condition-label.json" />
<template>
  <div class="funnelAddedConditionLabel">
    <div class="funnelAddedConditionLabel__label">
      {{ label }}
      <div
        class="funnelAddedConditionLabel__displayValue"
        v-text="displayValue"
      />
    </div>

    <div class="funnelAddedConditionLabel__buttonContainer">
      <Tooltip :text="$t('edit')">
        <IconButton
          :icon="icons.Memo"
          :size="iconSize"
          :button-type="iconButtonType"
          @click="emit('edit')"
        />
      </Tooltip>

      <div class="funnelAddedConditionLabel__deleteButton">
        <Tooltip :text="$t('delete')">
          <IconButton
            :icon="icons.ButtonClose"
            :size="iconSize"
            :button-type="iconButtonType"
            @click="emit('delete')"
          />
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Icons } from "@/const/Icons";
import { IconButtonType } from "@/const/IconButtons";
import IconButton from "@/components/IconButton.vue";
import Tooltip from "@/components/Tooltip.vue";

const icons = Icons;
const iconButtonType = IconButtonType.Weak;
const iconSize = 18;

defineProps<{
  label: string;
  displayValue: string;
}>();

const emit = defineEmits<{
  (e: "edit"): void;
  (e: "delete"): void;
}>();
</script>

<style lang="scss" scoped>
.funnelAddedConditionLabel {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: $colorBase300;
}

.funnelAddedConditionLabel__label {
  flex: 1;
  word-break: break-word;
  font-size: 14px;
  line-height: 1.5;
}

.funnelAddedConditionLabel__displayValue {
  margin-top: 4px;
  color: $colorBase700;
  font-size: 12px;
}

.funnelAddedConditionLabel__buttonContainer {
  display: flex;
  align-items: center;
  margin-left: 20px;

  ::v-deep .tooltip {
    display: block;
  }
}

.funnelAddedConditionLabel__deleteButton {
  margin-left: 20px;
}
</style>
