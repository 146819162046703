<template>
  <div class="child-filter-node-ad">
    <FilterNodeItem
      v-for="(additionalCondition, index) in condition.additionalConditions"
      :key="index"
      :options="condition.selectOption(additionalCondition, isFirstNode)"
      :condition-type="additionalCondition.conditionType"
      @input="
        type => {
          onInputItem(type, index);
        }
      "
      @remove="onRemoveAdditionalCondition(index)"
    >
      <FilterAdTypeField
        v-if="additionalCondition.conditionType === adType"
        :condition="additionalCondition"
        :is-selected-ad-inverntory="condition.hasAdInventoryCondition"
        :is-selected-ad-group="condition.hasAdGroupCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterAdMediaField
        v-if="additionalCondition.conditionType === adMedia"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterAdInventoryField
        v-if="additionalCondition.conditionType === adInverntory"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterAdCampaignField
        v-if="additionalCondition.conditionType === adCampaign"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterAdGroupField
        v-if="additionalCondition.conditionType === adGroup"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterLandingPageUrlField
        v-if="additionalCondition.conditionType === landingPageUrl"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterLandingPageTitleField
        v-if="additionalCondition.conditionType === landingPageTitle"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterDateHourField
        v-if="additionalCondition.conditionType === dateHour"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
      <FilterPeriodField
        v-if="additionalCondition.conditionType === period"
        :condition="additionalCondition"
        @input="cnd => onInputAdditionalCondition(cnd, index)"
      />
    </FilterNodeItem>
    <FilterAddConditionButton
      v-if="condition.isConditionAddable"
      @click="onClickAddCondition"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Model, Vue } from "vue-property-decorator";
import FilterNodeItem from "@/components/filter/FilterNodeItem.vue";
import FilterAddConditionButton from "@/components/filter/FilterAddConditionButton.vue";
import FilterAdTypeField from "@/components/filter/form/FilterAdTypeField.vue";
import FilterAdMediaField from "@/components/filter/form/FilterAdMediaField.vue";
import FilterAdInventoryField from "@/components/filter/form/FilterAdInventoryField.vue";
import FilterAdCampaignField from "@/components/filter/form/FilterAdCampaignField.vue";
import FilterAdGroupField from "@/components/filter/form/FilterAdGroupField.vue";
import FilterLandingPageUrlField from "@/components/filter/form/FilterLandingPageUrlField.vue";
import FilterLandingPageTitleField from "@/components/filter/form/FilterLandingPageTitleField.vue";
import FilterDateHourField from "@/components/filter/form/FilterDateHourField.vue";
import FilterPeriodField from "@/components/filter/form/FilterPeriodField.vue";

import {
  ChildFilterNodeForAd,
  ChildFilterNodeForAdCondition
} from "@/models/search/filter-node/ChildFilterNodeForAd";
import { FilterNodeConditionType } from "@/models/search/filter-node-condition/FilterNodeCondition";

import {
  FilterAdTypeCondition,
  defaultAdType
} from "@/models/search/filter-node-condition/FilterAdTypeCondition";
import { FilterAdMediaCondition } from "@/models/search/filter-node-condition/FilterAdMediaCondition";
import { FilterAdInventoryCondition } from "@/models/search/filter-node-condition/FilterAdInventoryCondition";
import { FilterAdCampaignCondition } from "@/models/search/filter-node-condition/FilterAdCampaignCondition";
import { FilterAdGroupCondition } from "@/models/search/filter-node-condition/FilterAdGroupCondition";
import { FilterLandingPageUrlCondition } from "@/models/search/filter-node-condition/FilterLandingPageUrlCondition";
import { FilterLandingPageTitleCondition } from "@/models/search/filter-node-condition/FilterLandingPageTitleCondition";
import { FilterAdditionalTimingCondition } from "@/models/search/filter-node-condition/FilterNodeCondition";
import { FilterFirstTimeCondition } from "@/models/search/filter-node-condition/FilterFirstTimeCondition";
import { FilterPeriodCondition } from "@/models/search/filter-node-condition/FilterPeriodCondition";
import { FilterDateHourCondition } from "@/models/search/filter-node-condition/FilterDateHourCondition";
import { MatchMethod } from "@/models/search/MatchMethod";

@Component({
  components: {
    FilterNodeItem,
    FilterAdTypeField,
    FilterAdMediaField,
    FilterAdInventoryField,
    FilterAdCampaignField,
    FilterAdGroupField,
    FilterLandingPageUrlField,
    FilterLandingPageTitleField,
    FilterDateHourField,
    FilterPeriodField,
    FilterAddConditionButton
  }
})
export default class ChildFilterNodeAd extends Vue {
  adType = FilterNodeConditionType.AdType;
  adMedia = FilterNodeConditionType.AdMedia;
  adInverntory = FilterNodeConditionType.AdInverntory;
  adCampaign = FilterNodeConditionType.AdCampaign;
  adGroup = FilterNodeConditionType.AdGroup;
  landingPageUrl = FilterNodeConditionType.LandingPageUrl;
  landingPageTitle = FilterNodeConditionType.LandingPageTitle;

  dateHour = FilterNodeConditionType.DateHour;
  period = FilterNodeConditionType.Period;

  @Model("input", { type: ChildFilterNodeForAd, required: true })
  condition!: ChildFilterNodeForAd;

  @Prop({ type: Number, required: true })
  depth!: number;

  @Prop({ type: Boolean, required: true })
  isFirstNode!: boolean;

  onInput(condition: ChildFilterNodeForAd) {
    this.$emit("input", condition);
  }

  onRemoveAdditionalCondition(index: number) {
    const additionalConditions = this.condition.additionalConditions.filter(
      (cnd, idx) => idx !== index
    );
    this.onInput(new ChildFilterNodeForAd(additionalConditions, this.depth));
  }

  onInputItem(type: FilterNodeConditionType, index: number) {
    let condition!:
      | FilterAdTypeCondition
      | FilterAdMediaCondition
      | FilterAdInventoryCondition
      | FilterAdCampaignCondition
      | FilterAdGroupCondition
      | FilterLandingPageUrlCondition
      | FilterLandingPageTitleCondition
      | FilterAdditionalTimingCondition;

    if (type === FilterNodeConditionType.AdType) {
      condition = new FilterAdTypeCondition(
        defaultAdType(
          this.condition.hasAdInventoryCondition,
          this.condition.hasAdGroupCondition
        )
      );
    } else if (type === FilterNodeConditionType.AdMedia) {
      condition = new FilterAdMediaCondition("");
    } else if (type === FilterNodeConditionType.AdInverntory) {
      condition = new FilterAdInventoryCondition("");
    } else if (type === FilterNodeConditionType.AdCampaign) {
      condition = new FilterAdCampaignCondition("");
    } else if (type === FilterNodeConditionType.AdGroup) {
      condition = new FilterAdGroupCondition("");
    } else if (type === FilterNodeConditionType.LandingPageUrl) {
      condition = new FilterLandingPageUrlCondition("", MatchMethod.Partial);
    } else if (type === FilterNodeConditionType.LandingPageTitle) {
      condition = new FilterLandingPageTitleCondition("", MatchMethod.Partial);
    } else if (type === FilterNodeConditionType.FirstTime) {
      condition = FilterFirstTimeCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.DateHour) {
      condition = FilterDateHourCondition.buildDefaultData();
    } else if (type === FilterNodeConditionType.Period) {
      condition = FilterPeriodCondition.buildDefaultData();
    }

    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(new ChildFilterNodeForAd(additionalConditions, this.depth));
  }

  onInputAdditionalCondition(
    condition: ChildFilterNodeForAdCondition,
    index: number
  ) {
    const additionalConditions = this.condition.additionalConditions.map(
      (cnd, idx) => (idx === index ? condition : cnd)
    );
    this.onInput(new ChildFilterNodeForAd(additionalConditions, this.depth));
  }

  onClickAddCondition() {
    const additionalConditions = this.condition.additionalConditions;
    let condition!:
      | FilterAdTypeCondition
      | FilterAdMediaCondition
      | FilterAdInventoryCondition
      | FilterAdCampaignCondition;

    if (!this.condition.hasAdTypeCondition) {
      condition = new FilterAdTypeCondition(
        defaultAdType(
          this.condition.hasAdInventoryCondition,
          this.condition.hasAdGroupCondition
        )
      );
    } else if (!this.condition.hasAdMediaCondition) {
      condition = new FilterAdMediaCondition("");
    } else if (!this.condition.isInverntoryDisabled(null)) {
      condition = new FilterAdInventoryCondition("");
    } else if (!this.condition.hasAdCampaignCondition) {
      condition = new FilterAdCampaignCondition("");
    }
    additionalConditions.push(condition);
    this.onInput(new ChildFilterNodeForAd(additionalConditions, this.depth));
  }
}
</script>
