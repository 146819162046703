import { ApiRes } from "@/api/api-res";
import { UserPvStats } from "@/models/UserPvStats";
import {
  ServiceIdType,
  User,
  getDisplayId,
  isFixedServiceId
} from "@/models/User";
import { VisitOverview } from "@/models/overview/VisitOverview";

export class UserInfo {
  constructor(
    public readonly userId: string,
    public readonly serviceId: string,
    public readonly serviceIdType: ServiceIdType,
    public createdAt: Date,
    public readonly createdAtTimezoneOffset: number,
    public readonly pv: UserPvStats
  ) {}

  public static fromJson(json: ApiRes.UserInfo): UserInfo {
    return new UserInfo(
      json.user_id,
      json.service_id,
      json.service_id_type,
      new Date(json.profile_created_time_sec * 1000),
      json.profile_created_timezone,
      UserPvStats.fromPv(json.pv)
    );
  }

  /**
   * 表示用サービスIDを返す
   */
  get serviceIdForDisplay(): string {
    return getDisplayId(this.serviceIdType, this.serviceId, this.userId);
  }

  get isFixedServiceId(): boolean {
    return isFixedServiceId(this.serviceIdType);
  }

  /**
   * FIXME
   */
  public static fromUser(user: User): UserInfo {
    return new UserInfo(
      user.id,
      user.serviceId,
      user.serviceIdType,
      new Date(),
      new Date().getTimezoneOffset(),
      user.overviews.visit.reduce(
        (pvStats: UserPvStats, overview: VisitOverview) =>
          pvStats.merge(overview.pvStats),
        new UserPvStats(0, 0, 0, 0)
      )
    );
  }
}
